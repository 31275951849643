import { Language } from "@vipscasino/core";
import React, { useState } from "react";
import styles from "./PromotionComboBox.module.scss";
import {
  getPromotionTranslation,
  Promotion,
  searchPromotions,
} from "./promotionService";

interface Props {
  onSelectedPromotion: (promotion: Promotion) => void;
  preferredLang?: Language;
  excludedIds?: string[];
}

const resultsLimit = 10;

const PromotionComboBox: React.FC<Props> = (props) => {
  const [results, setResults] = useState<Promotion[] | undefined>();
  const [query, setQuery] = useState(``);

  function getTitle(promotion: Promotion): string {
    return getPromotionTranslation(promotion, props.preferredLang)?.title ?? ``;
  }

  return (
    <div className={styles.main}>
      <input
        type="search"
        className={styles.input}
        value={query}
        placeholder="Type to add promotion"
        onChange={(event) => {
          const q = event.currentTarget.value;
          setQuery(q);
          setResults(searchPromotions(q, resultsLimit, props.excludedIds));
        }}
      />
      {results && results.length > 0 && (
        <div className={styles.results}>
          {results.map((promotion, i) => {
            return (
              <div
                key={i}
                className={styles.result}
                onClick={() => {
                  setQuery(``);
                  setResults(undefined);
                  props.onSelectedPromotion(promotion);
                }}
              >
                <div className={styles.text}>{`${promotion.id} - ${
                  promotion.path
                } - ${getTitle(promotion)}`}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PromotionComboBox;
