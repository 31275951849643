import { AppState } from "../../AppState";

export function defaultHeaders(state: AppState): HeadersInit {
  return {
    "Content-Type": `application/json`,
    authorization: state.user
      ? state.user.googleLoginResponse.getAuthResponse().id_token
      : ``,
  };
}
