import { markdownToHtml } from "@vipscasino/markdown";
import React, { useEffect, useState } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  source?: string;
}
const Markdown: React.FC<Props> = (props) => {
  const [html, setHtml] = useState(``);

  useEffect(() => {
    if (props.source) {
      setHtml(markdownToHtml(props.source));
    }
  }, [props.source]);

  return (
    <div className={props.className}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default Markdown;
