import { Image as FirestoreImage, imageCollection } from "@vipscasino/core";
import firebase from "firebase/app";
import { firebaseApp } from "../lib/firebase";
import * as index from "./imageIndex";

const db = firebaseApp.firestore();

export interface Image extends FirestoreImage<firebase.firestore.Timestamp> {
  id: string;
}

const images = new Map<string, Image>();
let cancelSubscription: () => void;

export function loadImages(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collection(imageCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        const image = { ...data, id: doc.id } as Image;
        if (change.type === `removed`) {
          images.delete(image.id);
        } else {
          images.set(image.id, image);
        }
      });
      indexImages();
      window.dispatchEvent(new Event(`images-loaded`));
    });
}

function indexImages(): void {
  const imageDocs = Array.from(images.values()).map((image) => {
    const doc: index.ImageDoc = {
      id: image.id,
      name: image.name,
    };
    return doc;
  });

  index.indexImages(imageDocs);
}

export function getImage(id: string): Image | undefined {
  return images.get(id);
}

export function getImages(): Image[] {
  return Array.from(images.values());
}

export function searchImages(
  query: string,
  limit?: number,
  excludedIds?: string[],
): Image[] {
  let results = index.searchImages(query);
  if (!results || results.length === 0) {
    return [];
  }
  if (excludedIds) {
    results = results.filter((result) => !excludedIds.includes(result.ref));
  }
  if (limit) {
    results = results.splice(0, limit);
  }

  return results
    .map((result) => {
      const imageId = result.ref;
      const image = images.get(imageId);
      if (image) {
        return image;
      }
      return undefined;
    })
    .filter((image) => image !== undefined) as Image[];
}
