import React from "react";
import styles from "./TranslationsItem.module.scss";

interface Props {
  label: string;
  invalid?: boolean;
}

const TranslationsItem: React.FC<Props> = (props) => {
  return (
    <div className={props.invalid ? styles.warning : ``}>
      <div className={styles.label}>{props.label}</div>
      {props.children}
    </div>
  );
};

export default TranslationsItem;
