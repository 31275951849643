import React, { useEffect, useReducer } from "react";
import AppContext from "./AppContext";
import { initialState, reducer } from "./AppState";
import Home from "./home/Home";
import Login from "./login/Login";

const safetyMargin = 60 * 1000;

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!state.user) {
      return;
    }

    const interval =
      state.user.googleLoginResponse.getAuthResponse().expires_at -
      Date.now() -
      safetyMargin;

    // refresh id_token
    const intervalRef = setInterval(async () => {
      if (!state.user) {
        return;
      }
      await state.user.googleLoginResponse.reloadAuthResponse();
    }, interval);

    return () => clearInterval(intervalRef);
  }, [state.user]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {state.user ? <Home /> : <Login />}
    </AppContext.Provider>
  );
};

export default App;
