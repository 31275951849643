import { countries, Country, Language, languages } from "@vipscasino/core";
import React from "react";
import Flag from "../component/Flag";
import styles from "./Translations.module.scss";

interface Props {
  byCountry?: boolean;
  selectedCountry?: Country;
  existingCountries?: Country[];
  validCountries?: Country[];
  onSelectedCountry?: (country: Country) => void;
  selectedLang?: Language;
  existingLangs: Language[];
  validLangs?: Language[];
  onSelectedLang: (lang: Language) => void;
}

const Translations: React.FC<Props> = (props) => {
  function countryComp(country: Country, key: number): JSX.Element {
    const icon = <Flag country={country} />;
    if (icon) {
      let className = ``;
      if (props.selectedCountry === country) {
        className = styles.selected;
      } else if (!props.existingCountries?.includes(country)) {
        className = styles.empty;
      }
      if (props.validCountries && !props.validCountries.includes(country)) {
        className = `${className} ${styles.invalid}`;
      }
      return (
        <button
          key={key}
          onClick={() => props.onSelectedCountry?.(country)}
          className={className}
        >
          {icon}
        </button>
      );
    }
    return <></>;
  }

  function languageComp(lang: Language, key: number): JSX.Element {
    let className = ``;
    if (props.selectedLang && lang === props.selectedLang) {
      className = styles.selected;
    } else if (!props.existingLangs.includes(lang)) {
      className = styles.empty;
    }
    if (props.validLangs && !props.validLangs.includes(lang)) {
      className = `${className} ${styles.invalid}`;
    }
    return (
      <button
        key={key}
        onClick={() => props.onSelectedLang(lang)}
        className={className}
      >
        <div>{lang}</div>
      </button>
    );
  }

  return (
    <div className={styles.main}>
      {props.byCountry && (
        <div className={styles.countries}>
          {countries.map((country, i) => {
            return countryComp(country, i);
          })}
        </div>
      )}
      {(!props.byCountry || props.selectedCountry) && (
        <div className={styles.languages}>
          {languages.map((lang, i) => {
            return languageComp(lang, i);
          })}
        </div>
      )}
      <div className={styles.children}>{props.children}</div>
    </div>
  );
};

export default Translations;
