import { imagePlatforms, ImageUpdateRequest } from "@vipscasino/core";
import React, { useContext, useEffect, useReducer, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import AppContext from "../AppContext";
import ActionRow from "../component/ActionRow";
import ActionRowItem from "../component/ActionRowItem";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarContext from "../component/ActionToolbarContext";
import ActionToolbarItem from "../component/ActionToolbarItem";
import {
  ActionToolbarActionType,
  actionToolbarReducer,
  initialActionToolbarState,
} from "../component/ActionToolbarState";
import HorizontalScroll from "../component/HorizontalScroll";
import Icon from "../component/Icon";
import Loader from "../component/Loader";
import SortableTable from "../component/SortableTable";
import { config } from "../config";
import { compare, defaultSortOrder, Sort } from "../lib/sort";
import { getImagesFilter, getImagesSort, setImagesSort } from "../lib/storage";
import { getReadableFileSize } from "../lib/util/fileUtil";
import { defaultHeaders } from "../lib/util/httpUtil";
import styles from "./Images.module.scss";
import { getImages, Image } from "./imageService";
import ImagesFilterComp from "./ImagesFilter";
import { filterImages, filterIsEmpty, ImagesFilter } from "./imagesFilter";

const path = `images`;
const extensionRegex = `.+\\.([a-z]+)[^\\.]*`;

const updates: Map<string, ImageUpdateRequest> = new Map();

enum SortField {
  Name = `Name`,
  Type = `Type`,
  Platform = `Platform`,
  FileType = `FileType`,
  Size = `Size`,
  Dimensions = `Dimensions`,
  Created = `Created`,
  Modified = `Modified`,
}

const defaultSortField = SortField.Modified;

const Images: React.FC = () => {
  const { state } = useContext(AppContext);
  const [actionToolbarState, actionToolbarDispatch] = useReducer(
    actionToolbarReducer,
    initialActionToolbarState,
  );
  const [images, setImages] = useState<Image[] | undefined>();
  const [filteredImages, setFilteredImages] = useState<Image[]>([]);
  const [filter, setFilter] = useState<ImagesFilter>(getImagesFilter() ?? {});
  const [loading, setLoading] = useState(true);
  const [showIds, setShowIds] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [sort, setSort] = useState<Sort>(
    getImagesSort() ?? {
      field: defaultSortField,
      order: defaultSortOrder,
    },
  );

  // set images when loaded/updated
  useEffect(() => {
    if (state.imagesLoaded) {
      setImages(getImages());
    }
  }, [state.imagesLoaded]);

  // filter images
  useEffect(() => {
    if (images) {
      setFilteredImages(filterImages(filter, images));
      setLoading(false);
    }
  }, [filter, images]);

  if (saving) {
    return <Loader message="Saving ..." />;
  }

  if (loading) {
    return <Loader message="Loading images ..." />;
  }

  async function remove(id: string): Promise<void> {
    if (window.confirm(`Are you sure you want to remove this image?`)) {
      const url = `${config.api}/images/${id}/`;
      const res = await fetch(url, {
        method: `DELETE`,
        headers: defaultHeaders(state),
      });
      if (images && res.status === 200) {
        setImages(images.filter((b) => b.id !== id));
      }
    }
  }

  async function update(): Promise<void> {
    setSaving(true);
    setEditMode(false);

    for (const [id, data] of Array.from(updates.entries())) {
      const res = await fetch(`${config.api}/images/${id}`, {
        method: `PUT`,
        headers: defaultHeaders(state),
        body: JSON.stringify(data),
      });
      console.info(await res.text());
    }

    updates.clear();
    setSaving(false);
  }

  function isSortField(sortField: string): sortField is SortField {
    return Object.values(SortField).includes(sortField as SortField);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function sortFieldValues(field: string, i1: Image, i2: Image): any[] {
    switch (field) {
      case SortField.Name:
        return [i1.name.toLowerCase(), i2.name.toLowerCase()];
      case SortField.Type:
        return [i1.type, i2.type];
      case SortField.Platform:
        return [i1.platform, i2.platform];
      case SortField.FileType:
        return [getImageFileType(i1), getImageFileType(i2)];
      case SortField.Size:
        return [i1.size, i2.size];
      case SortField.Dimensions:
        return [i1.width + 0.01 * i1.height, i2.width + 0.01 * i2.height];
      case SortField.Created:
        return [i1.created, i2.created];
      case SortField.Modified:
        return [i1.modified.date, i2.modified.date];
      default:
        throw new Error(`Unsupported sort field: ` + field);
    }
  }

  function getSortedImages(): Image[] {
    const sortField = isSortField(sort.field) ? sort.field : defaultSortField;
    return filteredImages.sort((i1, i2) => {
      const [val1, val2] = sortFieldValues(sortField, i1, i2);
      return compare(val1, val2, sort.order);
    });
  }

  function onSort(sort: Sort): void {
    setImagesSort(sort);
    setSort(sort);
  }

  return (
    <div className={styles.main}>
      <ActionToolbarContext.Provider
        value={{ actionToolbarState, actionToolbarDispatch }}
      >
        <ActionToolbar name="Images">
          {editMode ? (
            <>
              <ActionToolbarItem
                type="button"
                icon={<Icon name="cancel" />}
                name="Cancel"
                onClick={() => {
                  setEditMode(false);
                  updates.clear();
                }}
              />
              <ActionToolbarItem
                type="button"
                icon={<Icon name="save" />}
                name="Save"
                onClick={() => update()}
              />
            </>
          ) : (
            <>
              <ActionToolbarItem
                type="link"
                icon={<Icon name="add" />}
                name="Add"
                to={`/${path}/add`}
              />
              <ActionToolbarItem
                type="link"
                icon={<Icon name="add" />}
                name="Multi Add"
                to={`/${path}/add/multi`}
              />
              <ActionToolbarItem
                type="button"
                icon={<Icon name="edit" />}
                name="Multi Edit"
                onClick={() => setEditMode(true)}
              />
              <ActionToolbarItem
                type="button"
                icon={<Icon name="filter" />}
                name="Filter"
                active={filterActive}
                notification={!filterIsEmpty(filter)}
                onClick={() => {
                  setFilterActive((value) => !value);
                  actionToolbarDispatch({
                    type: ActionToolbarActionType.ShowExpanded,
                    component: actionToolbarState.expanded ? undefined : (
                      <ImagesFilterComp
                        filter={filter}
                        onChange={(filter: ImagesFilter) => setFilter(filter)}
                      />
                    ),
                  });
                }}
              />
            </>
          )}
        </ActionToolbar>
      </ActionToolbarContext.Provider>

      <HorizontalScroll>
        <SortableTable
          sort={sort}
          onSort={onSort}
          columns={[
            { name: `Actions` },
            {
              name: `ID`,
              onShowToggle: () => setShowIds((value) => !value),
              show: showIds,
            },
            { name: `Preview` },
            { name: `Name`, sortField: editMode ? undefined : SortField.Name },
            {
              name: `Type`,
              sortField: editMode ? undefined : SortField.Type,
            },
            {
              name: `Platform`,
              sortField: editMode ? undefined : SortField.Platform,
            },
            {
              name: `File type`,
              sortField: editMode ? undefined : SortField.FileType,
            },
            { name: `Size`, sortField: editMode ? undefined : SortField.Size },
            {
              name: `Dimensions`,
              sortField: editMode ? undefined : SortField.Dimensions,
            },
            {
              name: `Created`,
              sortField: editMode ? undefined : SortField.Created,
            },
            {
              name: `Modified`,
              sortField: editMode ? undefined : SortField.Modified,
            },
          ].splice(editMode ? 1 : 0)}
        >
          {getSortedImages().map((image, i) => {
            return (
              <ImageComp
                key={i}
                image={image}
                editMode={editMode}
                onRemove={remove}
                showIds={showIds}
                onUpdate={(id, field) => {
                  let dto = updates.get(id);
                  if (!dto) {
                    dto = {};
                  }
                  dto = { ...dto, ...field };
                  updates.set(id, dto);
                }}
              />
            );
          })}
        </SortableTable>
      </HorizontalScroll>
    </div>
  );
};

export default Images;

function getImageFileType(image: Image): string | undefined {
  const match = image.urls.default.match(extensionRegex);
  return match ? match[1] : undefined;
}

interface ImageProps {
  image: Image;
  editMode: boolean;
  showIds: boolean;
  onRemove: (id: string) => Promise<void>;
  onUpdate: (id: string, field: ImageUpdateRequest) => void;
}

const ImageComp: React.FC<ImageProps> = (props) => {
  const [removing, setRemoving] = useState(false);

  const id = props.image.id;
  const image = props.image;

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  async function remove(): Promise<void> {
    setRemoving(true);
    await props.onRemove(id);
    setRemoving(false);
  }

  function getFileType(): string {
    const fileType = getImageFileType(image);
    return fileType ? fileType : `unknown`;
  }

  const created = image.created.toDate();
  const modified = image.modified.date.toDate();
  const editStyle: React.CSSProperties = props.editMode
    ? { backgroundColor: `white` }
    : {};

  return (
    <tr>
      {!props.editMode && (
        <td>
          <ActionRow>
            <ActionRowItem iconName="edit" to={`/${path}/${id}`} title="Edit" />
            <ActionRowItem
              iconName="copy"
              to={`/${path}/${id}/clone`}
              title="Clone"
            />
            <ActionRowItem
              iconName="delete"
              onClick={() => (removing ? null : remove())}
              title="Delete"
            />
          </ActionRow>
        </td>
      )}
      <td className={styles.id}>{props.showIds && id}</td>
      <td>
        <img src={image.urls.thumb} alt="Thumb" />
      </td>
      <td style={editStyle}>
        {props.editMode ? (
          <input
            className={styles.requiredTextInput}
            required
            type="text"
            defaultValue={image.name}
            onChange={(event) => {
              const name = event.currentTarget.value;
              if (name && name.trim().length > 0) {
                props.onUpdate(id, { name: name });
              }
            }}
          />
        ) : (
          image.name
        )}
      </td>
      <td>{image.type}</td>
      <td>{imagePlatforms.get(image.platform)}</td>
      <td>{getFileType()}</td>
      <td>{getReadableFileSize(image.size)}</td>
      <td>{`${image.width}x${image.height}`}</td>
      <td title={dateTimeString(created)} style={{ whiteSpace: `nowrap` }}>
        {created.toLocaleDateString(`sv`)}
      </td>
      <td title={dateTimeString(modified)} style={{ whiteSpace: `nowrap` }}>
        {modified.toLocaleDateString(`sv`)}
      </td>
    </tr>
  );
};
