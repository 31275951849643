import { Language } from "@vipscasino/core";
import React, { useState } from "react";
import styles from "./TournamentComboBox.module.scss";
import {
  getTournamentTranslation,
  searchTournaments,
  Tournament,
} from "./tournamentService";

interface Props {
  onSelectedTournament: (tournament: Tournament) => void;
  preferredLang?: Language;
  excludedIds?: string[];
}

const resultsLimit = 10;

const TournamentComboBox: React.FC<Props> = (props) => {
  const [results, setResults] = useState<Tournament[] | undefined>();
  const [query, setQuery] = useState(``);

  function getTitle(tournament: Tournament): string {
    return (
      getTournamentTranslation(tournament, props.preferredLang)?.title ?? ``
    );
  }

  return (
    <div className={styles.main}>
      <input
        type="search"
        className={styles.input}
        value={query}
        placeholder="Type to add tournament"
        onChange={(event) => {
          const q = event.currentTarget.value;
          setQuery(q);
          setResults(searchTournaments(q, resultsLimit, props.excludedIds));
        }}
      />
      {results && results.length > 0 && (
        <div className={styles.results}>
          {results.map((tournament, i) => {
            return (
              <div
                key={i}
                className={styles.result}
                onClick={() => {
                  setQuery(``);
                  setResults(undefined);
                  props.onSelectedTournament(tournament);
                }}
              >
                <div className={styles.text}>{`${tournament.id} - ${
                  tournament.path
                } - ${getTitle(tournament)}`}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TournamentComboBox;
