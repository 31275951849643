import React, { useState } from "react";
import styles from "./GameTagComboBox.module.scss";
import { GameTag, searchGameTags } from "./gameTagService";

interface Props {
  onSelectedTag: (tag: GameTag) => void;
  excludedIds?: string[];
}

const resultsLimit = 10;

const GameTagComboBox: React.FC<Props> = (props) => {
  const [results, setResults] = useState<GameTag[] | undefined>();
  const [query, setQuery] = useState(``);

  return (
    <div className={styles.main}>
      <input
        type="search"
        className={styles.input}
        value={query}
        placeholder="Type to add tag"
        onChange={(event) => {
          const q = event.currentTarget.value;
          setQuery(q);
          setResults(searchGameTags(q, resultsLimit, props.excludedIds));
        }}
      />
      {results && results.length > 0 && (
        <div className={styles.results}>
          {results.map((tag, i) => {
            return (
              <div
                key={i}
                className={styles.result}
                onClick={() => {
                  setQuery(``);
                  setResults(undefined);
                  props.onSelectedTag(tag);
                }}
              >
                <div className={styles.text}>
                  <HighlightText text={tag.key} hightlight={query} /> -{` `}
                  <span>{tag.type}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GameTagComboBox;

const HighlightText: React.FC<{ text: string; hightlight: string }> = (
  props,
) => {
  const regExp = new RegExp(`(${props.hightlight})`, `gi`);
  const parts = props.text.split(regExp);

  return (
    <>
      {parts.map((part, i) => (
        <span
          key={i}
          className={`${
            part.toLowerCase() === props.hightlight.toLowerCase() &&
            styles.highlight
          }`}
        >
          {part}
        </span>
      ))}
    </>
  );
};
