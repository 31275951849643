import {
  brands,
  countries,
  Country,
  defaultCountry,
  defaultLanguage,
  getTranslatedCountries,
  getTranslatedLanguages,
  isBrand,
  isPromotionOrTournamentPriority,
  Language,
  languages,
  promotionOrTournamentPriorities,
  PromotionOrTournamentPriority,
  tournamentCollection,
  TournamentCreateRequest,
  TournamentTranslation,
  TournamentTranslations,
  TournamentTranslationsUpdateRequest,
  TournamentTranslationUpdate,
  TournamentUpdateRequest,
  Translations,
} from "@vipscasino/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router";
import AppContext from "../AppContext";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarItem from "../component/ActionToolbarItem";
import CustomDatePicker from "../component/CustomDatePicker";
import Icon from "../component/Icon";
import KeyValueGrid from "../component/KeyValueGrid";
import KeyValueGridItem from "../component/KeyValueGridItem";
import Loader from "../component/Loader";
import MarkdownEditor from "../component/MarkdownEditor";
import { config } from "../config";
import GamePicker from "../game/GamePicker";
import { Game, getGame } from "../game/gameService";
import ImagePicker from "../image/ImagePicker";
import { firebaseApp } from "../lib/firebase";
import { validateTextByCountry } from "../lib/util/countryUtil";
import { defaultHeaders } from "../lib/util/httpUtil";
import {
  TournamentPreview,
  TournamentPreviewProps,
} from "../promotion/PromotionOrTournamentPreview";
import TranslationsComp from "../translation/Translations";
import TranslationsItem from "../translation/TranslationsItem";
import styles from "./TournamentEdit.module.scss";
import { pathExists, Tournament } from "./tournamentService";

const db = firebaseApp.firestore();
const defaultPriority: PromotionOrTournamentPriority = `5`;

interface Params {
  id?: string;
  clone?: string;
}

function translationIsNonEmpty(t: TournamentTranslation): boolean {
  if (
    t.title.trim().length > 0 ||
    t.description.trim().length > 0 ||
    t.termsAndConditions.trim().length > 0 ||
    (t.optInButtonText?.trim().length ?? 0) > 0
  ) {
    return true;
  }

  return false;
}

function isValidTranslation(t: TournamentTranslation): boolean {
  let emptyCount = 0;
  if (t.title.trim().length === 0) {
    emptyCount += 1;
  }
  if (t.description.trim().length === 0) {
    emptyCount += 1;
  }
  if (t.termsAndConditions.trim().length === 0) {
    emptyCount += 1;
  }
  if (
    (emptyCount > 0 && emptyCount < 3) ||
    (emptyCount === 3 && (t.optInButtonText?.trim().length ?? 0) > 0)
  ) {
    return false;
  }
  return true;
}

function getFirstTranslatedLanguage(
  translations: Map<Country, Map<Language, TournamentTranslation>>,
  country: Country,
): Language | undefined {
  const ct = translations.get(country);
  if (!ct) {
    return undefined;
  }
  for (const [lang, t] of Array.from(ct.entries())) {
    if (translationIsNonEmpty(t)) {
      return lang;
    }
  }
  return undefined;
}

const TournamentEdit: React.FC = () => {
  const history = useHistory();
  const params = useParams<Params>();
  const id = params.id;
  const clone = params.clone;
  const { state } = useContext(AppContext);
  const [tournament, setTournament] = useState<Tournament | undefined>();
  const [updateRequest, setUpdateRequest] = useState<TournamentUpdateRequest>(
    {},
  );
  const [tournamentLoading, setTournamentLoading] = useState<boolean>(!!id);
  const [saving, setSaving] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>();
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [translations, setTranslations] = useState<
    Map<Country, Map<Language, TournamentTranslation>>
  >(new Map());
  const [showPreview, setShowPreview] = useState(false);
  const [desktopPreview, setDesktopPreview] = useState(true);
  const [desktopImageUpdate, setDesktopImageUpdate] = useState<
    string | undefined
  >();
  const [mobileImageUpdate, setMobileImageUpdate] = useState<
    string | undefined
  >();
  const pathRef = useRef<HTMLInputElement>(null);

  const loading =
    tournamentLoading || !state.gamesLoaded || !state.imagesLoaded;

  // validate form
  useEffect(() => {
    setValid(isValid(tournament, updateRequest, translations, clone));
  }, [tournament, updateRequest, translations, clone]);

  function isValid(
    tournament: Tournament | undefined,
    ur: TournamentUpdateRequest,
    translations: Map<Country, Map<Language, TournamentTranslation>>,
    clone: string | undefined,
  ): boolean {
    for (const ct of Array.from(translations.values())) {
      for (const t of Array.from(ct.values())) {
        if (!isValidTranslation(t)) {
          return false;
        }
      }
    }

    if (tournament && !clone) {
      if (ur.path !== undefined) {
        if (
          ur.path.trim().length === 0 ||
          pathExists(ur.path, ur.brand ?? tournament.brand, tournament)
        ) {
          return false;
        }
      }
      const aFrom = ur.activeFrom ?? tournament.activeFrom.toMillis();
      const aTo = ur.activeTo ?? tournament.activeTo.toMillis();
      const pFrom = ur.publishedFrom ?? tournament.publishedFrom.toMillis();
      const pTo = ur.publishedTo ?? tournament.publishedTo.toMillis();
      if (aFrom >= aTo) {
        return false;
      } else if (pFrom >= pTo) {
        return false;
      } else {
        return pFrom <= aFrom && pTo >= aTo;
      }
    }

    if (!ur.brand) {
      return false;
    }
    if (!ur.desktopImage || ur.desktopImage.trim().length === 0) {
      return false;
    }
    if (!ur.mobileImage || ur.mobileImage.trim().length === 0) {
      return false;
    }
    if (!ur.activeFrom || !ur.activeTo || ur.activeFrom >= ur.activeTo) {
      return false;
    }
    if (
      !ur.publishedFrom ||
      !ur.publishedTo ||
      ur.publishedFrom >= ur.publishedTo
    ) {
      return false;
    }
    if (ur.publishedFrom > ur.activeFrom || ur.publishedTo < ur.activeTo) {
      return false;
    }
    if (
      !ur.path ||
      ur.path.trim().length === 0 ||
      pathExists(ur.path, ur.brand)
    ) {
      return false;
    }
    return true;
  }

  // validate path
  useEffect(() => {
    if (pathRef.current) {
      const brand = updateRequest.brand ?? tournament?.brand;
      if (
        brand &&
        pathExists(
          pathRef.current.value,
          brand,
          !clone ? tournament : undefined,
        )
      ) {
        pathRef.current.setCustomValidity(`Path already exists`);
      } else {
        pathRef.current.setCustomValidity(``);
      }
    }
  }, [tournament, clone, updateRequest, loading]);

  // init create request
  useEffect(() => {
    if (!id) {
      setUpdateRequest({
        priority: defaultPriority,
      });
    }
  }, [id]);

  // load tournament
  useEffect(() => {
    if (id) {
      setTournamentLoading(true);
      db.collection(tournamentCollection)
        .doc(id)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            throw Error(`Tournament with id ${id} does not exist`);
          }

          const tournament = {
            ...snapshot.data(),
            id: snapshot.id,
          } as Tournament;
          setTournament(tournament);

          const countryTranslations = new Map<
            Country,
            Map<Language, TournamentTranslation>
          >();
          const tt = tournament.translations ?? {};
          for (const country of getTranslatedCountries(tt)) {
            const translations = new Map<Language, TournamentTranslation>();
            countryTranslations.set(country, translations);
            for (const lang of getTranslatedLanguages(tt[country]!)) {
              translations.set(lang, tt[country]![lang]!);
            }
          }
          setTranslations(countryTranslations);
          if (countryTranslations.size > 0) {
            const selectedCountry = Array.from(countryTranslations.keys())[0];
            setSelectedCountry(selectedCountry);
            setSelectedLang(
              getFirstTranslatedLanguage(countryTranslations, selectedCountry),
            );
          }

          if (clone) {
            setUpdateRequest({
              brand: tournament.brand,
              path: tournament.path,
              aspireTableName: tournament.aspireTableName,
              priority: tournament.priority ?? defaultPriority,
              games: tournament.games.map((g) => g.id),
              desktopImage: tournament.image.desktop.ref.id,
              mobileImage: tournament.image.mobile.ref.id,
              activeFrom: tournament.activeFrom.toMillis(),
              activeTo: tournament.activeTo.toMillis(),
              publishedFrom: tournament.publishedFrom.toMillis(),
              publishedTo: tournament.publishedTo.toMillis(),
            });
          }

          setTournamentLoading(false);
        });
    }
  }, [id, clone]);

  if (saving) {
    return <Loader message="Saving tournament ..." />;
  }

  if (loading) {
    return <Loader message="Loading tournament ..." />;
  }

  const selectedTrans =
    selectedCountry && selectedLang
      ? translations.get(selectedCountry)?.get(selectedLang)
      : undefined;

  const previewAvailable =
    valid && selectedTrans && translationIsNonEmpty(selectedTrans);

  async function save(): Promise<void> {
    setSaving(true);

    const update = id && !clone;
    const data = update ? getUpdateRequest() : getCreateRequest();
    const url = `${config.api}/tournaments/${update ? `${id}/` : ``}`;
    const res = await fetch(url, {
      method: `${update ? `PUT` : `POST`}`,
      headers: defaultHeaders(state),
      body: JSON.stringify(data),
    });
    console.info(await res.text());

    setSaving(false);
    history.goBack();
  }

  function getUpdateRequest(): TournamentUpdateRequest {
    const tur = getTranslationsUpdateRequest();
    if (Object.keys(tur).length === 0) {
      // no translation changes
      return updateRequest;
    }

    return {
      ...updateRequest,
      ...{
        translations: tur,
      },
    };
  }

  function getTranslationsUpdateRequest(): TournamentTranslationsUpdateRequest {
    const tur: TournamentTranslationsUpdateRequest = {};

    for (const [country, tMap] of Array.from(translations.entries())) {
      tur[country] = {};
      for (const [lang, t] of Array.from(tMap.entries())) {
        const tu = getTranslationUpdate(country, lang, t);
        if (!tu) {
          continue;
        }

        if (translationIsNonEmpty(t)) {
          tur[country]![lang] = tu;
        } else if (
          tournament?.translations[country] &&
          tournament.translations[country]![lang]
        ) {
          tur[country]![lang] = `removed`;
        }
      }
      if (Object.keys(tur[country]!).length === 0) {
        delete tur[country];
      }
    }

    return tur;
  }

  function getTranslationUpdate(
    country: Country,
    lang: Language,
    t: TournamentTranslation,
  ): TournamentTranslationUpdate | undefined {
    const tNew = getTrimmedTranslation(t);

    if (
      tournament?.translations[country] &&
      tournament.translations[country]![lang]
    ) {
      let tu: TournamentTranslationUpdate = {};
      const tOld = tournament.translations[country]![lang]!;
      if (tNew.title !== tOld.title) {
        tu = { ...tu, title: tNew.title };
      }
      if (tNew.description !== tOld.description) {
        tu = { ...tu, description: tNew.description };
      }
      if (tNew.termsAndConditions !== tOld.termsAndConditions) {
        tu = { ...tu, termsAndConditions: tNew.termsAndConditions };
      }
      if (
        (tNew.optInButtonText || tOld.optInButtonText) &&
        tNew.optInButtonText !== tOld.optInButtonText
      ) {
        tu = { ...tu, optInButtonText: tNew.optInButtonText };
      }
      return Object.keys(tu).length > 0 ? tu : undefined;
    }

    return translationIsNonEmpty(tNew) ? { ...tNew } : undefined;
  }

  function getTrimmedTranslation(
    t: TournamentTranslation,
  ): TournamentTranslation {
    return t.optInButtonText
      ? { ...t, optInButtonText: t.optInButtonText.trim() }
      : t;
  }

  function getCreateRequest(): TournamentCreateRequest {
    const tt: TournamentTranslations = {};
    for (const [country, tMap] of Array.from(translations.entries())) {
      const ct: Translations<TournamentTranslation> = {};
      for (const [lang, t] of Array.from(tMap.entries())) {
        if (t.title.trim().length > 0) {
          ct[lang] = getTrimmedTranslation(t);
        }
      }
      if (Object.keys(ct).length > 0) {
        tt[country] = ct;
      }
    }

    return {
      brand: updateRequest.brand!,
      path: updateRequest.path!,
      aspireTableName: updateRequest.aspireTableName ?? ``,
      priority: updateRequest.priority,
      games: updateRequest.games ?? [],
      translations: tt,
      activeFrom: updateRequest.activeFrom!,
      activeTo: updateRequest.activeTo!,
      publishedFrom: updateRequest.publishedFrom!,
      publishedTo: updateRequest.publishedTo!,
      desktopImage: updateRequest.desktopImage!,
      mobileImage: updateRequest.mobileImage!,
    };
  }

  function cancel(): void {
    history.goBack();
  }

  function onUpdate(field: TournamentUpdateRequest): void {
    setUpdateRequest({ ...updateRequest, ...field });
  }

  function emptyTranslation(): TournamentTranslation {
    return {
      title: ``,
      description: ``,
      termsAndConditions: ``,
      optInButtonText: ``,
    };
  }

  function onTranslationUpdate(field: TournamentTranslationUpdate): void {
    if (!selectedCountry || !selectedLang) {
      return;
    }

    const translationsCopy: Map<
      Country,
      Map<Language, TournamentTranslation>
    > = new Map(translations);

    const selectedTranslations: Map<Language, TournamentTranslation> =
      translationsCopy.get(selectedCountry) ?? new Map();

    const t = selectedTranslations.get(selectedLang) ?? emptyTranslation();
    selectedTranslations.set(selectedLang, { ...t, ...field });
    translationsCopy.set(selectedCountry, selectedTranslations);
    setTranslations(translationsCopy);
  }

  function existingLangs(country: Country | undefined): Language[] {
    if (!country) {
      return [];
    }

    return languages.filter((lang) => {
      const t = translations.get(country)?.get(lang);
      return !!t && translationIsNonEmpty(t);
    });
  }

  function validLangs(country: Country | undefined): Language[] {
    if (!country) {
      return [];
    }

    return languages.filter((lang) => {
      const t = translations.get(country)?.get(lang);
      return !t || isValidTranslation(t);
    });
  }

  function existingCountries(): Country[] {
    return countries.filter((country) => {
      const t = translations.get(country);
      return !!t && existingLangs(country).length > 0;
    });
  }

  function validCountries(): Country[] {
    return countries.filter((country) => {
      const t = translations.get(country);
      return !t || validLangs(country).length === languages.length;
    });
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  function getGames(): Game[] {
    const games = updateRequest.games
      ? updateRequest.games
      : tournament
      ? tournament.games.map((g) => g.id)
      : [];
    return games
      .map((gameId) => getGame(gameId))
      .filter((game) => game !== undefined) as Game[];
  }

  const activeFromSelected = updateRequest.activeFrom
    ? new Date(updateRequest.activeFrom)
    : tournament
    ? tournament.activeFrom.toDate()
    : null;

  const activeToSelected = updateRequest.activeTo
    ? new Date(updateRequest.activeTo)
    : tournament
    ? tournament.activeTo.toDate()
    : null;

  const publishedFromSelected = updateRequest.publishedFrom
    ? new Date(updateRequest.publishedFrom)
    : tournament
    ? tournament.publishedFrom.toDate()
    : null;

  const publishedToSelected = updateRequest.publishedTo
    ? new Date(updateRequest.publishedTo)
    : tournament
    ? tournament.publishedTo.toDate()
    : null;

  function previewProps(): TournamentPreviewProps {
    let image = desktopPreview ? desktopImageUpdate : mobileImageUpdate;
    if (!image && tournament) {
      image = desktopPreview
        ? tournament.image.desktop.default
        : tournament.image.mobile.default;
    }

    return {
      desktop: desktopPreview,
      image: image,
      language: selectedLang,
      translation: selectedTrans,
      activeFrom: activeFromSelected ?? undefined,
      activeTo: activeToSelected ?? undefined,
      modified: tournament?.modified.date.toDate() ?? new Date(),
      games: (updateRequest.games?.length ?? tournament?.games.length ?? 0) > 0,
      aspireTableName:
        updateRequest.aspireTableName ?? tournament?.aspireTableName,
      onRequestClose: () => setShowPreview(false),
    };
  }

  function getCopyTranslations(): Map<string, TournamentTranslation> {
    const map: Map<string, TournamentTranslation> = new Map();
    if (
      selectedCountry !== defaultCountry ||
      selectedLang !== defaultLanguage
    ) {
      const trans = translations.get(defaultCountry)?.get(defaultLanguage);
      if (trans && translationIsNonEmpty(trans)) {
        map.set(`${defaultCountry}-${defaultLanguage}`, trans);
      }
    }
    if (
      selectedLang &&
      selectedCountry !== defaultCountry &&
      selectedLang !== defaultLanguage
    ) {
      const trans = translations.get(defaultCountry)?.get(selectedLang);
      if (trans && translationIsNonEmpty(trans)) {
        map.set(`${defaultCountry}-${selectedLang}`, trans);
      }
    }
    return map;
  }

  function textValid(text: string | undefined): boolean {
    if (!text || !selectedCountry) {
      return true;
    }
    return validateTextByCountry(text, selectedCountry);
  }

  return (
    <section className={styles.main}>
      <ActionToolbar name={`${tournament ? `Edit` : `Add`} tournament`}>
        <ActionToolbarItem
          type="button"
          icon={<Icon name="cancel" />}
          name="Cancel"
          onClick={() => cancel()}
        />
        <ActionToolbarItem
          type="button"
          icon={<Icon name="save" />}
          name="Save"
          disabled={!valid}
          onClick={() => save()}
        />
      </ActionToolbar>

      {showPreview && <TournamentPreview {...previewProps()} />}

      <KeyValueGrid>
        {id && !clone && (
          <KeyValueGridItem label="ID" editable={false}>
            {id}
          </KeyValueGridItem>
        )}

        {tournament && !clone && (
          <KeyValueGridItem label="Created" editable={false}>
            {dateTimeString(tournament.created.toDate() as Date)}
          </KeyValueGridItem>
        )}

        <KeyValueGridItem label="Brand" editable>
          <select
            value={updateRequest.brand ?? tournament?.brand}
            onChange={(event) => {
              const brand = event.currentTarget.value;
              if (!isBrand(brand)) {
                throw new Error(`invalid brand: ` + brand);
              }
              onUpdate({ brand: brand });
            }}
          >
            {!tournament?.brand && !updateRequest.brand && <option value="" />}
            {brands.map((brand, i) => (
              <option key={i} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label="Path" editable>
          <input
            type="text"
            required
            ref={pathRef}
            placeholder="Add a path"
            defaultValue={tournament ? tournament.path : ``}
            onChange={(event) => {
              onUpdate({ path: event.currentTarget.value });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Aspire leader board" editable>
          <input
            type="text"
            placeholder="Add a leader board"
            defaultValue={tournament ? tournament.aspireTableName : ``}
            onChange={(event) => {
              onUpdate({ aspireTableName: event.currentTarget.value });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Priority" editable>
          <select
            value={updateRequest.priority ?? tournament?.priority}
            onChange={(event) => {
              const priority = event.currentTarget.value;
              if (!isPromotionOrTournamentPriority(priority)) {
                throw new Error(`invalid tournament priority: ` + priority);
              }
              onUpdate({ priority: priority });
            }}
          >
            {!tournament?.priority && !updateRequest.priority && (
              <option value="" />
            )}
            {promotionOrTournamentPriorities.map((priority, i) => (
              <option key={i} value={priority}>
                {priority}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label="Active From" editable>
          <CustomDatePicker
            selected={activeFromSelected}
            placeholder="Add a valid from date"
            onChange={(date) => {
              onUpdate({ activeFrom: date ? date.getTime() : undefined });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Active to" editable>
          <CustomDatePicker
            selected={activeToSelected}
            placeholder="Add a valid to date"
            onChange={(date) => {
              onUpdate({ activeTo: date ? date.getTime() : undefined });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Published From" editable>
          <CustomDatePicker
            selected={publishedFromSelected}
            placeholder="Add a valid from date"
            onChange={(date) => {
              onUpdate({ publishedFrom: date ? date.getTime() : undefined });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Published to" editable>
          <CustomDatePicker
            selected={publishedToSelected}
            placeholder="Add a valid to date"
            onChange={(date) => {
              onUpdate({ publishedTo: date ? date.getTime() : undefined });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Games" editable>
          <GamePicker
            games={getGames()}
            onSelectedGames={(games) => {
              onUpdate({ games: games.map((game) => game.id) });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Translations" editable noPadding>
          <TranslationsComp
            byCountry={true}
            selectedCountry={selectedCountry}
            existingCountries={existingCountries()}
            validCountries={validCountries()}
            onSelectedCountry={(country) => {
              if (country !== selectedCountry) {
                setSelectedCountry(country);
                setSelectedLang(
                  getFirstTranslatedLanguage(translations, country),
                );
              }
            }}
            selectedLang={selectedLang}
            existingLangs={existingLangs(selectedCountry)}
            validLangs={validLangs(selectedCountry)}
            onSelectedLang={(lang) => setSelectedLang(lang)}
          >
            {selectedCountry && selectedLang && (
              <div className={styles.translationActions}>
                <button
                  className={styles.translationAction}
                  onClick={() => onTranslationUpdate(emptyTranslation())}
                >
                  <Icon name="close" />
                  <p>Clear</p>
                </button>
                {Array.from(getCopyTranslations().entries()).map(
                  ([lang, trans], i) => (
                    <button
                      key={i}
                      className={styles.translationAction}
                      onClick={() => onTranslationUpdate(trans)}
                    >
                      <Icon name="copy" />
                      <p>{`Copy from ${lang}`}</p>
                    </button>
                  ),
                )}
              </div>
            )}

            <div
              className={
                previewAvailable ? styles.previews : styles.previewsDisabled
              }
            >
              <div
                className={
                  previewAvailable ? styles.preview : styles.previewDisabled
                }
                onClick={() => {
                  if (previewAvailable) {
                    setShowPreview(true);
                    setDesktopPreview(true);
                  }
                }}
                title="Desktop Preview"
              >
                <Icon name="desktop" className={styles.previewIcon} />
              </div>
              <div
                className={
                  previewAvailable ? styles.preview : styles.previewDisabled
                }
                onClick={() => {
                  if (previewAvailable) {
                    setShowPreview(true);
                    setDesktopPreview(false);
                  }
                }}
                title="Mobile Preview"
              >
                <Icon name="mobile" className={styles.previewIcon} />
              </div>
            </div>

            <TranslationsItem label="Title">
              <input
                type="text"
                disabled={!tournament && !selectedLang}
                placeholder="Add a title"
                value={selectedTrans?.title ?? ``}
                onChange={(event) => {
                  onTranslationUpdate({ title: event.currentTarget.value });
                }}
              />
            </TranslationsItem>

            <TranslationsItem label="Opt-in button text">
              <input
                type="text"
                disabled={!tournament && !selectedLang}
                placeholder="Add an opt-in button text"
                value={selectedTrans?.optInButtonText ?? ``}
                onChange={(event) => {
                  onTranslationUpdate({
                    optInButtonText: event.currentTarget.value,
                  });
                }}
              />
            </TranslationsItem>

            <TranslationsItem
              label="Description"
              invalid={!textValid(selectedTrans?.description)}
            >
              <MarkdownEditor
                text={selectedTrans?.description ?? ``}
                editMode={true}
                editRows={10}
                disabled={!tournament && !selectedLang}
                placeholder="Add a description"
                markdownClass={styles.markdownDesc}
                onChange={(value) => {
                  onTranslationUpdate({ description: value });
                }}
              />
            </TranslationsItem>

            <TranslationsItem
              label="Terms &amp; Conditions"
              invalid={!textValid(selectedTrans?.termsAndConditions)}
            >
              <MarkdownEditor
                text={selectedTrans?.termsAndConditions ?? ``}
                editMode={true}
                disabled={!tournament && !selectedLang}
                placeholder="Add terms and conditions"
                editRows={10}
                markdownClass={styles.markdownTac}
                onChange={(value) => {
                  onTranslationUpdate({ termsAndConditions: value });
                }}
              />
            </TranslationsItem>
          </TranslationsComp>
        </KeyValueGridItem>

        <KeyValueGridItem label="Desktop Image" editable>
          <ImagePicker
            imageUrl={tournament?.image.desktop.default}
            onSelectedImage={(image) => {
              onUpdate({ desktopImage: image.id });
              setDesktopImageUpdate(image.urls.default);
            }}
            filter={(image) =>
              image.type === `Tournament` &&
              (image.platform === `desktop` ||
                image.platform === `desktopAndMobile`)
            }
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Mobile Image" editable>
          <ImagePicker
            imageUrl={tournament?.image.mobile.default}
            onSelectedImage={(image) => {
              onUpdate({ mobileImage: image.id });
              setMobileImageUpdate(image.urls.default);
            }}
            filter={(image) =>
              image.type === `Tournament` &&
              (image.platform === `mobile` ||
                image.platform === `desktopAndMobile`)
            }
          />
        </KeyValueGridItem>
      </KeyValueGrid>
    </section>
  );
};

export default TournamentEdit;
