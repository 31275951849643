import { DependencyList, useEffect, useState } from "react";

interface CustomEventListener {
  (event: Event | CustomEvent): void;
}

export function useEventListener(
  eventName: string,
  eventCallback: CustomEventListener,
  deps?: DependencyList,
  element: Window | Document | Element = window,
  options: boolean | AddEventListenerOptions = {},
): boolean {
  const [listenerAdded, setListenerAdded] = useState(false);

  useEffect(() => {
    element.addEventListener(eventName, eventCallback, options);
    setListenerAdded(true);
    return () => {
      element.removeEventListener(eventName, eventCallback);
    };
  }, [element, eventCallback, options, eventName, deps]);
  return listenerAdded;
}
