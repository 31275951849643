import {
  Banner as FirestoreBanner,
  bannerCollection,
  BannerType,
  Country,
  getTranslatedCountries,
  isDepositBanner,
  isGameBanner,
  isLinkBanner,
  isPromotionBanner,
  isRegistrationBanner,
  isTournamentBanner,
} from "@vipscasino/core";
import firebase from "firebase/app";
import { firebaseApp } from "../lib/firebase";
import {
  getPromotion,
  isPromotionLive,
  isPromotionUpcoming,
  Promotion,
} from "../promotion/promotionService";
import {
  getTournament,
  isTournamentPublished,
  Tournament,
} from "../tournament/tournamentService";

const db = firebaseApp.firestore();

export interface Banner
  extends FirestoreBanner<
    firebase.firestore.Timestamp,
    firebase.firestore.DocumentReference
  > {
  id: string;
}

const banners = new Map<string, Banner>();
let cancelSubscription: () => void;

export function loadBanners(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collection(bannerCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        const banner = { ...data, id: doc.id } as Banner;
        if (change.type === `removed`) {
          banners.delete(banner.id);
        } else {
          banners.set(banner.id, banner);
        }
      });
      window.dispatchEvent(new Event(`banners-loaded`));
    });
}

export function getBanner(bannerId: string): Banner | undefined {
  return banners.get(bannerId);
}

export function getBanners(filter?: (banner: Banner) => boolean): Banner[] {
  const result = Array.from(banners.values());
  return filter ? result.filter(filter) : result;
}

export function getBannerType(banner: Banner): BannerType {
  if (isGameBanner(banner)) {
    return `Game`;
  } else if (isPromotionBanner(banner)) {
    return `Promotion`;
  } else if (isTournamentBanner(banner)) {
    return `Tournament`;
  } else if (isLinkBanner(banner)) {
    return `Link`;
  } else if (isRegistrationBanner(banner)) {
    return `Registration`;
  } else if (isDepositBanner(banner)) {
    return `Deposit`;
  }
  return `Image`;
}

export enum BannerReadyStatus {
  Ready = `Ready`,
  PartReady = `PartReady`,
  NotReady = `NotReady`,
}

export function isBannerLive(banner: Banner): boolean {
  const status = getBannerLiveStatus(banner);
  return (
    status === BannerReadyStatus.Ready || status === BannerReadyStatus.PartReady
  );
}

export function isBannerUpcoming(banner: Banner): boolean {
  const status = getBannerUpcomingStatus(banner);
  return (
    status === BannerReadyStatus.Ready || status === BannerReadyStatus.PartReady
  );
}

export function getBannerLiveStatus(banner: Banner): BannerReadyStatus {
  const now = Date.now();
  if (
    !banner.active ||
    banner.validFrom.toMillis() > now ||
    banner.validTo.toMillis() < now ||
    getTranslatedCountries(banner.translations).length === 0
  ) {
    return BannerReadyStatus.NotReady;
  }

  if (
    isPromotionBanner<unknown, firebase.firestore.DocumentReference>(banner)
  ) {
    const promotion = getPromotion(banner.promotion.id);
    if (!promotion || promotion.brand !== banner.brand) {
      return BannerReadyStatus.NotReady;
    }
    if (!isPromotionLive(promotion) && !isPromotionUpcoming(promotion)) {
      return BannerReadyStatus.NotReady;
    }
    return getPromotionCountryMatch(banner, promotion);
  }

  if (
    isTournamentBanner<unknown, firebase.firestore.DocumentReference>(banner)
  ) {
    const tournament = getTournament(banner.tournament.id);
    if (!tournament || tournament.brand !== banner.brand) {
      return BannerReadyStatus.NotReady;
    }
    if (!isTournamentPublished(tournament)) {
      return BannerReadyStatus.NotReady;
    }
    return getTournamentCountryMatch(banner, tournament);
  }

  return BannerReadyStatus.Ready;
}

export function getBannerUpcomingStatus(banner: Banner): BannerReadyStatus {
  const now = Date.now();
  if (
    !banner.active ||
    banner.validFrom.toMillis() <= now ||
    banner.validTo.toMillis() <= now ||
    getTranslatedCountries(banner.translations).length === 0
  ) {
    return BannerReadyStatus.NotReady;
  }

  if (
    isPromotionBanner<unknown, firebase.firestore.DocumentReference>(banner)
  ) {
    const promotion = getPromotion(banner.promotion.id);
    if (!promotion || promotion.brand !== banner.brand) {
      return BannerReadyStatus.NotReady;
    }
    if (
      !promotion.active ||
      promotion.validTo.toMillis() <= banner.validFrom.toMillis()
    ) {
      return BannerReadyStatus.NotReady;
    }
    return getPromotionCountryMatch(banner, promotion);
  }

  if (
    isTournamentBanner<unknown, firebase.firestore.DocumentReference>(banner)
  ) {
    const tournament = getTournament(banner.tournament.id);
    if (!tournament || tournament.brand !== banner.brand) {
      return BannerReadyStatus.NotReady;
    }
    if (
      tournament.publishedFrom.toMillis() >= banner.validTo.toMillis() ||
      tournament.publishedTo.toMillis() <= banner.validFrom.toMillis()
    ) {
      return BannerReadyStatus.NotReady;
    }
    return getTournamentCountryMatch(banner, tournament);
  }

  return BannerReadyStatus.Ready;
}

function getPromotionCountryMatch(
  banner: Banner,
  promotion: Promotion,
): BannerReadyStatus {
  return getCountryMatch(
    banner,
    getTranslatedCountries(promotion.translations),
  );
}

function getTournamentCountryMatch(
  banner: Banner,
  tournament: Tournament,
): BannerReadyStatus {
  return getCountryMatch(
    banner,
    getTranslatedCountries(tournament.translations),
  );
}

function getCountryMatch(
  banner: Banner,
  countries: Country[],
): BannerReadyStatus {
  const bCountries = getTranslatedCountries(banner.translations);
  let countryMatchCount = 0;
  for (const country of bCountries) {
    if (countries.includes(country)) {
      ++countryMatchCount;
    }
  }

  return countryMatchCount === bCountries.length
    ? BannerReadyStatus.Ready
    : countryMatchCount === 0
    ? BannerReadyStatus.NotReady
    : BannerReadyStatus.PartReady;
}
