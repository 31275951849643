import React from "react";
import { defaultSortOrder, Sort, SortOrder } from "../lib/sort";
import Icon from "./Icon";
import styles from "./SortableTable.module.scss";

interface SortableTableColumn {
  name: string;
  sortField?: string;
  className?: string;
  colSpan?: number;
  onShowToggle?: () => void;
  /**
   * Only valid if onShowToggle is defined
   */
  show?: boolean;
}

interface SortableTableProps {
  columns: SortableTableColumn[];
  sort: Sort;
  onSort: (sort: Sort) => void;
}

const SortableTable: React.FC<SortableTableProps> = (props) => {
  function doSort(sortField: string): void {
    props.onSort({
      field: sortField,
      order:
        props.sort.field === sortField ? toggleSortOrder() : defaultSortOrder,
    });
  }

  function toggleSortOrder(): SortOrder {
    return props.sort.order === `Asc` ? `Desc` : `Asc`;
  }

  function isSortActive(column: SortableTableColumn): boolean {
    return props.sort.field === column.sortField;
  }

  return (
    <>
      <ResultsLabel results={React.Children.count(props.children)} />
      <table className={styles.sortableTable}>
        <thead>
          <tr>
            {props.columns.map((col, i) => {
              return col.onShowToggle ? (
                <td
                  key={i}
                  colSpan={col.colSpan}
                  className={col.className}
                  onClick={col.onShowToggle}
                >
                  <div className={styles.showToggle}>
                    <div>{col.name}</div>
                    {col.show ? <Icon name="eye" /> : <Icon name="eye-off" />}
                  </div>
                </td>
              ) : (
                <td
                  key={i}
                  colSpan={col.colSpan}
                  className={col.className}
                  onClick={() => (col.sortField ? doSort(col.sortField) : null)}
                >
                  <div
                    className={`${styles.header} ${
                      col.sortField && styles.sortable
                    } ${isSortActive(col) && styles.sortActive}`}
                  >
                    <div>{col.name}</div>
                    {isSortActive(col) && props.sort.order === `Desc` && (
                      <Icon
                        name="sort-descending"
                        className={styles.sortIcon}
                      />
                    )}
                    {isSortActive(col) && props.sort.order === `Asc` && (
                      <Icon name="sort-ascending" className={styles.sortIcon} />
                    )}
                  </div>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
      <ResultsLabel results={React.Children.count(props.children)} />
    </>
  );
};

export default SortableTable;

interface ResultsLabelProps {
  results: number;
}
const ResultsLabel: React.FC<ResultsLabelProps> = (props) => {
  return <div className={styles.results}>Showing {props.results} results</div>;
};
