import {
  Brand,
  Country,
  getTranslatedCountries,
  getTranslatedLanguages,
  Language,
  Promotion as FirestorePromotion,
  promotionCollection,
  PromotionTranslation,
} from "@vipscasino/core";
import firebase from "firebase/app";
import { firebaseApp } from "../lib/firebase";
import * as index from "./promotionIndex";

const db = firebaseApp.firestore();

export interface Promotion
  extends FirestorePromotion<
    firebase.firestore.Timestamp,
    firebase.firestore.DocumentReference
  > {
  id: string;
}

const promotions = new Map<string, Promotion>();
const paths = new Map<string, string>();
let cancelSubscription: () => void;

export function loadPromotions(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collection(promotionCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        const promotion = { ...data, id: doc.id } as Promotion;
        if (change.type === `removed`) {
          promotions.delete(promotion.id);
          paths.delete(pathKey(promotion.brand, promotion.path));
        } else {
          const oldPromotion = promotions.get(promotion.id);
          if (oldPromotion) {
            paths.delete(pathKey(oldPromotion.brand, oldPromotion.path));
          }
          promotions.set(promotion.id, promotion);
          paths.set(pathKey(promotion.brand, promotion.path), promotion.id);
        }
      });
      indexPromotions();
      window.dispatchEvent(new Event(`promotions-loaded`));
    });
}

function pathKey(brand: Brand, path: string): string {
  return `${brand}_${path.trim()}`;
}

function indexPromotions(): void {
  const docs = Array.from(promotions.values()).map((promotion) => {
    const title = getTranslatedCountries(promotion.translations)
      .flatMap((country) =>
        getTranslatedLanguages(promotion.translations[country]!).map(
          (lang) => promotion.translations[country]![lang]!.title,
        ),
      )
      .join(` `);
    const doc: index.PromotionDoc = {
      id: promotion.id,
      title: title,
      path: promotion.path,
    };
    return doc;
  });

  index.indexPromotions(docs);
}

export function getPromotion(promotionId: string): Promotion | undefined {
  return promotions.get(promotionId);
}

export function getPromotions(): Promotion[] {
  return Array.from(promotions.values());
}

export function searchPromotions(
  query: string,
  limit?: number,
  excludedIds?: string[],
): Promotion[] {
  let results = index.searchPromotions(query);
  if (!results || results.length === 0) {
    return [];
  }
  if (excludedIds) {
    results = results.filter((result) => !excludedIds.includes(result.ref));
  }
  if (limit) {
    results = results.splice(0, limit);
  }

  return results
    .map((result) => {
      const promotionId = result.ref;
      const promotion = promotions.get(promotionId);
      if (promotion) {
        return promotion;
      }
      return undefined;
    })
    .filter((promotion) => promotion !== undefined) as Promotion[];
}

export function pathExists(
  path: string,
  brand: Brand,
  excludedPromotion?: Promotion,
): boolean {
  if (excludedPromotion) {
    if (
      excludedPromotion.brand === brand &&
      excludedPromotion.path.trim() === path.trim()
    ) {
      return false;
    }
  }
  return paths.has(pathKey(brand, path));
}

export function getPromotionTranslation(
  promotion: Promotion,
  preferredLang: Language | undefined,
): PromotionTranslation | undefined {
  const translations = promotion.translations;
  const countries = getTranslatedCountries(translations);

  if (countries.length === 0) {
    return undefined;
  }

  let preferredCountry: Country | undefined;
  if (preferredLang) {
    preferredCountry = countries.find((c) => !!translations[c]![preferredLang]);
  }
  if (preferredCountry && preferredLang) {
    return translations[preferredCountry]![preferredLang];
  } else {
    const t = translations[countries[0]]!;
    return t[getTranslatedLanguages(t)[0]];
  }
}

export function isPromotionLive(promotion: Promotion): boolean {
  const now = Date.now();
  return (
    promotion.active &&
    promotion.validFrom.toMillis() <= now &&
    promotion.validTo.toMillis() >= now &&
    getTranslatedCountries(promotion.translations).length > 0
  );
}

export function isPromotionUpcoming(promotion: Promotion): boolean {
  const now = Date.now();
  return (
    promotion.active &&
    promotion.validFrom.toMillis() > now &&
    promotion.validTo.toMillis() > now &&
    getTranslatedCountries(promotion.translations).length > 0
  );
}
