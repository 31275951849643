import React, { useEffect, useState } from "react";
import Chip from "../../component/Chip";
import GameTagComboBox from "./GameTagComboBox";
import styles from "./GameTagPicker.module.scss";
import { GameTag } from "./gameTagService";

interface Props {
  onSelectedTags: (tags: GameTag[]) => void;
  tags: GameTag[];
}

function sortTags(tags: GameTag[]): GameTag[] {
  return tags.sort((t1, t2) => {
    if (t1.type > t2.type) {
      return 1;
    } else if (t1.type < t2.type) {
      return -1;
    }

    if (t1.key > t2.key) {
      return 1;
    } else if (t1.key < t2.key) {
      return -1;
    }

    return 0;
  });
}

const GameTagPicker: React.FC<Props> = (props) => {
  const [selectedTags, setSelectedTags] = useState<GameTag[]>([]);

  useEffect(() => {
    setSelectedTags(sortTags(props.tags));
  }, [props.tags]);

  return (
    <div className={styles.main}>
      {selectedTags.length > 0 && (
        <div className={styles.chips}>
          {selectedTags.map((tag, i) => {
            return (
              <Chip
                key={i}
                label={tag.type}
                title={tag.key}
                onRemove={() => {
                  const newTags = selectedTags.filter((t) => t.id !== tag.id);
                  props.onSelectedTags(newTags);
                }}
              />
            );
          })}
        </div>
      )}
      <GameTagComboBox
        onSelectedTag={(tag) => {
          const newTags = sortTags([...selectedTags, tag]);
          props.onSelectedTags(newTags);
        }}
        excludedIds={selectedTags.map((tag) => tag.id)}
      />
    </div>
  );
};

export default GameTagPicker;
