import React, { useContext } from "react";
import styles from "./ActionToolbar.module.scss";
import ActionToolbarContext from "./ActionToolbarContext";

interface Props {
  name: string;
}

const ActionToolbar: React.FC<Props> = (props) => {
  const { actionToolbarState } = useContext(ActionToolbarContext);
  return (
    <section className={styles.toolbar}>
      <div className={styles.top}>
        <h2>{props.name}</h2>
        {props.children}
      </div>
      {actionToolbarState.expanded && (
        <div className={styles.expanded}>{actionToolbarState.expanded}</div>
      )}
    </section>
  );
};

export default ActionToolbar;
