import {
  GameTag as FirestoreGameTag,
  gameTagCollection,
} from "@vipscasino/core";
import {
  getTranslatedLanguages,
  Translations,
} from "@vipscasino/core/lib/translation/translation";
import firebase from "firebase/app";
import { firebaseApp } from "../../lib/firebase";
import * as index from "./gameTagIndex";

const db = firebaseApp.firestore();

export interface GameTag
  extends FirestoreGameTag<firebase.firestore.Timestamp> {
  id: string;
}

const tags = new Map<string, GameTag>();
const keys = new Set<string>();
let cancelSubscription: () => void;

export function loadGameTags(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collection(gameTagCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        const tag = { ...data, id: doc.id } as GameTag;
        if (change.type === `removed`) {
          tags.delete(tag.id);
          keys.delete(tag.key.trim());
        } else {
          tags.set(tag.id, tag);
          keys.add(tag.key.trim());
        }
      });
      indexGameTags();
      window.dispatchEvent(new Event(`game-tags-loaded`));
    });
}

export function getGameTag(tagId: string): GameTag | undefined {
  return tags.get(tagId);
}

export function getGameTagByKey(key: string): GameTag | undefined {
  return Array.from(tags.values()).find((tag) => tag.key === key);
}

export function getGameTags(): GameTag[] {
  return Array.from(tags.values());
}

export function searchGameTags(
  query: string,
  limit?: number,
  excludedIds?: string[],
): GameTag[] {
  let results = index.searchGameTags(query);
  if (!results || results.length === 0) {
    return [];
  }
  if (excludedIds) {
    results = results.filter((result) => !excludedIds.includes(result.ref));
  }
  if (limit) {
    results = results.splice(0, limit);
  }

  return results
    .map((result) => {
      const tag = tags.get(result.ref);
      if (tag) {
        return tag;
      }
      return undefined;
    })
    .filter((tag) => tag !== undefined) as GameTag[];
}

function indexGameTags(): void {
  const docs = Array.from(tags.values()).map((tag) => {
    const doc: index.GameTagDoc = {
      id: tag.id,
      key: tag.key,
      name: translationToString(tag.name),
    };
    return doc;
  });

  index.indexGameTags(docs);
}

function translationToString(translations: Translations<string>): string {
  return getTranslatedLanguages(translations)
    .map((lang) => translations[lang])
    .join(` `);
}

export function keyExists(key: string): boolean {
  return keys.has(key.trim());
}
