import React, { useState } from "react";
import Icon from "../component/Icon";
import ImageComboBox from "./ImageComboBox";
import styles from "./ImageMultiPicker.module.scss";
import { Image } from "./imageService";

interface Props {
  initialImages?: ImageWrapper[];
  onSelectedImages: (images: string[]) => void;
  filter: (image: Image) => boolean;
}

interface ImageWrapper {
  id: string;
  url: string;
}

const ImageMultiPicker: React.FC<Props> = (props) => {
  const [images, setImages] = useState<ImageWrapper[]>(
    props.initialImages ? props.initialImages : [],
  );

  function onSelected(image: Image): void {
    if (!images.map((img) => img.id).includes(image.id)) {
      const newImages = [...images, { id: image.id, url: image.urls.default }];
      setImages(newImages);
      props.onSelectedImages(newImages.map((img) => img.id));
    }
  }

  function onRemoved(imageId: string): void {
    const newImages = images.filter((image) => image.id !== imageId);
    setImages(newImages);
    props.onSelectedImages(newImages.map((img) => img.id));
  }

  return (
    <div className={styles.content}>
      {images.map((image, i) => (
        <div key={i} className={styles.imageContainer}>
          <img src={image.url} alt={image.url} className={styles.image} />
          <div
            className={styles.removeIcon}
            onClick={() => onRemoved(image.id)}
          >
            <Icon name="close" />
          </div>
        </div>
      ))}
      <ImageComboBox
        onSelectedImage={onSelected}
        filter={props.filter}
        excludedIds={images.map((image) => image.id)}
      />
    </div>
  );
};

export default ImageMultiPicker;
