import { EntityModified } from "@vipscasino/core";
import firebase from "firebase/app";
import { firebaseApp } from "../../lib/firebase";
import { Game } from "../gameService";

const db = firebaseApp.firestore();

export interface GameUpdateEntry {
  modified: EntityModified<Date>;
  changes: GameChange[];
}

export interface GameChange {
  key: string;
  before?: string;
  after?: string;
}

export async function loadGameHistory(game: Game): Promise<Game[]> {
  const snapshot = await db
    .collection(`game_history`)
    .where(`_id`, `==`, game.id)
    .get();

  const history = snapshot.docs
    .map((doc) => {
      const data = doc.data();
      return { ...data, id: data._id } as Game;
    })
    .sort(
      (g1, g2) => g2.modified.date.toMillis() - g1.modified.date.toMillis(),
    );

  return [game, ...history];
}

export function getGameUpdateEntry(
  gameBefore: Game,
  gameAfter: Game,
): GameUpdateEntry {
  const before = toJsonObject(gameBefore);
  const after = toJsonObject(gameAfter);
  const changes: GameChange[] = [];

  for (const key of Object.keys(before)) {
    if (before[key] !== after[key]) {
      changes.push({ key: key, before: before[key], after: after[key] });
    }
  }

  for (const key of Object.keys(after)) {
    if (before[key] === undefined) {
      changes.push({ key: key, after: after[key] });
    }
  }

  return {
    modified: {
      user: gameAfter.modified.user,
      date: gameAfter.modified.date.toDate(),
    },
    changes: changes.sort((c1, c2) => c1.key.localeCompare(c2.key)),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toJsonObject(game: any): { [field in string]?: string } {
  const obj: { [field in string]?: string } = {};
  for (const key of Object.keys(game)) {
    if (key.startsWith(`_`)) {
      continue;
    }
    if (key === `modified`) {
      continue;
    }
    if (key === `images`) {
      continue;
    }
    if (key === `similar`) {
      continue;
    }
    if (key === `externalIds`) {
      continue;
    }
    if (key === `touched`) {
      continue;
    }
    if (key === `lastPlayed`) {
      continue;
    }
    if (key === `turnover`) {
      continue;
    }
    const value = game[key];
    if (typeof value === `function`) {
      continue;
    }
    obj[key] = JSON.stringify(toJsonValue(value), undefined, 4);
  }
  return obj;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toJsonValue(value: any): any {
  if (value === null) {
    return null;
  }
  if (value instanceof firebase.firestore.Timestamp) {
    return value.toDate();
  }
  if (isDocumentReference(value)) {
    return fromDocumentReference(value);
  }
  if (Array.isArray(value)) {
    const arr = [];
    for (const entry of value) {
      arr.push(toJsonValue(entry));
    }
    return arr;
  }
  if (typeof value === `object`) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    for (const key of Object.keys(value).sort((k1, k2) =>
      k1.localeCompare(k2),
    )) {
      const val = value[key];
      obj[key] = toJsonValue(val);
    }
    return obj;
  }
  return value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isDocumentReference(value: any): boolean {
  return !!value[`firestore`];
}

function fromDocumentReference(
  ref: firebase.firestore.DocumentReference<unknown>,
): string {
  return ref.id;
}
