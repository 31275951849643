import React from "react";
import {
  ActionToolbarAction,
  ActionToolbarState,
  initialActionToolbarState,
} from "./ActionToolbarState";

interface ActionToolbarContextValue {
  actionToolbarState: ActionToolbarState;
  actionToolbarDispatch: React.Dispatch<ActionToolbarAction>;
}

const ActionToolbarContext = React.createContext<ActionToolbarContextValue>({
  actionToolbarState: initialActionToolbarState,
  actionToolbarDispatch: () => {
    // do nothing
  },
});

export default ActionToolbarContext;
