import React from "react";
import styles from "./FilterFormItem.module.scss";

interface Props {
  label: string;
}

const FilterFormItem: React.FC<Props> = (props) => {
  const label = props.label || `\u00A0`;
  return (
    <div className={styles.item}>
      <label>{label}</label>
      <div>{props.children}</div>
    </div>
  );
};

export default FilterFormItem;
