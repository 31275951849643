import {
  Affiliate as FirestoreAffiliate,
  affiliateCollection,
} from "@vipscasino/core";
import firebase from "firebase/app";
import { firebaseApp } from "../lib/firebase";
import * as index from "./affiliateIndex";

const db = firebaseApp.firestore();

export interface Affiliate
  extends FirestoreAffiliate<firebase.firestore.Timestamp> {
  id: string;
}

const affiliates = new Map<string, Affiliate>();
let cancelSubscription: () => void;

export function loadAffiliates(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collection(affiliateCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        const affiliate = { ...data, id: doc.id } as Affiliate;
        if (change.type === `removed`) {
          affiliates.delete(affiliate.id);
        } else {
          affiliates.set(affiliate.id, affiliate);
        }
      });
      indexAffiliates();
      window.dispatchEvent(new Event(`affiliates-loaded`));
    });
}

function indexAffiliates(): void {
  const docs = Array.from(affiliates.values()).map((affiliate) => {
    const doc: index.AffiliateDoc = {
      id: affiliate.id,
      company: affiliate.company,
    };
    return doc;
  });

  index.indexAffiliates(docs);
}

export function getAffiliate(affiliateId: string): Affiliate | undefined {
  return affiliates.get(affiliateId);
}

export function getAffiliates(): Affiliate[] {
  return Array.from(affiliates.values());
}

export function searchAffiliates(
  query: string,
  limit?: number,
  excludedIds?: string[],
): Affiliate[] {
  let results = index.searchAffiliates(query);
  if (!results || results.length === 0) {
    return [];
  }
  if (excludedIds) {
    results = results.filter((result) => !excludedIds.includes(result.ref));
  }
  if (limit) {
    results = results.splice(0, limit);
  }

  return results
    .map((result) => {
      const affiliateId = result.ref;
      const affiliate = affiliates.get(affiliateId);
      if (affiliate) {
        return affiliate;
      }
      return undefined;
    })
    .filter((affiliate) => affiliate !== undefined) as Affiliate[];
}
