import { brands, isBrand } from "@vipscasino/core";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../component/CustomDatePicker";
import FilterForm from "../component/FilterForm";
import FilterFormButton from "../component/FilterFormButton";
import FilterFormItem from "../component/FilterFormItem";
import { removeTournamentsFilter, setTournamentsFilter } from "../lib/storage";
import { filterIsEmpty, TournamentsFilter } from "./tournamentsFilter";

interface Props {
  filter: TournamentsFilter;
  onChange: (filter: TournamentsFilter) => void;
}

const TournamentsFilterComp: React.FC<Props> = (props) => {
  const [filter, setFilter] = useState<TournamentsFilter>(props.filter);

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (filterIsEmpty(filter)) {
      removeTournamentsFilter();
    } else {
      setTournamentsFilter(filter);
    }
  }, [filter]);

  function updateFilter(filter: TournamentsFilter): void {
    setFilter(filter);
    props.onChange(filter);
  }

  function onChange(field: TournamentsFilter): void {
    updateFilter({ ...filter, ...field });
  }

  return (
    <FilterForm>
      <FilterFormItem label="Published">
        <input
          type="checkbox"
          checked={!!filter.published}
          onChange={(event) => {
            const published = event.currentTarget.checked;
            onChange({ published: published ? published : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Live">
        <input
          type="checkbox"
          checked={!!filter.live}
          onChange={(event) => {
            const live = event.currentTarget.checked;
            onChange({ live: live ? live : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Upcoming">
        <input
          type="checkbox"
          checked={!!filter.upcoming}
          onChange={(event) => {
            const upcoming = event.currentTarget.checked;
            onChange({ upcoming: upcoming ? upcoming : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Brand">
        <select
          value={filter.brand ? filter.brand : ``}
          onChange={(event) => {
            const brand = event.currentTarget.value;
            if (!brand) {
              onChange({ brand: undefined });
            } else if (isBrand(brand)) {
              onChange({ brand: brand });
            }
          }}
        >
          <option />
          {brands.map((brand, i) => (
            <option key={i} value={brand}>
              {brand}
            </option>
          ))}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Max active start date">
        <CustomDatePicker
          selected={filter.activeFrom ? new Date(filter.activeFrom) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ activeFrom: date ? date.getTime() : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Min active end date">
        <CustomDatePicker
          selected={filter.activeTo ? new Date(filter.activeTo) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ activeTo: date ? date.getTime() : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Max published start date">
        <CustomDatePicker
          selected={
            filter.publishedFrom ? new Date(filter.publishedFrom) : null
          }
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ publishedFrom: date ? date.getTime() : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Min published end date">
        <CustomDatePicker
          selected={filter.publishedTo ? new Date(filter.publishedTo) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ publishedTo: date ? date.getTime() : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Created between">
        <CustomDatePicker
          selected={filter.createdStart ? new Date(filter.createdStart) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ createdStart: date ? date.getTime() : undefined });
          }}
        />
        &nbsp;-&nbsp;
        <CustomDatePicker
          selected={filter.createdEnd ? new Date(filter.createdEnd) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ createdEnd: date ? date.getTime() : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormButton
        label="Reset"
        type="reset"
        onClick={() => updateFilter({})}
      />
    </FilterForm>
  );
};

export default TournamentsFilterComp;
