import { getTranslatedLanguages, Language } from "@vipscasino/core";
import React, { useContext, useEffect, useReducer, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import AppContext from "../../AppContext";
import ActionRow from "../../component/ActionRow";
import ActionRowItem from "../../component/ActionRowItem";
import ActionToolbar from "../../component/ActionToolbar";
import ActionToolbarContext from "../../component/ActionToolbarContext";
import ActionToolbarItem from "../../component/ActionToolbarItem";
import {
  ActionToolbarActionType,
  actionToolbarReducer,
  initialActionToolbarState,
} from "../../component/ActionToolbarState";
import HorizontalScroll from "../../component/HorizontalScroll";
import Icon from "../../component/Icon";
import Languages from "../../component/Languages";
import Loader from "../../component/Loader";
import SortableTable from "../../component/SortableTable";
import { compare, defaultSortOrder, Sort } from "../../lib/sort";
import {
  getGameTagsFilter,
  getGameTagsSort,
  setGameTagsSort,
} from "../../lib/storage";
import { GamesProps } from "../Games";
import { getTagGameCount } from "../gameService";
import styles from "./GameTags.module.scss";
import { GameTag, getGameTags } from "./gameTagService";
import {
  filterGameTags,
  filterIsEmpty,
  GameTagsFilter,
} from "./gameTagsFilter";
import GameTagsFilterComp from "./GameTagsFilter";

interface DecoratedGameTag extends GameTag {
  gameCount: number;
}

const path = `game_tags`;

enum SortField {
  Key = `Key`,
  Type = `Type`,
  GameCount = `GameCount`,
  Created = `Created`,
  Modified = `Modified`,
}

const defaultSortField = SortField.Modified;

const GameTags: React.FC = () => {
  const { state } = useContext(AppContext);
  const [actionToolbarState, actionToolbarDispatch] = useReducer(
    actionToolbarReducer,
    initialActionToolbarState,
  );
  const [gameTags, setGameTags] = useState<GameTag[] | undefined>();
  const [filteredGameTags, setFilteredGameTags] = useState<
    GameTag[] | undefined
  >();
  const [filter, setFilter] = useState<GameTagsFilter>(
    getGameTagsFilter() ?? {},
  );
  const [decoratedGameTags, setDecoratedGameTags] = useState<
    DecoratedGameTag[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [showIds, setShowIds] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [sort, setSort] = useState<Sort>(
    getGameTagsSort() ?? {
      field: defaultSortField,
      order: defaultSortOrder,
    },
  );

  // set game tags when loaded/updated
  useEffect(() => {
    if (state.gameTagsLoaded) {
      setGameTags(getGameTags());
    }
  }, [state.gameTagsLoaded]);

  // filter game tags
  useEffect(() => {
    if (gameTags) {
      setFilteredGameTags(filterGameTags(filter, gameTags));
    }
  }, [filter, gameTags]);

  // add game count to game tags
  useEffect(() => {
    if (filteredGameTags && state.gamesLoaded) {
      setDecoratedGameTags(
        filteredGameTags.map((tag) => {
          return { ...tag, gameCount: getTagGameCount(tag.id) };
        }),
      );
      setLoading(false);
    }
  }, [filteredGameTags, state.gamesLoaded]);

  if (loading) {
    return <Loader message="Loading tags ..." />;
  }

  function isSortField(sortField: string): sortField is SortField {
    return Object.values(SortField).includes(sortField as SortField);
  }

  function sortFieldValues(
    field: string,
    tag1: DecoratedGameTag,
    tag2: DecoratedGameTag,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] {
    switch (field) {
      case SortField.Key:
        return [tag1.key, tag2.key];
      case SortField.Type:
        return [tag1.type, tag2.type];
      case SortField.GameCount:
        return [tag1.gameCount, tag2.gameCount];
      case SortField.Created:
        return [tag1.created, tag2.created];
      case SortField.Modified:
        return [tag1.modified.date, tag2.modified.date];
      default:
        throw new Error(`Unsupported sort field: ` + field);
    }
  }

  function getSortedTags(): DecoratedGameTag[] {
    const sortField = isSortField(sort.field) ? sort.field : defaultSortField;
    return decoratedGameTags.sort((tag1, tag2) => {
      const [val1, val2] = sortFieldValues(sortField, tag1, tag2);
      return compare(val1, val2, sort.order);
    });
  }

  function onSort(sort: Sort): void {
    setGameTagsSort(sort);
    setSort(sort);
  }

  return (
    <div className={styles.main}>
      <ActionToolbarContext.Provider
        value={{ actionToolbarState, actionToolbarDispatch }}
      >
        <ActionToolbar name="Tags">
          <ActionToolbarItem
            type="link"
            icon={<Icon name="add" />}
            name="Add"
            to={`/${path}/add`}
          />

          <ActionToolbarItem
            type="button"
            icon={<Icon name="filter" />}
            name="Filter"
            active={filterActive}
            notification={!filterIsEmpty(filter)}
            onClick={() => {
              setFilterActive((value) => !value);
              actionToolbarDispatch({
                type: ActionToolbarActionType.ShowExpanded,
                component: actionToolbarState.expanded ? undefined : (
                  <GameTagsFilterComp
                    filter={filter}
                    onChange={(filter: GameTagsFilter) => setFilter(filter)}
                  />
                ),
              });
            }}
          />
        </ActionToolbar>
      </ActionToolbarContext.Provider>

      <HorizontalScroll>
        <SortableTable
          sort={sort}
          onSort={onSort}
          columns={[
            { name: `Actions` },
            {
              name: `ID`,
              onShowToggle: () => setShowIds((value) => !value),
              show: showIds,
            },
            { name: `Languages` },
            { name: `Name` },
            {
              name: `Type`,
              sortField: SortField.Type,
            },
            {
              name: `Key`,
              sortField: SortField.Key,
            },
            {
              name: `Game Count`,
              sortField: SortField.GameCount,
            },
            {
              name: `Created`,
              sortField: SortField.Created,
            },
            {
              name: `Modified`,
              sortField: SortField.Modified,
            },
          ]}
        >
          {getSortedTags().map((tag, i) => {
            return (
              <GameTagComp
                key={i}
                tag={tag}
                // onRemove={remove}
                showIds={showIds}
              />
            );
          })}
        </SortableTable>
      </HorizontalScroll>
    </div>
  );
};

export default GameTags;

interface GameTagProps {
  tag: DecoratedGameTag;
  showIds: boolean;
}

const GameTagComp: React.FC<GameTagProps> = (props) => {
  const history = useHistory<GamesProps>();
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();

  const tag = props.tag;

  function getName(): string | undefined {
    const existingLangs = tag.name ? getTranslatedLanguages(tag.name) : [];
    const defaultName =
      existingLangs.length > 0 ? tag.name[existingLangs[0]]! : undefined;

    if (!selectedLang) {
      return defaultName;
    }

    const name = tag.name[selectedLang];
    if (!name) {
      return defaultName;
    }

    return name;
  }

  const name = getName();

  function langs(): JSX.Element {
    if (!name) {
      return <></>;
    }

    const existingLangs = tag.name ? getTranslatedLanguages(tag.name) : [];
    const currentLang = selectedLang ?? existingLangs[0];

    return (
      <Languages
        existingLangs={existingLangs}
        onLangSelected={setSelectedLang}
        selectedLang={currentLang}
        selectedClassName={styles.selected}
      />
    );
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  const created = tag.created.toDate();
  const modified = tag.modified.date.toDate();
  return (
    <tr>
      <td>
        <ActionRow>
          <ActionRowItem
            iconName="edit"
            to={`/${path}/${tag.id}`}
            title="Edit"
          />
        </ActionRow>
      </td>
      <td className={styles.id}>{props.showIds && tag.id}</td>
      <td className={styles.languages}>{langs()}</td>
      <td>{name ? name : ``}</td>
      <td>{tag.type}</td>
      <td>{tag.key}</td>
      <td className={styles.center}>
        <span
          className={styles.gameCount}
          title="See Games"
          onClick={() =>
            history.push(`/games`, { filterOverride: { tag: `"${tag.key}"` } })
          }
        >
          {tag.gameCount}
        </span>
      </td>
      <td title={dateTimeString(created)} style={{ whiteSpace: `nowrap` }}>
        {created.toLocaleDateString(`sv`)}
      </td>
      <td
        title={`${dateTimeString(modified)} - ${tag.modified.user}`}
        style={{ whiteSpace: `nowrap` }}
      >
        {modified.toLocaleDateString(`sv`)}
      </td>
    </tr>
  );
};
