import React, { useContext } from "react";
import { GoogleLogin, GoogleLoginResponse } from "react-google-login";
import AppContext from "../AppContext";
import { AppActionType } from "../AppState";
import Logo from "../component/Logo";
import { config } from "../config";
import styles from "./Login.module.scss";

const Login: React.FC = () => {
  const { dispatch } = useContext(AppContext);

  return (
    <section className={styles.login}>
      <header>
        <Logo large />
      </header>
      <main>
        <GoogleLogin
          isSignedIn
          clientId={config.googleClientId}
          onSuccess={async (response) => {
            // can never be offline
            const glr = response as GoogleLoginResponse;

            dispatch({
              type: AppActionType.SetUser,
              payload: {
                name: glr.getBasicProfile().getName(),
                avatar: glr.getBasicProfile().getImageUrl(),
                googleLoginResponse: glr,
              },
            });
          }}
          onFailure={(error) => {
            if (error === `popup_closed_by_user`) {
              return;
            }
            console.info(`FAILURE`, error);
          }}
        />
      </main>
      <footer>{process.env.REACT_APP_VERSION}</footer>
    </section>
  );
};

export default Login;
