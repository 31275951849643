import React, { useState } from "react";
import Markdown from "./Markdown";
import styles from "./MarkdownEditor.module.scss";

interface MarkdownProps {
  text?: string;
  markdownText?: string;
  editMode: boolean;
  editCols?: number;
  editRows?: number;
  placeholder?: string;
  disabled?: boolean;
  markdownClass?: string;
  onChange?: (value: string) => void;
}

const MarkdownEditor: React.FC<MarkdownProps> = (props) => {
  const [preview, setPreview] = useState(false);

  if (props.editMode) {
    return (
      <div>
        <div className={styles.editableContent}>
          <textarea
            value={props.text}
            disabled={props.disabled}
            placeholder={props.placeholder}
            cols={props.editCols}
            rows={props.editRows}
            onChange={(event) => {
              if (props.onChange) {
                props.onChange(event.currentTarget.value);
              }
            }}
          />
          <div className={styles.markdownContainer}>
            <Markdown
              className={props.markdownClass}
              source={
                props.markdownText !== undefined
                  ? props.markdownText
                  : props.text
              }
            />
            {/* <ReactMarkdown
              className={props.markdownClass}
              source={
                props.markdownText !== undefined
                  ? props.markdownText
                  : props.text
              }
            /> */}
          </div>
        </div>
        <a
          className={styles.markdownGuide}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.markdownguide.org/cheat-sheet/"
        >
          Markdown guide
        </a>
      </div>
    );
  }

  const previewLink = (
    <a
      href="dummy"
      onClick={(event) => {
        event.preventDefault();
        setPreview(preview ? false : true);
      }}
    >
      {preview ? `Hide` : `Preview`}
    </a>
  );

  let body: JSX.Element;

  if (preview) {
    body = (
      <>
        <div className={styles.linkContainer}>{previewLink}</div>
        <div className={styles.markdownContainer}>
          <Markdown className={props.markdownClass} source={props.text} />
        </div>
      </>
    );
  } else {
    body = previewLink;
  }

  return <div className={styles.content}>{body}</div>;
};

export default MarkdownEditor;
