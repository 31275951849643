import {
  Image,
  imageCollection,
  ImageCreateRequest,
  ImagePlatform,
  imagePlatforms,
  ImageType,
  imageTypes,
  ImageUpdateRequest,
  isImagePlatform,
  isImageType,
  isValidImagePlatform,
} from "@vipscasino/core";
import firebase from "firebase/app";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router";
import AppContext from "../AppContext";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarItem from "../component/ActionToolbarItem";
import Icon from "../component/Icon";
import KeyValueGrid from "../component/KeyValueGrid";
import KeyValueGridItem from "../component/KeyValueGridItem";
import Loader from "../component/Loader";
import { config } from "../config";
import { firebaseApp } from "../lib/firebase";
import { defaultHeaders } from "../lib/util/httpUtil";
import styles from "./ImageEdit.module.scss";
import ImageEditor from "./ImageEditor";

const db = firebaseApp.firestore();

interface Params {
  id?: string;
  clone?: string;
  multi?: string;
}

const ImageEdit: React.FC = () => {
  const history = useHistory();
  const params = useParams<Params>();
  const id = params.id;
  const clone = params.clone;
  const multi = params.multi;
  const uploadUrlBase = `${config.api}/images/${multi ? `multi/` : ``}`;
  const { state } = useContext(AppContext);
  const [image, setImage] = useState<
    Image<firebase.firestore.Timestamp> | undefined
  >();
  const [updateRequest, setUpdateRequest] = useState<ImageUpdateRequest>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);

  // validate form
  useEffect(() => {
    setValid(isValid(image, updateRequest, clone));
  }, [image, updateRequest, clone]);

  function isValid(
    image: Image<firebase.firestore.Timestamp> | undefined,
    ur: ImageUpdateRequest,
    clone: string | undefined,
  ): boolean {
    if (image && !clone) {
      return ur.name === undefined || ur.name.trim().length > 0;
    }

    if (!ur.name || ur.name.trim().length === 0) {
      return false;
    }
    if (!ur.type || !ur.platform || !ur.urlAndSize) {
      return false;
    }
    return true;
  }

  // load image
  useEffect(() => {
    if (id) {
      setLoading(true);
      db.collection(imageCollection)
        .doc(id)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            throw Error(`Image with id ${id} does not exist`);
          }

          const img = snapshot.data() as Image<firebase.firestore.Timestamp>;
          setImage(img);

          if (clone) {
            setUpdateRequest({
              name: img.name,
              type: img.type,
              platform: img.platform,
              urlAndSize: {
                url: img.urls.default,
                width: img.width,
                height: img.height,
                size: img.size,
              },
            });
          }

          setLoading(false);
        });
    }
  }, [id, clone]);

  if (saving) {
    return <Loader message="Saving image ..." />;
  }

  if (loading) {
    return <Loader message="Loading image ..." />;
  }

  async function save(): Promise<void> {
    setSaving(true);

    const update = id && !clone;
    const data = update ? updateRequest : getCreateRequest();
    const url = `${config.api}/images/${update ? `${id}/` : ``}`;
    await fetch(url, {
      method: `${update ? `PUT` : `POST`}`,
      headers: defaultHeaders(state),
      body: JSON.stringify(data),
    });

    setSaving(false);
    history.goBack();
  }

  function getCreateRequest(): ImageCreateRequest {
    return {
      name: updateRequest.name!,
      type: updateRequest.type!,
      platform: updateRequest.platform!,
      urlAndSize: updateRequest.urlAndSize!,
    };
  }

  function cancel(): void {
    history.goBack();
  }

  function onUpdate(field: ImageUpdateRequest): void {
    setUpdateRequest({ ...updateRequest, ...field });
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  const imageType = updateRequest.type
    ? updateRequest.type
    : image && !clone
    ? image.type
    : undefined;

  const imagePlatform = updateRequest.platform
    ? updateRequest.platform
    : image && !clone
    ? image.platform
    : undefined;

  function uploadEnabled(): boolean {
    if (!imageType || !imagePlatform) {
      return false;
    }
    return isValidImagePlatform(imagePlatform, imageType);
  }

  function uploadUrl(): string {
    if (!imageType || !imagePlatform) {
      throw Error(`Type or Platform is not specified`);
    }
    return `${uploadUrlBase}?type=${imageType}&platform=${imagePlatform}`;
  }

  function validPlatforms(type?: ImageType): [ImagePlatform, string][] {
    if (!type) {
      return [];
    }
    return Array.from(imagePlatforms.entries()).filter(([platform]) => {
      return isImagePlatform(platform) && isValidImagePlatform(platform, type);
    }) as [ImagePlatform, string][];
  }

  function platformUpdateByType(type: ImageType): ImagePlatform | undefined {
    const platforms = validPlatforms(type);
    if (platforms.length === 1) {
      return platforms[0][0];
    }

    if (!updateRequest.platform) {
      return undefined;
    }

    return isValidImagePlatform(updateRequest.platform, type)
      ? updateRequest.platform
      : undefined;
  }

  return (
    <section className={styles.main}>
      <ActionToolbar
        name={`${image ? `Edit` : `Add`} ${
          multi ? `multiple images` : `image`
        }`}
      >
        <ActionToolbarItem
          type="button"
          icon={<Icon name="cancel" />}
          name="Cancel"
          onClick={() => cancel()}
        />
        {!multi && (
          <ActionToolbarItem
            type="button"
            icon={<Icon name="save" />}
            name="Save"
            disabled={!valid}
            onClick={() => save()}
          />
        )}
      </ActionToolbar>

      {multi && (
        <div className={styles.multiInfo}>
          When uploading multiple images, the images are named as their
          respective files (minus extension). E.g. the file twin_spin.jpg would
          result in an image named twin_spin. The uploaded images are also
          automatically saved, and when the upload is complete you will be
          redirected back to the images page.
        </div>
      )}

      <KeyValueGrid>
        {id && !clone && (
          <KeyValueGridItem label="ID" editable={false}>
            {id}
          </KeyValueGridItem>
        )}

        {image && !clone && (
          <KeyValueGridItem label="Created" editable={false}>
            {dateTimeString(image.created.toDate() as Date)}
          </KeyValueGridItem>
        )}

        {!multi && (
          <KeyValueGridItem label="Name" editable>
            <input
              type="text"
              placeholder="Add a descriptive name"
              value={
                updateRequest.name !== undefined
                  ? updateRequest.name
                  : image && !clone
                  ? image.name
                  : ``
              }
              onChange={(event) => {
                onUpdate({ name: event.currentTarget.value });
              }}
            />
          </KeyValueGridItem>
        )}

        <KeyValueGridItem label="Type" editable>
          <select
            value={imageType ? imageType : ``}
            disabled={image && !clone}
            onChange={(event) => {
              const type = event.currentTarget.value;
              if (!isImageType(type)) {
                throw new Error(`invalid ImageType: ` + type);
              }
              onUpdate({
                type: type,
                platform: platformUpdateByType(type),
                urlAndSize: undefined,
              });
            }}
          >
            {!updateRequest.type && <option value="" />}
            {imageTypes.map((type, i) => (
              <option key={i} value={type}>
                {type}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label="Platform" editable>
          <select
            value={imagePlatform ? imagePlatform : ``}
            disabled={image && !clone}
            onChange={(event) => {
              const platform = event.currentTarget.value;
              if (!isImagePlatform(platform)) {
                throw new Error(`invalid ImagePlatform: ` + platform);
              }
              onUpdate({ platform: platform, urlAndSize: undefined });
            }}
          >
            {!updateRequest.platform && <option value="" />}
            {validPlatforms(imageType).map(([platform, name], i) => (
              <option key={i} value={platform}>
                {name}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        {uploadEnabled() && (
          <KeyValueGridItem label="Image" editable>
            <ImageEditor
              imageUrl={
                updateRequest.urlAndSize
                  ? updateRequest.urlAndSize.url
                  : image
                  ? clone
                    ? ``
                    : image.urls.default
                  : ``
              }
              multi={!!multi}
              uploadUrl={uploadUrl()}
              onUploaded={(uploadResult) => {
                if (multi) {
                  history.goBack();
                } else {
                  onUpdate({ urlAndSize: JSON.parse(uploadResult) });
                }
              }}
            />
          </KeyValueGridItem>
        )}
      </KeyValueGrid>
    </section>
  );
};

export default ImageEdit;
