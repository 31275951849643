import React from "react";
import styles from "./KeyValueGridItem.module.scss";

interface Props {
  label: string;
  editable: boolean;
  noPadding?: boolean;
  dirty?: boolean;
}

const KeyValueGridItem: React.FC<Props> = (props) => {
  return (
    <>
      <div className={styles.label}>{props.label}</div>
      <div
        className={`${styles.children} ${props.editable && styles.editable} ${
          props.noPadding && styles.noPadding
        } ${props.editable && props.dirty && styles.dirty} `}
      >
        {props.children}
      </div>
    </>
  );
};

export default KeyValueGridItem;
