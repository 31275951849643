import React, { useContext, useState } from "react";
import { GoogleLogout } from "react-google-login";
import { NavLink, Route, Switch } from "react-router-dom";
import { loadAffiliates } from "../affiliate/affiliateService";
import AppContext from "../AppContext";
import { AppActionType } from "../AppState";
import BannerEdit from "../banner/BannerEdit";
import Banners from "../banner/Banners";
import { loadBanners } from "../banner/bannerService";
import Logo from "../component/Logo";
import { config } from "../config";
import GameCollectionEdit from "../game/collection/GameCollectionEdit";
import GameCollections from "../game/collection/GameCollections";
import { loadGameCollections } from "../game/collection/gameCollectionService";
import { loadGameDescriptions } from "../game/gameDescriptionService";
import GameEdit from "../game/GameEdit";
import Games from "../game/Games";
import { loadGames } from "../game/gameService";
import GameHistory from "../game/history/GameHistory";
import GameProviders from "../game/provider/GameProviders";
import { loadGameProviders } from "../game/provider/gameProviderService";
import GameTagEdit from "../game/tag/GameTagEdit";
import GameTags from "../game/tag/GameTags";
import { loadGameTags } from "../game/tag/gameTagService";
import { useEventListener } from "../hooks/useEventListener";
import ImageEdit from "../image/ImageEdit";
import Images from "../image/Images";
import { loadImages } from "../image/imageService";
import Information from "../information/Information";
import NotFoundPage from "../NotFoundPage";
import PromotionEdit from "../promotion/PromotionEdit";
import Promotions from "../promotion/Promotions";
import { loadPromotions } from "../promotion/promotionService";
import Reporting from "../reporting/Reporting";
import TournamentEdit from "../tournament/TournamentEdit";
import Tournaments from "../tournament/Tournaments";
import { loadTournaments } from "../tournament/tournamentService";
import { loadUserRefs } from "../user/userRefService";
import styles from "./Home.module.scss";

export function envBorder(): React.CSSProperties {
  const width = `0.25rem`;
  const env = process.env.REACT_APP_ENV;
  let border = `none`;
  if (env === `dev`) {
    border = `${width} solid green`;
  } else if (env === `stage`) {
    border = `${width} solid #ffd000`;
  }
  return { borderTop: border };
}

function useFirestoreInit(
  eventName: string,
  callback: () => void,
  afterListenerAdded: () => void,
): void {
  const [skip, setSkip] = useState(false);
  const listener = useEventListener(eventName, callback, []);

  if (listener && !skip) {
    afterListenerAdded();
    setSkip(true);
  }
}

const Home: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  // listen for games loaded events
  useFirestoreInit(
    `games-loaded`,
    () => dispatch({ type: AppActionType.GamesLoaded, loaded: Date.now() }),
    () => loadGames(),
  );

  // listen for game descriptions loaded events
  useFirestoreInit(
    `game-descriptions-loaded`,
    () =>
      dispatch({
        type: AppActionType.GameDescriptionsLoaded,
        loaded: Date.now(),
      }),
    () => loadGameDescriptions(),
  );

  // listen for game collections loaded events
  useFirestoreInit(
    `game-collections-loaded`,
    () =>
      dispatch({
        type: AppActionType.GameCollectionsLoaded,
        loaded: Date.now(),
      }),
    () => loadGameCollections(),
  );

  // listen for game providers loaded events
  useFirestoreInit(
    `game-providers-loaded`,
    () =>
      dispatch({
        type: AppActionType.GameProvidersLoaded,
        loaded: Date.now(),
      }),
    () => loadGameProviders(),
  );

  // listen for game providers loaded events
  useFirestoreInit(
    `game-tags-loaded`,
    () =>
      dispatch({
        type: AppActionType.GameTagsLoaded,
        loaded: Date.now(),
      }),
    () => loadGameTags(),
  );

  // listen for images loaded events
  useFirestoreInit(
    `images-loaded`,
    () => dispatch({ type: AppActionType.ImagesLoaded, loaded: Date.now() }),
    () => loadImages(),
  );

  // listen for promotions loaded events
  useFirestoreInit(
    `promotions-loaded`,
    () =>
      dispatch({ type: AppActionType.PromotionsLoaded, loaded: Date.now() }),
    () => loadPromotions(),
  );

  // listen for tournaments loaded events
  useFirestoreInit(
    `tournaments-loaded`,
    () =>
      dispatch({ type: AppActionType.TournamentsLoaded, loaded: Date.now() }),
    () => loadTournaments(),
  );

  // listen for banners loaded events
  useFirestoreInit(
    `banners-loaded`,
    () => dispatch({ type: AppActionType.BannersLoaded, loaded: Date.now() }),
    () => loadBanners(),
  );

  // listen for user refs loaded events
  useFirestoreInit(
    `user-refs-loaded`,
    () => dispatch({ type: AppActionType.UserRefsLoaded, loaded: Date.now() }),
    () => loadUserRefs(),
  );

  // listen for affiliates loaded events
  useFirestoreInit(
    `affiliates-loaded`,
    () =>
      dispatch({ type: AppActionType.AffiliatesLoaded, loaded: Date.now() }),
    () => loadAffiliates(),
  );

  return (
    <section className={styles.home}>
      <header className={styles.header} style={envBorder()}>
        <NavLink to="/" className={styles.logo}>
          <Logo />
        </NavLink>
        <div className={styles.user}>
          <div className={styles.name}>{state.user!.name}</div>
          <GoogleLogout
            clientId={config.googleClientId}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                Sign out
              </button>
            )}
            onLogoutSuccess={() => {
              dispatch({ type: AppActionType.SetUser });
            }}
          />
        </div>
      </header>

      <div className={styles.navContainer}>
        <nav className={styles.nav}>
          <ActiveNavLink to="/games">Games</ActiveNavLink>
          <div className={styles.subLink}>
            <ActiveNavLink to="/game_collections">Collections</ActiveNavLink>
          </div>
          <div className={styles.subLink}>
            <ActiveNavLink to="/game_providers">Providers</ActiveNavLink>
          </div>
          <div className={styles.subLink}>
            <ActiveNavLink to="/game_tags">Tags</ActiveNavLink>
          </div>
          <ActiveNavLink to="/banners">Banners</ActiveNavLink>
          <ActiveNavLink to="/promotions">Promotions</ActiveNavLink>
          <ActiveNavLink to="/tournaments">Tournaments</ActiveNavLink>
          <ActiveNavLink to="/images">Images</ActiveNavLink>
          <ActiveNavLink to="/information">Information</ActiveNavLink>
          <ActiveNavLink to="/reporting">Reporting</ActiveNavLink>
        </nav>
      </div>

      <main className={styles.main}>
        <Switch>
          <Route exact path="/">
            <></>
          </Route>

          {/* Games */}
          <Route exact path="/games">
            <Games />
          </Route>
          <Route exact path="/games/add">
            <GameEdit />
          </Route>
          <Route exact path="/games/:id">
            <GameEdit />
          </Route>
          <Route path="/games/:id/:clone">
            <GameEdit />
          </Route>

          {/* Game collections */}
          <Route exact path="/game_collections">
            <GameCollections />
          </Route>
          <Route exact path="/game_collections/add">
            <GameCollectionEdit />
          </Route>
          <Route exact path="/game_collections/:id">
            <GameCollectionEdit />
          </Route>
          <Route path="/game_collections/:id/:clone">
            <GameCollectionEdit />
          </Route>

          {/* Game providers */}
          <Route path="/game_providers">
            <GameProviders />
          </Route>

          {/* Game tags */}
          <Route exact path="/game_tags">
            <GameTags />
          </Route>
          <Route exact path="/game_tags/add">
            <GameTagEdit />
          </Route>
          <Route exact path="/game_tags/:id">
            <GameTagEdit />
          </Route>

          {/* Game history */}
          <Route exact path="/game_history/:id">
            <GameHistory />
          </Route>

          {/* Promotions */}
          <Route exact path="/promotions">
            <Promotions />
          </Route>
          <Route exact path="/promotions/add">
            <PromotionEdit />
          </Route>
          <Route exact path="/promotions/:id">
            <PromotionEdit />
          </Route>
          <Route path="/promotions/:id/:clone">
            <PromotionEdit />
          </Route>

          {/* Banners */}
          <Route exact path="/banners">
            <Banners />
          </Route>
          <Route exact path="/banners/add">
            <BannerEdit />
          </Route>
          <Route exact path="/banners/:id">
            <BannerEdit />
          </Route>
          <Route path="/banners/:id/:clone">
            <BannerEdit />
          </Route>

          {/* Tournaments */}
          <Route exact path="/tournaments">
            <Tournaments />
          </Route>
          <Route exact path="/tournaments/add">
            <TournamentEdit />
          </Route>
          <Route exact path="/tournaments/:id">
            <TournamentEdit />
          </Route>
          <Route path="/tournaments/:id/:clone">
            <TournamentEdit />
          </Route>

          {/* Images */}
          <Route exact path="/images">
            <Images />
          </Route>
          <Route exact path="/images/add">
            <ImageEdit />
          </Route>
          <Route exact path="/images/add/:multi">
            <ImageEdit />
          </Route>
          <Route exact path="/images/:id">
            <ImageEdit />
          </Route>
          <Route path="/images/:id/:clone">
            <ImageEdit />
          </Route>

          {/* Reporting */}
          <Route exact path="/reporting">
            <Reporting />
          </Route>

          {/* Information */}
          <Route exact path="/information">
            <Information />
          </Route>

          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </main>

      <footer className={styles.footer}>{process.env.REACT_APP_VERSION}</footer>
    </section>
  );
};

export default Home;

interface ActiveNavLinkProps {
  to: string;
}

const ActiveNavLink: React.FC<ActiveNavLinkProps> = (props) => {
  return (
    <NavLink
      to={props.to}
      className={styles.link}
      activeClassName={styles.activeLink}
      isActive={(match, location) => !!match}
    >
      {props.children}
    </NavLink>
  );
};
