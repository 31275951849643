import React from "react";
import { ReactComponent as Users } from "../assets/icons/account-group.svg";
import { ReactComponent as Upload } from "../assets/icons/baseline-cloud_upload-24px.svg";
import { ReactComponent as Cancel } from "../assets/icons/cancel.svg";
import { ReactComponent as Mobile } from "../assets/icons/cellphone.svg";
import { ReactComponent as ChevronLeft } from "../assets/icons/chevron-left.svg";
import { ReactComponent as Clipboard } from "../assets/icons/clipboard-text-outline.svg";
import { ReactComponent as Close } from "../assets/icons/close.svg";
import { ReactComponent as Copy } from "../assets/icons/content-copy.svg";
import { ReactComponent as Save } from "../assets/icons/content-save.svg";
import { ReactComponent as Delete } from "../assets/icons/delete.svg";
import { ReactComponent as EyeOff } from "../assets/icons/eye-off.svg";
import { ReactComponent as Eye } from "../assets/icons/eye.svg";
import { ReactComponent as Filter } from "../assets/icons/filter-variant.svg";
import { ReactComponent as History } from "../assets/icons/history.svg";
import { ReactComponent as AddImage } from "../assets/icons/image-plus.svg";
import { ReactComponent as MenuDown } from "../assets/icons/menu-down.svg";
import { ReactComponent as Desktop } from "../assets/icons/monitor.svg";
import { ReactComponent as Edit } from "../assets/icons/pencil.svg";
import { ReactComponent as Add } from "../assets/icons/plus.svg";
import { ReactComponent as SortAscending } from "../assets/icons/sort-ascending.svg";
import { ReactComponent as SortDescending } from "../assets/icons/sort-descending.svg";
import { ReactComponent as Sync } from "../assets/icons/sync.svg";

export type IconName =
  | "add"
  | "add-image"
  | "cancel"
  | "chevron-left"
  | "clipboard"
  | "close"
  | "copy"
  | "delete"
  | "desktop"
  | "edit"
  | "eye"
  | "eye-off"
  | "filter"
  | "history"
  | "mobile"
  | "menu-down"
  | "save"
  | "sort-ascending"
  | "sort-descending"
  | "sync"
  | "upload"
  | "users";

interface Props extends React.SVGProps<SVGSVGElement> {
  name: IconName;
}

const Icon: React.FC<Props> = (props) => {
  switch (props.name) {
    case `add`:
      return <Add {...props} />;
    case `add-image`:
      return <AddImage {...props} />;
    case `cancel`:
      return <Cancel {...props} />;
    case `chevron-left`:
      return <ChevronLeft {...props} />;
    case `clipboard`:
      return <Clipboard {...props} />;
    case `close`:
      return <Close {...props} />;
    case `copy`:
      return <Copy {...props} />;
    case `delete`:
      return <Delete {...props} />;
    case `desktop`:
      return <Desktop {...props} />;
    case `edit`:
      return <Edit {...props} />;
    case `eye`:
      return <Eye {...props} />;
    case `eye-off`:
      return <EyeOff {...props} />;
    case `filter`:
      return <Filter {...props} />;
    case `history`:
      return <History {...props} />;
    case `mobile`:
      return <Mobile {...props} />;
    case `menu-down`:
      return <MenuDown {...props} />;
    case `save`:
      return <Save {...props} />;
    case `sync`:
      return <Sync {...props} />;
    case `upload`:
      return <Upload {...props} />;
    case `sort-ascending`:
      return <SortAscending {...props} />;
    case `sort-descending`:
      return <SortDescending {...props} />;
    case `users`:
      return <Users {...props} />;
  }
};

export default Icon;
