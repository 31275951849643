import { BannersFilter } from "../banner/bannersFilter";
import { GamesFilter } from "../game/gamesFilter";
import { GameTagsFilter } from "../game/tag/gameTagsFilter";
import { ImagesFilter } from "../image/imagesFilter";
import { PromotionsFilter } from "../promotion/promotionsFilter";
import { TournamentsFilter } from "../tournament/tournamentsFilter";
import { Sort } from "./sort";

const prefix = `vips-backoffice`;

const keyPromotionsFilter = `${prefix}-promotions-filter`;
const keyShowPromotionsFilter = `${prefix}-show-promotions-filter`;
const keyBannersFilter = `${prefix}-banners-filter`;
const keyShowBannersFilter = `${prefix}-show-banners-filter`;
const keyGamesFilter = `${prefix}-games-filter`;
const keyShowGamesFilter = `${prefix}-show-games-filter`;
const keyTournamentsFilter = `${prefix}-tournaments-filter`;
const keyImagesFilter = `${prefix}-images-filter`;
const keyGameTagsFilter = `${prefix}-game-tags-filter`;

const keyGamesSort = `${prefix}-games-sort`;
const keyImagesSort = `${prefix}-images-sort`;
const keyGameCollectionsSort = `${prefix}-game-collections-sort`;
const keyGameTagsSort = `${prefix}-game-tags-sort`;
const keyTournamentsSort = `${prefix}-tournaments-sort`;
const keyBannersSort = `${prefix}-banners-sort`;
const keyPromotionsSort = `${prefix}-promotions-sort`;
const keyGameProvidersSort = `${prefix}-game-providers-sort`;

export function getPromotionsFilter(): PromotionsFilter | null {
  const value = localStorage.getItem(keyPromotionsFilter);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(`Failed to parse '${value}'`, e);
    return null;
  }
}

export function setPromotionsFilter(value: PromotionsFilter): void {
  localStorage.setItem(keyPromotionsFilter, JSON.stringify(value));
}

export function removePromotionsFilter(): void {
  localStorage.removeItem(keyPromotionsFilter);
}

export function getShowPromotionsFilter(): boolean {
  return getBoolean(keyShowPromotionsFilter, false);
}

export function setShowPromotionsFilter(value: boolean): void {
  localStorage.setItem(keyShowPromotionsFilter, String(value));
}

export function getBannersFilter(): BannersFilter | null {
  const value = localStorage.getItem(keyBannersFilter);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(`Failed to parse '${value}'`, e);
    return null;
  }
}

export function setBannersFilter(value: BannersFilter): void {
  localStorage.setItem(keyBannersFilter, JSON.stringify(value));
}

export function removeBannersFilter(): void {
  localStorage.removeItem(keyBannersFilter);
}

export function getShowBannersFilter(): boolean {
  return getBoolean(keyShowBannersFilter, false);
}

export function setShowBannersFilter(value: boolean): void {
  localStorage.setItem(keyShowBannersFilter, String(value));
}

export function getGamesFilter(): GamesFilter | null {
  const value = localStorage.getItem(keyGamesFilter);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(`Failed to parse '${value}'`, e);
    return null;
  }
}

export function setGamesFilter(value: GamesFilter): void {
  localStorage.setItem(keyGamesFilter, JSON.stringify(value));
}

export function removeGamesFilter(): void {
  localStorage.removeItem(keyGamesFilter);
}

export function getShowGamesFilter(): boolean {
  return getBoolean(keyShowGamesFilter, true);
}

export function setShowGamesFilter(value: boolean): void {
  localStorage.setItem(keyShowGamesFilter, String(value));
}

export function getTournamentsFilter(): TournamentsFilter | null {
  const value = localStorage.getItem(keyTournamentsFilter);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(`Failed to parse '${value}'`, e);
    return null;
  }
}

export function setTournamentsFilter(value: TournamentsFilter): void {
  localStorage.setItem(keyTournamentsFilter, JSON.stringify(value));
}

export function removeTournamentsFilter(): void {
  localStorage.removeItem(keyTournamentsFilter);
}

export function getImagesFilter(): ImagesFilter | null {
  const value = localStorage.getItem(keyImagesFilter);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(`Failed to parse '${value}'`, e);
    return null;
  }
}

export function setImagesFilter(value: ImagesFilter): void {
  localStorage.setItem(keyImagesFilter, JSON.stringify(value));
}

export function removeImagesFilter(): void {
  localStorage.removeItem(keyImagesFilter);
}

export function getGameTagsFilter(): GameTagsFilter | null {
  const value = localStorage.getItem(keyGameTagsFilter);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(`Failed to parse '${value}'`, e);
    return null;
  }
}

export function setGameTagsFilter(value: GameTagsFilter): void {
  localStorage.setItem(keyGameTagsFilter, JSON.stringify(value));
}

export function removeGameTagsFilter(): void {
  localStorage.removeItem(keyGameTagsFilter);
}

function getSort(key: string): Sort | null {
  const value = localStorage.getItem(key);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(`Failed to parse '${value}'`, e);
    return null;
  }
}

export function getGamesSort(): Sort | null {
  return getSort(keyGamesSort);
}

export function setGamesSort(value: Sort): void {
  localStorage.setItem(keyGamesSort, JSON.stringify(value));
}

export function getImagesSort(): Sort | null {
  return getSort(keyImagesSort);
}

export function setImagesSort(value: Sort): void {
  localStorage.setItem(keyImagesSort, JSON.stringify(value));
}

export function getGameCollectionsSort(): Sort | null {
  return getSort(keyGameCollectionsSort);
}

export function setGameCollectionsSort(value: Sort): void {
  localStorage.setItem(keyGameCollectionsSort, JSON.stringify(value));
}

export function getGameTagsSort(): Sort | null {
  return getSort(keyGameTagsSort);
}

export function setGameTagsSort(value: Sort): void {
  localStorage.setItem(keyGameTagsSort, JSON.stringify(value));
}

export function getTournamentsSort(): Sort | null {
  return getSort(keyTournamentsSort);
}

export function setTournamentsSort(value: Sort): void {
  localStorage.setItem(keyTournamentsSort, JSON.stringify(value));
}

export function getBannersSort(): Sort | null {
  return getSort(keyBannersSort);
}

export function setBannersSort(value: Sort): void {
  localStorage.setItem(keyBannersSort, JSON.stringify(value));
}

export function getPromotionsSort(): Sort | null {
  return getSort(keyPromotionsSort);
}

export function setPromotionsSort(value: Sort): void {
  localStorage.setItem(keyPromotionsSort, JSON.stringify(value));
}

export function getGameProvidersSort(): Sort | null {
  return getSort(keyGameProvidersSort);
}

export function setGameProvidersSort(value: Sort): void {
  localStorage.setItem(keyGameProvidersSort, JSON.stringify(value));
}

function getBoolean(key: string, defaultValue: boolean): boolean {
  const value = localStorage.getItem(key);
  if (!value) {
    return defaultValue;
  }

  return value === `true`;
}
