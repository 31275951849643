import { Language } from "@vipscasino/core";
import React from "react";
import styles from "./Languages.module.scss";

interface Props {
  existingLangs: Language[];
  selectedLang?: Language;
  selectedClassName?: string;
  /**
   * If undefined, all langs are considered selected
   */
  onLangSelected?: (lang: Language) => void;
}

const Languages: React.FC<Props> = (props) => {
  return (
    <span>
      {props.existingLangs.map((lang, i) => {
        let className = ``;
        if (props.selectedClassName) {
          if (props.selectedLang === lang || !props.onLangSelected) {
            className = props.selectedClassName;
          }
        }
        if (props.onLangSelected) {
          className = `${className} ${styles.selectable}`;
        }
        return (
          <div
            key={i}
            className={className}
            onClick={() =>
              props.onLangSelected ? props.onLangSelected(lang) : null
            }
          >
            {lang}
          </div>
        );
      })}
    </span>
  );
};

export default Languages;
