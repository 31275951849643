import { Country } from "@vipscasino/core";
import React from "react";
import { ReactComponent as FlagCa } from "../assets/icons/flags/ca.svg";
import { ReactComponent as FlagCl } from "../assets/icons/flags/cl.svg";
import { ReactComponent as FlagDe } from "../assets/icons/flags/de.svg";
import { ReactComponent as FlagFi } from "../assets/icons/flags/fi.svg";
import { ReactComponent as FlagGb } from "../assets/icons/flags/gb.svg";
import { ReactComponent as FlagIn } from "../assets/icons/flags/in.svg";
import { ReactComponent as FlagNo } from "../assets/icons/flags/no.svg";
import { ReactComponent as FlagNz } from "../assets/icons/flags/nz.svg";
import { ReactComponent as FlagPe } from "../assets/icons/flags/pe.svg";
import { ReactComponent as FlagWorld } from "../assets/icons/flags/world.svg";
import { handleError } from "../lib/util/errorHandler";

interface Props extends React.SVGProps<SVGSVGElement> {
  country: Country;
}

const Flag: React.FC<Props> = (props) => {
  switch (props.country) {
    case `ZZ`:
      return <FlagWorld {...props} />;
    case `CA`:
      return <FlagCa {...props} />;
    case `NZ`:
      return <FlagNz {...props} />;
    case `GB`:
      return <FlagGb {...props} />;
    case `IN`:
      return <FlagIn {...props} />;
    case `DE`:
      return <FlagDe {...props} />;
    case `FI`:
      return <FlagFi {...props} />;
    case `NO`:
      return <FlagNo {...props} />;
    case `PE`:
      return <FlagPe {...props} />;
    case `CL`:
      return <FlagCl {...props} />;
    default:
      handleError(`No SVG flag found for country: ${props.country}`);
      return <></>;
  }
};

export default Flag;
