import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../AppContext";
import { getReadableFileSize } from "../lib/util/fileUtil";
import Chip from "./Chip";
import Dropzone from "./Dropzone";
import styles from "./FileUpload.module.scss";
import Icon from "./Icon";

interface Props {
  url: string;
  method: UploadMethod;
  placeholder?: string;
  maxFileSize?: number;
  maxTotalFileSize?: number;
  multi?: boolean;
  validTypes?: string[];
  uploadingMessage?: string;
  onUploaded: (uploadResult: string) => void;
  onCancel?: () => void;
}

export type UploadMethod = "POST" | "PUT";

const defaultMaxFileSize = 3 * 1024 * 1024;
const defaultMaxTotalFileSize = 5 * 1024 * 1024;
const defaultUploadingMessage = `Uploading file(s) ...`;

const FileUpload: React.FC<Props> = (props) => {
  const { state } = useContext(AppContext);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string>();

  let maxFileSize = props.maxFileSize ? props.maxFileSize : defaultMaxFileSize;
  if (props.maxTotalFileSize) {
    maxFileSize = Math.min(maxFileSize, props.maxTotalFileSize);
  }

  let maxTotalFileSize = props.maxTotalFileSize
    ? props.maxTotalFileSize
    : defaultMaxTotalFileSize;
  maxTotalFileSize = Math.max(maxFileSize, maxTotalFileSize);

  const uploadingMessage = props.uploadingMessage
    ? props.uploadingMessage
    : defaultUploadingMessage;

  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  function tooLargeFileError(file: File): string {
    return `${file.name} is too large (${file.size} bytes). Max size is ${maxFileSize} bytes.`;
  }

  function totalFileSize(): number {
    return files.map((file) => file.size).reduce((f1, f2) => f1 + f2, 0);
  }

  async function onFilesAdded(selectedFiles: File[]): Promise<void> {
    let filesToAdd = selectedFiles.filter(
      (file) => !props.validTypes || props.validTypes.includes(file.type),
    );

    if (!props.multi) {
      filesToAdd = filesToAdd.splice(0, 1);
      setFiles([]);
    }

    let error = ``;
    filesToAdd.forEach((file) => {
      if (file.size > maxFileSize) {
        error = `${error} ${tooLargeFileError(file)}`;
      }
    });
    error = error.trim();

    if (error.length > 0) {
      setFiles([]);
      setError(error);
    } else {
      let totFileSize = totalFileSize();
      filesToAdd = filesToAdd
        .filter((file) => {
          totFileSize += file.size;
          return totFileSize <= maxTotalFileSize;
        })
        .filter(
          (file) =>
            !files.find((f) => f.name === file.name && f.size === file.size),
        );
      if (!props.multi && filesToAdd.length > 0) {
        await uploadFiles(filesToAdd);
      } else {
        setFiles((oldFiles) => [...oldFiles, ...filesToAdd]);
        setError(undefined);
      }
    }
  }

  async function uploadFiles(files: File[]): Promise<void> {
    if (files.length === 0) {
      return;
    }

    setError(undefined);
    setUploading(true);

    const formData = new FormData();
    let fileIndex = 1;
    files.forEach((file) => {
      formData.append(`file${fileIndex}`, file);
      fileIndex++;
    });

    try {
      const response = await fetch(props.url, {
        method: props.method,
        headers: {
          authorization: state.user
            ? state.user.googleLoginResponse.getAuthResponse().id_token
            : ``,
        },
        body: formData,
      });

      setFiles([]);
      if (response.ok) {
        setError(undefined);
        props.onUploaded(await response.text());
      } else {
        setError(await response.text());
      }
    } catch (e) {
      console.error(`File upload failed: `, e);
      if (mounted.current) {
        setError(e.message);
      }
    } finally {
      if (mounted.current) {
        setUploading(false);
      }
    }
  }

  return (
    <div>
      {props.onCancel && (
        <Icon
          name="chevron-left"
          className={`${styles.back} ${uploading ? styles.backDisabled : ``}`}
          onClick={() => {
            if (!uploading) {
              props.onCancel!();
            }
          }}
        />
      )}
      <div className={styles.content}>
        <div className={styles.dropzone}>
          <Dropzone onFilesAdded={onFilesAdded} disabled={uploading} />
        </div>
        <div className={styles.contentRight}>
          <div className={styles.files}>
            {uploading ? (
              <p className={styles.loader}>{uploadingMessage}</p>
            ) : files.length === 0 ? (
              <span
                className={`${styles.placeholder} ${error ? styles.error : ``}`}
              >
                {error
                  ? error
                  : props.placeholder
                  ? props.placeholder
                  : `No file${props.multi ? `s` : ``} selected`}
              </span>
            ) : (
              <div className={styles.chips}>
                {files.map((file, i) => {
                  return (
                    <Chip
                      key={i}
                      title={`${file.name} (${getReadableFileSize(file.size)})`}
                      onRemove={() => {
                        const newfiles = files.filter(
                          (f) => f.name !== file.name || f.size !== file.size,
                        );
                        setFiles(newfiles);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.uploadAction}>
            <button
              disabled={files.length === 0 || uploading}
              onClick={() => uploadFiles(files)}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
