import {
  defaultLanguage,
  Language,
} from "@vipscasino/core/lib/language/language";
import i18n, { ResourceLanguage } from "i18next";
import { initReactI18next } from "react-i18next";
import appDe from "../../node_modules/@vipscasino/shared-frontend/src/i18n/de.json";
import appEn from "../../node_modules/@vipscasino/shared-frontend/src/i18n/en.json";
import appEs from "../../node_modules/@vipscasino/shared-frontend/src/i18n/es.json";
import appFi from "../../node_modules/@vipscasino/shared-frontend/src/i18n/fi.json";
import appNo from "../../node_modules/@vipscasino/shared-frontend/src/i18n/no.json";

i18n.use(initReactI18next).init({
  debug: false,
  ns: [`app`],
  defaultNS: `app`,
  resources: getResources(),
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
});

export default i18n;

function getResources(): { [key in Language]: ResourceLanguage } {
  return {
    en: appEn,
    fi: appFi,
    no: appNo,
    de: appDe,
    es: appEs,
  };
}
