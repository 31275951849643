import { Brand } from "@vipscasino/core";
import {
  isTournamentLive,
  isTournamentPublished,
  isTournamentUpcoming,
  Tournament,
} from "./tournamentService";

export interface TournamentsFilter {
  published?: boolean;
  live?: boolean;
  upcoming?: boolean;
  brand?: Brand;
  activeFrom?: number;
  activeTo?: number;
  publishedFrom?: number;
  publishedTo?: number;
  createdStart?: number;
  createdEnd?: number;
}

export function filterTournaments(
  filter: TournamentsFilter,
  tournaments: Tournament[],
): Tournament[] {
  let result = [...tournaments];

  if (filter.published) {
    result = result.filter((tournament) => isTournamentPublished(tournament));
  }
  if (filter.live) {
    result = result.filter((tournament) => isTournamentLive(tournament));
  }
  if (filter.upcoming) {
    result = result.filter((tournament) => isTournamentUpcoming(tournament));
  }
  if (filter.brand) {
    result = result.filter((tournament) => tournament.brand === filter.brand);
  }
  if (filter.activeFrom) {
    const activeFrom = filter.activeFrom;
    result = result.filter((tournament) => {
      return tournament.activeFrom.toMillis() <= activeFrom;
    });
  }
  if (filter.activeTo) {
    const activeTo = filter.activeTo;
    result = result.filter((tournament) => {
      return tournament.activeTo.toMillis() >= activeTo;
    });
  }
  if (filter.publishedFrom) {
    const publishedFrom = filter.publishedFrom;
    result = result.filter((tournament) => {
      return tournament.publishedFrom.toMillis() <= publishedFrom;
    });
  }
  if (filter.publishedTo) {
    const publishedTo = filter.publishedTo;
    result = result.filter((tournament) => {
      return tournament.publishedTo.toMillis() >= publishedTo;
    });
  }
  if (filter.createdStart) {
    const createdStart = filter.createdStart;
    result = result.filter((tournament) => {
      return tournament.created.toMillis() >= createdStart;
    });
  }
  if (filter.createdEnd) {
    const createdEnd = filter.createdEnd;
    result = result.filter((tournament) => {
      return tournament.created.toMillis() <= createdEnd;
    });
  }
  return result;
}

export function filterIsEmpty(filter: TournamentsFilter): boolean {
  return Object.values(filter).every((v) => v === undefined);
}
