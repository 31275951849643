import { Brand, PromotionType } from "@vipscasino/core";
import {
  isPromotionLive,
  isPromotionUpcoming,
  Promotion,
} from "./promotionService";

export interface PromotionsFilter {
  active?: boolean;
  live?: boolean;
  upcoming?: boolean;
  brand?: Brand;
  type?: PromotionType;
  validFrom?: number;
  validTo?: number;
  createdStart?: number;
  createdEnd?: number;
}

export function filterPromotions(
  filter: PromotionsFilter,
  promotions: Promotion[],
): Promotion[] {
  let result = [...promotions];
  if (filter.active) {
    result = result.filter((promotion) => {
      return promotion.active;
    });
  }
  if (filter.live) {
    result = result.filter((promotion) => isPromotionLive(promotion));
  }
  if (filter.upcoming) {
    result = result.filter((promotion) => isPromotionUpcoming(promotion));
  }
  if (filter.brand) {
    result = result.filter((promotion) => promotion.brand === filter.brand);
  }
  if (filter.type) {
    result = result.filter((promotion) => {
      return promotion.type === filter.type;
    });
  }
  if (filter.validFrom) {
    const validFrom = filter.validFrom;
    result = result.filter((promotion) => {
      return promotion.validFrom.toMillis() <= validFrom;
    });
  }
  if (filter.validTo) {
    const validTo = filter.validTo;
    result = result.filter((promotion) => {
      return promotion.validTo.toMillis() >= validTo;
    });
  }
  if (filter.createdStart) {
    const createdStart = filter.createdStart;
    result = result.filter((promotion) => {
      return promotion.created.toMillis() >= createdStart;
    });
  }
  if (filter.createdEnd) {
    const createdEnd = filter.createdEnd;
    result = result.filter((promotion) => {
      return promotion.created.toMillis() <= createdEnd;
    });
  }
  return result;
}

export function filterIsEmpty(filter: PromotionsFilter): boolean {
  return Object.values(filter).every((v) => v === undefined);
}
