import {
  imagePlatforms,
  imageTypes,
  isImagePlatform,
  isImageType,
} from "@vipscasino/core";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../component/CustomDatePicker";
import FilterForm from "../component/FilterForm";
import FilterFormButton from "../component/FilterFormButton";
import FilterFormItem from "../component/FilterFormItem";
import { removeImagesFilter, setImagesFilter } from "../lib/storage";
import { filterIsEmpty, ImagesFilter } from "./imagesFilter";

interface Props {
  filter: ImagesFilter;
  onChange: (filter: ImagesFilter) => void;
}

const ImagesFilterComp: React.FC<Props> = (props) => {
  const [filter, setFilter] = useState<ImagesFilter>(props.filter);
  const [applyTimeout, setApplyTimeout] = useState<
    NodeJS.Timeout | undefined
  >();

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (filterIsEmpty(filter)) {
      removeImagesFilter();
    } else {
      setImagesFilter(filter);
    }
  }, [filter]);

  function updateFilter(
    filter: ImagesFilter,
    applyTimeoutMillis: number,
  ): void {
    setFilter(filter);

    if (applyTimeout) {
      clearTimeout(applyTimeout);
    }

    if (applyTimeoutMillis > 0) {
      setApplyTimeout(
        setTimeout(() => {
          props.onChange(filter);
        }, applyTimeoutMillis),
      );
    } else {
      props.onChange(filter);
    }
  }

  function onChange(field: ImagesFilter, applyTimeoutMillis = 300): void {
    updateFilter({ ...filter, ...field }, applyTimeoutMillis);
  }

  return (
    <FilterForm>
      <FilterFormItem label="Name">
        <input
          type="text"
          value={filter.name ? filter.name : ``}
          placeholder="Search by name"
          onChange={(event) => {
            const name = event.currentTarget.value;
            onChange({ name: name ? name : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Type">
        <select
          value={filter.type ? filter.type : ``}
          onChange={(event) => {
            const type = event.currentTarget.value;
            if (!type) {
              onChange({ type: undefined });
            } else if (isImageType(type)) {
              onChange({ type: type });
            }
          }}
        >
          <option />
          {imageTypes.map((type, i) => (
            <option key={i} value={type}>
              {type}
            </option>
          ))}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Platform">
        <select
          value={filter.platform ? filter.platform : ``}
          onChange={(event) => {
            const platform = event.currentTarget.value;
            if (!platform) {
              onChange({ platform: undefined });
            } else if (isImagePlatform(platform)) {
              onChange({ platform: platform });
            }
          }}
        >
          <option />
          {Array.from(imagePlatforms.entries()).map(([platform, name], i) => (
            <option key={i} value={platform}>
              {name}
            </option>
          ))}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Created between">
        <CustomDatePicker
          selected={filter.createdStart ? new Date(filter.createdStart) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ createdStart: date ? date.getTime() : undefined });
          }}
        />
        &nbsp;-&nbsp;
        <CustomDatePicker
          selected={filter.createdEnd ? new Date(filter.createdEnd) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ createdEnd: date ? date.getTime() : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormButton
        label="Reset"
        type="reset"
        onClick={() => updateFilter({}, 300)}
      />
    </FilterForm>
  );
};

export default ImagesFilterComp;
