import {
  gameCollection,
  GameDescription as FirestoreGameDescription,
  gameDescriptionCollection,
  GameDescriptionCreateRequest,
  GameDescriptionTranslation,
  GameDescriptionTranslations,
  GameDescriptionTranslationsUpdateRequest,
  GameDescriptionTranslationUpdate,
  GameDescriptionUpdateRequest,
  Language,
  languages,
} from "@vipscasino/core";
import firebase from "firebase/app";
import { TFunction } from "i18next";
import { config } from "../config";
import { firebaseApp } from "../lib/firebase";

const db = firebaseApp.firestore();

export interface GameDescription
  extends FirestoreGameDescription<firebase.firestore.Timestamp> {
  id: string;
}

export const gameDescriptionParsePropKeys = [
  `name`,
  `provider`,
  `wheels`,
  `winLines`,
  `subCategory`,
  `releaseYear`,
  `volatility`,
  `rtp`,
] as const;

export type GameDescriptionParsePropKey = typeof gameDescriptionParsePropKeys[number];

export type GameDescriptionParseProps = {
  [field in GameDescriptionParsePropKey]?: string;
};

const descriptions = new Map<string, GameDescription>();
let cancelSubscription: () => void;

export function loadGameDescriptions(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collectionGroup(gameDescriptionCollection)
    .onSnapshot((snapshot) => {
      snapshot
        .docChanges()
        .filter((change) => {
          const doc = change.doc;
          const gameRef = doc.ref.parent.parent;
          return gameRef && gameRef.parent.id === gameCollection;
        })
        .forEach((change) => {
          const doc = change.doc;
          const gameRef = doc.ref.parent.parent;
          const gameId = gameRef ? gameRef.id : ``;
          const description = {
            ...doc.data(),
            id: doc.id,
          } as GameDescription;
          if (change.type === `removed`) {
            descriptions.delete(gameId);
          } else {
            descriptions.set(gameId, description);
          }
        });
      window.dispatchEvent(new Event(`game-descriptions-loaded`));
    });
}

export function getGameDescription(
  gameId: string,
): GameDescription | undefined {
  return descriptions.get(gameId);
}

export function parseTranslation(
  translation: string,
  language: Language,
  t: TFunction,
  props: GameDescriptionParseProps,
): string {
  let result: string = translation;

  Object.keys(props).forEach((key) => {
    const propKey = key as keyof GameDescriptionParseProps;
    const propVal = props[propKey];

    if (propVal) {
      let val = propVal;

      if (propKey === `subCategory`) {
        val = t(val, { lng: language });
      } else if (propKey === `volatility`) {
        val = t(`volatility_${val}`, { lng: language });
      }

      result = result.replace(i18nReplace(key), val);
    }
  });

  return result;
}

function i18nReplace(prop: string): string {
  return `{{- ${prop}}}`;
}

export function getDescriptionLanguages(
  translations: Map<Language, GameDescriptionTranslation>,
): Language[] {
  return languages.filter((lang) => {
    return translationExists(translations.get(lang));
  });
}

function translationExists(t: GameDescriptionTranslation | undefined): boolean {
  return !!t && translationIsNonEmpty(t);
}

function translationIsNonEmpty(t: GameDescriptionTranslation): boolean {
  return t.description.trim().length > 0;
}

export async function saveDescription(
  gameId: string,
  description: GameDescription | undefined,
  translations: Map<Language, GameDescriptionTranslation>,
  headers: HeadersInit,
): Promise<void> {
  const data = description
    ? getUpdateRequest(description, translations)
    : getCreateRequest(translations);

  if (!data) {
    return;
  }

  const url = `${config.api}/games/${gameId}/descriptions/${
    description ? `${description.id}/` : ``
  }`;

  await fetch(url, {
    method: `${description ? `PUT` : `POST`}`,
    headers: headers,
    body: JSON.stringify(data),
  });
}

function getCreateRequest(
  translations: Map<Language, GameDescriptionTranslation>,
): GameDescriptionCreateRequest | undefined {
  const gdt: GameDescriptionTranslations = {};

  for (const [lang, t] of Array.from(translations.entries())) {
    if (t.description.trim().length > 0) {
      gdt[lang] = t;
    }
  }

  if (Object.keys(gdt).length === 0) {
    // no translations
    return undefined;
  }

  return {
    translations: gdt,
  };
}

function getUpdateRequest(
  description: GameDescription | undefined,
  translations: Map<Language, GameDescriptionTranslation>,
): GameDescriptionUpdateRequest | undefined {
  const tur = getTranslationsUpdateRequest(description, translations);
  if (Object.keys(tur).length === 0) {
    // no translation changes
    return undefined;
  }

  return {
    translations: tur,
  };
}

function getTranslationsUpdateRequest(
  description: GameDescription | undefined,
  translations: Map<Language, GameDescriptionTranslation>,
): GameDescriptionTranslationsUpdateRequest {
  const tur: GameDescriptionTranslationsUpdateRequest = {};

  for (const [lang, t] of Array.from(translations.entries())) {
    const tu = getTranslationUpdate(lang, description, t);
    if (!tu) {
      continue;
    }

    if (translationIsNonEmpty(t)) {
      tur[lang] = tu;
    } else if (description?.translations[lang]) {
      tur[lang] = `removed`;
    }
  }

  return tur;
}

function getTranslationUpdate(
  lang: Language,
  description: GameDescription | undefined,
  t: GameDescriptionTranslation,
): GameDescriptionTranslationUpdate | undefined {
  const tNew = { ...t };

  if (description?.translations[lang]) {
    let tu: GameDescriptionTranslationUpdate = {};
    const tOld = description.translations[lang]!;
    if (tNew.description !== tOld.description) {
      tu = { ...tu, description: tNew.description };
    }
    return Object.keys(tu).length > 0 ? tu : undefined;
  }

  return translationIsNonEmpty(tNew) ? { ...tNew } : undefined;
}
