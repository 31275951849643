import {
  Information as Info,
  informationCollection,
  informationId,
} from "@vipscasino/core";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import AppContext from "../AppContext";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarItem from "../component/ActionToolbarItem";
import Icon from "../component/Icon";
import Loader from "../component/Loader";
import { config } from "../config";
import { firebaseApp } from "../lib/firebase";
import { defaultHeaders } from "../lib/util/httpUtil";
import styles from "./Information.module.scss";

const db = firebaseApp.firestore();

const Information: React.FC = () => {
  const { state } = useContext(AppContext);
  const [message, setMessage] = useState<string>(``);
  const [newMessage, setNewMessage] = useState<string>(``);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = db
      .collection(informationCollection)
      .doc(informationId)
      .onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const info = snapshot.data() as Info;
          setMessage(info.message);
          setNewMessage(info.message);
        } else {
          setMessage(``);
        }
        setLoading(false);
      });
    return () => unsubscribe();
  }, []);

  async function onSave(): Promise<void> {
    setSaving(true);

    const url = `${config.api}/information/`;
    await fetch(url, {
      method: `PUT`,
      headers: defaultHeaders(state),
      body: JSON.stringify({ message: newMessage }),
    });

    setSaving(false);
  }

  async function onDelete(): Promise<void> {
    const response = window.confirm(
      `Are you sure you want to delete this message?`,
    );
    if (!response) {
      return;
    }
    setSaving(true);

    const url = `${config.api}/information/`;
    await fetch(url, {
      method: `DELETE`,
      headers: defaultHeaders(state),
    });

    setMessage(``);
    setNewMessage(``);
    setSaving(false);
  }
  // async function delete(): Promise<void> {
  //   setSaving(true);

  //   const url = `${config.api}/information/`;
  //   await fetch(url, {
  //     method: "DELETE",
  //     headers: defaultHeaders(state),
  //     body: JSON.stringify({ message: newMessage }),
  //   });

  //   setSaving(false);
  // }

  if (loading) {
    return <Loader message="Loading information..." />;
  }

  console.info(message, newMessage);
  return (
    <section className={styles.main}>
      <ActionToolbar name="Information">
        <ActionToolbarItem
          type="button"
          icon={<Icon name="save" />}
          name="Save"
          disabled={saving || message === newMessage}
          onClick={onSave}
        />
        <ActionToolbarItem
          type="button"
          icon={<Icon name="delete" />}
          name="Delete"
          disabled={saving || message === undefined}
          onClick={onDelete}
        />
      </ActionToolbar>

      <label>This message will be shown on site</label>
      <textarea
        value={newMessage}
        onChange={(event) => setNewMessage(event.target.value)}
      ></textarea>
    </section>
  );
};

export default Information;
