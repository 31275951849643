import {
  Country,
  getTranslatedCountries,
  getTranslatedLanguages,
  isGamePromotion,
  isPromotionBanner,
  isRegistrationPromotion,
  Language,
  promotionDisplayOptions,
} from "@vipscasino/core";
import firebase from "firebase/app";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import AppContext from "../AppContext";
import { BannerProps } from "../banner/BannerEdit";
import { getBanners } from "../banner/bannerService";
import ActionRow from "../component/ActionRow";
import ActionRowItem from "../component/ActionRowItem";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarContext from "../component/ActionToolbarContext";
import ActionToolbarItem from "../component/ActionToolbarItem";
import {
  ActionToolbarActionType,
  actionToolbarReducer,
  initialActionToolbarState,
} from "../component/ActionToolbarState";
import Flags from "../component/Flags";
import HorizontalScroll from "../component/HorizontalScroll";
import Icon from "../component/Icon";
import Languages from "../component/Languages";
import Loader from "../component/Loader";
import SortableTable from "../component/SortableTable";
import { config } from "../config";
import { getGame } from "../game/gameService";
import { compare, defaultSortOrder, Sort } from "../lib/sort";
import {
  getPromotionsFilter,
  getPromotionsSort,
  setPromotionsSort,
} from "../lib/storage";
import { defaultHeaders } from "../lib/util/httpUtil";
import UserListModal from "../user/UserListModal";
import { getPromotionUsers } from "../user/userRefService";
import {
  PromotionPreview,
  PromotionPreviewProps,
} from "./PromotionOrTournamentPreview";
import styles from "./Promotions.module.scss";
import {
  getPromotions,
  isPromotionLive,
  isPromotionUpcoming,
  Promotion,
} from "./promotionService";
import PromotionsFilterComp from "./PromotionsFilter";
import {
  filterIsEmpty,
  filterPromotions,
  PromotionsFilter,
} from "./promotionsFilter";

const path = `promotions`;

enum SortField {
  Brand = `Brand`,
  Path = `Path`,
  Live = `Live`,
  Upcoming = `Upcoming`,
  Type = `Type`,
  DisplayOption = `DisplayOption`,
  Priority = `Priority`,
  ValidFrom = `ValidFrom`,
  ValidTo = `ValidTo`,
  Created = `Created`,
  Modified = `Modified`,
}

const defaultSortField = SortField.Modified;

const Promotions: React.FC = () => {
  const { state } = useContext(AppContext);
  const [actionToolbarState, actionToolbarDispatch] = useReducer(
    actionToolbarReducer,
    initialActionToolbarState,
  );
  const [promotions, setPromotions] = useState<Promotion[] | undefined>();
  const [filteredPromotions, setFilteredPromotions] = useState<Promotion[]>([]);
  const [filter, setFilter] = useState<PromotionsFilter>(
    getPromotionsFilter() ?? {},
  );
  // const [showFilter] = useState(getShowPromotionsFilter());
  const [loading, setLoading] = useState(true);
  const [showIds, setShowIds] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [sort, setSort] = useState<Sort>(
    getPromotionsSort() ?? {
      field: defaultSortField,
      order: defaultSortOrder,
    },
  );
  const [optInPromotionId, setOptInPromotionId] = useState<
    string | undefined
  >();
  const [previewPromotion, setPreviewPromotion] = useState<
    Promotion | undefined
  >();
  const [previewCountry, setPreviewCountry] = useState<Country | undefined>();
  const [previewLang, setPreviewLang] = useState<Language | undefined>();
  const [desktopPreview, setDesktopPreview] = useState(true);

  // set promotions when loaded/updated
  useEffect(() => {
    if (state.promotionsLoaded) {
      setPromotions(getPromotions());
    }
  }, [state.promotionsLoaded]);

  // filter promotions
  useEffect(() => {
    if (promotions) {
      setFilteredPromotions(filterPromotions(filter, promotions));
      setLoading(false);
    }
  }, [filter, promotions]);

  if (loading) {
    return <Loader message="Loading promotions ..." />;
  }

  async function remove(id: string): Promise<void> {
    const referencingBanners = getBanners(
      (banner) =>
        isPromotionBanner<unknown, firebase.firestore.DocumentReference>(
          banner,
        ) && banner.promotion.id === id,
    );
    if (referencingBanners.length > 0) {
      const bannerString = referencingBanners
        .map((b) => `id=${b.id}`)
        .join(`, `);
      window.alert(
        `This promotion is referenced by the following banner${
          referencingBanners.length > 1 ? `s` : ``
        } [${bannerString}], and cannot be removed.`,
      );
    } else if (
      window.confirm(`Are you sure you want to remove this promotion?`)
    ) {
      const url = `${config.api}/${path}/${id}/`;
      const res = await fetch(url, {
        method: `DELETE`,
        headers: defaultHeaders(state),
      });
      if (promotions && res.status === 200) {
        setPromotions(promotions.filter((p) => p.id !== id));
      }
    }
  }

  function isSortField(sortField: string): sortField is SortField {
    return Object.values(SortField).includes(sortField as SortField);
  }

  function sortFieldValues(
    field: string,
    p1: Promotion,
    p2: Promotion,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] {
    switch (field) {
      case SortField.Brand:
        return [p1.brand, p2.brand];
      case SortField.Path:
        return [p1.path, p2.path];
      case SortField.Live:
        return [isPromotionLive(p1), isPromotionLive(p2)];
      case SortField.Upcoming:
        return [isPromotionUpcoming(p1), isPromotionUpcoming(p2)];
      case SortField.Type:
        return [typeText(p1), typeText(p2)];
      case SortField.DisplayOption:
        return [p1.displayOption, p2.displayOption];
      case SortField.Priority:
        return [
          p1.priority ? -p1.priority : undefined,
          p2.priority ? -p2.priority : undefined,
        ];
      case SortField.ValidFrom:
        return [p1.validFrom, p2.validFrom];
      case SortField.ValidTo:
        return [p1.validTo, p2.validTo];
      case SortField.Created:
        return [p1.created, p2.created];
      case SortField.Modified:
        return [p1.modified.date, p2.modified.date];
      default:
        throw new Error(`Unsupported sort field: ` + field);
    }
  }

  function getSortedPromotions(): Promotion[] {
    const sortField = isSortField(sort.field) ? sort.field : defaultSortField;
    return filteredPromotions.sort((p1, p2) => {
      const [val1, val2] = sortFieldValues(sortField, p1, p2);
      return compare(val1, val2, sort.order);
    });
  }

  function onSort(sort: Sort): void {
    setPromotionsSort(sort);
    setSort(sort);
  }

  function previewProps(
    promotion: Promotion,
    country: Country,
    lang: Language,
  ): PromotionPreviewProps {
    const image = desktopPreview
      ? promotion.image.desktop.default
      : promotion.image.mobile.default;
    const translation =
      promotion.translations[country] && promotion.translations[country]![lang];

    return {
      desktop: desktopPreview,
      image: image,
      language: lang,
      translation: translation,
      type: promotion.type,
      validFrom: promotion.validFrom.toDate(),
      validTo: promotion.validTo.toDate(),
      modified: promotion.modified.date.toDate(),
      onRequestClose: () => {
        setPreviewPromotion(undefined);
      },
    };
  }

  return (
    <div className={styles.main}>
      <ActionToolbarContext.Provider
        value={{ actionToolbarState, actionToolbarDispatch }}
      >
        <ActionToolbar name="Promotions">
          <ActionToolbarItem
            type="link"
            icon={<Icon name="add" />}
            name="Add"
            to={`/${path}/add`}
          />

          <ActionToolbarItem
            type="button"
            icon={<Icon name="filter" />}
            name="Filter"
            active={filterActive}
            notification={!filterIsEmpty(filter)}
            onClick={() => {
              setFilterActive((value) => !value);
              actionToolbarDispatch({
                type: ActionToolbarActionType.ShowExpanded,
                component: actionToolbarState.expanded ? undefined : (
                  <PromotionsFilterComp
                    filter={filter}
                    onChange={(filter: PromotionsFilter) => setFilter(filter)}
                  />
                ),
              });
            }}
          />
        </ActionToolbar>
      </ActionToolbarContext.Provider>
      {optInPromotionId && (
        <UserListModal
          users={[...getPromotionUsers(optInPromotionId)].sort(
            (u1, u2) =>
              u2.modified.date.toMillis() - u1.modified.date.toMillis(),
          )}
          modifiedLabel={`Opt-in date`}
          onRequestClose={() => {
            setOptInPromotionId(undefined);
          }}
        />
      )}
      {previewPromotion && previewCountry && previewLang && (
        <PromotionPreview
          {...previewProps(previewPromotion, previewCountry, previewLang)}
        />
      )}
      <HorizontalScroll>
        <SortableTable
          sort={sort}
          onSort={onSort}
          columns={[
            { name: `Actions` },
            {
              name: `ID`,
              onShowToggle: () => setShowIds((value) => !value),
              show: showIds,
            },
            { name: `Countries` },
            { name: `Languages` },
            {
              name: `Brand`,
              sortField: SortField.Brand,
            },
            {
              name: `Path`,
              sortField: SortField.Path,
            },
            {
              name: `Live`,
              sortField: SortField.Live,
            },
            {
              name: `Upcoming`,
              sortField: SortField.Upcoming,
            },
            {
              name: `Type`,
              sortField: SortField.Type,
            },
            { name: `Reference` },
            { name: `Preview` },
            { name: `Opt-in button text` },
            {
              name: `Displayed when`,
              sortField: SortField.DisplayOption,
            },
            {
              name: `Priority`,
              sortField: SortField.Priority,
            },
            {
              name: `Valid From`,
              sortField: SortField.ValidFrom,
            },
            {
              name: `Valid To`,
              sortField: SortField.ValidTo,
            },
            {
              name: `Created`,
              sortField: SortField.Created,
            },
            {
              name: `Modified`,
              sortField: SortField.Modified,
            },
          ]}
        >
          {getSortedPromotions().map((promotion, i) => {
            return (
              <PromotionComp
                key={i}
                promotion={promotion}
                onRemove={remove}
                showIds={showIds}
                onShowOptInUsers={(id) => {
                  setOptInPromotionId(id);
                }}
                onPreview={(promotion, desktop, country, lang) => {
                  setPreviewPromotion(promotion);
                  setDesktopPreview(desktop);
                  setPreviewCountry(country);
                  setPreviewLang(lang);
                }}
              />
            );
          })}
        </SortableTable>
      </HorizontalScroll>
    </div>
  );
};

export default Promotions;

function typeText(promotion: Promotion): string {
  if (
    isRegistrationPromotion(promotion) &&
    (promotion.referrers?.length ?? 0) > 0
  ) {
    return `${promotion.type} (affiliate)`;
  }
  return promotion.type;
}

interface PromotionProps {
  promotion: Promotion;
  showIds: boolean;
  onRemove: (id: string) => Promise<void>;
  onShowOptInUsers: (id: string) => void;
  onPreview: (
    promotion: Promotion,
    desktop: boolean,
    country: Country,
    lang: Language,
  ) => void;
}

const PromotionComp: React.FC<PromotionProps> = (props) => {
  const { state } = useContext(AppContext);
  const history = useHistory<BannerProps>();
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>();
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [removing, setRemoving] = useState(false);

  const promotion = props.promotion;
  const id = promotion.id;

  // reset selected language when changing country
  useEffect(() => {
    if (selectedCountry) {
      const langs = getTranslatedLanguages(
        promotion.translations[selectedCountry] ?? {},
      );
      if (langs.length > 0) {
        setSelectedLang(langs[0]);
      } else {
        setSelectedLang(undefined);
      }
    }
  }, [promotion.translations, selectedCountry]);

  const existingCountries = getTranslatedCountries(promotion.translations);
  const currentCountry =
    selectedCountry ??
    (existingCountries.length > 0 ? existingCountries[0] : undefined);
  const existingLangs = currentCountry
    ? getTranslatedLanguages(promotion.translations[currentCountry] ?? {})
    : [];
  const currentLang =
    selectedLang ?? (existingLangs.length > 0 ? existingLangs[0] : undefined);
  const translation =
    currentCountry &&
    currentLang &&
    promotion.translations[currentCountry] &&
    promotion.translations[currentCountry]![currentLang];

  function countries(): JSX.Element {
    return (
      <Flags
        existingCountries={existingCountries}
        onCountrySelected={setSelectedCountry}
        selectedCountry={currentCountry}
        selectedClassName={styles.selected}
      />
    );
  }

  function langs(): JSX.Element {
    return (
      <Languages
        existingLangs={existingLangs}
        onLangSelected={setSelectedLang}
        selectedLang={currentLang}
        selectedClassName={styles.selected}
      />
    );
  }

  function type(): string {
    return typeText(promotion);
  }

  function typeRef(): JSX.Element {
    if (
      isGamePromotion<unknown, firebase.firestore.DocumentReference>(promotion)
    ) {
      const game = getGame(promotion.game.id);
      if (game) {
        return (
          <NavLink to={`/games/${game.id}`}>{`${game.name} - ${game.external
            .filter((e) => e.active)
            .map((e) => e.id)
            .join(` - `)}`}</NavLink>
        );
      }
    }
    return <></>;
  }

  function live(): string {
    return isPromotionLive(promotion) ? `✓` : ``;
  }

  function upcoming(): string {
    return isPromotionUpcoming(promotion) ? `✓` : ``;
  }

  function optInButtonText(): string | undefined {
    return translation?.optInButtonText;
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  function validFrom(): string {
    return dateTimeString(promotion.validFrom.toDate() as Date);
  }

  function validTo(): string {
    return dateTimeString(promotion.validTo.toDate() as Date);
  }

  async function remove(): Promise<void> {
    setRemoving(true);
    await props.onRemove(id);
    setRemoving(false);
  }

  const created = promotion.created.toDate() as Date;
  const modified = promotion.modified.date.toDate();
  return (
    <tr>
      <td>
        <ActionRow>
          <ActionRowItem iconName="edit" to={`/${path}/${id}`} title="Edit" />
          <ActionRowItem
            iconName="copy"
            to={`/${path}/${id}/clone`}
            title="Clone"
          />
          <ActionRowItem
            iconName="add-image"
            onClick={() =>
              history.push(`/banners/add`, { promotionId: promotion.id })
            }
            title="Create Banner"
          />
          {translation?.optInButtonText && state.userRefsLoaded && (
            <ActionRowItem
              iconName="users"
              onClick={() => props.onShowOptInUsers(id)}
              title="Show opt-in users"
            />
          )}
          <ActionRowItem
            iconName="delete"
            onClick={() => (removing ? null : remove())}
            title="Delete"
          />
        </ActionRow>
      </td>
      <td className={styles.id}>{props.showIds && id}</td>
      <td className={styles.flags}>{countries()}</td>
      <td className={styles.languages}>{langs()}</td>
      <td>{promotion.brand}</td>
      <td>{promotion.path}</td>
      <td className={styles.checked}>{live()}</td>
      <td className={styles.checked}>{upcoming()}</td>
      <td>{type()}</td>
      <td>{typeRef()}</td>
      <td>
        {currentCountry && currentLang && (
          <div className={styles.preview}>
            <div
              className={styles.platformItem}
              onClick={() =>
                props.onPreview(promotion, true, currentCountry, currentLang)
              }
              title="Desktop Preview"
            >
              <Icon name="desktop" className={styles.platformIcon} />
            </div>
            <div
              className={styles.platformItem}
              onClick={() =>
                props.onPreview(promotion, false, currentCountry, currentLang)
              }
              title="Mobile Preview"
            >
              <Icon name="mobile" className={styles.platformIcon} />
            </div>
          </div>
        )}
      </td>
      <td>{optInButtonText()}</td>
      <td>{promotionDisplayOptions.get(promotion.displayOption)}</td>
      <td>{promotion.priority}</td>
      <td>{validFrom()}</td>
      <td>{validTo()}</td>
      <td title={dateTimeString(created)} style={{ whiteSpace: `nowrap` }}>
        {created.toLocaleDateString(`sv`)}
      </td>
      <td title={dateTimeString(modified)} style={{ whiteSpace: `nowrap` }}>
        {modified.toLocaleDateString(`sv`)}
      </td>
    </tr>
  );
};
