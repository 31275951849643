import React from "react";
import { AppAction, AppState, initialState } from "./AppState";

interface AppContextValue {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
}

const AppContext = React.createContext<AppContextValue>({
  state: initialState,
  dispatch: () => {
    // do nothing
  },
});

export default AppContext;
