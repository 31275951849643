import {
  GameTag,
  gameTagCollection,
  GameTagCreateRequest,
  GameTagName,
  GameTagType,
  gameTagTypes,
  GameTagUpdateRequest,
  getTranslatedLanguages,
  isGameTagType,
  Language,
  languages,
} from "@vipscasino/core";
import firebase from "firebase/app";
import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router";
import AppContext from "../../AppContext";
import ActionToolbar from "../../component/ActionToolbar";
import ActionToolbarItem from "../../component/ActionToolbarItem";
import Icon from "../../component/Icon";
import KeyValueGrid from "../../component/KeyValueGrid";
import KeyValueGridItem from "../../component/KeyValueGridItem";
import Loader from "../../component/Loader";
import { config } from "../../config";
import { firebaseApp } from "../../lib/firebase";
import { defaultHeaders } from "../../lib/util/httpUtil";
import Translations from "../../translation/Translations";
import TranslationsItem from "../../translation/TranslationsItem";
import styles from "./GameTagEdit.module.scss";
import { keyExists } from "./gameTagService";

const db = firebaseApp.firestore();

interface Params {
  id?: string;
}

const GameTagEdit: React.FC = () => {
  const history = useHistory();
  const params = useParams<Params>();
  const id = params.id;
  const { state } = useContext(AppContext);
  const [gameTag, setGameTag] = useState<
    GameTag<firebase.firestore.Timestamp> | undefined
  >();
  const [loading, setLoading] = useState<boolean>(!!id);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [valid, setValid] = useState<boolean>(!!id);
  const [key, setKey] = useState<string | undefined>();
  const [type, setType] = useState<GameTagType | undefined>();
  const [nameMap, setNameMap] = useState<Map<Language, string>>(new Map());
  const keyRef = useRef<HTMLInputElement>(null);

  // validate form
  useEffect(() => {
    if (!id) {
      let valid = true;
      if (!type) {
        valid = false;
      } else if (!key || key.trim().length === 0 || keyExists(key)) {
        valid = false;
      }
      setValid(valid);
    }
  }, [id, key, type]);

  // load game tag
  useEffect(() => {
    if (id) {
      db.collection(gameTagCollection)
        .doc(id)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            throw Error(`GameTag with id ${id} does not exist`);
          }

          const tag = snapshot.data() as GameTag<firebase.firestore.Timestamp>;
          setGameTag(tag);

          const translations = new Map<Language, string>();
          const name = tag.name;
          if (name) {
            for (const lang of getTranslatedLanguages(name)) {
              translations.set(lang, name[lang]!);
            }
            setNameMap(translations);
          }
          if (translations.size > 0) {
            setSelectedLang(Array.from(translations.keys())[0]);
          }

          setLoading(false);
        });
    }
  }, [id]);

  // validate key
  useEffect(() => {
    if (keyRef.current) {
      if (keyExists(keyRef.current.value)) {
        keyRef.current.setCustomValidity(`Key already exists`);
      } else {
        keyRef.current.setCustomValidity(``);
      }
    }
  }, [key]);

  if (loading) {
    return <Loader message="Loading tag ..." />;
  }

  if (saving) {
    return <Loader message="Saving tag ..." />;
  }

  async function save(): Promise<void> {
    setSaving(true);

    const data = id ? getUpdateRequest() : getCreateRequest();
    const url = `${config.api}/game_tags/${id ? `${id}/` : ``}`;
    await fetch(url, {
      method: `${id ? `PUT` : `POST`}`,
      headers: defaultHeaders(state),
      body: JSON.stringify(data),
    });

    setSaving(false);
    history.goBack();
  }

  function getUpdateRequest(): GameTagUpdateRequest {
    return {
      name: getTagName(),
    };
  }

  function getCreateRequest(): GameTagCreateRequest {
    return {
      key: key!,
      type: type!,
      name: getTagName(),
    };
  }

  function getTagName(): GameTagName {
    const nur: GameTagName = {};

    for (const [lang, n] of Array.from(nameMap.entries())) {
      const nu = getNameUpdate(lang, n);
      if (nu !== undefined) {
        nur[lang] = nu;
      }
    }

    return nur;
  }

  function getNameUpdate(lang: Language, name: string): string | undefined {
    const trimmedName = name.trim();
    if (gameTag?.name?.[lang]) {
      return trimmedName !== gameTag.name[lang]! ? trimmedName : undefined;
    }
    return trimmedName.length > 0 ? trimmedName : undefined;
  }

  function cancel(): void {
    history.goBack();
  }

  function onNameUpdate(name: string): void {
    if (!selectedLang) {
      return;
    }

    const nameMapCopy: Map<Language, string> = new Map(nameMap);
    nameMapCopy.set(selectedLang, name);
    setNameMap(nameMapCopy);
  }

  function existingLangs(): Language[] {
    return languages.filter((lang) => {
      const name = nameMap.get(lang);
      return name !== undefined && name.trim().length > 0;
    });
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  return (
    <section className={styles.main}>
      <ActionToolbar name={`${gameTag ? `Edit` : `Add`} tag`}>
        <ActionToolbarItem
          type="button"
          icon={<Icon name="cancel" />}
          name="Cancel"
          onClick={() => cancel()}
        />
        <ActionToolbarItem
          type="button"
          icon={<Icon name="save" />}
          name="Save"
          disabled={!valid}
          onClick={() => save()}
        />
      </ActionToolbar>

      <KeyValueGrid>
        {id && (
          <KeyValueGridItem label="ID" editable={false}>
            {id}
          </KeyValueGridItem>
        )}

        {gameTag && (
          <KeyValueGridItem label="Created" editable={false}>
            {dateTimeString(gameTag.created.toDate() as Date)}
          </KeyValueGridItem>
        )}

        {gameTag ? (
          <KeyValueGridItem label="Key" editable={false}>
            {gameTag.key}
          </KeyValueGridItem>
        ) : (
          <KeyValueGridItem label="Key" editable>
            <input
              type="text"
              required
              ref={keyRef}
              placeholder="Add a key"
              defaultValue=""
              onChange={(event) => {
                setKey(event.currentTarget.value);
              }}
            />
          </KeyValueGridItem>
        )}

        {gameTag ? (
          <KeyValueGridItem label="Type" editable={false}>
            {gameTag.type}
          </KeyValueGridItem>
        ) : (
          <KeyValueGridItem label="Type" editable>
            <select
              defaultValue={undefined}
              onChange={(event) => {
                const type = event.currentTarget.value;
                if (!isGameTagType(type)) {
                  throw new Error(`invalid Game Tag Type: ` + type);
                }
                setType(type);
              }}
            >
              {!type && <option value="" />}
              {gameTagTypes.map((type, i) => (
                <option key={i} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </KeyValueGridItem>
        )}

        <KeyValueGridItem label="Translations" editable noPadding>
          <Translations
            selectedLang={selectedLang}
            existingLangs={existingLangs()}
            onSelectedLang={(lang) => setSelectedLang(lang)}
          >
            <TranslationsItem label="Name">
              <input
                type="text"
                disabled={!selectedLang}
                placeholder="Add a name"
                value={
                  selectedLang && nameMap.get(selectedLang)
                    ? nameMap.get(selectedLang)!
                    : ``
                }
                onChange={(event) => {
                  onNameUpdate(event.currentTarget.value);
                }}
              />
            </TranslationsItem>
          </Translations>
        </KeyValueGridItem>
      </KeyValueGrid>
    </section>
  );
};

export default GameTagEdit;
