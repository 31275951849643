import { gameTagTypes, isGameTagType } from "@vipscasino/core";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../../component/CustomDatePicker";
import FilterForm from "../../component/FilterForm";
import FilterFormButton from "../../component/FilterFormButton";
import FilterFormItem from "../../component/FilterFormItem";
import { removeGameTagsFilter, setGameTagsFilter } from "../../lib/storage";
import { filterIsEmpty, GameTagsFilter } from "./gameTagsFilter";

interface Props {
  filter: GameTagsFilter;
  onChange: (filter: GameTagsFilter) => void;
}

const GameTagsFilterComp: React.FC<Props> = (props) => {
  const [filter, setFilter] = useState<GameTagsFilter>(props.filter);
  const [applyTimeout, setApplyTimeout] = useState<
    NodeJS.Timeout | undefined
  >();

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (filterIsEmpty(filter)) {
      removeGameTagsFilter();
    } else {
      setGameTagsFilter(filter);
    }
  }, [filter]);

  function updateFilter(
    filter: GameTagsFilter,
    applyTimeoutMillis: number,
  ): void {
    setFilter(filter);

    if (applyTimeout) {
      clearTimeout(applyTimeout);
    }

    if (applyTimeoutMillis > 0) {
      setApplyTimeout(
        setTimeout(() => {
          props.onChange(filter);
        }, applyTimeoutMillis),
      );
    } else {
      props.onChange(filter);
    }
  }

  function onChange(field: GameTagsFilter, applyTimeoutMillis = 300): void {
    updateFilter({ ...filter, ...field }, applyTimeoutMillis);
  }

  return (
    <FilterForm>
      <FilterFormItem label="Search">
        <input
          type="text"
          value={filter.query ? filter.query : ``}
          placeholder="Search by name or key"
          onChange={(event) => {
            const query = event.currentTarget.value;
            onChange({ query: query ? query : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Type">
        <select
          value={filter.type ? filter.type : ``}
          onChange={(event) => {
            const type = event.currentTarget.value;
            if (!type) {
              onChange({ type: undefined });
            } else if (isGameTagType(type)) {
              onChange({ type: type });
            }
          }}
        >
          <option />
          {gameTagTypes.map((type, i) => (
            <option key={i} value={type}>
              {type}
            </option>
          ))}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Created between">
        <CustomDatePicker
          selected={filter.createdStart ? new Date(filter.createdStart) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ createdStart: date ? date.getTime() : undefined });
          }}
        />
        &nbsp;-&nbsp;
        <CustomDatePicker
          selected={filter.createdEnd ? new Date(filter.createdEnd) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ createdEnd: date ? date.getTime() : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormButton
        label="Reset"
        type="reset"
        onClick={() => updateFilter({}, 300)}
      />
    </FilterForm>
  );
};

export default GameTagsFilterComp;
