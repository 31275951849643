import {
  bannerDisplayOptions,
  BannerType,
  Country,
  getTranslatedLanguages,
  isGameBanner,
  isLinkBanner,
  isPromotionBanner,
  Language,
  languages,
  PromotionTranslation,
  TournamentTranslation,
} from "@vipscasino/core";
import { isTournamentBanner } from "@vipscasino/core/lib/banner/banner";
import {
  CountryTranslations,
  getTranslatedCountries,
  Translations,
} from "@vipscasino/core/lib/translation/translation";
import firebase from "firebase/app";
import React, { useContext, useEffect, useReducer, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from "react-router-dom";
import AppContext from "../AppContext";
import ActionRow from "../component/ActionRow";
import ActionRowItem from "../component/ActionRowItem";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarContext from "../component/ActionToolbarContext";
import ActionToolbarItem from "../component/ActionToolbarItem";
import {
  ActionToolbarActionType,
  actionToolbarReducer,
  initialActionToolbarState,
} from "../component/ActionToolbarState";
import Flags from "../component/Flags";
import HorizontalScroll from "../component/HorizontalScroll";
import Icon from "../component/Icon";
import Languages from "../component/Languages";
import Loader from "../component/Loader";
import SortableTable from "../component/SortableTable";
import { config } from "../config";
import { getGame } from "../game/gameService";
import { compare, defaultSortOrder, Sort } from "../lib/sort";
import {
  getBannersFilter,
  getBannersSort,
  setBannersSort,
} from "../lib/storage";
import { defaultHeaders } from "../lib/util/httpUtil";
import { getPromotion } from "../promotion/promotionService";
import { getTournament } from "../tournament/tournamentService";
import BannerPreview, { BannerPreviewProps } from "./BannerPreview";
import styles from "./Banners.module.scss";
import {
  Banner,
  BannerReadyStatus,
  getBannerLiveStatus,
  getBanners,
  getBannerType,
  getBannerUpcomingStatus,
  isBannerLive,
  isBannerUpcoming,
} from "./bannerService";
import { BannersFilter, filterBanners, filterIsEmpty } from "./bannersFilter";
import BannersFilterComp from "./BannersFilter";

const path = `banners`;

enum SortField {
  Brand = `Brand`,
  Live = `Live`,
  Upcoming = `Upcoming`,
  Type = `Type`,
  Platform = `Platform`,
  DisplayOption = `DisplayOption`,
  Priority = `Priority`,
  ValidFrom = `ValidFrom`,
  ValidTo = `ValidTo`,
  Created = `Created`,
  Modified = `Modified`,
}

const defaultSortField = SortField.Modified;

const Banners: React.FC = () => {
  const { state } = useContext(AppContext);
  const [actionToolbarState, actionToolbarDispatch] = useReducer(
    actionToolbarReducer,
    initialActionToolbarState,
  );
  const [banners, setBanners] = useState<Banner[] | undefined>();
  const [filteredBanners, setFilteredBanners] = useState<Banner[]>([]);
  const [filter, setFilter] = useState<BannersFilter>(getBannersFilter() ?? {});
  const [loading, setLoading] = useState(true);
  const [showIds, setShowIds] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [sort, setSort] = useState<Sort>(
    getBannersSort() ?? {
      field: defaultSortField,
      order: defaultSortOrder,
    },
  );
  const [previewBanner, setPreviewBanner] = useState<Banner | undefined>();
  const [previewCountry, setPreviewCountry] = useState<Country | undefined>();
  const [previewLang, setPreviewLang] = useState<Language | undefined>();
  const [desktopPreview, setDesktopPreview] = useState(true);

  // set banners when loaded/updated
  useEffect(() => {
    if (state.bannersLoaded) {
      setBanners(getBanners());
    }
  }, [state.bannersLoaded]);

  // filter banners
  useEffect(() => {
    if (banners) {
      setFilteredBanners(filterBanners(filter, banners));
      setLoading(false);
    }
  }, [filter, banners]);

  if (
    loading ||
    !state.gamesLoaded ||
    !state.promotionsLoaded ||
    !state.tournamentsLoaded
  ) {
    return <Loader message="Loading banners ..." />;
  }

  async function remove(id: string): Promise<void> {
    if (window.confirm(`Are you sure you want to remove this banner?`)) {
      const url = `${config.api}/${path}/${id}/`;
      const res = await fetch(url, {
        method: `DELETE`,
        headers: defaultHeaders(state),
      });
      if (banners && res.status === 200) {
        setBanners(banners.filter((b) => b.id !== id));
      }
    }
  }

  function isSortField(sortField: string): sortField is SortField {
    return Object.values(SortField).includes(sortField as SortField);
  }

  function sortFieldValues(
    field: string,
    b1: Banner,
    b2: Banner,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] {
    switch (field) {
      case SortField.Brand:
        return [b1.brand, b2.brand];
      case SortField.Live:
        return [isBannerLive(b1), isBannerLive(b2)];
      case SortField.Upcoming:
        return [isBannerUpcoming(b1), isBannerUpcoming(b2)];
      case SortField.Type:
        return [getBannerType(b1), getBannerType(b2)];
      case SortField.Platform:
        return [b1.platform, b2.platform];
      case SortField.DisplayOption:
        return [b1.displayOption, b2.displayOption];
      case SortField.Priority:
        return [
          b1.priority ? -b1.priority : undefined,
          b2.priority ? -b2.priority : undefined,
        ];
      case SortField.ValidFrom:
        return [b1.validFrom, b2.validFrom];
      case SortField.ValidTo:
        return [b1.validTo, b2.validTo];
      case SortField.Created:
        return [b1.created, b2.created];
      case SortField.Modified:
        return [b1.modified.date, b2.modified.date];
      default:
        throw new Error(`Unsupported sort field: ` + field);
    }
  }

  function getSortedBanners(): Banner[] {
    const sortField = isSortField(sort.field) ? sort.field : defaultSortField;
    return filteredBanners.sort((b1, b2) => {
      const [val1, val2] = sortFieldValues(sortField, b1, b2);
      return compare(val1, val2, sort.order);
    });
  }

  function onSort(sort: Sort): void {
    setBannersSort(sort);
    setSort(sort);
  }

  function previewProps(banner: Banner): BannerPreviewProps {
    const layout = banner.layout;
    const platformLayout = desktopPreview ? layout.desktop : layout.mobile;
    const image = desktopPreview
      ? banner.image.desktop.default
      : banner.image.mobile.default;
    const translation =
      previewCountry &&
      previewLang &&
      banner.translations[previewCountry] &&
      banner.translations[previewCountry]![previewLang];
    const platformTranslation = translation
      ? desktopPreview
        ? translation.desktop
        : translation.mobile
      : undefined;
    const text = platformTranslation?.text;
    const buttonText = platformTranslation?.buttonText;

    return {
      desktop: desktopPreview,
      layout: platformLayout,
      image: image,
      text: text,
      buttonText: buttonText,
      onRequestClose: () => {
        setPreviewBanner(undefined);
      },
    };
  }

  return (
    <div className={styles.main}>
      <ActionToolbarContext.Provider
        value={{ actionToolbarState, actionToolbarDispatch }}
      >
        <ActionToolbar name="Banners">
          <ActionToolbarItem
            type="link"
            icon={<Icon name="add" />}
            name="Add"
            to={`/${path}/add`}
          />

          <ActionToolbarItem
            type="button"
            icon={<Icon name="filter" />}
            name="Filter"
            active={filterActive}
            notification={!filterIsEmpty(filter)}
            onClick={() => {
              setFilterActive((value) => !value);
              actionToolbarDispatch({
                type: ActionToolbarActionType.ShowExpanded,
                component: actionToolbarState.expanded ? undefined : (
                  <BannersFilterComp
                    filter={filter}
                    onChange={(filter: BannersFilter) => setFilter(filter)}
                  />
                ),
              });
            }}
          />
        </ActionToolbar>
      </ActionToolbarContext.Provider>
      {previewBanner && <BannerPreview {...previewProps(previewBanner)} />}
      <HorizontalScroll>
        <SortableTable
          sort={sort}
          onSort={onSort}
          columns={[
            { name: `Actions` },
            {
              name: `ID`,
              onShowToggle: () => setShowIds((value) => !value),
              show: showIds,
            },
            { name: `Countries` },
            { name: `Languages` },
            {
              name: `Brand`,
              sortField: SortField.Brand,
            },
            {
              name: `Live`,
              sortField: SortField.Live,
            },
            {
              name: `Upcoming`,
              sortField: SortField.Upcoming,
            },
            {
              name: `Type`,
              sortField: SortField.Type,
            },
            { name: `Reference` },
            {
              name: `Platforms`,
              sortField: SortField.Platform,
            },
            {
              name: `Displayed when`,
              sortField: SortField.DisplayOption,
            },
            {
              name: `Priority`,
              sortField: SortField.Priority,
            },
            {
              name: `Valid From`,
              sortField: SortField.ValidFrom,
            },
            {
              name: `Valid To`,
              sortField: SortField.ValidTo,
            },
            {
              name: `Created`,
              sortField: SortField.Created,
            },
            {
              name: `Modified`,
              sortField: SortField.Modified,
            },
          ]}
        >
          {getSortedBanners().map((banner, i) => {
            return (
              <BannerComp
                key={i}
                banner={banner}
                onRemove={remove}
                showIds={showIds}
                onPreview={(banner, desktop, country, lang) => {
                  setPreviewBanner(banner);
                  setDesktopPreview(desktop);
                  setPreviewCountry(country);
                  setPreviewLang(lang);
                }}
              />
            );
          })}
        </SortableTable>
      </HorizontalScroll>
    </div>
  );
};

export default Banners;

interface BannerProps {
  banner: Banner;
  showIds: boolean;
  onRemove: (id: string) => Promise<void>;
  onPreview: (
    banner: Banner,
    desktop: boolean,
    country?: Country,
    lang?: Language,
  ) => void;
}

const BannerComp: React.FC<BannerProps> = (props) => {
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>();
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [removing, setRemoving] = useState(false);

  const banner = props.banner;
  const id = banner.id;
  const liveStatus = getBannerLiveStatus(banner);
  const upcomingStatus = getBannerUpcomingStatus(banner);

  // reset selected language when changing country
  useEffect(() => {
    if (selectedCountry) {
      const langs = getTranslatedLanguages(
        banner.translations[selectedCountry] ?? {},
      );
      if (langs.length > 0) {
        setSelectedLang(langs[0]);
      } else {
        setSelectedLang(undefined);
      }
    }
  }, [banner.translations, selectedCountry]);

  const existingCountries = getTranslatedCountries(banner.translations);
  const currentCountry =
    selectedCountry ??
    (existingCountries.length > 0 ? existingCountries[0] : undefined);
  const existingLangs = currentCountry
    ? getTranslatedLanguages(banner.translations[currentCountry] ?? {})
    : [];
  const currentLang =
    selectedLang ?? (existingLangs.length > 0 ? existingLangs[0] : undefined);

  function countries(): JSX.Element {
    return (
      <Flags
        existingCountries={existingCountries}
        onCountrySelected={setSelectedCountry}
        selectedCountry={currentCountry}
        selectedClassName={styles.selected}
      />
    );
  }

  function langs(): JSX.Element {
    return (
      <Languages
        existingLangs={existingLangs}
        onLangSelected={setSelectedLang}
        selectedLang={currentLang}
        selectedClassName={styles.selected}
      />
    );
  }

  function liveOrUpcoming(status: BannerReadyStatus): string {
    return status === BannerReadyStatus.Ready ||
      status === BannerReadyStatus.PartReady
      ? `✓`
      : ``;
  }

  function liveOrUpcomingTitle(status: BannerReadyStatus): string {
    if (status !== BannerReadyStatus.PartReady) {
      return ``;
    }

    const refT = isPromotionBanner<
      unknown,
      firebase.firestore.DocumentReference
    >(banner)
      ? getPromotion(banner.promotion.id)?.translations
      : isTournamentBanner<unknown, firebase.firestore.DocumentReference>(
          banner,
        )
      ? getTournament(banner.tournament.id)?.translations
      : {};

    if (!refT) {
      // should never happen
      return ``;
    }

    const refCountries = getTranslatedCountries(refT);
    const bCountries = getTranslatedCountries(banner.translations);
    const missingCountries: Country[] = [];
    for (const country of bCountries) {
      if (!refCountries.includes(country)) {
        missingCountries.push(country);
      }
    }

    return `Missing countries: ${missingCountries.join(`, `)}`;
  }

  function type(): BannerType {
    return getBannerType(banner);
  }

  function typeRef(): JSX.Element {
    function getTranslation<T>(
      translations: CountryTranslations<T>,
    ): T | undefined {
      let country: Country | undefined;
      if (currentCountry) {
        country = currentCountry;
      } else {
        const existingCountries = getTranslatedCountries(translations);
        if (existingCountries.length > 0) {
          country = existingCountries[0];
        }
      }

      const ct = country ? translations[country] : undefined;
      if (!ct) {
        return undefined;
      }

      const lang = getLanguage(ct);
      return lang ? ct[lang] : undefined;
    }

    function getLanguage(
      translations: Translations<unknown>,
    ): Language | undefined {
      if (currentLang && translations[currentLang]) {
        return currentLang;
      } else {
        const existingLangs = getTranslatedLanguages(translations);
        return languages.find((lang) => existingLangs.includes(lang));
      }
    }

    if (isGameBanner<unknown, firebase.firestore.DocumentReference>(banner)) {
      const game = getGame(banner.game.id);
      if (game) {
        return (
          <NavLink to={`/games/${game.id}`}>{`${game.name} - ${game.external
            .filter((e) => e.active)
            .map((e) => e.id)
            .join(` - `)}`}</NavLink>
        );
      } else {
        return <></>;
      }
    } else if (
      isPromotionBanner<unknown, firebase.firestore.DocumentReference>(banner)
    ) {
      const promotion = getPromotion(banner.promotion.id);
      if (promotion) {
        const translation = getTranslation<PromotionTranslation>(
          promotion.translations,
        );
        const title = translation?.title ?? ``;
        return <NavLink to={`/promotions/${promotion.id}`}>{title}</NavLink>;
      } else {
        return <></>;
      }
    } else if (
      isTournamentBanner<unknown, firebase.firestore.DocumentReference>(banner)
    ) {
      const tournament = getTournament(banner.tournament.id);
      if (tournament) {
        const translation = getTranslation<TournamentTranslation>(
          tournament.translations,
        );
        const title = translation?.title ?? ``;
        return <NavLink to={`/tournaments/${tournament.id}`}>{title}</NavLink>;
      } else {
        return <></>;
      }
    } else if (isLinkBanner(banner)) {
      return <div>{banner.link}</div>;
    }
    return <></>;
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  function validFrom(): string {
    return dateTimeString(banner.validFrom.toDate());
  }

  function validTo(): string {
    return dateTimeString(banner.validTo.toDate());
  }

  async function remove(): Promise<void> {
    setRemoving(true);
    await props.onRemove(id);
    setRemoving(false);
  }

  const created = banner.created.toDate();
  const modified = banner.modified.date.toDate();
  return (
    <tr>
      <td>
        <ActionRow>
          <ActionRowItem iconName="edit" to={`/${path}/${id}`} title="Edit" />
          <ActionRowItem
            iconName="copy"
            to={`/${path}/${id}/clone`}
            title="Clone"
          />
          <ActionRowItem
            iconName="delete"
            onClick={() => (removing ? null : remove())}
            title="Delete"
          />
        </ActionRow>
      </td>
      <td className={styles.id}>{props.showIds && id}</td>
      <td className={styles.flags}>{countries()}</td>
      <td className={styles.languages}>{langs()}</td>
      <td>{banner.brand}</td>
      <td
        className={
          liveStatus === BannerReadyStatus.Ready
            ? styles.checked
            : styles.partlyChecked
        }
        title={liveOrUpcomingTitle(liveStatus)}
      >
        {liveOrUpcoming(liveStatus)}
      </td>
      <td
        className={
          upcomingStatus === BannerReadyStatus.Ready
            ? styles.checked
            : styles.partlyChecked
        }
        title={liveOrUpcomingTitle(upcomingStatus)}
      >
        {liveOrUpcoming(upcomingStatus)}
      </td>
      <td style={{ whiteSpace: `nowrap` }}>{type()}</td>
      <td>{typeRef()}</td>
      <td>
        <div className={styles.platform}>
          {banner.platform !== `mobile` && (
            <div
              className={styles.platformItem}
              onClick={() =>
                props.onPreview(banner, true, currentCountry, currentLang)
              }
              title="Desktop Preview"
            >
              <Icon name="desktop" className={styles.platformIcon} />
            </div>
          )}
          {banner.platform !== `desktop` && (
            <div
              className={styles.platformItem}
              onClick={() =>
                props.onPreview(banner, false, currentCountry, currentLang)
              }
              title="Mobile Preview"
            >
              <Icon name="mobile" className={styles.platformIcon} />
            </div>
          )}
        </div>
      </td>
      <td>{bannerDisplayOptions.get(banner.displayOption)}</td>
      <td>{banner.priority}</td>
      <td>{validFrom()}</td>
      <td>{validTo()}</td>
      <td title={dateTimeString(created)} style={{ whiteSpace: `nowrap` }}>
        {created.toLocaleDateString(`sv`)}
      </td>
      <td title={dateTimeString(modified)} style={{ whiteSpace: `nowrap` }}>
        {modified.toLocaleDateString(`sv`)}
      </td>
    </tr>
  );
};
