import React, { useState } from "react";
import styles from "./ImageComboBox.module.scss";
import { Image, searchImages } from "./imageService";

interface Props {
  onSelectedImage: (image: Image) => void;
  filter: (image: Image) => boolean;
  excludedIds?: string[];
}

const resultsLimit = 10;

const ImageComboBox: React.FC<Props> = (props) => {
  const [results, setResults] = useState<Image[] | undefined>();
  const [query, setQuery] = useState(``);

  return (
    <div className={styles.main}>
      <input
        type="search"
        className={styles.input}
        value={query}
        placeholder="Type to find an image to select"
        onChange={(event) => {
          const q = event.currentTarget.value;
          setQuery(q);
          setResults(searchImages(q, undefined, props.excludedIds));
        }}
      />
      {results && results.length > 0 && (
        <div className={styles.results}>
          {results
            .filter(props.filter)
            .splice(0, resultsLimit)
            .map((image, i) => {
              return (
                <div
                  key={i}
                  className={styles.result}
                  onClick={() => {
                    setQuery(``);
                    setResults(undefined);
                    props.onSelectedImage(image);
                  }}
                >
                  <img src={image.urls.thumb} alt="Thumb" />
                  <div className={styles.dimensions}>
                    {`${image.width}x${image.height}`}
                  </div>
                  <div className={styles.text}>
                    <HighlightText text={image.name} highlight={query} />
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default ImageComboBox;

const HighlightText: React.FC<{ text: string; highlight: string }> = (
  props,
) => {
  const regExp = new RegExp(`(${props.highlight})`, `gi`);
  const parts = props.text.split(regExp);

  return (
    <>
      {parts.map((part, i) => (
        <span
          key={i}
          className={`${
            part.toLowerCase() === props.highlight.toLowerCase() &&
            styles.highlight
          }`}
        >
          {part}
        </span>
      ))}
    </>
  );
};
