import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router-dom";
import AppContext from "../../AppContext";
import ActionToolbar from "../../component/ActionToolbar";
import ActionToolbarItem from "../../component/ActionToolbarItem";
import HorizontalScroll from "../../component/HorizontalScroll";
import Icon from "../../component/Icon";
import Loader from "../../component/Loader";
import SortableTable from "../../component/SortableTable";
import { defaultSortOrder } from "../../lib/sort";
import { Game, getGame } from "../gameService";
import styles from "./GameHistory.module.scss";
import {
  GameUpdateEntry,
  getGameUpdateEntry,
  loadGameHistory,
} from "./gameHistoryService";

interface Params {
  id: string;
}

const GameHistory: React.FC = () => {
  const { state } = useContext(AppContext);
  const history = useHistory();
  const [gameHistory, setGameHistory] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);

  const gameId = useParams<Params>().id;

  // load game history
  useEffect(() => {
    if (state.gamesLoaded) {
      const game = getGame(gameId);
      if (!game) {
        throw Error(`Game with id ${gameId} does not exist`);
      }
      loadGameHistory(game).then((gameHistory) => {
        setGameHistory(gameHistory);
        setLoading(false);
      });
    }
  }, [gameId, state.gamesLoaded]);

  if (loading) {
    return <Loader message="Loading history ..." />;
  }

  function getUpdateEntries(): GameUpdateEntry[] {
    return gameHistory
      .slice(0, gameHistory.length - 1)
      .map((game, i) => getGameUpdateEntry(gameHistory[i + 1], game))
      .filter((gu) => gu.changes.length !== 0);
  }

  return (
    <div className={styles.main}>
      <ActionToolbar name="Game History">
        <ActionToolbarItem
          type="button"
          icon={<Icon name="cancel" />}
          name="Cancel"
          onClick={() => history.goBack()}
        />
      </ActionToolbar>

      <HorizontalScroll>
        <SortableTable
          sort={{ field: ``, order: defaultSortOrder }}
          onSort={() => null}
          columns={[{ name: `Change` }, { name: `Date` }, { name: `User` }]}
        >
          {getUpdateEntries().map((update, i) => {
            return <GameHistoryRow key={i} update={update} />;
          })}
        </SortableTable>
      </HorizontalScroll>
    </div>
  );
};

export default GameHistory;

interface RowProps {
  update: GameUpdateEntry;
}

const GameHistoryRow: React.FC<RowProps> = (props) => {
  function changes(): JSX.Element {
    return (
      <table className={styles.changes}>
        <tbody>
          {props.update.changes.map((change, i) => {
            return (
              <tr key={i}>
                <td>{change.key}</td>
                <td>
                  <div className={styles.changeValue}>{change.before}</div>
                </td>
                <td>{`->`}</td>
                <td>
                  <div className={styles.changeValue}>{change.after}</div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  return (
    <tr>
      <td>{changes()}</td>
      <td style={{ whiteSpace: `nowrap` }}>
        {dateTimeString(props.update.modified.date)}
      </td>
      <td>{props.update.modified.user}</td>
    </tr>
  );
};
