import {
  promotionCollection,
  tournamentCollection,
  UserRef as FirestoreUserRef,
  userRefCollection,
} from "@vipscasino/core";
import firebase from "firebase/app";
import { firebaseApp } from "../lib/firebase";

const db = firebaseApp.firestore();

export interface UserRef
  extends FirestoreUserRef<firebase.firestore.Timestamp> {}

const promotionUsers: Map<string, UserRef[]> = new Map();
const tournamentUsers: Map<string, UserRef[]> = new Map();

let cancelUserRefSubscription: () => void;

export function loadUserRefs(): void {
  promotionUsers.clear();
  tournamentUsers.clear();

  if (cancelUserRefSubscription) {
    cancelUserRefSubscription();
  }

  cancelUserRefSubscription = db
    .collectionGroup(userRefCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const parentRef = doc.ref.parent.parent;
        if (parentRef) {
          addOrRemove(
            doc.data() as UserRef,
            parentRef.parent.id,
            parentRef.id,
            change.type === `removed`,
          );
        }
      });
      window.dispatchEvent(new Event(`user-refs-loaded`));
    });
}

function addOrRemove(
  userRef: UserRef,
  parentCollection: string,
  parentId: string,
  remove: boolean,
): void {
  let affectedMap: Map<string, UserRef[]> | undefined;
  if (parentCollection === promotionCollection) {
    affectedMap = promotionUsers;
  } else if (parentCollection === tournamentCollection) {
    affectedMap = tournamentUsers;
  }

  if (affectedMap) {
    if (remove) {
      const users = affectedMap.get(parentId);
      if (users) {
        const userIndex = users.findIndex((u) => u.userId === userRef.userId);
        if (userIndex > -1) {
          users.splice(userIndex, 1);
        }
      }
    } else {
      if (!affectedMap.has(parentId)) {
        affectedMap.set(parentId, []);
      }
      affectedMap.get(parentId)?.push(userRef);
    }
  }
}

export function getPromotionUsers(promotionId: string): UserRef[] {
  return promotionUsers.get(promotionId) ?? [];
}

export function getTournamentUsers(tournamentId: string): UserRef[] {
  return tournamentUsers.get(tournamentId) ?? [];
}
