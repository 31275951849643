import sv from "date-fns/locale/sv";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

registerLocale(`sv`, sv);
setDefaultLocale(`sv`);

const defaultDateFormat = `yyyy-MM-dd HH:mm:ss`;

interface DatePickerProps {
  selected: Date | null;
  placeholder?: string;
  optional?: boolean;
  omitTimeSelect?: boolean;
  dateFormat?: string;
  disabled?: boolean;
  onChange: (date: Date | null) => void;
}

const CustomDatePicker: React.FC<DatePickerProps> = (props) => {
  const [date, setDate] = useState(props.selected);

  useEffect(() => {
    setDate(props.selected);
  }, [props.selected]);

  return (
    <DatePicker
      selected={date}
      dateFormat={props.dateFormat ? props.dateFormat : defaultDateFormat}
      showTimeSelect={!props.omitTimeSelect}
      required={!props.optional}
      disabled={props.disabled}
      placeholderText={props.placeholder}
      onChange={(date) => {
        const singleDate = date as Date | null;
        setDate(singleDate);
        props.onChange(singleDate);
      }}
    />
  );
};

export default CustomDatePicker;
