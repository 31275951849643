import { Country } from "@vipscasino/core";
import React from "react";
import Flag from "./Flag";
import styles from "./Flags.module.scss";

interface Props {
  existingCountries: Country[];
  selectedCountry?: Country;
  selectedClassName?: string;
  /**
   * If undefined, all countries are considered selected
   */
  onCountrySelected?: (country: Country) => void;
}

const Flags: React.FC<Props> = (props) => {
  return (
    <>
      {props.existingCountries.map((country, i) => {
        let className = ``;
        if (props.selectedClassName) {
          if (props.selectedCountry === country || !props.onCountrySelected) {
            className = props.selectedClassName;
          }
        }
        if (props.onCountrySelected) {
          className = `${className} ${styles.selectable}`;
        }
        return (
          <Flag
            country={country}
            key={i}
            className={className}
            onClick={() =>
              props.onCountrySelected ? props.onCountrySelected(country) : null
            }
          />
        );
      })}
    </>
  );
};

export default Flags;
