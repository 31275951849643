import React from "react";
import styles from "./Loader.module.scss";

interface Props {
  message: string;
}

const Loader: React.FC<Props> = (props) => {
  return <p className={styles.loader}>{props.message}</p>;
};

export default Loader;
