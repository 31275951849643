import { GoogleLoginResponse } from "react-google-login";

export interface AppState {
  user?: SetUserPayload;
  gamesLoaded: number;
  gameDescriptionsLoaded: number;
  imagesLoaded: number;
  promotionsLoaded: number;
  tournamentsLoaded: number;
  bannersLoaded: number;
  gameCollectionsLoaded: number;
  gameProvidersLoaded: number;
  gameTagsLoaded: number;
  userRefsLoaded: number;
  affiliatesLoaded: number;
}

export const initialState: AppState = {
  gamesLoaded: 0,
  gameDescriptionsLoaded: 0,
  imagesLoaded: 0,
  promotionsLoaded: 0,
  tournamentsLoaded: 0,
  bannersLoaded: 0,
  gameCollectionsLoaded: 0,
  gameProvidersLoaded: 0,
  gameTagsLoaded: 0,
  userRefsLoaded: 0,
  affiliatesLoaded: 0,
};

export enum AppActionType {
  SetUser = `SetUser`,
  GamesLoaded = `GamesLoaded`,
  GameDescriptionsLoaded = `GameDescriptionsLoaded`,
  ImagesLoaded = `ImagesLoaded`,
  PromotionsLoaded = `PromotionsLoaded`,
  TournamentsLoaded = `TournamentsLoaded`,
  BannersLoaded = `BannersLoaded`,
  GameCollectionsLoaded = `GameCollectionsLoaded`,
  GameProvidersLoaded = `GameProvidersLoaded`,
  GameTagsLoaded = `GameTagsLoaded`,
  UserRefsLoaded = `UserRefsLoaded`,
  AffiliatesLoaded = `AffiliatesLoaded`,
}

export type AppAction =
  | {
      type: AppActionType.SetUser;
      payload?: SetUserPayload;
    }
  | {
      type: AppActionType.GamesLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.GameDescriptionsLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.ImagesLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.PromotionsLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.TournamentsLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.BannersLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.GameCollectionsLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.GameProvidersLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.GameTagsLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.UserRefsLoaded;
      loaded: number;
    }
  | {
      type: AppActionType.AffiliatesLoaded;
      loaded: number;
    };

export interface SetUserPayload {
  name: string;
  avatar: string;
  googleLoginResponse: GoogleLoginResponse;
}

export function reducer(state: AppState, action: AppAction): AppState {
  // console.info("action", action);
  switch (action.type) {
    case AppActionType.SetUser:
      return {
        ...state,
        user: action.payload,
      };
    case AppActionType.GamesLoaded:
      return {
        ...state,
        gamesLoaded: action.loaded,
      };
    case AppActionType.GameDescriptionsLoaded:
      return {
        ...state,
        gameDescriptionsLoaded: action.loaded,
      };
    case AppActionType.ImagesLoaded:
      return {
        ...state,
        imagesLoaded: action.loaded,
      };
    case AppActionType.PromotionsLoaded:
      return {
        ...state,
        promotionsLoaded: action.loaded,
      };
    case AppActionType.TournamentsLoaded:
      return {
        ...state,
        tournamentsLoaded: action.loaded,
      };
    case AppActionType.BannersLoaded:
      return {
        ...state,
        bannersLoaded: action.loaded,
      };
    case AppActionType.GameCollectionsLoaded:
      return {
        ...state,
        gameCollectionsLoaded: action.loaded,
      };
    case AppActionType.GameProvidersLoaded:
      return {
        ...state,
        gameProvidersLoaded: action.loaded,
      };
    case AppActionType.GameTagsLoaded:
      return {
        ...state,
        gameTagsLoaded: action.loaded,
      };
    case AppActionType.UserRefsLoaded:
      return {
        ...state,
        userRefsLoaded: action.loaded,
      };
    case AppActionType.AffiliatesLoaded:
      return {
        ...state,
        affiliatesLoaded: action.loaded,
      };
  }
}
