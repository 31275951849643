import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { removeGamesFilter, setGamesFilter } from "../lib/storage";
import styles from "./GameSearchFilter.module.scss";
import { filterIsEmpty, GamesFilter } from "./gamesFilter";

interface Props {
  filter: GamesFilter;
  onChange: (filter: GamesFilter) => void;
}

const GameSearchFilterComp: React.FC<Props> = (props) => {
  const [filter, setFilter] = useState<GamesFilter>(props.filter);
  const [applyTimeout, setApplyTimeout] = useState<
    NodeJS.Timeout | undefined
  >();

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (filterIsEmpty(filter)) {
      removeGamesFilter();
    } else {
      setGamesFilter(filter);
    }
  }, [filter]);

  function updateFilter(filter: GamesFilter): void {
    setFilter(filter);

    if (applyTimeout) {
      clearTimeout(applyTimeout);
    }

    setApplyTimeout(
      setTimeout(() => {
        props.onChange(filter);
      }, 500),
    );
  }

  function onChange(field: GamesFilter): void {
    updateFilter({ ...filter, ...field, showAll: undefined });
  }

  return (
    <input
      type="text"
      value={filter.name ? filter.name : ``}
      placeholder="ID or Name"
      className={styles.search}
      onChange={(event) => {
        const name = event.currentTarget.value;
        onChange({ name: name ? name : undefined });
      }}
    />
  );
};

export default GameSearchFilterComp;
