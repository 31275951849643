import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/firestore";

export const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

firebaseApp.analytics();

// if (process.env.NODE_ENV !== "test") {
//   firebaseApp
//     .firestore()
//     .enablePersistence({
//       synchronizeTabs: true,
//     })
//     .catch(err => handleError(err));
// }
