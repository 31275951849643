import elasticlunr from "../../elasticlunrCustom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let index: any;

export interface GameTagDoc {
  id: string;
  key: string;
  name: string;
}

export interface GameTagSearchResult {
  ref: string;
}

export function indexGameTags(docs: GameTagDoc[]): void {
  index = elasticlunr(function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`key`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`name`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.saveDocument(false);

    docs.forEach((doc) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.addDoc(doc);
    });
  });
}

export function searchGameTags(
  query: string,
): GameTagSearchResult[] | undefined {
  if (!index) {
    return undefined;
  }

  const res = index.search(query, {
    fields: {
      key: { boost: 1 },
      name: { boost: 1 },
    },
    bool: `AND`,
    expand: true,
  });

  return res;
}
