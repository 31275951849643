import * as elasticlunr from "elasticlunr";

// disabled, bad result when using builtin stemmer
elasticlunr.stemmer = (str: string) => str;
elasticlunr.clearStopWords();
elasticlunr.tokenizer = function (str: string) {
  if (!arguments.length || str === null || str === undefined) return [];

  return str
    .toString()
    .trim()
    .toLowerCase()
    .split(elasticlunr.tokenizer.seperator);
};
elasticlunr.tokenizer.seperator = /[\s-]+/;

export default elasticlunr;
