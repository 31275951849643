import elasticlunr from "../elasticlunrCustom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let gameIndex: any;

export interface GameDoc {
  id: string;
  externalId: string;
  name: string;
}

export interface GameSearchResult {
  ref: string;
}

export function indexGames(games: GameDoc[]): void {
  gameIndex = elasticlunr(function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`id`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`externalId`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`name`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.saveDocument(false);

    games.forEach((doc) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.addDoc(doc);
    });
  });
}

export function searchGames(query: string): GameSearchResult[] | undefined {
  if (!gameIndex) {
    return undefined;
  }

  const res = gameIndex.search(query, {
    fields: {
      id: { boost: 1 },
      externalId: { boost: 1 },
      name: { boost: 1 },
    },
    bool: `AND`,
    expand: true,
  });

  return res;
}
