import { GameTagType } from "@vipscasino/core";
import { GameTag, searchGameTags } from "./gameTagService";

export interface GameTagsFilter {
  query?: string;
  type?: GameTagType;
  createdStart?: number;
  createdEnd?: number;
}

export function filterGameTags(
  filter: GameTagsFilter,
  tags: GameTag[],
): GameTag[] {
  let result = [...tags];

  if (filter.query) {
    const query = filter.query;
    result = searchGameTags(query);
  }
  if (filter.type) {
    const type = filter.type;
    result = result.filter((tag) => {
      return tag.type === type;
    });
  }
  if (filter.createdStart) {
    const createdStart = filter.createdStart;
    result = result.filter((tag) => {
      return tag.created.toMillis() >= createdStart;
    });
  }
  if (filter.createdEnd) {
    const createdEnd = filter.createdEnd;
    result = result.filter((tag) => {
      return tag.created.toMillis() <= createdEnd;
    });
  }
  return result;
}

export function filterIsEmpty(filter: GameTagsFilter): boolean {
  return Object.values(filter).every((v) => v === undefined);
}
