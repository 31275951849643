export function getReadableFileSize(bytes: number): string {
  if (bytes <= 0) {
    return `unknown`;
  }

  const format = new Intl.NumberFormat(`en-US`, { maximumFractionDigits: 1 });

  const mb = 1024 * 1024;
  if (bytes / mb > 1) {
    return `${format.format(bytes / mb)} MB`;
  }

  const kb = 1024;
  if (bytes / kb > 1) {
    return `${format.format(bytes / kb)} KB`;
  }

  return `${bytes} B`;
}
