import { ImagePlatform, ImageType } from "@vipscasino/core";
import { Image, searchImages } from "./imageService";

export interface ImagesFilter {
  name?: string;
  type?: ImageType;
  platform?: ImagePlatform;
  createdStart?: number;
  createdEnd?: number;
}

export function filterImages(filter: ImagesFilter, images: Image[]): Image[] {
  let result = [...images];

  if (filter.name) {
    const name = filter.name;
    result = searchImages(name);
  }
  if (filter.type) {
    const type = filter.type;
    result = result.filter((image) => {
      return image.type === type;
    });
  }
  if (filter.platform) {
    const platform = filter.platform;
    result = result.filter((image) => {
      return image.platform === platform;
    });
  }
  if (filter.createdStart) {
    const createdStart = filter.createdStart;
    result = result.filter((image) => {
      return image.created.toMillis() >= createdStart;
    });
  }
  if (filter.createdEnd) {
    const createdEnd = filter.createdEnd;
    result = result.filter((image) => {
      return image.created.toMillis() <= createdEnd;
    });
  }
  return result;
}

export function filterIsEmpty(filter: ImagesFilter): boolean {
  return Object.values(filter).every((v) => v === undefined);
}
