export type SortOrder = "Asc" | "Desc";

export const defaultSortOrder: SortOrder = `Desc`;

export interface Sort {
  field: string;
  order: SortOrder;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function compare(val1: any, val2: any, sortOrder?: SortOrder): number {
  if (val1 === val2) {
    return 0;
  }
  if (val1 === undefined) {
    return sortOrder === `Asc` ? -1 : 1;
  }
  if (val2 === undefined) {
    return sortOrder === `Asc` ? 1 : -1;
  }
  if (val1 > val2) {
    return sortOrder === `Asc` ? 1 : -1;
  }
  if (val1 < val2) {
    return sortOrder === `Asc` ? -1 : 1;
  }
  return 0;
}
