import elasticlunr from "../elasticlunrCustom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let promotionIndex: any;

export interface PromotionDoc {
  id: string;
  title: string;
  path: string;
}

export interface PromotionSearchResult {
  ref: string;
}

export function indexPromotions(promotions: PromotionDoc[]): void {
  promotionIndex = elasticlunr(function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`id`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`title`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`path`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.saveDocument(false);

    promotions.forEach((doc) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.addDoc(doc);
    });
  });
}

export function searchPromotions(
  query: string,
): PromotionSearchResult[] | undefined {
  if (!promotionIndex) {
    return undefined;
  }

  const res = promotionIndex.search(query, {
    fields: {
      id: { boost: 1 },
      title: { boost: 1 },
      path: { boost: 1 },
    },
    bool: `AND`,
    expand: true,
  });

  return res;
}
