import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./ActionToolbarItem.module.scss";

type Props = {
  icon: JSX.Element;
  name: string;
  active?: boolean;
  notification?: boolean;
} & (
  | {
      type: "button";
      onClick: () => void;
      disabled?: boolean;
      action?: "button" | "submit" | "reset";
    }
  | {
      type: "button-submit";
      disabled?: boolean;
    }
  | {
      type: "link";
      to: string;
    }
);

const ActionToolbarItem: React.FC<Props> = (props) => {
  const css = `${styles.item} ${props.active && styles.active} ${
    props.notification && styles.notification
  }`;

  const IconAndName: React.FC = () => (
    <>
      {props.icon}
      <p>{props.name}</p>
    </>
  );

  switch (props.type) {
    case `button`:
      return (
        <button
          className={css}
          onClick={() => props.onClick()}
          disabled={props.disabled ?? false}
        >
          <IconAndName />
        </button>
      );
    case `button-submit`:
      return (
        <button
          type="submit"
          className={css}
          disabled={props.disabled ?? false}
        >
          <IconAndName />
        </button>
      );
    case `link`:
      return (
        <NavLink className={css} to={props.to}>
          <IconAndName />
        </NavLink>
      );
  }
};

export default ActionToolbarItem;
