import {
  defaultLanguage,
  gameCategories,
  gameCollection,
  GameCreateRequest,
  GameDescriptionTranslation,
  GameDescriptionTranslationUpdate,
  gameDisabledOptions,
  GameExternal,
  gameProviderCommissionTypes,
  gameSubCategories,
  GameUpdateRequest,
  gameVolatilities,
  gameWageringContributions,
  getTranslatedLanguages,
  isGameCategory,
  isGameDisabledOption,
  isGameProviderCommissionType,
  isGameSubCategory,
  isGameWageringContribution,
  isLicense,
  Language,
  licenses,
} from "@vipscasino/core";
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import AppContext from "../AppContext";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarContext from "../component/ActionToolbarContext";
import ActionToolbarItem from "../component/ActionToolbarItem";
import {
  ActionToolbarActionType,
  actionToolbarReducer,
  initialActionToolbarState,
} from "../component/ActionToolbarState";
import CustomDatePicker from "../component/CustomDatePicker";
import Icon from "../component/Icon";
import KeyValueGrid from "../component/KeyValueGrid";
import KeyValueGridItem from "../component/KeyValueGridItem";
import Loader from "../component/Loader";
import MarkdownEditor from "../component/MarkdownEditor";
import { config } from "../config";
import ImageMultiPicker from "../image/ImageMultiPicker";
import { firebaseApp } from "../lib/firebase";
import { defaultHeaders } from "../lib/util/httpUtil";
import Translations from "../translation/Translations";
import TranslationsItem from "../translation/TranslationsItem";
import {
  GameDescription,
  gameDescriptionParsePropKeys,
  getDescriptionLanguages,
  getGameDescription,
  parseTranslation,
  saveDescription,
} from "./gameDescriptionService";
import styles from "./GameEdit.module.scss";
import {
  externalIdExists,
  Game,
  getProviderCommissionFromType,
  isValidCreateOrUpdate,
  pathExists,
  useDefaultGameSubCategories,
} from "./gameService";
import {
  GameProvider,
  getGameProvider,
  getGameProviders,
} from "./provider/gameProviderService";
import GameTagPicker from "./tag/GameTagPicker";
import { GameTag, getGameTag } from "./tag/gameTagService";

const db = firebaseApp.firestore();
const mostPopularCollection = `mostPopularCollection`;
const pathLengthWarningThreshold = 25;

type GameProperties = Pick<
  Game,
  "releaseYear" | "rtp" | "wheels" | "winLines" | "volatility" | "maxExposure"
> & {
  providerName?: string;
  tags: string[];
};

interface PropsParams {
  id?: string;
  clone?: string;
}

const GameEdit: React.FC = () => {
  const { state } = useContext(AppContext);
  const [actionToolbarState, actionToolbarDispatch] = useReducer(
    actionToolbarReducer,
    initialActionToolbarState,
  );

  const history = useHistory();
  const params = useParams<PropsParams>();

  const id = params.id;
  const clone = params.clone;
  const update = !!id && !clone;
  const [game, setGame] = useState<Game | undefined>();
  const [providers, setProviders] = useState<GameProvider[]>([]);
  const [description, setDescription] = useState<GameDescription | undefined>();
  const [descriptionTranslations, setDescriptionTranslations] = useState<
    Map<Language, GameDescriptionTranslation>
  >(new Map());
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [updateRequest, setUpdateRequest] = useState<GameUpdateRequest>({});
  const [gameLoading, setGameLoading] = useState<boolean>(!!id);
  const [providersLoading, setProvidersLoading] = useState<boolean>(true);
  const [descriptionLoading, setDescriptionLoading] = useState<boolean>(!!id);
  const [saving, setSaving] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [externalData, setExternalData] = useState<
    GameProperties | undefined
  >();
  const [externalDataStatus, setExternalDataStatus] = useState<
    undefined | "loading" | "completed" | "failed"
  >();
  const [externalIdUpdate, setExternalIdUpdate] = useState<
    string | undefined
  >();
  const [externalIdValid, setExternalIdValid] = useState<boolean>(false);
  const [fetchDataActive, setFetchDataActive] = useState(false);
  const pathRef = useRef<HTMLInputElement>(null);
  const providerRef = useRef<HTMLSelectElement>(null);
  const releaseYearRef = useRef<HTMLInputElement>(null);
  const rtpRef = useRef<HTMLInputElement>(null);
  const wheelsRef = useRef<HTMLInputElement>(null);
  const winLinesRef = useRef<HTMLInputElement>(null);
  const volatilityRef = useRef<HTMLSelectElement>(null);
  const maxExposureRef = useRef<HTMLInputElement>(null);
  const fetchDataRef = useRef<HTMLInputElement>(null);
  const externalIdRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const loading =
    gameLoading ||
    providersLoading ||
    descriptionLoading ||
    !state.imagesLoaded ||
    !state.gameTagsLoaded;

  // validate form
  useEffect(() => {
    setValid(isValidCreateOrUpdate(game, updateRequest, update));
  }, [game, updateRequest, update]);

  // validate path
  useEffect(() => {
    if (pathRef.current) {
      const path = pathRef.current.value;
      if (pathExists(path, update ? game : undefined)) {
        pathRef.current.setCustomValidity(`Path already exists`);
      } else if (path.length > pathLengthWarningThreshold) {
        pathRef.current.setCustomValidity(
          `Path longer than ${pathLengthWarningThreshold} characters`,
        );
      } else {
        pathRef.current.setCustomValidity(``);
      }
    }
  }, [game, update, updateRequest, loading]);

  // validate external ids
  useEffect(() => {
    const input = externalIdRef.current;
    if (input?.value) {
      const externalId = input.value;
      if (
        externalIdExists(externalId) ||
        updateRequest.external?.find((e) => e.id === externalId)
      ) {
        input.setCustomValidity(`External ID already exists`);
      } else {
        input.setCustomValidity(``);
      }
    } else if (input) {
      input.setCustomValidity(``);
    }
    setExternalIdValid(!!input?.validity.valid && !!input?.value);
  }, [game, externalIdUpdate, loading, updateRequest.external]);

  // load game
  useEffect(() => {
    if (id) {
      setGameLoading(true);
      db.collection(gameCollection)
        .doc(id)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            throw Error(`Game with id ${id} does not exist`);
          }

          const game = { ...snapshot.data(), id: snapshot.id } as Game;
          setGame(game);
          setGameLoading(false);
        });
    }
  }, [id]);

  // prepopulate update request
  useEffect(() => {
    if (game && clone) {
      const updateRequest: GameUpdateRequest = {
        ...game,
        external: [],
        similar: [],
        images: game.images?.map((image) => image.ref.id),
        provider: game.provider?.id,
        tags: game.tags?.map((t) => t.id),
        published: game.published.toMillis(),
      };
      setUpdateRequest(updateRequest);
    } else if (game) {
      setUpdateRequest({
        external: game.external
          .map((e) => {
            return { ...e };
          })
          .sort((e1, e2) => {
            if (e1.active === e2.active) {
              return 0;
            }
            return e1.active ? -1 : 1;
          }),
      });
    } else {
      setUpdateRequest({ external: [] });
    }
  }, [game, clone]);

  // load providers
  useEffect(() => {
    if (providersLoading && state.gameProvidersLoaded) {
      const providers = getGameProviders().sort((p1, p2) => {
        return p1.name.localeCompare(p2.name);
      });
      setProviders(providers);
      setProvidersLoading(false);
    }
  }, [providersLoading, state.gameProvidersLoaded]);

  // load description
  useEffect(() => {
    if (id && descriptionLoading && state.gameDescriptionsLoaded) {
      const description = getGameDescription(id);
      if (description) {
        if (!clone) {
          setDescription(description);
        }
        const translations = new Map<Language, GameDescriptionTranslation>();
        const dt = description.translations;
        for (const lang of getTranslatedLanguages(dt)) {
          translations.set(lang, dt[lang]!);
        }
        setDescriptionTranslations(translations);
        if (translations.size > 0) {
          setSelectedLang(Array.from(translations.keys())[0]);
        }
      }
      setDescriptionLoading(false);
    }
  }, [id, clone, descriptionLoading, state.gameDescriptionsLoaded]);

  const defaultSubCategories = useDefaultGameSubCategories();

  if (saving) {
    return <Loader message="Saving game ..." />;
  }

  if (loading) {
    return <Loader message="Loading game ..." />;
  }

  const selectedTrans = selectedLang
    ? descriptionTranslations.get(selectedLang)
    : undefined;

  async function save(): Promise<void> {
    setSaving(true);

    const data = update ? getUpdateRequest() : getCreateRequest();
    const url = `${config.api}/games/${update ? `${id}/` : ``}`;
    const headers = defaultHeaders(state);

    const response = await fetch(url, {
      method: `${update ? `PUT` : `POST`}`,
      headers: headers,
      body: JSON.stringify(data),
    });

    if (response.ok) {
      // save game description in a separate request
      const gameId = update ? id! : await response.text();
      await saveDescription(
        gameId,
        description,
        descriptionTranslations,
        headers,
      );
    }

    setSaving(false);
    history.goBack();
  }

  async function fetchData(): Promise<void> {
    setExternalDataStatus(`loading`);
    const path = fetchDataRef.current!.value;
    // if (updateRequest.path) {
    //   path = updateRequest.path;
    // } else if (game && game.path) {
    //   path = game.path;
    // }

    // should never happen (action should be disabled)
    if (!path) {
      setExternalDataStatus(undefined);
      return;
    }

    const url = `${config.api}/game_scrape/${path}`;
    const response = await fetch(url, {
      headers: defaultHeaders(state),
    });
    if (response.status !== 200) {
      setExternalDataStatus(`failed`);
      return;
    }
    const gp: GameProperties = await response.json();
    setExternalData(gp);
    setExternalDataStatus(`completed`);

    updateProvider(gp.providerName);
    updateRtp(gp.rtp);
    updateReleaseYear(gp.releaseYear);
    updateVolatility(gp.volatility);
    updateWheels(gp.wheels);
    updateWinLines(gp.winLines);
    updateMaxExposure(gp.maxExposure);
    updateTags(gp.tags);
  }

  async function openPage(): Promise<void> {
    window.open(
      `https://slotcatalog.com/en/slots/${fetchDataRef.current!.value}`,
      `_blank`,
    );
  }

  function updateProvider(
    providerName: string | undefined,
    force = false,
  ): void {
    if (
      providerName &&
      providerRef.current &&
      (force || !providerRef.current.value)
    ) {
      for (const provider of providers) {
        if (provider.name.toLowerCase() === providerName.toLowerCase()) {
          providerRef.current.value = provider.id;
          onUpdate({ provider: provider.id });
          break;
        }
      }
    }
  }

  function updateTags(tags: string[], force = false): void {
    for (const tagId of tags) {
      if (!updateRequest || !updateRequest.tags) {
        onUpdate({ tags: tags });
      } else {
        if (!updateRequest.tags.includes(`tagId`)) {
          onUpdate({ tags: [...updateRequest.tags, tagId] });
        }
      }
    }
  }

  function updateRtp(rtp: string | undefined, force = false): void {
    if (rtp && rtpRef.current && (force || !rtpRef.current.value)) {
      rtpRef.current.value = rtp;
      onUpdate({ rtp: rtp });
    }
  }

  function updateReleaseYear(
    releaseYear: string | undefined,
    force = false,
  ): void {
    if (
      releaseYear &&
      releaseYearRef.current &&
      (force || !releaseYearRef.current.value)
    ) {
      releaseYearRef.current.value = releaseYear;
      onUpdate({ releaseYear: releaseYear });
    }
  }

  function updateVolatility(
    volatility: string | undefined,
    force = false,
  ): void {
    if (
      volatility &&
      volatilityRef.current &&
      (force || !volatilityRef.current.value)
    ) {
      volatilityRef.current.value = volatility;
      onUpdate({ volatility: volatility });
    }
  }

  function updateWheels(wheels: string | undefined, force = false): void {
    if (wheels && wheelsRef.current && (force || !wheelsRef.current.value)) {
      wheelsRef.current.value = wheels;
      onUpdate({ wheels: wheels });
    }
  }

  function updateWinLines(winLines: number | undefined, force = false): void {
    if (
      winLines &&
      winLinesRef.current &&
      (force || !winLinesRef.current.value)
    ) {
      winLinesRef.current.value = String(winLines);
      onUpdate({ winLines: winLines });
    }
  }

  function updateMaxExposure(
    maxExposure: number | undefined,
    force = false,
  ): void {
    if (
      maxExposure &&
      maxExposureRef.current &&
      (force || !maxExposureRef.current.value)
    ) {
      maxExposureRef.current.value = String(maxExposure);
      onUpdate({ maxExposure: maxExposure });
    }
  }

  function getUpdateRequest(): GameUpdateRequest {
    let externalChange = false;
    if (
      (game?.external.length ?? 0) !== (updateRequest.external?.length ?? 0)
    ) {
      externalChange = true;
    } else {
      externalChange = !game?.external.every((eOld) => {
        const eNew = updateRequest.external?.find((e) => e.id === eOld.id);
        if (!eNew) {
          return false;
        }
        return (
          eOld.active === eNew.active &&
          eOld.demoDisabled === eNew.demoDisabled &&
          eOld.disabled === eNew.disabled &&
          eOld.license === eNew.license &&
          eOld.rtpOverride === eNew.rtpOverride
        );
      });
    }

    if (externalChange) {
      return updateRequest;
    } else {
      return { ...updateRequest, ...{ external: undefined } };
    }
  }

  function getCreateRequest(): GameCreateRequest {
    return {
      external: updateRequest.external!,
      name: updateRequest.name!,
      category: updateRequest.category!,
      subCategory: updateRequest.subCategory!,
      path: updateRequest.path!,
      disabled: !!updateRequest.disabled,
      rtp: updateRequest.rtp,
      rtpUk: updateRequest.rtpUk,
      volatility: updateRequest.volatility,
      releaseYear: updateRequest.releaseYear,
      wheels: updateRequest.wheels,
      winLines: updateRequest.winLines,
      provider: updateRequest.provider!,
      collections: updateRequest.collections ? updateRequest.collections : [],
      published: updateRequest.published!,
      images: updateRequest.images,
      wageringContribution:
        updateRequest.wageringContribution === `removed`
          ? undefined
          : updateRequest.wageringContribution,
      providerCommissionType:
        updateRequest.providerCommissionType === `removed`
          ? undefined
          : updateRequest.providerCommissionType,
    };
  }

  function cancel(): void {
    history.goBack();
  }

  function onUpdate(field: GameUpdateRequest): void {
    setUpdateRequest((ur) => {
      return { ...ur, ...field };
    });
  }

  function onExternalUpdate(field: Partial<GameExternal>, index: number): void {
    const externalCopy = [...(updateRequest.external ?? [])];
    const update = { ...externalCopy[index], ...field };
    externalCopy[index] = update;
    onUpdate({ external: externalCopy });
  }

  function onExternalAdd(externalId: string): void {
    const externalCopy = [...(updateRequest.external ?? [])];
    externalCopy.unshift({
      id: externalId,
      active: true,
      disabled: `never`,
      demoDisabled: `never`,
    });
    onUpdate({ external: externalCopy });
  }

  function onExternalRemove(index: number): void {
    const externalCopy = [...(updateRequest.external ?? [])];
    externalCopy.splice(index, 1);
    onUpdate({ external: externalCopy });
  }

  function onDescriptionUpdate(field: GameDescriptionTranslationUpdate): void {
    if (!selectedLang) {
      return;
    }

    const translationsCopy: Map<Language, GameDescriptionTranslation> = new Map(
      descriptionTranslations,
    );
    const t: GameDescriptionTranslation = translationsCopy.get(
      selectedLang,
    ) ?? {
      description: ``,
    };

    translationsCopy.set(selectedLang, { ...t, ...field });
    setDescriptionTranslations(translationsCopy);
  }

  function parsedDescriptionText(): string {
    if (!selectedLang || !selectedTrans) {
      return ``;
    }

    function getProviderName(providerId: string): string {
      return getGameProvider(providerId)?.name ?? ``;
    }

    return parseTranslation(selectedTrans.description, selectedLang, t, {
      name: updateRequest.name !== undefined ? updateRequest.name : game?.name,
      provider:
        updateRequest.provider !== undefined
          ? getProviderName(updateRequest.provider)
          : game && getProviderName(game.provider.id),
      wheels:
        updateRequest.wheels !== undefined
          ? updateRequest.wheels
          : game?.wheels,
      winLines:
        updateRequest.winLines !== undefined
          ? updateRequest.winLines.toString()
          : game?.winLines?.toString(),
      releaseYear:
        updateRequest.releaseYear !== undefined
          ? updateRequest.releaseYear
          : game?.releaseYear,
      rtp: updateRequest.rtp !== undefined ? updateRequest.rtp : game?.rtp,
      subCategory:
        updateRequest.subCategory !== undefined
          ? gameSubCategories.get(updateRequest.subCategory)
          : game && gameSubCategories.get(game.subCategory),
      volatility:
        updateRequest.volatility !== undefined
          ? updateRequest.volatility
          : game?.volatility,
    });
  }

  function descriptionVariables(): string {
    return gameDescriptionParsePropKeys
      .map((prop) => `{{- ${prop}}}`)
      .reduce((p1, p2) => `${p1}, ${p2}`);
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  function getTags(): GameTag[] {
    const tags = updateRequest.tags
      ? updateRequest.tags
      : game?.tags
      ? game.tags.map((tag) => tag.id)
      : [];

    return tags
      .map((ref) => getGameTag(ref))
      .filter((tag) => tag !== undefined) as GameTag[];
  }

  const Published: React.FC = () => {
    const selected = updateRequest.published
      ? new Date(updateRequest.published)
      : game
      ? game.published.toDate()
      : null;
    return (
      <CustomDatePicker
        selected={selected}
        placeholder="Add a valid publish date"
        onChange={(date) => {
          onUpdate({ published: date ? date.getTime() : undefined });
        }}
      />
    );
  };

  // const fetchDataEnabled = (game && game.path) || updateRequest.path;
  return (
    <section>
      <ActionToolbarContext.Provider
        value={{ actionToolbarState, actionToolbarDispatch }}
      >
        <ActionToolbar name={`${game ? `Edit` : `Add`} game`}>
          <ActionToolbarItem
            type="button"
            icon={<Icon name="cancel" />}
            name="Cancel"
            onClick={() => cancel()}
          />
          <ActionToolbarItem
            type="button"
            icon={<Icon name="save" />}
            name="Save"
            disabled={!valid}
            onClick={() => save()}
          />
          {/* <ActionToolbarItem
            type="button"
            icon={<Icon name="sync" />}
            name={
              externalDataStatus === "loading" ? "Fetching..." : "Fetch data"
            }
            disabled={externalDataStatus === "loading" || !fetchDataEnabled}
            onClick={() => fetchData()}
          /> */}
          <ActionToolbarItem
            type="button"
            icon={<Icon name="menu-down" />}
            name="More"
            active={fetchDataActive}
            notification={false}
            onClick={() => {
              setFetchDataActive((value) => !value);
              actionToolbarDispatch({
                type: ActionToolbarActionType.ShowExpanded,
                component: actionToolbarState.expanded ? undefined : (
                  <div className={styles.fetchData}>
                    <input
                      ref={fetchDataRef}
                      defaultValue={
                        game ? generateFetchDataPath(game.path) : ``
                      }
                    />
                    <button onClick={fetchData}>Fetch data</button>
                    <button
                      onClick={openPage}
                      style={{ marginLeft: `0.25rem` }}
                    >
                      Open
                    </button>
                  </div>
                ),
              });
            }}
          />
        </ActionToolbar>
      </ActionToolbarContext.Provider>
      {game?.incomplete && (
        <p className={styles.incomplete}>
          Incomplete! Update game and press save to complete the game.
        </p>
      )}

      {externalDataStatus === `failed` && (
        <p className={styles.error}>Could not fetch external data</p>
      )}
      <KeyValueGrid>
        {update && (
          <KeyValueGridItem label="ID" editable={false}>
            {id}
          </KeyValueGridItem>
        )}

        {game && !clone && (
          <KeyValueGridItem label="Created" editable={false}>
            {dateTimeString(game.created.toDate() as Date)}
          </KeyValueGridItem>
        )}

        <KeyValueGridItem label="External" editable>
          <div className={styles.externalAdd}>
            <input
              type="number"
              ref={externalIdRef}
              placeholder="Enter a valid Aspire Game ID"
              onChange={(event) =>
                setExternalIdUpdate(event.currentTarget.value)
              }
            />
            <button
              className={styles.externalAddButton}
              disabled={!externalIdValid}
              onClick={() => {
                const input = externalIdRef.current;
                if (input?.validity.valid && input.value.trim().length !== 0) {
                  onExternalAdd(input.value);
                  input.value = ``;
                }
              }}
            >
              <Icon name="add" />
              <p>Add</p>
            </button>
          </div>
          {updateRequest.external?.map((e, i) => (
            <GameExternalComp
              key={i}
              gameExternal={e}
              onUpdate={(field) => onExternalUpdate(field, i)}
              onRemove={
                game?.external.map((ge) => ge.id).includes(e.id)
                  ? undefined
                  : () => onExternalRemove(i)
              }
            />
          ))}
        </KeyValueGridItem>

        <KeyValueGridItem label="Published" editable>
          <Published />
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Name"
          editable
          dirty={!!updateRequest.name && !!game}
        >
          <input
            type="text"
            required
            defaultValue={game ? game.name : ``}
            placeholder="Add a name"
            onChange={(event) => {
              const path = generatePath(event.currentTarget.value);
              if (pathRef.current) {
                pathRef.current.value = path;
              }
              if (fetchDataRef.current) {
                fetchDataRef.current.value = generateFetchDataPath(path);
              }
              onUpdate({ name: event.currentTarget.value, path: path });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Path"
          editable
          dirty={!!updateRequest.path && !!game}
        >
          <input
            type="text"
            required
            ref={pathRef}
            defaultValue={game ? game.path : ``}
            placeholder="Add a path"
            onChange={(event) => onUpdate({ path: event.currentTarget.value })}
          />
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Category"
          editable
          dirty={!!updateRequest.category && !!game}
        >
          <select
            defaultValue={game ? game.category : undefined}
            onChange={(event) => {
              const category = event.currentTarget.value;
              if (!isGameCategory(category)) {
                throw new Error(`invalid GameCategory: ` + category);
              }
              onUpdate({ category: category });
            }}
          >
            {!game && !updateRequest.category && <option value="" />}
            {Array.from(gameCategories.entries()).map(([category, name], i) => (
              <option key={i} value={category}>
                {name}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Sub category"
          editable
          dirty={!!updateRequest.subCategory && !!game}
        >
          <select
            defaultValue={game ? game.subCategory : undefined}
            onChange={(event) => {
              const subCategory = event.currentTarget.value;
              if (!isGameSubCategory(subCategory)) {
                throw new Error(`invalid GameSubCategory: ` + subCategory);
              }
              onUpdate({ subCategory: subCategory });
            }}
          >
            {!game && !updateRequest.subCategory && <option value="" />}
            {Array.from(defaultSubCategories.entries()).map(
              ([subCategory, name], i) => (
                <option key={i} value={subCategory}>
                  {name}
                </option>
              ),
            )}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Provider"
          editable
          dirty={!!updateRequest.provider && !!game}
        >
          <div className={styles.inputWithNewValue}>
            <select
              defaultValue={game?.provider?.id ?? undefined}
              ref={providerRef}
              onChange={(event) =>
                onUpdate({ provider: event.currentTarget.value })
              }
            >
              {!game && !updateRequest.provider && <option value="" />}
              {game && !game.provider && <option value="" />}
              {providers.map((provider, i) => {
                return (
                  <option key={i} value={provider.id}>
                    {provider.name}
                  </option>
                );
              })}
            </select>
            {externalData?.providerName && (
              <div
                title="Click to replace"
                onClick={() => updateProvider(externalData.providerName, true)}
              >
                {externalData.providerName}
              </div>
            )}
          </div>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Volatility"
          editable
          dirty={!!updateRequest.volatility && !!game}
        >
          <div className={styles.inputWithNewValue}>
            <select
              defaultValue={game ? game.volatility : undefined}
              onChange={(event) =>
                onUpdate({ volatility: event.currentTarget.value })
              }
              ref={volatilityRef}
            >
              <option value="" />
              {Array.from(gameVolatilities.entries()).map(
                ([volatility, name], i) => (
                  <option key={i} value={volatility}>
                    {name}
                  </option>
                ),
              )}
            </select>
            {externalData?.volatility && (
              <div
                title="Click to replace"
                onClick={() => updateVolatility(externalData.volatility, true)}
              >
                {gameVolatilities.get(externalData.volatility)}
              </div>
            )}
          </div>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Disabled"
          editable
          dirty={updateRequest.disabled !== undefined && !!game}
        >
          <input
            type="checkbox"
            defaultChecked={game?.disabled ?? false}
            onChange={(event) =>
              onUpdate({ disabled: event.currentTarget.checked })
            }
          />
        </KeyValueGridItem>

        <KeyValueGridItem
          label="RTP"
          editable
          dirty={!!updateRequest.rtp && !!game}
        >
          <div className={styles.inputWithNewValue}>
            <input
              type="text"
              ref={rtpRef}
              defaultValue={game?.rtp ?? ``}
              placeholder="Optional RTP value (e.g. 94.00%)"
              onChange={(event) => onUpdate({ rtp: event.currentTarget.value })}
            />
            {externalData?.rtp && (
              <div
                title="Click to replace"
                onClick={() => updateRtp(externalData.rtp, true)}
              >
                {externalData.rtp}
              </div>
            )}
          </div>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="RTP UK"
          editable
          dirty={!!updateRequest.rtpUk && !!game}
        >
          <div className={styles.inputWithNewValue}>
            <input
              type="text"
              defaultValue={game?.rtpUk ?? ``}
              placeholder="Optional RTP value for UK (e.g. 94.00%)"
              onChange={(event) =>
                onUpdate({ rtpUk: event.currentTarget.value })
              }
            />
          </div>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Hit Rate"
          editable
          dirty={updateRequest.hitRate !== undefined && !!game}
        >
          <input
            type="text"
            defaultValue={game?.hitRate?.toString() ?? ``}
            pattern="((0(\.[0-9]+)?)|(1(\.0+)?))"
            placeholder="Optional hit rate (a number between 0 and 1)"
            onChange={(event) => {
              const value = event.currentTarget.value.trim();
              if (!value) {
                onUpdate({ hitRate: 0 });
              } else if (event.currentTarget.validity.valid) {
                onUpdate({ hitRate: parseFloat(value) });
              }
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Bonus Game Probability"
          editable
          dirty={updateRequest.bonusGameProbability !== undefined && !!game}
        >
          <input
            type="text"
            defaultValue={game?.bonusGameProbability?.toString() ?? ``}
            pattern="((0(\.[0-9]+)?)|(1(\.0+)?))"
            placeholder="Optional bonus game probability (a number between 0 and 1)"
            onChange={(event) => {
              const value = event.currentTarget.value.trim();
              if (!value) {
                onUpdate({ bonusGameProbability: 0 });
              } else if (event.currentTarget.validity.valid) {
                onUpdate({ bonusGameProbability: parseFloat(value) });
              }
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Max Exposure"
          editable
          dirty={!!updateRequest.maxExposure && !!game}
        >
          <div className={styles.inputWithNewValue}>
            <input
              type="text"
              ref={maxExposureRef}
              defaultValue={game?.maxExposure?.toString() ?? ``}
              pattern="((0(\.[0-9]+)?)|([1-9]([0-9]+)?(\.[0-9]+)?))"
              placeholder="Optional max exposure value"
              onChange={(event) => {
                const value = event.currentTarget.value.trim();
                if (!value) {
                  onUpdate({ maxExposure: 0 });
                } else if (event.currentTarget.validity.valid) {
                  onUpdate({ maxExposure: parseFloat(value) });
                }
              }}
            />
            {externalData?.maxExposure &&
              externalData?.maxExposure !== updateRequest.maxExposure && (
                <div
                  title="Click to replace"
                  onClick={() =>
                    updateMaxExposure(externalData.maxExposure, true)
                  }
                >
                  {externalData.maxExposure}
                </div>
              )}
          </div>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Max Exposure As Coins"
          editable
          dirty={updateRequest.maxExposureAsCoins !== undefined && !!game}
        >
          <input
            type="checkbox"
            defaultChecked={game?.maxExposureAsCoins ?? false}
            onChange={(event) =>
              onUpdate({ maxExposureAsCoins: event.currentTarget.checked })
            }
          />
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Release year"
          editable
          dirty={!!updateRequest.releaseYear && !!game}
        >
          <div className={styles.inputWithNewValue}>
            <input
              type="number"
              ref={releaseYearRef}
              defaultValue={game ? game.releaseYear : ``}
              placeholder="Optional realease year (e.g. 2014)"
              onChange={(event) =>
                onUpdate({ releaseYear: event.currentTarget.value })
              }
            />
            {externalData?.releaseYear && (
              <div
                title="Click to replace"
                onClick={() =>
                  updateReleaseYear(externalData.releaseYear, true)
                }
              >
                {externalData.releaseYear}
              </div>
            )}
          </div>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Wheels"
          editable
          dirty={!!updateRequest.wheels && !!game}
        >
          <div className={styles.inputWithNewValue}>
            <input
              type="text"
              ref={wheelsRef}
              defaultValue={game ? game.wheels : ``}
              placeholder="Optional wheels value (e.g. 5X3)"
              onChange={(event) =>
                onUpdate({ wheels: event.currentTarget.value })
              }
            />
            {externalData?.wheels && (
              <div
                title="Click to replace"
                onClick={() => updateWheels(externalData.wheels, true)}
              >
                {externalData.wheels}
              </div>
            )}
          </div>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Win Lines"
          editable
          dirty={!!updateRequest.winLines && !!game}
        >
          <div className={styles.inputWithNewValue}>
            <input
              type="number"
              ref={winLinesRef}
              defaultValue={game?.winLines ? game.winLines.toString() : ``}
              placeholder="Optional number of win lines (e.g. 5)"
              onChange={(event) => {
                const value = event.currentTarget.value.trim();
                if (!value) {
                  onUpdate({ winLines: 0 });
                } else if (event.currentTarget.validity.valid) {
                  onUpdate({ winLines: parseInt(value) });
                }
              }}
            />
            {` `}
            {externalData?.winLines && (
              <div
                title="Click to replace"
                onClick={() => updateWinLines(externalData.winLines, true)}
              >
                {externalData.winLines}
              </div>
            )}
          </div>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Wagering Contribution"
          editable
          dirty={!!updateRequest.wageringContribution && !!game}
        >
          <select
            defaultValue={game?.wageringContribution ?? ``}
            onChange={(event) => {
              const value = event.currentTarget.value;
              if (value) {
                const wc = parseInt(value);
                if (!isGameWageringContribution(wc)) {
                  throw new Error(`invalid GameWageringContribution: ` + value);
                }
                onUpdate({ wageringContribution: wc });
              } else {
                onUpdate({ wageringContribution: `removed` });
              }
            }}
          >
            <option key={0} value="" />
            {gameWageringContributions.map((wc, i) => (
              <option key={i + 1} value={wc}>
                {wc}%
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Provider Commission Type"
          editable
          dirty={!!updateRequest.providerCommissionType && !!game}
        >
          <select
            defaultValue={game?.providerCommissionType ?? ``}
            onChange={(event) => {
              const value = event.currentTarget.value;
              if (value) {
                if (!isGameProviderCommissionType(value)) {
                  throw new Error(
                    `invalid GameProviderCommissionType: ` + value,
                  );
                }
                onUpdate({ providerCommissionType: value });
              } else {
                onUpdate({ providerCommissionType: `removed` });
              }
            }}
          >
            <option key={0} value="" />
            {gameProviderCommissionTypes.map((type, i) => (
              <option key={i + 1} value={type}>
                {type} - {getProviderCommissionFromType(type)}%
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label="Tags" editable dirty={!!updateRequest.tags}>
          <GameTagPicker
            tags={getTags()}
            onSelectedTags={(tags) =>
              onUpdate({ tags: tags.map((tag) => tag.id) })
            }
          />
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Most popular"
          editable
          dirty={!!updateRequest.collections && !!game}
        >
          <input
            type="checkbox"
            defaultChecked={
              game ? game.collections.includes(mostPopularCollection) : false
            }
            onChange={(event) => {
              const collections = [];
              if (event.currentTarget.checked) {
                collections.push(mostPopularCollection);
              }

              onUpdate({ collections: collections });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem
          label="Default image override"
          editable
          dirty={updateRequest.defaultImageOverride !== undefined && !!game}
        >
          <input
            type="checkbox"
            defaultChecked={game ? game.defaultImageOverride : false}
            onChange={(event) =>
              onUpdate({ defaultImageOverride: event.currentTarget.checked })
            }
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Description" editable noPadding>
          <Translations
            selectedLang={selectedLang}
            existingLangs={getDescriptionLanguages(descriptionTranslations)}
            onSelectedLang={(lang) => setSelectedLang(lang)}
          >
            <div className={styles.translationActions}>
              <button
                className={styles.translationAction}
                onClick={() => onDescriptionUpdate({ description: `` })}
              >
                <Icon name="close" />
                <p>Clear</p>
              </button>
              {selectedLang !== defaultLanguage &&
                descriptionTranslations.has(defaultLanguage) && (
                  <button
                    className={styles.translationAction}
                    onClick={() => {
                      const defaultTrans = descriptionTranslations.get(
                        defaultLanguage,
                      );
                      if (defaultTrans) {
                        onDescriptionUpdate(defaultTrans);
                      }
                    }}
                  >
                    <Icon name="copy" />
                    <p>{`Copy from ${defaultLanguage}`}</p>
                  </button>
                )}
            </div>
            <TranslationsItem label="">
              <ul className={styles.descriptionInfo}>
                <li>
                  Description updates may take up to an hour to take effect.
                </li>
                <li>{`Supported variables are ${descriptionVariables()}.`}</li>
              </ul>
            </TranslationsItem>
            <TranslationsItem label="Description">
              <MarkdownEditor
                text={selectedTrans ? selectedTrans.description : ``}
                markdownText={parsedDescriptionText()}
                editMode={true}
                editCols={40}
                editRows={10}
                disabled={!selectedLang}
                placeholder="Add a description"
                markdownClass={styles.markdownDesc}
                onChange={(value) => {
                  onDescriptionUpdate({ description: value });
                }}
              />
            </TranslationsItem>
          </Translations>
        </KeyValueGridItem>

        <KeyValueGridItem label="Images" editable>
          <ImageMultiPicker
            initialImages={
              game?.images
                ? game.images.map((image) => {
                    return {
                      id: image.ref.id,
                      url: image.default,
                    };
                  })
                : undefined
            }
            onSelectedImages={(images) => {
              onUpdate({ images: images });
            }}
            filter={(image) => image.type === `Game`}
          />
        </KeyValueGridItem>
      </KeyValueGrid>
    </section>
  );
};

export default GameEdit;

interface GameExternalProps {
  gameExternal: GameExternal;
  onUpdate: (field: Partial<GameExternal>) => void;
  onRemove?: () => void;
}

const GameExternalComp: React.FC<GameExternalProps> = (props) => {
  return (
    <div className={styles.external}>
      <GameExternalItem label={`ID`}>{props.gameExternal.id}</GameExternalItem>
      <GameExternalItem label={`Archived`}>
        <input
          type="checkbox"
          checked={!props.gameExternal.active}
          onChange={(event) =>
            props.onUpdate({ active: !event.currentTarget.checked })
          }
        />
      </GameExternalItem>
      <GameExternalItem label={`Disabled`}>
        <select
          value={props.gameExternal.disabled}
          onChange={(event) => {
            const value = event.currentTarget.value;
            if (!isGameDisabledOption(value)) {
              throw new Error(`invalid GameDisabled: ` + value);
            }
            props.onUpdate({ disabled: value });
          }}
        >
          {Array.from(gameDisabledOptions.entries()).map(
            ([option, name], i) => (
              <option key={i} value={option}>
                {name}
              </option>
            ),
          )}
        </select>
      </GameExternalItem>
      <GameExternalItem label={`Demo Disabled`}>
        <select
          defaultValue={props.gameExternal.demoDisabled}
          onChange={(event) => {
            const value = event.currentTarget.value;
            if (!isGameDisabledOption(value)) {
              throw new Error(`invalid GameDemoDisabled: ` + value);
            }
            props.onUpdate({ demoDisabled: value });
          }}
        >
          {Array.from(gameDisabledOptions.entries()).map(
            ([option, name], i) => (
              <option key={i} value={option}>
                {name}
              </option>
            ),
          )}
        </select>
      </GameExternalItem>
      <GameExternalItem label={`License`}>
        <select
          defaultValue={props.gameExternal.license}
          onChange={(event) => {
            const value = event.currentTarget.value;
            if (value) {
              if (!isLicense(value)) {
                throw new Error(`invalid License: ` + value);
              }
              props.onUpdate({ license: value });
            } else {
              props.onUpdate({ license: undefined });
            }
          }}
        >
          <option value="" />
          {licenses.map((license, i) => (
            <option key={i} value={license}>
              {license}
            </option>
          ))}
        </select>
      </GameExternalItem>
      <GameExternalItem label="RTP Override">
        <input
          type="text"
          defaultValue={props.gameExternal.rtpOverride}
          placeholder="Optional RTP override (e.g. 94.00%)"
          onChange={(event) => {
            const value = event.currentTarget.value;
            if (!value || value.trim().length === 0) {
              props.onUpdate({ rtpOverride: undefined });
            } else {
              props.onUpdate({ rtpOverride: value });
            }
          }}
        />
      </GameExternalItem>
      {props.onRemove && (
        <div
          title="Remove"
          className={styles.externalRemove}
          onClick={props.onRemove}
        >
          <Icon name="close" />
        </div>
      )}
    </div>
  );
};

interface GameExternalItemProps {
  label: string;
}

const GameExternalItem: React.FC<GameExternalItemProps> = (props) => {
  return (
    <>
      <div className={styles.externalItemLabel}>{props.label}</div>
      <div>{props.children}</div>
    </>
  );
};

export function generatePath(name: string): string {
  if (!name) {
    return ``;
  }

  let path = name.trim().toLowerCase();
  path = path.replace(/\s+/g, `-`);
  path = path.replace(/[^\-a-z0-9]/g, ``);
  path = path.replace(/-+/g, `-`);
  if (path.startsWith(`-`)) {
    path = path.substr(1, path.length);
  }
  if (path.endsWith(`-`)) {
    path = path.substr(0, path.length - 1);
  }
  return path;
}

function generateFetchDataPath(path: string): string {
  const separator = `-`;
  const parts = path.split(separator);

  return parts.map((part) => capitalize(part)).join(separator);
}

function capitalize(str: string): string {
  // SlotCatalog doesn't capitalize 'of' and 'or'
  if (str === `of` || str === `or`) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}
