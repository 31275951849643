import {
  Country,
  getTranslatedCountries,
  getTranslatedLanguages,
  isTournamentBanner,
  Language,
} from "@vipscasino/core";
import firebase from "firebase/app";
import React, { useContext, useEffect, useReducer, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import AppContext from "../AppContext";
import { BannerProps } from "../banner/BannerEdit";
import { getBanners } from "../banner/bannerService";
import ActionRow from "../component/ActionRow";
import ActionRowItem from "../component/ActionRowItem";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarContext from "../component/ActionToolbarContext";
import ActionToolbarItem from "../component/ActionToolbarItem";
import {
  ActionToolbarActionType,
  actionToolbarReducer,
  initialActionToolbarState,
} from "../component/ActionToolbarState";
import Flags from "../component/Flags";
import HorizontalScroll from "../component/HorizontalScroll";
import Icon from "../component/Icon";
import Languages from "../component/Languages";
import Loader from "../component/Loader";
import SortableTable from "../component/SortableTable";
import { config } from "../config";
import { compare, defaultSortOrder, Sort } from "../lib/sort";
import {
  getTournamentsFilter,
  getTournamentsSort,
  setTournamentsSort,
} from "../lib/storage";
import { defaultHeaders } from "../lib/util/httpUtil";
import {
  TournamentPreview,
  TournamentPreviewProps,
} from "../promotion/PromotionOrTournamentPreview";
import UserListModal from "../user/UserListModal";
import { getTournamentUsers } from "../user/userRefService";
import styles from "./Tournaments.module.scss";
import {
  getTournaments,
  isTournamentLive,
  isTournamentPublished,
  isTournamentUpcoming,
  Tournament,
} from "./tournamentService";
import TournamentsFilterComp from "./TournamentsFilter";
import {
  filterIsEmpty,
  filterTournaments,
  TournamentsFilter,
} from "./tournamentsFilter";

const path = `tournaments`;

enum SortField {
  Brand = `Brand`,
  Live = `Live`,
  Published = `Published`,
  Upcoming = `Upcoming`,
  Path = `Path`,
  AspireLeaderBoard = `AspireLeaderBoard`,
  Priority = `Priority`,
  ActiveFrom = `ActiveFrom`,
  ActiveTo = `ActiveTo`,
  PublishedFrom = `PublishedFrom`,
  PublishedTo = `PublishedTo`,
  Created = `Created`,
  Modified = `Modified`,
}

const defaultSortField = SortField.Modified;

const Tournaments: React.FC = () => {
  const { state } = useContext(AppContext);
  const [actionToolbarState, actionToolbarDispatch] = useReducer(
    actionToolbarReducer,
    initialActionToolbarState,
  );
  const [tournaments, setTournaments] = useState<Tournament[] | undefined>();
  const [filteredTournaments, setFilteredTournaments] = useState<Tournament[]>(
    [],
  );
  const [filter, setFilter] = useState<TournamentsFilter>(
    getTournamentsFilter() ?? {},
  );
  // const [showFilter] = useState(getShowBannersFilter());
  const [loading, setLoading] = useState(true);
  const [showIds, setShowIds] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [sort, setSort] = useState<Sort>(
    getTournamentsSort() ?? {
      field: defaultSortField,
      order: defaultSortOrder,
    },
  );
  const [optInTournamentId, setOptInTournamentId] = useState<
    string | undefined
  >();
  const [previewTournament, setPreviewTournament] = useState<
    Tournament | undefined
  >();
  const [previewCountry, setPreviewCountry] = useState<Country | undefined>();
  const [previewLang, setPreviewLang] = useState<Language | undefined>();
  const [desktopPreview, setDesktopPreview] = useState(true);

  // set tournaments when loaded/updated
  useEffect(() => {
    if (state.tournamentsLoaded) {
      setTournaments(getTournaments());
    }
  }, [state.tournamentsLoaded]);

  // filter tournaments
  useEffect(() => {
    if (tournaments) {
      setFilteredTournaments(filterTournaments(filter, tournaments));
      setLoading(false);
    }
  }, [filter, tournaments]);

  if (loading) {
    return <Loader message="Loading tournaments ..." />;
  }

  async function remove(id: string): Promise<void> {
    const referencingBanners = getBanners(
      (banner) =>
        isTournamentBanner<unknown, firebase.firestore.DocumentReference>(
          banner,
        ) && banner.tournament.id === id,
    );
    if (referencingBanners.length > 0) {
      const bannerString = referencingBanners
        .map((b) => `id=${b.id}`)
        .join(`, `);
      window.alert(
        `This tournament is referenced by the following banner${
          referencingBanners.length > 1 ? `s` : ``
        } [${bannerString}], and cannot be removed.`,
      );
    } else if (
      window.confirm(`Are you sure you want to remove this tournament?`)
    ) {
      const url = `${config.api}/tournaments/${id}/`;
      const res = await fetch(url, {
        method: `DELETE`,
        headers: defaultHeaders(state),
      });
      if (tournaments && res.status === 200) {
        setTournaments(tournaments.filter((b) => b.id !== id));
      }
    }
  }

  function isSortField(sortField: string): sortField is SortField {
    return Object.values(SortField).includes(sortField as SortField);
  }

  function sortFieldValues(
    field: string,
    t1: Tournament,
    t2: Tournament,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] {
    switch (field) {
      case SortField.Brand:
        return [t1.brand, t2.brand];
      case SortField.Live:
        return [isTournamentLive(t1), isTournamentLive(t2)];
      case SortField.Published:
        return [isTournamentPublished(t1), isTournamentPublished(t2)];
      case SortField.Upcoming:
        return [isTournamentUpcoming(t1), isTournamentUpcoming(t2)];
      case SortField.Path:
        return [t1.path, t2.path];
      case SortField.AspireLeaderBoard:
        return [
          t1.aspireTableName.toLowerCase(),
          t2.aspireTableName.toLowerCase(),
        ];
      case SortField.Priority:
        return [
          t1.priority ? -t1.priority : undefined,
          t2.priority ? -t2.priority : undefined,
        ];
      case SortField.ActiveFrom:
        return [t1.activeFrom, t2.activeFrom];
      case SortField.ActiveTo:
        return [t1.activeTo, t2.activeTo];
      case SortField.PublishedFrom:
        return [t1.publishedFrom, t2.publishedFrom];
      case SortField.PublishedTo:
        return [t1.publishedTo, t2.publishedTo];
      case SortField.Created:
        return [t1.created, t2.created];
      case SortField.Modified:
        return [t1.modified.date, t2.modified.date];
      default:
        throw new Error(`Unsupported sort field: ` + field);
    }
  }

  function getSortedTournaments(): Tournament[] {
    const sortField = isSortField(sort.field) ? sort.field : defaultSortField;
    return filteredTournaments.sort((t1, t2) => {
      const [val1, val2] = sortFieldValues(sortField, t1, t2);
      return compare(val1, val2, sort.order);
    });
  }

  function onSort(sort: Sort): void {
    setTournamentsSort(sort);
    setSort(sort);
  }

  function previewProps(
    tournament: Tournament,
    country: Country,
    lang: Language,
  ): TournamentPreviewProps {
    const image = desktopPreview
      ? tournament.image.desktop.default
      : tournament.image.mobile.default;
    const translation =
      tournament.translations[country] &&
      tournament.translations[country]![lang];

    return {
      desktop: desktopPreview,
      image: image,
      language: lang,
      translation: translation,
      activeFrom: tournament.activeFrom.toDate(),
      activeTo: tournament.activeTo.toDate(),
      modified: tournament.modified.date.toDate(),
      games: tournament.games.length > 0,
      aspireTableName: tournament.aspireTableName,
      onRequestClose: () => {
        setPreviewTournament(undefined);
      },
    };
  }

  return (
    <div className={styles.main}>
      <ActionToolbarContext.Provider
        value={{ actionToolbarState, actionToolbarDispatch }}
      >
        <ActionToolbar name="Tournaments">
          <ActionToolbarItem
            type="link"
            icon={<Icon name="add" />}
            name="Add"
            to={`/${path}/add`}
          />

          <ActionToolbarItem
            type="button"
            icon={<Icon name="filter" />}
            name="Filter"
            active={filterActive}
            notification={!filterIsEmpty(filter)}
            onClick={() => {
              setFilterActive((value) => !value);
              actionToolbarDispatch({
                type: ActionToolbarActionType.ShowExpanded,
                component: actionToolbarState.expanded ? undefined : (
                  <TournamentsFilterComp
                    filter={filter}
                    onChange={(filter: TournamentsFilter) => setFilter(filter)}
                  />
                ),
              });
            }}
          />
        </ActionToolbar>
      </ActionToolbarContext.Provider>
      {optInTournamentId && (
        <UserListModal
          users={[...getTournamentUsers(optInTournamentId)].sort(
            (u1, u2) =>
              u2.modified.date.toMillis() - u1.modified.date.toMillis(),
          )}
          modifiedLabel={`Opt-in date`}
          onRequestClose={() => {
            setOptInTournamentId(undefined);
          }}
        />
      )}
      {previewTournament && previewCountry && previewLang && (
        <TournamentPreview
          {...previewProps(previewTournament, previewCountry, previewLang)}
        />
      )}
      <HorizontalScroll>
        <SortableTable
          sort={sort}
          onSort={onSort}
          columns={[
            { name: `Actions` },
            {
              name: `ID`,
              onShowToggle: () => setShowIds((value) => !value),
              show: showIds,
            },
            { name: `Countries` },
            { name: `Languages` },
            {
              name: `Brand`,
              sortField: SortField.Brand,
            },
            {
              name: `Published`,
              sortField: SortField.Published,
            },
            {
              name: `Live`,
              sortField: SortField.Live,
            },
            {
              name: `Upcoming`,
              sortField: SortField.Upcoming,
            },
            {
              name: `Path`,
              sortField: SortField.Path,
            },
            { name: `Preview` },
            { name: `Opt-in button text` },
            {
              name: `Aspire leader board`,
              sortField: SortField.AspireLeaderBoard,
            },
            {
              name: `Priority`,
              sortField: SortField.Priority,
            },
            {
              name: `Active From`,
              sortField: SortField.ActiveFrom,
            },
            {
              name: `Active To`,
              sortField: SortField.ActiveTo,
            },
            {
              name: `Published From`,
              sortField: SortField.PublishedFrom,
            },
            {
              name: `Published To`,
              sortField: SortField.PublishedTo,
            },
            {
              name: `Created`,
              sortField: SortField.Created,
            },
            {
              name: `Modified`,
              sortField: SortField.Modified,
            },
          ]}
        >
          {getSortedTournaments().map((tournament, i) => {
            return (
              <TournamentComp
                key={i}
                tournament={tournament}
                onRemove={remove}
                showIds={showIds}
                onShowOptInUsers={(id) => {
                  setOptInTournamentId(id);
                }}
                onPreview={(tournament, desktop, country, lang) => {
                  setPreviewTournament(tournament);
                  setDesktopPreview(desktop);
                  setPreviewCountry(country);
                  setPreviewLang(lang);
                }}
              />
            );
          })}
        </SortableTable>
      </HorizontalScroll>
    </div>
  );
};

export default Tournaments;

interface TournamentProps {
  tournament: Tournament;
  showIds: boolean;
  onRemove: (id: string) => Promise<void>;
  onShowOptInUsers: (id: string) => void;
  onPreview: (
    tournament: Tournament,
    desktop: boolean,
    country: Country,
    lang: Language,
  ) => void;
}

const TournamentComp: React.FC<TournamentProps> = (props) => {
  const { state } = useContext(AppContext);
  const history = useHistory<BannerProps>();
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>();
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [removing, setRemoving] = useState(false);

  const tournament = props.tournament;
  const id = tournament.id;

  // reset selected language when changing country
  useEffect(() => {
    if (selectedCountry) {
      const langs = getTranslatedLanguages(
        tournament.translations[selectedCountry] ?? {},
      );
      if (langs.length > 0) {
        setSelectedLang(langs[0]);
      } else {
        setSelectedLang(undefined);
      }
    }
  }, [tournament.translations, selectedCountry]);

  const existingCountries = getTranslatedCountries(tournament.translations);
  const currentCountry =
    selectedCountry ??
    (existingCountries.length > 0 ? existingCountries[0] : undefined);
  const existingLangs = currentCountry
    ? getTranslatedLanguages(tournament.translations[currentCountry] ?? {})
    : [];
  const currentLang =
    selectedLang ?? (existingLangs.length > 0 ? existingLangs[0] : undefined);
  const translation =
    currentCountry &&
    currentLang &&
    tournament.translations[currentCountry] &&
    tournament.translations[currentCountry]![currentLang];

  function countries(): JSX.Element {
    return (
      <Flags
        existingCountries={existingCountries}
        onCountrySelected={setSelectedCountry}
        selectedCountry={currentCountry}
        selectedClassName={styles.selected}
      />
    );
  }

  function langs(): JSX.Element {
    return (
      <Languages
        existingLangs={existingLangs}
        onLangSelected={setSelectedLang}
        selectedLang={currentLang}
        selectedClassName={styles.selected}
      />
    );
  }

  function published(): string {
    return isTournamentPublished(tournament) ? `✓` : ``;
  }

  function live(): string {
    return isTournamentLive(tournament) ? `✓` : ``;
  }

  function upcoming(): string {
    return isTournamentUpcoming(tournament) ? `✓` : ``;
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  async function remove(): Promise<void> {
    setRemoving(true);
    await props.onRemove(id);
    setRemoving(false);
  }

  const created = tournament.created.toDate();
  const modified = tournament.modified.date.toDate();
  return (
    <tr>
      <td>
        <ActionRow>
          <ActionRowItem iconName="edit" to={`/${path}/${id}`} title="Edit" />
          <ActionRowItem
            iconName="copy"
            to={`/${path}/${id}/clone`}
            title="Clone"
          />
          <ActionRowItem
            iconName="add-image"
            onClick={() =>
              history.push(`/banners/add`, { tournamentId: tournament.id })
            }
            title="Create Banner"
          />
          {translation?.optInButtonText && state.userRefsLoaded && (
            <ActionRowItem
              iconName="users"
              onClick={() => props.onShowOptInUsers(id)}
              title="Show opt-in users"
            />
          )}
          <ActionRowItem
            iconName="delete"
            onClick={() => (removing ? null : remove())}
            title="Delete"
          />
        </ActionRow>
      </td>
      <td className={styles.id}>{props.showIds && id}</td>
      <td className={styles.flags}>{countries()}</td>
      <td className={styles.languages}>{langs()}</td>
      <td>{tournament.brand}</td>
      <td className={styles.checked}>{published()}</td>
      <td className={styles.checked}>{live()}</td>
      <td className={styles.checked}>{upcoming()}</td>
      <td>{tournament.path}</td>
      <td>
        {currentCountry && currentLang && (
          <div className={styles.preview}>
            <div
              className={styles.platformItem}
              onClick={() =>
                props.onPreview(tournament, true, currentCountry, currentLang)
              }
              title="Desktop Preview"
            >
              <Icon name="desktop" className={styles.platformIcon} />
            </div>
            <div
              className={styles.platformItem}
              onClick={() =>
                props.onPreview(tournament, false, currentCountry, currentLang)
              }
              title="Mobile Preview"
            >
              <Icon name="mobile" className={styles.platformIcon} />
            </div>
          </div>
        )}
      </td>
      <td>{translation?.optInButtonText}</td>
      <td>{tournament.aspireTableName}</td>
      <td>{tournament.priority}</td>
      <td>{dateTimeString(tournament.activeFrom.toDate())}</td>
      <td>{dateTimeString(tournament.activeTo.toDate())}</td>
      <td>{dateTimeString(tournament.publishedFrom.toDate())}</td>
      <td>{dateTimeString(tournament.publishedTo.toDate())}</td>
      <td title={dateTimeString(created)} style={{ whiteSpace: `nowrap` }}>
        {created.toLocaleDateString(`sv`)}
      </td>
      <td title={dateTimeString(modified)} style={{ whiteSpace: `nowrap` }}>
        {modified.toLocaleDateString(`sv`)}
      </td>
    </tr>
  );
};
