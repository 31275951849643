import {
  gameCategories,
  gameVolatilities,
  isGameCategory,
  isGameSubCategory,
} from "@vipscasino/core";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import AppContext from "../AppContext";
import CustomDatePicker from "../component/CustomDatePicker";
import FilterForm from "../component/FilterForm";
import FilterFormButton from "../component/FilterFormButton";
import FilterFormItem from "../component/FilterFormItem";
import { removeGamesFilter, setGamesFilter } from "../lib/storage";
import { useDefaultGameSubCategories } from "./gameService";
import { filterIsEmpty, GamesFilter } from "./gamesFilter";
import styles from "./GamesFilter.module.scss";
import { GameProvider, getGameProviders } from "./provider/gameProviderService";

interface Props {
  filter: GamesFilter;
  onChange: (filter: GamesFilter) => void;
}

const GamesFilterComp: React.FC<Props> = (props) => {
  const { state } = useContext(AppContext);
  const [filter, setFilter] = useState<GamesFilter>(props.filter);
  const [providers, setProviders] = useState<GameProvider[]>([]);
  const [applyTimeout, setApplyTimeout] = useState<
    NodeJS.Timeout | undefined
  >();

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (filterIsEmpty(filter)) {
      removeGamesFilter();
    } else {
      setGamesFilter(filter);
    }
  }, [filter]);

  useEffect(() => {
    if (state.gameProvidersLoaded) {
      const providers = getGameProviders().sort((p1, p2) => {
        return p1.name.localeCompare(p2.name);
      });
      setProviders(providers);
    }
  }, [state.gameProvidersLoaded]);

  const gameSubCategories = useDefaultGameSubCategories();

  function updateFilter(filter: GamesFilter, applyTimeoutMillis: number): void {
    setFilter(filter);

    if (applyTimeout) {
      clearTimeout(applyTimeout);
    }

    if (applyTimeoutMillis > 0) {
      setApplyTimeout(
        setTimeout(() => {
          props.onChange(filter);
        }, applyTimeoutMillis),
      );
    } else {
      props.onChange(filter);
    }
  }

  function onChange(field: GamesFilter, applyTimeoutMillis = 500): void {
    updateFilter(
      { ...filter, ...field, showAll: undefined },
      applyTimeoutMillis,
    );
  }

  return (
    <FilterForm>
      <FilterFormItem label="Search">
        <input
          type="text"
          value={filter.name ? filter.name : ``}
          placeholder="ID or Name"
          className={styles.search}
          onChange={(event) => {
            const name = event.currentTarget.value;
            onChange({ name: name ? name : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Provider">
        <select
          value={filter.provider ? filter.provider : ``}
          onChange={(event) => {
            const provider = event.currentTarget.value;
            onChange({ provider: provider ? provider : undefined });
          }}
        >
          <option />
          {providers.map((provider, i) => {
            return (
              <option key={i} value={provider.id}>
                {provider.name}
              </option>
            );
          })}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Category">
        <select
          value={filter.category ? filter.category : ``}
          onChange={(event) => {
            const category = event.currentTarget.value;
            if (!category) {
              onChange({ category: undefined });
            } else if (isGameCategory(category)) {
              onChange({ category: category });
            }
          }}
        >
          <option />
          {Array.from(gameCategories.entries()).map(([category, name], i) => (
            <option key={i} value={category}>
              {name}
            </option>
          ))}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Sub category">
        <select
          value={filter.subCategory ? filter.subCategory : ``}
          onChange={(event) => {
            const subCategory = event.currentTarget.value;
            if (!subCategory) {
              onChange({ subCategory: undefined });
            } else if (isGameSubCategory(subCategory)) {
              onChange({ subCategory: subCategory });
            }
          }}
        >
          <option />
          {Array.from(gameSubCategories.entries()).map(
            ([subCategory, name], i) => (
              <option key={i} value={subCategory}>
                {name}
              </option>
            ),
          )}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Volatility">
        <select
          value={filter.volatility ? filter.volatility : ``}
          onChange={(event) => {
            const volatility = event.currentTarget.value;
            onChange({ volatility: volatility ? volatility : undefined });
          }}
        >
          <option />
          {Array.from(gameVolatilities.entries()).map(
            ([volatility, name], i) => (
              <option key={i} value={volatility}>
                {name}
              </option>
            ),
          )}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Tag">
        <input
          type="text"
          value={filter.tag ?? ``}
          onChange={(event) => {
            const tag = event.currentTarget.value;
            onChange({ tag: tag ? tag : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Published between">
        <div className={styles.dateSpan}>
          <CustomDatePicker
            selected={
              filter.publishedStart ? new Date(filter.publishedStart) : null
            }
            placeholder="Start date"
            optional={true}
            onChange={(date) => {
              onChange({ publishedStart: date ? date.getTime() : undefined });
            }}
          />
          &nbsp;-&nbsp;
          <CustomDatePicker
            selected={
              filter.publishedEnd ? new Date(filter.publishedEnd) : null
            }
            placeholder="End date"
            optional={true}
            onChange={(date) => {
              onChange({ publishedEnd: date ? date.getTime() : undefined });
            }}
          />
        </div>
      </FilterFormItem>

      <FilterFormItem label="Created between">
        <div className={styles.dateSpan}>
          <CustomDatePicker
            selected={
              filter.createdStart ? new Date(filter.createdStart) : null
            }
            placeholder="Start date"
            optional={true}
            onChange={(date) => {
              onChange({ createdStart: date ? date.getTime() : undefined });
            }}
          />
          &nbsp;-&nbsp;
          <CustomDatePicker
            selected={filter.createdEnd ? new Date(filter.createdEnd) : null}
            placeholder="End date"
            optional={true}
            onChange={(date) => {
              onChange({ createdEnd: date ? date.getTime() : undefined });
            }}
          />
        </div>
      </FilterFormItem>

      <FilterFormItem label="Enabled">
        <input
          type="checkbox"
          checked={!!filter.enabled}
          onChange={(event) => {
            const enabled = event.currentTarget.checked;
            onChange({ enabled: enabled ? enabled : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Disabled">
        <input
          type="checkbox"
          checked={!!filter.disabled}
          onChange={(event) => {
            const disabled = event.currentTarget.checked;
            onChange({ disabled: disabled ? disabled : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Incomplete">
        <input
          type="checkbox"
          checked={!!filter.incomplete}
          onChange={(event) => {
            const incomplete = event.currentTarget.checked;
            onChange({ incomplete: incomplete ? incomplete : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Archived">
        <input
          type="checkbox"
          checked={!!filter.archived}
          onChange={(event) => {
            const archived = event.currentTarget.checked;
            onChange({ archived: archived ? archived : undefined });
          }}
        />
      </FilterFormItem>

      {/* <FilterFormItem label="Most popular">
        <input
          type="checkbox"
          checked={!!filter.mostPopular}
          onChange={event => {
            const mostPopular = event.currentTarget.checked;
            onChange({ mostPopular: mostPopular ? mostPopular : undefined });
          }}
        />
      </FilterFormItem> */}

      <FilterFormItem label="No tags">
        <input
          type="checkbox"
          checked={!!filter.noTags}
          onChange={(event) => {
            const noTags = event.currentTarget.checked;
            onChange({ noTags: noTags ? noTags : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormButton
        label="Reset"
        type="reset"
        onClick={() => updateFilter({}, 0)}
      />

      <FilterFormButton
        label="Show All"
        type="button"
        onClick={() => updateFilter({ showAll: true }, 300)}
      />
    </FilterForm>
  );
};

export default GamesFilterComp;
