import React, { useState } from "react";
import ImageComboBox from "./ImageComboBox";
import styles from "./ImagePicker.module.scss";
import { Image } from "./imageService";

interface Props {
  imageUrl?: string;
  onSelectedImage: (image: Image) => void;
  filter: (image: Image) => boolean;
}

const ImagePicker: React.FC<Props> = (props) => {
  const [image, setImage] = useState<Image | undefined>();

  function onSelectedImage(selectedImage: Image): void {
    if (!image || image.id !== selectedImage.id) {
      setImage(selectedImage);
      props.onSelectedImage(selectedImage);
    }
  }

  function getImageUrl(): string | undefined {
    if (image) {
      return image.urls.default;
    }
    return props.imageUrl;
  }

  const imageUrl = getImageUrl();

  return (
    <div className={styles.content}>
      <ImageComboBox onSelectedImage={onSelectedImage} filter={props.filter} />
      {imageUrl && (
        <img src={imageUrl} alt={imageUrl} className={styles.image} />
      )}
    </div>
  );
};

export default ImagePicker;
