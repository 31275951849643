import React, { useState } from "react";
import { Affiliate, searchAffiliates } from "./affiliateService";
import styles from "./AffiliateWebsiteComboBox.module.scss";

interface Props {
  onSelectedWebsite: (website: string) => void;
  excludedWebsites?: string[];
}

const resultsLimit = 10;

interface SingleWebsiteAffiliate {
  id: string;
  company: string;
  website: string;
}

const AffiliateWebsiteComboBox: React.FC<Props> = (props) => {
  const [results, setResults] = useState<Affiliate[] | undefined>();
  const [query, setQuery] = useState(``);

  function toWebsites(affiliates: Affiliate[]): SingleWebsiteAffiliate[] {
    return affiliates
      .filter((affiliate) => affiliate.websites.length > 0)
      .flatMap((affiliate) =>
        affiliate.websites.map((website) => {
          return {
            id: affiliate.id,
            company: affiliate.company,
            website: website,
          };
        }),
      );
  }

  return (
    <div className={styles.main}>
      <input
        type="search"
        className={styles.input}
        value={query}
        placeholder="Search by affiliate id or company"
        onChange={(event) => {
          const q = event.currentTarget.value;
          setQuery(q);
          setResults(searchAffiliates(q, resultsLimit));
        }}
      />
      {results && results.length > 0 && (
        <div className={styles.results}>
          {toWebsites(results)
            .filter((a) => !props.excludedWebsites?.includes(a.website))
            .map((affiliate, i) => {
              return (
                <div
                  key={i}
                  className={styles.result}
                  onClick={() => {
                    setQuery(``);
                    setResults(undefined);
                    props.onSelectedWebsite(affiliate.website);
                  }}
                >
                  <div className={styles.text}>
                    {`${affiliate.id} - ${affiliate.company} - ${affiliate.website}`}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default AffiliateWebsiteComboBox;
