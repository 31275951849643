import React from "react";
import Icon from "../component/Icon";
import styles from "./UserListModal.module.scss";
import { UserRef } from "./userRefService";

export interface UserListModalProps {
  users: UserRef[];
  modifiedLabel: string;
  onRequestClose: () => void;
}

const UserListModal: React.FC<UserListModalProps> = ({
  users,
  modifiedLabel,
  onRequestClose,
}) => {
  function copyToClipboard(): void {
    const dummy = document.createElement(`textarea`);
    document.body.appendChild(dummy);
    dummy.value = users
      .map((u) => `${u.userId}\t${dateTimeString(u.modified.date.toDate())}`)
      .reduce((u1, u2) => `${u1}\r\n${u2}`);
    dummy.select();
    document.execCommand(`copy`);
    document.body.removeChild(dummy);
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  return (
    <div className={styles.modal} onClick={onRequestClose}>
      <div className={styles.modalContent}>
        {users.length === 0 ? (
          <div className={styles.empty}>No Content</div>
        ) : (
          <div className={styles.content}>
            <div
              className={styles.clipboard}
              onClick={(e) => {
                copyToClipboard();
                e.stopPropagation();
              }}
              title="Copy to clipboard"
            >
              <Icon name="clipboard" className={styles.clipboardIcon} />
            </div>
            <div className={styles.listWrapper}>
              <table className={styles.list}>
                <thead>
                  <tr>
                    <td>User ID</td>
                    <td>{modifiedLabel}</td>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, i) => {
                    return (
                      <tr key={i}>
                        <td>{user.userId}</td>
                        <td
                          title={dateTimeString(user.modified.date.toDate())}
                          style={{ whiteSpace: `nowrap` }}
                        >
                          {user.modified.date.toDate().toLocaleDateString(`sv`)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserListModal;
