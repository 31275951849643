import {
  Language,
  PromotionTranslation,
  PromotionType,
  TournamentTranslation,
} from "@vipscasino/core";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "../component/Markdown";
import styles from "./PromotionOrTournamentPreview.module.scss";

const datePattern = `D MMM YYYY`;
const timePattern = `HH:mm`;
const minusChar = String.fromCharCode(8722);

export interface PromotionPreviewProps {
  desktop: boolean;
  image?: string;
  language?: Language;
  translation?: PromotionTranslation;
  type?: PromotionType;
  validFrom?: Date;
  validTo?: Date;
  modified: Date;
  onRequestClose: () => void;
}

export const PromotionPreview: React.FC<PromotionPreviewProps> = ({
  desktop,
  image,
  language,
  translation,
  type,
  validFrom,
  validTo,
  modified,
  onRequestClose,
}) => {
  const modalContentClassName = desktop
    ? styles.modalContentDesktop
    : styles.modalContentMobile;
  const promotionClassName = desktop
    ? styles.contentDesktop
    : styles.contentMobile;
  const gradientClassName =
    translation?.termsAndConditions || !desktop
      ? styles.gradient
      : styles.gradientNoTac;
  const imageClassName = desktop ? styles.imageDesktop : styles.imageMobile;

  if (!image || !language || !translation || !type || !validFrom || !validTo) {
    return (
      <div className={styles.modal} onClick={onRequestClose}>
        <div className={modalContentClassName}>
          <div className={styles.empty}>
            Missing content. No preview available.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modal} onClick={onRequestClose}>
      <div className={modalContentClassName}>
        <div className={promotionClassName}>
          <div className={gradientClassName}>
            <img className={imageClassName} src={image} alt="" />
            <h2 className={styles.title}>{translation.title}</h2>
            {type === `Registration` && (
              <div
                className={`${styles.buttonWrapper} ${styles.buttonWrapperReg}`}
              >
                {translation.buttonText && (
                  <button className={styles.button}>
                    {translation.buttonText}
                  </button>
                )}
              </div>
            )}
            {type === `News` && (
              <div className={styles.descDate}>{formatDescDate(modified)}</div>
            )}
            {type !== `Cashback` &&
              type !== `Registration` &&
              type !== `News` && (
                <div className={styles.descDate}>
                  {formatDescDateRange(validFrom, validTo)}
                </div>
              )}
            <Markdown
              className={styles.markdownDesc}
              source={translation.description}
            />
            {type !== `Registration` && (
              <div className={styles.buttonWrapper}>
                {translation.buttonText && (
                  <button className={styles.button}>
                    {translation.buttonText}
                  </button>
                )}
              </div>
            )}
          </div>

          {translation.termsAndConditions && (
            <Tac
              language={language}
              desktop={desktop}
              showValidRange={
                type !== `Cashback` &&
                type !== `Registration` &&
                type !== `News`
              }
              text={translation.termsAndConditions}
              modified={modified}
              from={validFrom}
              to={validTo}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export interface TournamentPreviewProps {
  desktop: boolean;
  image?: string;
  language?: Language;
  translation?: TournamentTranslation;
  activeFrom?: Date;
  activeTo?: Date;
  modified: Date;
  games: boolean;
  aspireTableName?: string;
  onRequestClose: () => void;
}

export const TournamentPreview: React.FC<TournamentPreviewProps> = ({
  desktop,
  image,
  language,
  translation,
  activeFrom,
  activeTo,
  modified,
  games,
  aspireTableName,
  onRequestClose,
}) => {
  const modalContentClassName = desktop
    ? styles.modalContentDesktop
    : styles.modalContentMobile;
  const tournamentClassName = desktop
    ? styles.contentDesktop
    : styles.contentMobile;
  const imageClassName = desktop ? styles.imageDesktop : styles.imageMobile;

  if (!image || !language || !translation || !activeFrom || !activeTo) {
    return (
      <div className={styles.modal} onClick={onRequestClose}>
        <div className={modalContentClassName}>
          <div className={styles.empty}>
            Missing content. No preview available.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modal} onClick={onRequestClose}>
      <div className={modalContentClassName}>
        <div className={tournamentClassName}>
          <div className={styles.gradient}>
            <img className={imageClassName} src={image} alt="" />
            <h2 className={styles.title}>{translation.title}</h2>
            <div className={styles.descDate}>
              {formatDescDateRange(activeFrom, activeTo)}
            </div>
            <Markdown
              className={styles.markdownDesc}
              source={translation.description}
            />

            {games && (
              <div className={styles.gamesPlaceholder}>Games Placeholder</div>
            )}

            {aspireTableName && (
              <div className={styles.leaderboardPlaceholder}>
                Leaderboard Placeholder
              </div>
            )}
          </div>

          <Tac
            language={language}
            desktop={desktop}
            showValidRange
            text={translation.termsAndConditions}
            modified={modified}
            from={activeFrom}
            to={activeTo}
          />
        </div>
      </div>
    </div>
  );
};

interface TacProps {
  language: Language;
  desktop: boolean;
  showValidRange: boolean;
  text: string;
  modified: Date;
  from: Date;
  to: Date;
}

const Tac: React.FC<TacProps> = (props) => {
  const [showTac, setshowTac] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={props.desktop ? styles.tacDesktop : styles.tacMobile}>
      <p
        className={styles.tacTrigger}
        onClick={(e) => {
          setshowTac(!showTac);
          e.stopPropagation();
        }}
      >
        {showTac ? minusChar : `+`} {t(`terms`, { lng: props.language })}
      </p>
      {showTac && (
        <>
          {props.showValidRange && (
            <div className={styles.tacValid}>
              {t(`runs_between`)}
              {` `}
              {formatTacDate(props.modified, props.from, props.to)}
            </div>
          )}
          <Markdown className={styles.markdownTac} source={props.text} />
          <div className={styles.tacUpdated}>
            {t(`updated`)}:{` `}
            {formatTacDate(props.modified, props.from, props.to, true)}
          </div>
        </>
      )}
    </div>
  );
};

function formatDescDate(date: Date): string {
  return moment(date).format(datePattern);
}

function formatDescDateRange(from: Date, to: Date): string {
  return `${formatDescDate(from)} - ${formatDescDate(to)}`;
}

function formatTacDate(
  modified: Date,
  from: Date,
  to: Date,
  updated?: boolean,
): string {
  if (updated) {
    const date = moment(modified);
    return `${date.format(datePattern)} ${date.format(timePattern)}`;
  }
  const validFrom = moment(from);
  const validTo = moment(to);
  return `${validFrom.format(datePattern)} ${validFrom.format(
    timePattern,
  )} - ${validTo.format(datePattern)} ${validTo.format(timePattern)}`;
}
