import React from "react";
import styles from "./Chip.module.scss";
import Icon from "./Icon";

interface Props {
  label?: string;
  title: string;
  onRemove: () => void;
}

const Chip: React.FC<Props> = (props) => {
  return (
    <div className={styles.chip}>
      <div className={styles.text}>
        {props.label && <label>{props.label}</label>}
        <div>{props.title}</div>
      </div>
      <Icon name="close" onClick={props.onRemove} />
    </div>
  );
};

export default Chip;
