import {
  bannerCollection,
  BannerCreateRequest,
  BannerDisplayOption,
  bannerDisplayOptions,
  BannerLayout,
  BannerLayoutPosition,
  bannerLayoutPositions,
  BannerPlatform,
  BannerPlatformLayout,
  BannerPlatformLayoutUpdate,
  bannerPlatforms,
  BannerPlatformTranslation,
  BannerPlatformTranslationUpdate,
  bannerPriorities,
  BannerPriority,
  BannerTranslation,
  BannerTranslations,
  BannerTranslationsUpdateRequest,
  BannerTranslationUpdate,
  BannerType,
  bannerTypes,
  BannerUpdateRequest,
  Brand,
  brands,
  countries,
  Country,
  defaultCountry,
  defaultLanguage,
  getTranslatedCountries,
  getTranslatedLanguages,
  isBannerDisplayOption,
  isBannerLayoutPosition,
  isBannerPlatform,
  isBannerPriority,
  isBannerType,
  isBrand,
  isDepositBanner,
  isGameBanner,
  isLinkBanner,
  isPromotionBanner,
  isRegistrationBanner,
  isTournamentBanner,
  Language,
  languages,
  Translations,
} from "@vipscasino/core";
import firebase from "firebase/app";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink, useHistory, useParams } from "react-router-dom";
import AppContext from "../AppContext";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarItem from "../component/ActionToolbarItem";
import CustomDatePicker from "../component/CustomDatePicker";
import Icon from "../component/Icon";
import KeyValueGrid from "../component/KeyValueGrid";
import KeyValueGridItem from "../component/KeyValueGridItem";
import Loader from "../component/Loader";
import MarkdownEditor from "../component/MarkdownEditor";
import { config } from "../config";
import GameComboBox from "../game/GameComboBox";
import { Game, getGame } from "../game/gameService";
import ImagePicker from "../image/ImagePicker";
import { firebaseApp } from "../lib/firebase";
import { defaultHeaders } from "../lib/util/httpUtil";
import PromotionComboBox from "../promotion/PromotionComboBox";
import {
  getPromotion,
  getPromotionTranslation,
  Promotion,
} from "../promotion/promotionService";
import TournamentComboBox from "../tournament/TournamentComboBox";
import {
  getTournament,
  getTournamentTranslation,
  Tournament,
} from "../tournament/tournamentService";
import TranslationsComp from "../translation/Translations";
import TranslationsItem from "../translation/TranslationsItem";
import styles from "./BannerEdit.module.scss";
import BannerPreview, { BannerPreviewProps } from "./BannerPreview";
import { Banner, getBannerType } from "./bannerService";

const db = firebaseApp.firestore();

const defaultTextLayout: BannerLayoutPosition = `topLeft`;
const defaultButtonLayout: BannerLayoutPosition = `bottomLeft`;
const defaultDisplayOption: BannerDisplayOption = `always`;
const defaultPlatform: BannerPlatform = `desktopAndMobile`;
const defaultPriority: BannerPriority = `5`;

interface Params {
  id?: string;
  clone?: string;
}

export interface BannerProps {
  promotionId?: string;
  tournamentId?: string;
}

function translationIsNonEmpty(t: BannerTranslation): boolean {
  function platformTranslationIsNonEmpty(
    t: BannerPlatformTranslation,
  ): boolean {
    if (
      (t.text && t.text.trim().length > 0) ||
      (t.buttonText && t.buttonText.trim().length > 0) ||
      (t.tacLinkPreLoginText && t.tacLinkPreLoginText.trim().length > 0) ||
      (t.tacLinkPostLoginText && t.tacLinkPostLoginText.trim().length > 0)
    ) {
      return true;
    }
    return false;
  }

  return (
    platformTranslationIsNonEmpty(t.desktop) ||
    platformTranslationIsNonEmpty(t.mobile)
  );
}

function isValidTranslation(t: BannerTranslation): boolean {
  function isValidPlatformTranslation(t: BannerPlatformTranslation): boolean {
    if (t.text.trim().length === 0) {
      if (t.buttonText && t.buttonText.trim().length > 0) {
        return false;
      }
      if (t.tacLinkPreLoginText && t.tacLinkPreLoginText.trim().length > 0) {
        return false;
      }
      if (t.tacLinkPostLoginText && t.tacLinkPostLoginText.trim().length > 0) {
        return false;
      }
    }
    return true;
  }

  return (
    isValidPlatformTranslation(t.desktop) &&
    isValidPlatformTranslation(t.mobile)
  );
}

function getFirstTranslatedLanguage(
  translations: Map<Country, Map<Language, BannerTranslation>>,
  country: Country,
): Language | undefined {
  const ct = translations.get(country);
  if (!ct) {
    return undefined;
  }
  for (const [lang, t] of Array.from(ct.entries())) {
    if (translationIsNonEmpty(t)) {
      return lang;
    }
  }
  return undefined;
}

const BannerEdit: React.FC = (props) => {
  const history = useHistory<BannerProps | undefined>();
  const locationState = history.location.state;
  const promotionId = locationState?.promotionId;
  const tournamentId = locationState?.tournamentId;
  const params = useParams<Params>();
  const id = params.id;
  const clone = params.clone;
  const { state } = useContext(AppContext);
  const [banner, setBanner] = useState<Banner | undefined>();
  const [game, setGame] = useState<Game | undefined>();
  const [promotion, setPromotion] = useState<Promotion | undefined>();
  const [tournament, setTournament] = useState<Tournament | undefined>();
  const [updateRequest, setUpdateRequest] = useState<BannerUpdateRequest>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>();
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [translations, setTranslations] = useState<
    Map<Country, Map<Language, BannerTranslation>>
  >(new Map());
  const [showPreview, setShowPreview] = useState(false);
  const [desktopPreview, setDesktopPreview] = useState(true);
  const [desktopImageUpdate, setDesktopImageUpdate] = useState<
    string | undefined
  >();
  const [mobileImageUpdate, setMobileImageUpdate] = useState<
    string | undefined
  >();

  // validate form
  useEffect(() => {
    setValid(isValid(banner, updateRequest, translations, clone));
  }, [banner, updateRequest, translations, clone]);

  function isValid(
    banner: Banner | undefined,
    ur: BannerUpdateRequest,
    translations: Map<Country, Map<Language, BannerTranslation>>,
    clone: string | undefined,
  ): boolean {
    for (const ct of Array.from(translations.values())) {
      for (const t of Array.from(ct.values())) {
        if (!isValidTranslation(t)) {
          return false;
        }
      }
    }

    if (banner && !clone) {
      if (ur.typeRef !== undefined && ur.typeRef.trim().length === 0) {
        return false;
      }

      if (ur.platform) {
        if (ur.platform !== `mobile`) {
          if (!ur.desktopImage && !banner.image.desktop.default) {
            return false;
          }
        }
        if (ur.platform !== `desktop`) {
          if (!ur.mobileImage && !banner.image.mobile.default) {
            return false;
          }
        }
      }

      if (ur.validFrom && ur.validTo) {
        return ur.validTo > ur.validFrom;
      } else if (ur.validFrom) {
        return banner.validTo.toMillis() > ur.validFrom;
      } else if (ur.validTo) {
        return ur.validTo > banner.validFrom.toMillis();
      } else {
        return true;
      }
    }

    if (!ur.brand) {
      return false;
    }

    if (!ur.type) {
      return false;
    }

    switch (ur.type) {
      case `Game`:
      case `Promotion`:
      case `Tournament`:
      case `Link`:
        if (!ur.typeRef || ur.typeRef.trim().length === 0) {
          return false;
        }
    }

    if (ur.platform !== `mobile`) {
      if (!ur.desktopImage || ur.desktopImage.trim().length === 0) {
        return false;
      }
    }

    if (ur.platform !== `desktop`) {
      if (!ur.mobileImage || ur.mobileImage.trim().length === 0) {
        return false;
      }
    }

    if (!ur.validFrom || !ur.validTo) {
      return false;
    }

    return ur.validTo > ur.validFrom;
  }

  // init create request
  useEffect(() => {
    if (!id) {
      let type: BannerType | undefined;
      let typeRef: string | undefined;
      let validFrom: number | undefined;
      let validTo: number | undefined;
      let brand: Brand | undefined;
      if (promotionId) {
        type = `Promotion`;
        typeRef = promotionId;
        const promotion = getPromotion(promotionId);
        if (promotion) {
          validFrom = promotion.validFrom.toMillis();
          validTo = promotion.validTo.toMillis();
          brand = promotion.brand;
        }
      } else if (tournamentId) {
        type = `Tournament`;
        typeRef = tournamentId;
        const tournament = getTournament(tournamentId);
        if (tournament) {
          validFrom = tournament.activeFrom.toMillis();
          validTo = tournament.activeTo.toMillis();
          brand = tournament.brand;
        }
      }

      setUpdateRequest({
        layout: {
          desktop: {
            text: defaultTextLayout,
            button: defaultButtonLayout,
          },
          mobile: {
            text: defaultTextLayout,
            button: defaultButtonLayout,
          },
        },
        displayOption: defaultDisplayOption,
        priority: defaultPriority,
        platform: defaultPlatform,
        type: type,
        typeRef: typeRef,
        validFrom: validFrom,
        validTo: validTo,
        brand: brand,
      });
    }
  }, [id, promotionId, tournamentId]);

  // update type references
  useEffect(() => {
    if (updateRequest.typeRef) {
      if (updateRequest.type === `Game`) {
        setGame(getGame(updateRequest.typeRef));
      } else if (updateRequest.type === `Promotion`) {
        setPromotion(getPromotion(updateRequest.typeRef));
      } else if (updateRequest.type === `Tournament`) {
        setTournament(getTournament(updateRequest.typeRef));
      }
    }
  }, [updateRequest.type, updateRequest.typeRef]);

  // load banner
  useEffect(() => {
    if (id) {
      setLoading(true);
      db.collection(bannerCollection)
        .doc(id)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            throw Error(`Banner with id ${id} does not exist`);
          }

          const banner = { ...snapshot.data(), id: snapshot.id } as Banner;

          const countryTranslations = new Map<
            Country,
            Map<Language, BannerTranslation>
          >();
          const bt = banner.translations ?? {};
          for (const country of getTranslatedCountries(bt)) {
            const translations = new Map<Language, BannerTranslation>();
            countryTranslations.set(country, translations);
            for (const lang of getTranslatedLanguages(bt[country]!)) {
              translations.set(lang, bt[country]![lang]!);
            }
          }
          setTranslations(countryTranslations);
          if (countryTranslations.size > 0) {
            const selectedCountry = Array.from(countryTranslations.keys())[0];
            setSelectedCountry(selectedCountry);
            setSelectedLang(
              getFirstTranslatedLanguage(countryTranslations, selectedCountry),
            );
          }

          // set banner when translations are initialized,
          // in case of promotion banner
          setBanner(banner);

          if (clone) {
            const updateRequest: BannerUpdateRequest = {
              type: `Image`,
              brand: banner.brand,
              active: banner.active,
              layout: banner.layout,
              platform: banner.platform,
              displayOption: banner.displayOption,
              priority: banner.priority ?? defaultPriority,
              desktopImage: banner.image.desktop.default
                ? banner.image.desktop.ref.id
                : ``,
              mobileImage: banner.image.mobile.default
                ? banner.image.mobile.ref.id
                : ``,
              validFrom: banner.validFrom.toMillis(),
              validTo: banner.validTo.toMillis(),
            };
            if (
              isGameBanner<unknown, firebase.firestore.DocumentReference>(
                banner,
              )
            ) {
              updateRequest.type = `Game`;
              updateRequest.typeRef = banner.game.id;
            } else if (
              isPromotionBanner<unknown, firebase.firestore.DocumentReference>(
                banner,
              )
            ) {
              updateRequest.type = `Promotion`;
              updateRequest.typeRef = banner.promotion.id;
            } else if (
              isTournamentBanner<unknown, firebase.firestore.DocumentReference>(
                banner,
              )
            ) {
              updateRequest.type = `Tournament`;
              updateRequest.typeRef = banner.tournament.id;
            } else if (isLinkBanner<unknown, unknown>(banner)) {
              updateRequest.type = `Link`;
              updateRequest.typeRef = banner.link;
            } else if (isRegistrationBanner<unknown, unknown>(banner)) {
              updateRequest.type = `Registration`;
              updateRequest.typeRef = String(banner.type);
            } else if (isDepositBanner<unknown, unknown>(banner)) {
              updateRequest.type = `Deposit`;
              updateRequest.typeRef = String(banner.type);
            }
            setUpdateRequest(updateRequest);
          }

          if (
            !(
              isGameBanner(banner) ||
              isPromotionBanner(banner) ||
              isTournamentBanner(banner)
            )
          ) {
            setLoading(false);
          }
        });
    }
  }, [id, clone]);

  // get game, iff game banner
  useEffect(() => {
    if (game || !state.gamesLoaded) {
      return;
    }

    if (
      banner &&
      isGameBanner<unknown, firebase.firestore.DocumentReference>(banner)
    ) {
      const game = getGame(banner.game.id);
      if (!game) {
        throw Error(`Game with id ${banner.game.id} does not exist`);
      }
      setGame(game);
      setLoading(false);
    }
  }, [banner, game, state.gamesLoaded]);

  // get promotion, iff promotion banner
  useEffect(() => {
    if (promotion || !state.promotionsLoaded) {
      return;
    }

    if (
      banner &&
      isPromotionBanner<unknown, firebase.firestore.DocumentReference>(banner)
    ) {
      const promotion = getPromotion(banner.promotion.id);
      if (!promotion) {
        throw Error(`Promotion with id ${banner.promotion.id} does not exist`);
      }
      setPromotion(promotion);
      setLoading(false);
    }
  }, [banner, promotion, state.promotionsLoaded]);

  // get tournament, iff tournament banner
  useEffect(() => {
    if (tournament || !state.tournamentsLoaded) {
      return;
    }

    if (
      banner &&
      isTournamentBanner<unknown, firebase.firestore.DocumentReference>(banner)
    ) {
      const tournament = getTournament(banner.tournament.id);
      if (!tournament) {
        throw Error(
          `Tournament with id ${banner.tournament.id} does not exist`,
        );
      }
      setTournament(tournament);
      setLoading(false);
    }
  }, [banner, state.tournamentsLoaded, tournament]);

  if (saving) {
    return <Loader message="Saving banner..." />;
  }

  if (loading || !state.imagesLoaded) {
    return <Loader message="Loading banner..." />;
  }

  const selectedTrans =
    selectedCountry && selectedLang
      ? translations.get(selectedCountry)?.get(selectedLang)
      : undefined;

  async function save(): Promise<void> {
    setSaving(true);

    const update = id && !clone;
    const data = update ? getUpdateRequest() : getCreateRequest();
    const url = `${config.api}/banners/${update ? `${id}/` : ``}`;
    const res = await fetch(url, {
      method: `${update ? `PUT` : `POST`}`,
      headers: defaultHeaders(state),
      body: JSON.stringify(data),
    });
    console.info(await res.text());

    setSaving(false);
    history.goBack();
  }

  function getUpdateRequest(): BannerUpdateRequest {
    const tur = getTranslationsUpdateRequest();
    if (Object.keys(tur).length === 0) {
      // no translation changes
      return updateRequest;
    }

    return {
      ...updateRequest,
      ...{
        translations: tur,
      },
    };
  }

  function getTranslationsUpdateRequest(): BannerTranslationsUpdateRequest {
    const tur: BannerTranslationsUpdateRequest = {};

    for (const [country, tMap] of Array.from(translations.entries())) {
      tur[country] = {};
      for (const [lang, t] of Array.from(tMap.entries())) {
        const tu = getTranslationUpdate(country, lang, t);
        if (!tu) {
          continue;
        }

        if (translationIsNonEmpty(t)) {
          tur[country]![lang] = tu;
        } else if (
          banner?.translations[country] &&
          banner.translations[country]![lang]
        ) {
          tur[country]![lang] = `removed`;
        }
      }
      if (Object.keys(tur[country]!).length === 0) {
        delete tur[country];
      }
    }

    return tur;
  }

  function getTranslationUpdate(
    country: Country,
    lang: Language,
    t: BannerTranslation,
  ): BannerTranslationUpdate | undefined {
    const tNew = getTrimmedTranslation(t);

    function getPlatformTranslationUpdate(
      tOld: BannerPlatformTranslation,
      tNew: BannerPlatformTranslation,
    ): BannerPlatformTranslationUpdate | undefined {
      let tu: BannerPlatformTranslationUpdate = {};
      if (
        (tNew.buttonText || tOld.buttonText) &&
        tNew.buttonText !== tOld.buttonText
      ) {
        tu = { ...tu, buttonText: tNew.buttonText };
      }
      if (
        (tNew.tacLinkPreLoginText || tOld.tacLinkPreLoginText) &&
        tNew.tacLinkPreLoginText !== tOld.tacLinkPreLoginText
      ) {
        tu = { ...tu, tacLinkPreLoginText: tNew.tacLinkPreLoginText };
      }
      if (
        (tNew.tacLinkPostLoginText || tOld.tacLinkPostLoginText) &&
        tNew.tacLinkPostLoginText !== tOld.tacLinkPostLoginText
      ) {
        tu = { ...tu, tacLinkPostLoginText: tNew.tacLinkPostLoginText };
      }
      if (tNew.text !== tOld.text) {
        tu = { ...tu, text: tNew.text };
      }
      return Object.keys(tu).length > 0 ? tu : undefined;
    }

    if (banner?.translations[country] && banner.translations[country]![lang]) {
      let tu: BannerTranslationUpdate = {};
      const tOld = banner.translations[country]![lang]!;
      const desktopUpdate = getPlatformTranslationUpdate(
        tOld.desktop,
        tNew.desktop,
      );
      const mobileUpdate = getPlatformTranslationUpdate(
        tOld.mobile,
        tNew.mobile,
      );
      if (desktopUpdate) {
        tu = { ...tu, desktop: desktopUpdate };
      }
      if (mobileUpdate) {
        tu = { ...tu, mobile: mobileUpdate };
      }
      return Object.keys(tu).length > 0 ? tu : undefined;
    }

    return translationIsNonEmpty(tNew) ? tNew : undefined;
  }

  function getTrimmedTranslation(t: BannerTranslation): BannerTranslation {
    function getTrimmedPlatformTranslation(
      t: BannerPlatformTranslation,
    ): BannerPlatformTranslation {
      const tTrimmed: BannerPlatformTranslation = { text: t.text.trim() };
      if (t.buttonText !== undefined) {
        tTrimmed.buttonText = t.buttonText.trim();
      }
      if (t.tacLinkPreLoginText !== undefined) {
        tTrimmed.tacLinkPreLoginText = t.tacLinkPreLoginText.trim();
      }
      if (t.tacLinkPostLoginText !== undefined) {
        tTrimmed.tacLinkPostLoginText = t.tacLinkPostLoginText.trim();
      }
      return tTrimmed;
    }

    return {
      desktop: getTrimmedPlatformTranslation(t.desktop),
      mobile: getTrimmedPlatformTranslation(t.mobile),
    };
  }

  function getCreateRequest(): BannerCreateRequest {
    const bt: BannerTranslations = {};
    for (const [country, tMap] of Array.from(translations.entries())) {
      const ct: Translations<BannerTranslation> = {};
      for (const [lang, t] of Array.from(tMap.entries())) {
        if (
          t.desktop.text.trim().length > 0 ||
          t.mobile.text.trim().length > 0
        ) {
          ct[lang] = getTrimmedTranslation(t);
        }
      }
      if (Object.keys(ct).length > 0) {
        bt[country] = ct;
      }
    }

    return {
      brand: updateRequest.brand!,
      active: updateRequest.active ?? false,
      translations: bt,
      layout: updateRequest.layout as BannerLayout,
      platform: updateRequest.platform!,
      displayOption: updateRequest.displayOption!,
      priority: updateRequest.priority,
      showTacLinkPreLogin: updateRequest.showTacLinkPreLogin ?? false,
      showTacLinkPostLogin: updateRequest.showTacLinkPostLogin ?? false,
      validFrom: updateRequest.validFrom!,
      validTo: updateRequest.validTo!,
      desktopImage: updateRequest.desktopImage
        ? updateRequest.desktopImage
        : ``,
      mobileImage: updateRequest.mobileImage ? updateRequest.mobileImage : ``,
      type: updateRequest.type!,
      typeRef: updateRequest.typeRef === undefined ? `` : updateRequest.typeRef,
    };
  }

  function cancel(): void {
    history.goBack();
  }

  function onUpdate(field: BannerUpdateRequest): void {
    setUpdateRequest({ ...updateRequest, ...field });
  }

  function emptyPlatformTranslation(): BannerPlatformTranslation {
    return {
      text: ``,
      buttonText: ``,
      tacLinkPreLoginText: ``,
      tacLinkPostLoginText: ``,
    };
  }

  function emptyTranslation(): BannerTranslation {
    return {
      desktop: emptyPlatformTranslation(),
      mobile: emptyPlatformTranslation(),
    };
  }

  function onFullTranslationUpdate(field: Partial<BannerTranslation>): void {
    if (!selectedCountry || !selectedLang) {
      return;
    }

    const translationsCopy: Map<
      Country,
      Map<Language, BannerTranslation>
    > = new Map(translations);

    const selectedTranslations: Map<Language, BannerTranslation> =
      translationsCopy.get(selectedCountry) ?? new Map();

    const t = selectedTranslations.get(selectedLang) ?? emptyTranslation();
    selectedTranslations.set(selectedLang, { ...t, ...field });
    translationsCopy.set(selectedCountry, selectedTranslations);
    setTranslations(translationsCopy);
  }

  function onTranslationUpdate(
    field: BannerPlatformTranslationUpdate,
    desktop: boolean,
  ): void {
    if (!selectedCountry || !selectedLang) {
      return;
    }

    const translationsCopy: Map<
      Country,
      Map<Language, BannerTranslation>
    > = new Map(translations);

    const selectedTranslations: Map<Language, BannerTranslation> =
      translationsCopy.get(selectedCountry) ?? new Map();

    let t = selectedTranslations.get(selectedLang) ?? emptyTranslation();
    const update = { ...(desktop ? t.desktop : t.mobile), ...field };
    t = {
      ...t,
      ...(desktop ? { desktop: update } : { mobile: update }),
    };
    selectedTranslations.set(selectedLang, t);
    translationsCopy.set(selectedCountry, selectedTranslations);
    setTranslations(translationsCopy);
  }

  function onLayoutUpdate(
    field: BannerPlatformLayoutUpdate,
    desktop: boolean,
  ): void {
    let layout = updateRequest.layout ?? {};
    const update = { ...(desktop ? layout.desktop : layout.mobile), ...field };
    layout = {
      ...layout,
      ...(desktop ? { desktop: update } : { mobile: update }),
    };
    onUpdate({ layout: layout });
  }

  function existingLangs(country: Country | undefined): Language[] {
    if (!country) {
      return [];
    }

    return languages.filter((lang) => {
      const t = translations.get(country)?.get(lang);
      return !!t && translationIsNonEmpty(t);
    });
  }

  function validLangs(country: Country | undefined): Language[] {
    if (!country) {
      return [];
    }

    return languages.filter((lang) => {
      const t = translations.get(country)?.get(lang);
      return !t || isValidTranslation(t);
    });
  }

  function existingCountries(): Country[] {
    return countries.filter((country) => {
      const t = translations.get(country);
      return !!t && existingLangs(country).length > 0;
    });
  }

  function validCountries(): Country[] {
    return countries.filter((country) => {
      const t = translations.get(country);
      return !t || validLangs(country).length === languages.length;
    });
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  const validFromSelected = updateRequest.validFrom
    ? new Date(updateRequest.validFrom)
    : banner
    ? banner.validFrom.toDate()
    : null;

  const validToSelected = updateRequest.validTo
    ? new Date(updateRequest.validTo)
    : banner
    ? banner.validTo.toDate()
    : null;

  function typeRef(): JSX.Element {
    let placeHolder = `Add a valid ${updateRequest.type} ID`;
    if (updateRequest.type === `Link`) {
      placeHolder = `Add a valid link, without language (e.g. /casino/browse/popular)`;
    }
    return (
      <input
        type="text"
        value={updateRequest.typeRef}
        placeholder={placeHolder}
        onChange={(event) =>
          onUpdate({ typeRef: event.currentTarget.value.trim() })
        }
      />
    );
  }

  function textLayout(desktop: boolean): JSX.Element {
    const platformLayout = banner
      ? desktop
        ? banner.layout.desktop
        : banner.layout.mobile
      : undefined;
    return (
      <select
        defaultValue={platformLayout ? platformLayout.text : defaultTextLayout}
        onChange={(event) => {
          const layout = event.currentTarget.value;
          if (!isBannerLayoutPosition(layout)) {
            throw new Error(`invalid BannerLayoutPosition: ` + layout);
          }
          onLayoutUpdate({ text: layout }, desktop);
        }}
      >
        {Array.from(bannerLayoutPositions.entries()).map(
          ([layout, name], i) => (
            <option key={i} value={layout}>
              {name}
            </option>
          ),
        )}
      </select>
    );
  }

  function buttonLayout(desktop: boolean): JSX.Element {
    const platformLayout = banner
      ? desktop
        ? banner.layout.desktop
        : banner.layout.mobile
      : undefined;
    return (
      <select
        defaultValue={
          platformLayout ? platformLayout.button : defaultButtonLayout
        }
        onChange={(event) => {
          const layout = event.currentTarget.value;
          if (!isBannerLayoutPosition(layout)) {
            throw new Error(`invalid BannerLayoutPosition: ` + layout);
          }
          onLayoutUpdate({ button: layout }, desktop);
        }}
      >
        {Array.from(bannerLayoutPositions.entries()).map(
          ([layout, name], i) => (
            <option key={i} value={layout}>
              {name}
            </option>
          ),
        )}
      </select>
    );
  }

  function text(desktop: boolean): JSX.Element {
    const text = selectedTrans
      ? desktop
        ? selectedTrans.desktop.text
        : selectedTrans.mobile.text
      : ``;
    return (
      <MarkdownEditor
        text={text}
        editMode={true}
        editRows={3}
        disabled={!banner && !selectedLang}
        placeholder={`Add an overlay ${desktop ? `desktop` : `mobile`} text`}
        markdownClass={styles.markdownBanner}
        onChange={(value) => {
          onTranslationUpdate({ text: value }, desktop);
        }}
      />
    );
  }

  function buttonText(desktop: boolean): JSX.Element {
    const buttonText = selectedTrans
      ? desktop
        ? selectedTrans.desktop.buttonText
        : selectedTrans.mobile.buttonText
      : ``;
    return (
      <input
        type="text"
        disabled={!banner && !selectedLang}
        placeholder={`Add a ${
          desktop ? `desktop` : `mobile`
        } button text (optional)`}
        value={buttonText ?? ``}
        onChange={(event) => {
          onTranslationUpdate(
            { buttonText: event.currentTarget.value },
            desktop,
          );
        }}
      />
    );
  }

  function tacLinkPreLoginText(desktop: boolean): JSX.Element {
    const tacLinkPreLoginText = selectedTrans
      ? desktop
        ? selectedTrans.desktop.tacLinkPreLoginText
        : selectedTrans.mobile.tacLinkPreLoginText
      : ``;
    return (
      <input
        type="text"
        disabled={!banner && !selectedLang}
        placeholder={`Add a ${
          desktop ? `desktop` : `mobile`
        } T&C link text (optional)`}
        value={tacLinkPreLoginText ?? ``}
        onChange={(event) => {
          onTranslationUpdate(
            { tacLinkPreLoginText: event.currentTarget.value },
            desktop,
          );
        }}
      />
    );
  }

  function tacLinkPostLoginText(desktop: boolean): JSX.Element {
    const tacLinkPostLoginText = selectedTrans
      ? desktop
        ? selectedTrans.desktop.tacLinkPostLoginText
        : selectedTrans.mobile.tacLinkPostLoginText
      : ``;
    return (
      <input
        type="text"
        disabled={!banner && !selectedLang}
        placeholder={`Add a ${
          desktop ? `desktop` : `mobile`
        } T&C link text (optional)`}
        value={tacLinkPostLoginText ?? ``}
        onChange={(event) => {
          onTranslationUpdate(
            { tacLinkPostLoginText: event.currentTarget.value },
            desktop,
          );
        }}
      />
    );
  }

  const platforms = (
    <select
      defaultValue={banner ? banner.platform : defaultPlatform}
      onChange={(event) => {
        const platform = event.currentTarget.value;
        if (!isBannerPlatform(platform)) {
          throw new Error(`invalid BannerPlatform: ` + platform);
        }
        onUpdate({ platform: platform });
      }}
    >
      {Array.from(bannerPlatforms.entries()).map(([platform, name], i) => (
        <option key={i} value={platform}>
          {name}
        </option>
      ))}
    </select>
  );

  const displayOption = (
    <select
      defaultValue={banner ? banner.displayOption : defaultDisplayOption}
      onChange={(event) => {
        const option = event.currentTarget.value;
        if (!isBannerDisplayOption(option)) {
          throw new Error(`invalid BannerDisplayOption: ` + option);
        }
        onUpdate({ displayOption: option });
      }}
    >
      {Array.from(bannerDisplayOptions.entries()).map(([option, name], i) => (
        <option key={i} value={option}>
          {name}
        </option>
      ))}
    </select>
  );

  function bannerPreviewProps(): BannerPreviewProps {
    // LAYOUT
    let textLayout: BannerLayoutPosition | undefined;
    let buttonLayout: BannerLayoutPosition | undefined;
    if (updateRequest.layout) {
      const platformLayout = desktopPreview
        ? updateRequest.layout.desktop
        : updateRequest.layout.mobile;
      if (platformLayout) {
        textLayout = platformLayout.text;
        buttonLayout = platformLayout.button;
      }
    }
    if (!(textLayout && buttonLayout) && banner) {
      const layout = banner.layout;
      const platformLayout = desktopPreview ? layout.desktop : layout.mobile;
      textLayout = textLayout ?? platformLayout.text;
      buttonLayout = buttonLayout ?? platformLayout.button;
    }
    const layout: BannerPlatformLayout | undefined =
      textLayout && buttonLayout
        ? { text: textLayout, button: buttonLayout }
        : undefined;

    // IMAGE
    let image: string | undefined = desktopPreview
      ? desktopImageUpdate
      : mobileImageUpdate;
    if (!image && banner) {
      image = desktopPreview
        ? banner.image.desktop.default
        : banner.image.mobile.default;
    }

    // TEXTS
    const platformTranslation = selectedTrans
      ? desktopPreview
        ? selectedTrans.desktop
        : selectedTrans.mobile
      : undefined;
    const text = platformTranslation?.text;
    const buttonText = platformTranslation?.buttonText;

    return {
      desktop: desktopPreview,
      layout: layout,
      image: image,
      text: text,
      buttonText: buttonText,
      onRequestClose: () => setShowPreview(false),
    };
  }

  function getCopyTranslations(): Map<string, BannerTranslation> {
    const map: Map<string, BannerTranslation> = new Map();
    if (
      selectedCountry !== defaultCountry ||
      selectedLang !== defaultLanguage
    ) {
      const trans = translations.get(defaultCountry)?.get(defaultLanguage);
      if (trans && translationIsNonEmpty(trans)) {
        map.set(`${defaultCountry}-${defaultLanguage}`, trans);
      }
    }
    if (
      selectedLang &&
      selectedCountry !== defaultCountry &&
      selectedLang !== defaultLanguage
    ) {
      const trans = translations.get(defaultCountry)?.get(selectedLang);
      if (trans && translationIsNonEmpty(trans)) {
        map.set(`${defaultCountry}-${selectedLang}`, trans);
      }
    }
    return map;
  }

  return (
    <div className={styles.main}>
      <ActionToolbar name={`${banner ? `Edit` : `Add`} banner`}>
        <ActionToolbarItem
          type="button"
          icon={<Icon name="cancel" />}
          name="Cancel"
          onClick={() => cancel()}
        />
        <ActionToolbarItem
          type="button"
          icon={<Icon name="save" />}
          name="Save"
          disabled={!valid}
          onClick={() => save()}
        />
      </ActionToolbar>

      {showPreview && <BannerPreview {...bannerPreviewProps()} />}

      <KeyValueGrid>
        {id && !clone && (
          <KeyValueGridItem label="ID" editable={false}>
            {id}
          </KeyValueGridItem>
        )}

        {banner && !clone && (
          <KeyValueGridItem label="Created" editable={false}>
            {dateTimeString(banner.created.toDate() as Date)}
          </KeyValueGridItem>
        )}

        {banner && isLinkBanner(banner) && !clone && (
          <KeyValueGridItem label="Link" editable>
            {banner.link}
          </KeyValueGridItem>
        )}

        {banner && !clone ? (
          <KeyValueGridItem label="Type" editable={false}>
            {getBannerType(banner)}
          </KeyValueGridItem>
        ) : (
          <KeyValueGridItem label="Type" editable>
            <select
              value={updateRequest.type}
              onChange={(event) => {
                const type = event.currentTarget.value;
                if (!isBannerType(type)) {
                  throw new Error(`invalid BannerType: ` + type);
                }
                onUpdate({ type: type, typeRef: `` });
              }}
            >
              {!updateRequest.type && <option value="" />}
              {bannerTypes.map((type, i) => (
                <option key={i} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </KeyValueGridItem>
        )}

        {((game && !updateRequest.type) || updateRequest.type === `Game`) && (
          <KeyValueGridItem label="Game" editable>
            <GameComp
              game={
                game &&
                (!updateRequest.type || updateRequest.typeRef === game.id)
                  ? game
                  : undefined
              }
              onSelectedGame={(game) => {
                onUpdate({ type: `Game`, typeRef: game.id });
              }}
            />
          </KeyValueGridItem>
        )}

        {((promotion && !updateRequest.type) ||
          updateRequest.type === `Promotion`) && (
          <KeyValueGridItem label="Promotion" editable>
            <PromotionComp
              promotion={
                promotion &&
                (!updateRequest.type || updateRequest.typeRef === promotion.id)
                  ? promotion
                  : undefined
              }
              preferredLang={selectedLang}
              onSelectedPromotion={(promotion) => {
                onUpdate({ type: `Promotion`, typeRef: promotion.id });
              }}
            />
          </KeyValueGridItem>
        )}

        {((tournament && !updateRequest.type) ||
          updateRequest.type === `Tournament`) && (
          <KeyValueGridItem label="Tournament" editable>
            <TournamentComp
              tournament={
                tournament &&
                (!updateRequest.type || updateRequest.typeRef === tournament.id)
                  ? tournament
                  : undefined
              }
              preferredLang={selectedLang}
              onSelectedTournament={(tournament) => {
                onUpdate({ type: `Tournament`, typeRef: tournament.id });
              }}
            />
          </KeyValueGridItem>
        )}

        {updateRequest.type === `Link` && (
          <KeyValueGridItem label="Link" editable>
            {typeRef()}
          </KeyValueGridItem>
        )}

        <KeyValueGridItem label="Desktop Image" editable>
          <ImagePicker
            imageUrl={banner?.image.desktop.default}
            onSelectedImage={(image) => {
              onUpdate({ desktopImage: image.id });
              setDesktopImageUpdate(image.urls.default);
            }}
            filter={(image) =>
              image.type === `Banner` &&
              (image.platform === `desktop` ||
                image.platform === `desktopAndMobile`)
            }
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Mobile Image" editable>
          <ImagePicker
            imageUrl={banner?.image.mobile.default}
            onSelectedImage={(image) => {
              onUpdate({ mobileImage: image.id });
              setMobileImageUpdate(image.urls.default);
            }}
            filter={(image) =>
              image.type === `Banner` &&
              (image.platform === `mobile` ||
                image.platform === `desktopAndMobile`)
            }
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Brand" editable>
          <select
            value={updateRequest.brand ?? banner?.brand}
            onChange={(event) => {
              const brand = event.currentTarget.value;
              if (!isBrand(brand)) {
                throw new Error(`invalid brand: ` + brand);
              }
              onUpdate({ brand: brand });
            }}
          >
            {!banner?.brand && !updateRequest.brand && <option value="" />}
            {brands.map((brand, i) => (
              <option key={i} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label="Enabled" editable>
          <input
            type="checkbox"
            defaultChecked={banner?.active ?? false}
            onChange={(event) => {
              onUpdate({ active: event.currentTarget.checked });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Platforms" editable>
          {platforms}
        </KeyValueGridItem>

        <KeyValueGridItem label="Displayed When" editable>
          {displayOption}
        </KeyValueGridItem>

        <KeyValueGridItem label="Priority" editable>
          <select
            value={updateRequest.priority ?? banner?.priority}
            onChange={(event) => {
              const priority = event.currentTarget.value;
              if (!isBannerPriority(priority)) {
                throw new Error(`invalid banner priority: ` + priority);
              }
              onUpdate({ priority: priority });
            }}
          >
            {!banner?.priority && !updateRequest.priority && (
              <option value="" />
            )}
            {bannerPriorities.map((priority, i) => (
              <option key={i} value={priority}>
                {priority}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label={`T&C Link (logged in)`} editable>
          <input
            type="checkbox"
            defaultChecked={banner?.showTacLinkPostLogin ?? false}
            onChange={(event) => {
              onUpdate({ showTacLinkPostLogin: event.currentTarget.checked });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label={`T&C Link (logged out)`} editable>
          <input
            type="checkbox"
            defaultChecked={banner?.showTacLinkPreLogin ?? false}
            onChange={(event) => {
              onUpdate({ showTacLinkPreLogin: event.currentTarget.checked });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Valid From" editable>
          <CustomDatePicker
            selected={validFromSelected}
            placeholder="Add a valid from date"
            onChange={(date) => {
              onUpdate({ validFrom: date ? date.getTime() : undefined });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Valid To" editable>
          <CustomDatePicker
            selected={validToSelected}
            placeholder="Add a valid to date"
            onChange={(date) => {
              onUpdate({ validTo: date ? date.getTime() : undefined });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Text Layout (desktop)" editable>
          {textLayout(true)}
        </KeyValueGridItem>

        <KeyValueGridItem label="Button Layout (desktop)" editable>
          {buttonLayout(true)}
        </KeyValueGridItem>

        <KeyValueGridItem label="Text Layout (mobile)" editable>
          {textLayout(false)}
        </KeyValueGridItem>

        <KeyValueGridItem label="Button Layout (mobile)" editable>
          {buttonLayout(false)}
        </KeyValueGridItem>

        <KeyValueGridItem label="Translations" editable noPadding>
          <TranslationsComp
            byCountry={true}
            selectedCountry={selectedCountry}
            existingCountries={existingCountries()}
            validCountries={validCountries()}
            onSelectedCountry={(country) => {
              if (country !== selectedCountry) {
                setSelectedCountry(country);
                setSelectedLang(
                  getFirstTranslatedLanguage(translations, country),
                );
              }
            }}
            selectedLang={selectedLang}
            existingLangs={existingLangs(selectedCountry)}
            validLangs={validLangs(selectedCountry)}
            onSelectedLang={(lang) => setSelectedLang(lang)}
          >
            {selectedCountry && selectedLang && (
              <div className={styles.translationActions}>
                <button
                  className={styles.translationAction}
                  onClick={() => onFullTranslationUpdate(emptyTranslation())}
                >
                  <Icon name="close" />
                  <p>Clear</p>
                </button>
                {Array.from(getCopyTranslations().entries()).map(
                  ([lang, trans], i) => (
                    <button
                      key={i}
                      className={styles.translationAction}
                      onClick={() => onFullTranslationUpdate(trans)}
                    >
                      <Icon name="copy" />
                      <p>{`Copy from ${lang}`}</p>
                    </button>
                  ),
                )}
              </div>
            )}

            <div className={styles.translationsDesc}>Desktop</div>

            <div className={styles.translationsContainer}>
              <div
                className={styles.previewItem}
                onClick={() => {
                  setShowPreview(true);
                  setDesktopPreview(true);
                }}
                title="Desktop Preview"
              >
                <Icon name="desktop" className={styles.previewIcon} />
              </div>
              <div className={styles.translations}>
                <TranslationsItem label="Text">{text(true)}</TranslationsItem>
                <TranslationsItem label="Button text">
                  {buttonText(true)}
                </TranslationsItem>
                <TranslationsItem label={`T&C link text (logged in)`}>
                  {tacLinkPostLoginText(true)}
                </TranslationsItem>
                <TranslationsItem label={`T&C link text (logged out)`}>
                  {tacLinkPreLoginText(true)}
                </TranslationsItem>
              </div>
            </div>

            <div className={styles.translationsDesc}>Mobile</div>

            <div className={styles.translationsContainer}>
              <div
                className={styles.previewItem}
                onClick={() => {
                  setShowPreview(true);
                  setDesktopPreview(false);
                }}
                title="Mobile Preview"
              >
                <Icon name="mobile" className={styles.previewIcon} />
              </div>
              <div className={styles.translations}>
                <TranslationsItem label="Text">{text(false)}</TranslationsItem>
                <TranslationsItem label="Button text">
                  {buttonText(false)}
                </TranslationsItem>
                <TranslationsItem label={`T&C link text (logged in)`}>
                  {tacLinkPostLoginText(false)}
                </TranslationsItem>
                <TranslationsItem label={`T&C link text (logged out)`}>
                  {tacLinkPreLoginText(false)}
                </TranslationsItem>
              </div>
            </div>
          </TranslationsComp>
        </KeyValueGridItem>
      </KeyValueGrid>
    </div>
  );
};

export default BannerEdit;

interface GameProps {
  game?: Game;
  onSelectedGame: (game: Game) => void;
}

const GameComp: React.FC<GameProps> = (props) => {
  const game = props.game;
  const [edit, setEdit] = useState<boolean | undefined>();

  if (!game || edit) {
    return (
      <GameComboBox
        onSelectedGame={(game) => {
          setEdit(false);
          props.onSelectedGame(game);
        }}
      />
    );
  }

  return (
    <div className={styles.refLink}>
      <NavLink to={`/games/${game.id}`}>{`${game.name} - ${game.external
        .filter((e) => e.active)
        .map((e) => e.id)
        .join(` - `)}`}</NavLink>
      <Icon
        name="edit"
        className={styles.refEditIcon}
        onClick={() => setEdit(true)}
      />
    </div>
  );
};

interface PromotionProps {
  promotion?: Promotion;
  preferredLang: Language | undefined;
  onSelectedPromotion: (promotion: Promotion) => void;
}

const PromotionComp: React.FC<PromotionProps> = (props) => {
  const promotion = props.promotion;
  const preferredLang = props.preferredLang;
  const [title, setTitle] = useState<string>(``);
  const [edit, setEdit] = useState<boolean | undefined>();

  useEffect(() => {
    if (promotion) {
      setTitle(getPromotionTranslation(promotion, preferredLang)?.title ?? ``);
    }
  }, [promotion, preferredLang]);

  if (!promotion || edit) {
    return (
      <PromotionComboBox
        preferredLang={preferredLang}
        onSelectedPromotion={(promotion) => {
          setEdit(false);
          props.onSelectedPromotion(promotion);
        }}
      />
    );
  }

  return (
    <div className={styles.refLink}>
      <NavLink to={`/promotions/${promotion.id}`}>{title}</NavLink>
      <Icon
        name="edit"
        className={styles.refEditIcon}
        onClick={() => setEdit(true)}
      />
    </div>
  );
};

interface TournamentProps {
  tournament?: Tournament;
  preferredLang: Language | undefined;
  onSelectedTournament: (tournament: Tournament) => void;
}

const TournamentComp: React.FC<TournamentProps> = (props) => {
  const tournament = props.tournament;
  const preferredLang = props.preferredLang;
  const [title, setTitle] = useState<string>(``);
  const [edit, setEdit] = useState<boolean | undefined>();

  useEffect(() => {
    if (tournament) {
      setTitle(
        getTournamentTranslation(tournament, preferredLang)?.title ?? ``,
      );
    }
  }, [tournament, preferredLang]);

  if (!tournament || edit) {
    return (
      <TournamentComboBox
        preferredLang={preferredLang}
        onSelectedTournament={(tournament) => {
          setEdit(false);
          props.onSelectedTournament(tournament);
        }}
      />
    );
  }

  return (
    <div className={styles.refLink}>
      <NavLink to={`/tournaments/${tournament.id}`}>{title}</NavLink>
      <Icon
        name="edit"
        className={styles.refEditIcon}
        onClick={() => setEdit(true)}
      />
    </div>
  );
};
