import { getTranslatedLanguages, Language } from "@vipscasino/core";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import AppContext from "../../AppContext";
import ActionRow from "../../component/ActionRow";
import ActionRowItem from "../../component/ActionRowItem";
import ActionToolbar from "../../component/ActionToolbar";
import ActionToolbarItem from "../../component/ActionToolbarItem";
import HorizontalScroll from "../../component/HorizontalScroll";
import Icon from "../../component/Icon";
import Languages from "../../component/Languages";
import Loader from "../../component/Loader";
import SortableTable from "../../component/SortableTable";
import { config } from "../../config";
import { compare, defaultSortOrder, Sort } from "../../lib/sort";
import {
  getGameCollectionsSort,
  setGameCollectionsSort,
} from "../../lib/storage";
import { defaultHeaders } from "../../lib/util/httpUtil";
import styles from "./GameCollections.module.scss";
import { GameCollection, getGameCollections } from "./gameCollectionService";

const path = `game_collections`;

enum SortField {
  Brand = `Brand`,
  Path = `Path`,
  Disabled = `Disabled`,
  Created = `Created`,
  Modified = `Modified`,
}

const defaultSortField = SortField.Modified;

const GameCollections: React.FC = () => {
  const { state } = useContext(AppContext);
  const [gameCollections, setGameCollections] = useState<GameCollection[]>([]);
  const [loading, setLoading] = useState(true);
  const [showIds, setShowIds] = useState(false);
  const [sort, setSort] = useState<Sort>(
    getGameCollectionsSort() ?? {
      field: defaultSortField,
      order: defaultSortOrder,
    },
  );

  // set game collections when loaded/updated
  useEffect(() => {
    if (state.gameCollectionsLoaded) {
      setGameCollections(getGameCollections());
      setLoading(false);
    }
  }, [state.gameCollectionsLoaded]);

  if (loading) {
    return <Loader message="Loading collections ..." />;
  }

  async function remove(id: string): Promise<void> {
    if (window.confirm(`Are you sure you want to remove this collection?`)) {
      const url = `${config.api}/game_collections/${id}/`;
      const res = await fetch(url, {
        method: `DELETE`,
        headers: defaultHeaders(state),
      });
      if (gameCollections && res.status === 200) {
        setGameCollections(gameCollections.filter((b) => b.id !== id));
      }
    }
  }

  function isSortField(sortField: string): sortField is SortField {
    return Object.values(SortField).includes(sortField as SortField);
  }

  function sortFieldValues(
    field: string,
    gc1: GameCollection,
    gc2: GameCollection,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] {
    switch (field) {
      case SortField.Brand:
        return [gc1.brand, gc2.brand];
      case SortField.Path:
        return [gc1.path, gc2.path];
      case SortField.Disabled:
        return [gc1.disabled, gc2.disabled];
      case SortField.Created:
        return [gc1.created, gc2.created];
      case SortField.Modified:
        return [gc1.modified.date, gc2.modified.date];
      default:
        throw new Error(`Unsupported sort field: ` + field);
    }
  }

  function getSortedCollections(): GameCollection[] {
    const sortField = isSortField(sort.field) ? sort.field : defaultSortField;
    return gameCollections.sort((gc1, gc2) => {
      const [val1, val2] = sortFieldValues(sortField, gc1, gc2);
      return compare(val1, val2, sort.order);
    });
  }

  function onSort(sort: Sort): void {
    setGameCollectionsSort(sort);
    setSort(sort);
  }

  return (
    <div className={styles.main}>
      <ActionToolbar name="Collections">
        <ActionToolbarItem
          type="link"
          icon={<Icon name="add" />}
          name="Add"
          to={`/${path}/add`}
        />
      </ActionToolbar>

      <HorizontalScroll>
        <SortableTable
          sort={sort}
          onSort={onSort}
          columns={[
            { name: `Actions` },
            {
              name: `ID`,
              onShowToggle: () => setShowIds((value) => !value),
              show: showIds,
            },
            { name: `Languages` },
            {
              name: `Brand`,
              sortField: SortField.Brand,
            },
            { name: `Name` },
            {
              name: `Path`,
              sortField: SortField.Path,
            },
            {
              name: `Disabled`,
              sortField: SortField.Disabled,
            },
            {
              name: `Created`,
              sortField: SortField.Created,
            },
            {
              name: `Modified`,
              sortField: SortField.Modified,
            },
          ]}
        >
          {getSortedCollections().map((collection, i) => {
            return (
              <GameCollectionComp
                key={i}
                collection={collection}
                onRemove={remove}
                showIds={showIds}
              />
            );
          })}
        </SortableTable>
      </HorizontalScroll>
    </div>
  );
};

export default GameCollections;

interface GameCollectionProps {
  collection: GameCollection;
  showIds: boolean;
  onRemove: (id: string) => Promise<void>;
}

const GameCollectionComp: React.FC<GameCollectionProps> = (props) => {
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [removing, setRemoving] = useState(false);

  const gc = props.collection;

  function getName(): string | undefined {
    const existingLangs = getTranslatedLanguages(gc.name);
    const defaultName =
      existingLangs.length > 0 ? gc.name[existingLangs[0]]! : undefined;

    if (!selectedLang) {
      return defaultName;
    }

    const name = gc.name[selectedLang];
    if (!name) {
      return defaultName;
    }

    return name;
  }

  const name = getName();

  function langs(): JSX.Element {
    if (!name) {
      return <></>;
    }

    const existingLangs = getTranslatedLanguages(gc.name);
    const currentLang = selectedLang ?? existingLangs[0];

    return (
      <Languages
        existingLangs={existingLangs}
        onLangSelected={setSelectedLang}
        selectedLang={currentLang}
        selectedClassName={styles.selected}
      />
    );
  }

  function disabled(): string {
    return gc.disabled ? `✓` : ``;
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  async function remove(): Promise<void> {
    setRemoving(true);
    await props.onRemove(gc.id);
    setRemoving(false);
  }

  const created = gc.created.toDate();
  const modified = gc.modified.date.toDate();
  return (
    <tr>
      <td>
        <ActionRow>
          <ActionRowItem
            iconName="edit"
            to={`/${path}/${gc.id}`}
            title="Edit"
          />
          <ActionRowItem
            iconName="copy"
            to={`/${path}/${gc.id}/clone`}
            title="Clone"
          />
          <ActionRowItem
            iconName="delete"
            onClick={() => (removing ? null : remove())}
            title="Delete"
          />
        </ActionRow>
      </td>
      <td className={styles.id}>{props.showIds && gc.id}</td>
      <td className={styles.languages}>{langs()}</td>
      <td>{gc.brand}</td>
      <td>{name ? name : ``}</td>
      <td>{gc.path}</td>
      <td className={styles.checked}>{disabled()}</td>
      <td title={dateTimeString(created)} style={{ whiteSpace: `nowrap` }}>
        {created.toLocaleDateString(`sv`)}
      </td>
      <td title={dateTimeString(modified)} style={{ whiteSpace: `nowrap` }}>
        {modified.toLocaleDateString(`sv`)}
      </td>
    </tr>
  );
};
