import {
  brands,
  countries,
  Country,
  defaultCountry,
  defaultLanguage,
  getTranslatedCountries,
  getTranslatedLanguages,
  isBrand,
  isDepositPromotion,
  isGamePromotion,
  isPromotionDisplayOption,
  isPromotionOrTournamentPriority,
  isPromotionType,
  isRegistrationPromotion,
  Language,
  languages,
  promotionCollection,
  PromotionCreateRequest,
  PromotionDisplayOption,
  promotionDisplayOptions,
  promotionOrTournamentPriorities,
  PromotionOrTournamentPriority,
  PromotionTranslation,
  PromotionTranslations,
  PromotionTranslationsUpdateRequest,
  PromotionTranslationUpdate,
  promotionTypes,
  PromotionUpdateRequest,
  Translations,
} from "@vipscasino/core";
import firebase from "firebase/app";
import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink, useHistory, useParams } from "react-router-dom";
import AffiliateWebsitePicker from "../affiliate/AffiliateWebsitePicker";
import AppContext from "../AppContext";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarItem from "../component/ActionToolbarItem";
import CustomDatePicker from "../component/CustomDatePicker";
import Icon from "../component/Icon";
import KeyValueGrid from "../component/KeyValueGrid";
import KeyValueGridItem from "../component/KeyValueGridItem";
import Loader from "../component/Loader";
import MarkdownEditor from "../component/MarkdownEditor";
import { config } from "../config";
import GameComboBox from "../game/GameComboBox";
import { Game, getGame } from "../game/gameService";
import ImagePicker from "../image/ImagePicker";
import { firebaseApp } from "../lib/firebase";
import { validateTextByCountry } from "../lib/util/countryUtil";
import { defaultHeaders } from "../lib/util/httpUtil";
import TranslationsComp from "../translation/Translations";
import TranslationsItem from "../translation/TranslationsItem";
import styles from "./PromotionEdit.module.scss";
import {
  PromotionPreview,
  PromotionPreviewProps,
} from "./PromotionOrTournamentPreview";
import { pathExists, Promotion } from "./promotionService";

const db = firebaseApp.firestore();
const defaultDisplayOption: PromotionDisplayOption = `always`;
const defaultPriority: PromotionOrTournamentPriority = `5`;

interface Params {
  id?: string;
  clone?: string;
}

function translationIsNonEmpty(t: PromotionTranslation): boolean {
  if (
    t.title.trim().length > 0 ||
    t.buttonText.trim().length > 0 ||
    (t.optInButtonText?.trim().length ?? 0) > 0 ||
    t.description.trim().length > 0 ||
    t.termsAndConditions.trim().length > 0
  ) {
    return true;
  }

  return false;
}

function translationIsEmpty(t: PromotionTranslation): boolean {
  return !translationIsNonEmpty(t);
}

function isValidTranslation(t: PromotionTranslation): boolean {
  return (
    translationIsEmpty(t) ||
    (t.title.trim().length > 0 && t.description.trim().length > 0)
  );
}

function getFirstTranslatedLanguage(
  translations: Map<Country, Map<Language, PromotionTranslation>>,
  country: Country,
): Language | undefined {
  const ct = translations.get(country);
  if (!ct) {
    return undefined;
  }
  for (const [lang, t] of Array.from(ct.entries())) {
    if (translationIsNonEmpty(t)) {
      return lang;
    }
  }
  return undefined;
}

const PromotionEdit: React.FC = (props) => {
  const history = useHistory();
  const params = useParams<Params>();
  const id = params.id;
  const clone = params.clone;
  const { state } = useContext(AppContext);
  const [promotion, setPromotion] = useState<Promotion | undefined>();
  const [game, setGame] = useState<Game | undefined>();

  const [updateRequest, setUpdateRequest] = useState<PromotionUpdateRequest>(
    {},
  );
  const [promotionLoading, setPromotionLoading] = useState<boolean>(!!id);
  const [saving, setSaving] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>();
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [translations, setTranslations] = useState<
    Map<Country, Map<Language, PromotionTranslation>>
  >(new Map());
  const [showPreview, setShowPreview] = useState(false);
  const [desktopPreview, setDesktopPreview] = useState(true);
  const [desktopImageUpdate, setDesktopImageUpdate] = useState<
    string | undefined
  >();
  const [mobileImageUpdate, setMobileImageUpdate] = useState<
    string | undefined
  >();
  const pathRef = useRef<HTMLInputElement>(null);

  const loading = promotionLoading || !state.imagesLoaded;

  // validate form
  useEffect(() => {
    setValid(isValid(promotion, updateRequest, translations, clone));
  }, [updateRequest, translations, clone, promotion]);

  function isValid(
    promotion: Promotion | undefined,
    ur: PromotionUpdateRequest,
    translations: Map<Country, Map<Language, PromotionTranslation>>,
    clone: string | undefined,
  ): boolean {
    for (const ct of Array.from(translations.values())) {
      for (const t of Array.from(ct.values())) {
        if (!isValidTranslation(t)) {
          return false;
        }
      }
    }

    if (promotion && !clone) {
      if (
        ur.path !== undefined &&
        (ur.path.trim().length === 0 ||
          pathExists(ur.path, ur.brand ?? promotion.brand, promotion))
      ) {
        return false;
      } else if (ur.validFrom && ur.validTo) {
        return ur.validTo > ur.validFrom;
      } else if (ur.validFrom) {
        return promotion.validTo.toMillis() > ur.validFrom;
      } else if (ur.validTo) {
        return ur.validTo > promotion.validFrom.toMillis();
      } else {
        return true;
      }
    }

    if (!ur.brand) {
      return false;
    }
    if (!ur.type) {
      return false;
    }
    if (ur.type === `Game` && (!ur.typeRef || ur.typeRef.trim().length === 0)) {
      return false;
    }
    if (!ur.desktopImage || ur.desktopImage.trim().length === 0) {
      return false;
    }
    if (!ur.mobileImage || ur.mobileImage.trim().length === 0) {
      return false;
    }
    if (!ur.validFrom || !ur.validTo || ur.validFrom >= ur.validTo) {
      return false;
    }
    if (
      !ur.path ||
      ur.path.trim().length === 0 ||
      pathExists(ur.path, ur.brand)
    ) {
      return false;
    }
    return true;
  }

  // validate path
  useEffect(() => {
    if (pathRef.current) {
      const brand = updateRequest.brand ?? promotion?.brand;
      if (
        brand &&
        pathExists(pathRef.current.value, brand, !clone ? promotion : undefined)
      ) {
        pathRef.current.setCustomValidity(`Path already exists`);
      } else {
        pathRef.current.setCustomValidity(``);
      }
    }
  }, [promotion, clone, updateRequest, loading]);

  // init create request
  useEffect(() => {
    if (!id) {
      setUpdateRequest({
        displayOption: defaultDisplayOption,
        priority: defaultPriority,
      });
    }
  }, [id]);

  // load promotion
  useEffect(() => {
    if (id) {
      setPromotionLoading(true);
      db.collection(promotionCollection)
        .doc(id)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            throw Error(`Promotion with id ${id} does not exist`);
          }

          const promotion = {
            ...snapshot.data(),
            id: snapshot.id,
          } as Promotion;
          setPromotion(promotion);

          const countryTranslations = new Map<
            Country,
            Map<Language, PromotionTranslation>
          >();
          const pt = promotion.translations ?? {};
          for (const country of getTranslatedCountries(pt)) {
            const translations = new Map<Language, PromotionTranslation>();
            countryTranslations.set(country, translations);
            for (const lang of getTranslatedLanguages(pt[country]!)) {
              translations.set(lang, pt[country]![lang]!);
            }
          }
          setTranslations(countryTranslations);
          if (countryTranslations.size > 0) {
            const selectedCountry = Array.from(countryTranslations.keys())[0];
            setSelectedCountry(selectedCountry);
            setSelectedLang(
              getFirstTranslatedLanguage(countryTranslations, selectedCountry),
            );
          }

          if (clone) {
            const updateRequest: PromotionUpdateRequest = {
              brand: promotion.brand,
              active: promotion.active,
              type: promotion.type,
              path: promotion.path,
              displayOption: promotion.displayOption,
              priority: promotion.priority ?? defaultPriority,
              desktopImage: promotion.image.desktop.ref.id,
              mobileImage: promotion.image.mobile.ref.id,
              validFrom: promotion.validFrom.toMillis(),
              validTo: promotion.validTo.toMillis(),
              hidden: promotion.hidden,
              upcomingHidden: promotion.upcomingHidden,
            };
            if (
              isGamePromotion<unknown, firebase.firestore.DocumentReference>(
                promotion,
              )
            ) {
              updateRequest.typeRef = promotion.game.id;
            }
            setUpdateRequest(updateRequest);
          }

          if (!isGamePromotion(promotion)) {
            setPromotionLoading(false);
          }
        });
    }
  }, [id, clone]);

  // get game, iff game promotion
  useEffect(() => {
    if (game || !state.gamesLoaded) {
      return;
    }

    if (
      promotion &&
      isGamePromotion<unknown, firebase.firestore.DocumentReference>(promotion)
    ) {
      const gameId = promotion.game.id;
      const game = getGame(gameId);
      if (!game) {
        throw Error(`Game with id ${gameId} does not exist`);
      }
      setGame(game);
      setPromotionLoading(false);
    }
  }, [promotion, game, state.gamesLoaded]);

  if (saving) {
    return <Loader message="Saving promotion..." />;
  }

  if (loading) {
    return <Loader message="Loading promotion..." />;
  }

  const selectedTrans =
    selectedCountry && selectedLang
      ? translations.get(selectedCountry)?.get(selectedLang)
      : undefined;

  const previewAvailable =
    valid && selectedTrans && translationIsNonEmpty(selectedTrans);

  const newsType =
    (promotion?.type === `News` && !updateRequest.type) ||
    updateRequest.type === `News`;

  async function save(): Promise<void> {
    setSaving(true);

    const update = id && !clone;
    const data = update ? getUpdateRequest() : getCreateRequest();
    const url = `${config.api}/promotions/${update ? `${id}/` : ``}`;
    const res = await fetch(url, {
      method: `${update ? `PUT` : `POST`}`,
      headers: defaultHeaders(state),
      body: JSON.stringify(data),
    });
    console.info(await res.text());

    setSaving(false);
    history.goBack();
  }

  function getUpdateRequest(): PromotionUpdateRequest {
    const tur = getTranslationsUpdateRequest();
    if (Object.keys(tur).length === 0) {
      // no translation changes
      return updateRequest;
    }

    return {
      ...updateRequest,
      ...{
        translations: tur,
      },
    };
  }

  function getTranslationsUpdateRequest(): PromotionTranslationsUpdateRequest {
    const tur: PromotionTranslationsUpdateRequest = {};

    for (const [country, tMap] of Array.from(translations.entries())) {
      tur[country] = {};
      for (const [lang, t] of Array.from(tMap.entries())) {
        const tu = getTranslationUpdate(country, lang, t);
        if (!tu) {
          continue;
        }

        if (translationIsNonEmpty(t)) {
          tur[country]![lang] = tu;
        } else if (
          promotion?.translations[country] &&
          promotion.translations[country]![lang]
        ) {
          tur[country]![lang] = `removed`;
        }
      }
      if (Object.keys(tur[country]!).length === 0) {
        delete tur[country];
      }
    }

    return tur;
  }

  function getTranslationUpdate(
    country: Country,
    lang: Language,
    t: PromotionTranslation,
  ): PromotionTranslationUpdate | undefined {
    const tNew = getTrimmedTranslation(t);

    if (
      promotion?.translations[country] &&
      promotion.translations[country]![lang]
    ) {
      let tu: PromotionTranslationUpdate = {};
      const tOld = promotion.translations[country]![lang]!;
      if (tNew.title !== tOld.title) {
        tu = { ...tu, title: tNew.title };
      }
      if (tNew.buttonText !== tOld.buttonText) {
        tu = { ...tu, buttonText: tNew.buttonText };
      }
      if (
        (tNew.optInButtonText || tOld.optInButtonText) &&
        tNew.optInButtonText !== tOld.optInButtonText
      ) {
        tu = { ...tu, optInButtonText: tNew.optInButtonText };
      }
      if (tNew.description !== tOld.description) {
        tu = { ...tu, description: tNew.description };
      }
      if (tNew.termsAndConditions !== tOld.termsAndConditions) {
        tu = { ...tu, termsAndConditions: tNew.termsAndConditions };
      }
      return Object.keys(tu).length > 0 ? tu : undefined;
    }

    return translationIsNonEmpty(tNew) ? { ...tNew } : undefined;
  }

  function getTrimmedTranslation(
    t: PromotionTranslation,
  ): PromotionTranslation {
    const tt = { ...t, buttonText: t.buttonText.trim() };
    if (t.optInButtonText) {
      tt.optInButtonText = t.optInButtonText.trim();
    }
    return tt;
  }

  function getNewsTranslation(t: PromotionTranslation): PromotionTranslation {
    return {
      title: t.title,
      description: t.description,
      buttonText: ``,
      termsAndConditions: ``,
    };
  }

  function getCreateRequest(): PromotionCreateRequest {
    const pt: PromotionTranslations = {};
    for (const [country, tMap] of Array.from(translations.entries())) {
      const ct: Translations<PromotionTranslation> = {};
      for (const [lang, t] of Array.from(tMap.entries())) {
        if (t.title.trim().length > 0) {
          ct[lang] = newsType
            ? getNewsTranslation(t)
            : getTrimmedTranslation(t);
        }
      }
      if (Object.keys(ct).length > 0) {
        pt[country] = ct;
      }
    }

    return {
      brand: updateRequest.brand!,
      path: updateRequest.path!,
      active: updateRequest.active ? true : false,
      translations: pt,
      type: updateRequest.type!,
      typeRef: updateRequest.typeRef === undefined ? `` : updateRequest.typeRef,
      displayOption: updateRequest.displayOption!,
      priority: updateRequest.priority,
      validFrom: updateRequest.validFrom!,
      validTo: updateRequest.validTo!,
      desktopImage: updateRequest.desktopImage!,
      mobileImage: updateRequest.mobileImage!,
      hideAfterFirstDeposit: updateRequest.hideAfterFirstDeposit,
      referrers: updateRequest.referrers,
      hidden: updateRequest.hidden,
      upcomingHidden: updateRequest.upcomingHidden,
    };
  }

  function cancel(): void {
    history.goBack();
  }

  function onUpdate(field: PromotionUpdateRequest): void {
    setUpdateRequest({ ...updateRequest, ...field });
  }

  function emptyTranslation(): PromotionTranslation {
    return {
      title: ``,
      buttonText: ``,
      optInButtonText: ``,
      description: ``,
      termsAndConditions: ``,
    };
  }

  function onTranslationUpdate(field: PromotionTranslationUpdate): void {
    if (!selectedCountry || !selectedLang) {
      return;
    }

    const translationsCopy: Map<
      Country,
      Map<Language, PromotionTranslation>
    > = new Map(translations);

    const selectedTranslations: Map<Language, PromotionTranslation> =
      translationsCopy.get(selectedCountry) ?? new Map();

    const t = selectedTranslations.get(selectedLang) ?? emptyTranslation();
    selectedTranslations.set(selectedLang, { ...t, ...field });
    translationsCopy.set(selectedCountry, selectedTranslations);
    setTranslations(translationsCopy);
  }

  function existingLangs(country: Country | undefined): Language[] {
    if (!country) {
      return [];
    }

    return languages.filter((lang) => {
      const t = translations.get(country)?.get(lang);
      return !!t && translationIsNonEmpty(t);
    });
  }

  function validLangs(country: Country | undefined): Language[] {
    if (!country) {
      return [];
    }

    return languages.filter((lang) => {
      const t = translations.get(country)?.get(lang);
      return !t || isValidTranslation(t);
    });
  }

  function existingCountries(): Country[] {
    return countries.filter((country) => {
      const t = translations.get(country);
      return !!t && existingLangs(country).length > 0;
    });
  }

  function validCountries(): Country[] {
    return countries.filter((country) => {
      const t = translations.get(country);
      return !t || validLangs(country).length === languages.length;
    });
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  const validFromSelected = updateRequest.validFrom
    ? new Date(updateRequest.validFrom)
    : promotion
    ? promotion.validFrom.toDate()
    : null;

  const validToSelected = updateRequest.validTo
    ? new Date(updateRequest.validTo)
    : promotion
    ? promotion.validTo.toDate()
    : null;

  function previewProps(): PromotionPreviewProps {
    let image = desktopPreview ? desktopImageUpdate : mobileImageUpdate;
    if (!image && promotion) {
      image = desktopPreview
        ? promotion.image.desktop.default
        : promotion.image.mobile.default;
    }

    return {
      desktop: desktopPreview,
      image: image,
      language: selectedLang,
      translation: selectedTrans,
      type: updateRequest.type ?? promotion?.type,
      validFrom: validFromSelected ?? undefined,
      validTo: validToSelected ?? undefined,
      modified: promotion?.modified.date.toDate() ?? new Date(),
      onRequestClose: () => setShowPreview(false),
    };
  }

  function getCopyTranslations(): Map<string, PromotionTranslation> {
    const map: Map<string, PromotionTranslation> = new Map();
    if (
      selectedCountry !== defaultCountry ||
      selectedLang !== defaultLanguage
    ) {
      const trans = translations.get(defaultCountry)?.get(defaultLanguage);
      if (trans && translationIsNonEmpty(trans)) {
        map.set(`${defaultCountry}-${defaultLanguage}`, trans);
      }
    }
    if (
      selectedLang &&
      selectedCountry !== defaultCountry &&
      selectedLang !== defaultLanguage
    ) {
      const trans = translations.get(defaultCountry)?.get(selectedLang);
      if (trans && translationIsNonEmpty(trans)) {
        map.set(`${defaultCountry}-${selectedLang}`, trans);
      }
    }
    return map;
  }

  function textValid(text: string | undefined): boolean {
    if (!text || !selectedCountry) {
      return true;
    }
    return validateTextByCountry(text, selectedCountry);
  }

  return (
    <div className={styles.main}>
      <ActionToolbar name={`${promotion ? `Edit` : `Add`} promotion`}>
        <ActionToolbarItem
          type="button"
          icon={<Icon name="cancel" />}
          name="Cancel"
          onClick={() => cancel()}
        />
        <ActionToolbarItem
          type="button"
          icon={<Icon name="save" />}
          name="Save"
          disabled={!valid}
          onClick={() => save()}
        />
      </ActionToolbar>

      {showPreview && <PromotionPreview {...previewProps()} />}

      <KeyValueGrid>
        {id && !clone && (
          <KeyValueGridItem label="ID" editable={false}>
            {id}
          </KeyValueGridItem>
        )}

        {promotion && !clone ? (
          <>
            <KeyValueGridItem label="Created" editable={false}>
              {dateTimeString(promotion.created.toDate() as Date)}
            </KeyValueGridItem>
            <KeyValueGridItem label="Type" editable={false}>
              {promotion.type}
            </KeyValueGridItem>
          </>
        ) : (
          <KeyValueGridItem label="Type" editable>
            <select
              defaultValue={promotion ? promotion.type : undefined}
              onChange={(event) => {
                const type = event.currentTarget.value;
                if (!isPromotionType(type)) {
                  throw new Error(`invalid PromotionType: ` + type);
                }
                onUpdate({ type: type, typeRef: `` });
              }}
            >
              {!promotion && !updateRequest.type && <option value="" />}
              {promotionTypes.map((type, i) => (
                <option key={i} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </KeyValueGridItem>
        )}

        {((game && !updateRequest.type) || updateRequest.type === `Game`) && (
          <KeyValueGridItem label="Game" editable>
            <GameComp
              game={
                game &&
                (!updateRequest.type || updateRequest.typeRef === game.id)
                  ? game
                  : undefined
              }
              onSelectedGame={(game) => {
                onUpdate({ type: `Game`, typeRef: game.id });
                setGame(game);
              }}
            />
          </KeyValueGridItem>
        )}

        {promotion && promotion.type === `Deposit` && !updateRequest.type && (
          <KeyValueGridItem label="Hide after first deposit" editable>
            {promotion &&
              isDepositPromotion<unknown, firebase.firestore.DocumentReference>(
                promotion,
              ) && (
                <input
                  type="checkbox"
                  defaultChecked={promotion.hideAfterFirstDeposit ?? false}
                  onChange={(event) => {
                    onUpdate({
                      type: `Deposit`,
                      hideAfterFirstDeposit: event.currentTarget.checked,
                    });
                  }}
                />
              )}
          </KeyValueGridItem>
        )}

        {updateRequest.type === `Deposit` && (
          <KeyValueGridItem label="Hide after first deposit" editable>
            <input
              type="checkbox"
              defaultChecked={updateRequest.hideAfterFirstDeposit ?? false}
              onChange={(event) => {
                onUpdate({
                  hideAfterFirstDeposit: event.currentTarget.checked,
                });
              }}
            />
          </KeyValueGridItem>
        )}

        {((promotion &&
          isRegistrationPromotion(promotion) &&
          !updateRequest.type) ||
          updateRequest.type === `Registration`) && (
          <KeyValueGridItem label="Referrers" editable>
            <ReferrersComp
              referrers={
                updateRequest.referrers ??
                (promotion && isRegistrationPromotion(promotion) && !clone
                  ? promotion.referrers
                  : undefined)
              }
              onSelectedReferrers={(referrers) => {
                onUpdate({ type: `Registration`, referrers: referrers });
              }}
            />
          </KeyValueGridItem>
        )}

        <KeyValueGridItem label="Brand" editable>
          <select
            value={updateRequest.brand ?? promotion?.brand}
            onChange={(event) => {
              const brand = event.currentTarget.value;
              if (!isBrand(brand)) {
                throw new Error(`invalid brand: ` + brand);
              }
              onUpdate({ brand: brand });
            }}
          >
            {!promotion?.brand && !updateRequest.brand && <option value="" />}
            {brands.map((brand, i) => (
              <option key={i} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label="Path" editable>
          <input
            type="text"
            required
            ref={pathRef}
            placeholder="Add a path"
            defaultValue={promotion ? promotion.path : ``}
            onChange={(event) => {
              onUpdate({ path: event.currentTarget.value });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Enabled" editable>
          <input
            type="checkbox"
            defaultChecked={promotion ? promotion.active : false}
            onChange={(event) => {
              onUpdate({ active: event.currentTarget.checked });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Hidden" editable>
          <input
            type="checkbox"
            defaultChecked={promotion?.hidden ?? false}
            onChange={(event) => {
              onUpdate({ hidden: event.currentTarget.checked });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Upcoming Hidden" editable>
          <input
            type="checkbox"
            defaultChecked={promotion?.upcomingHidden ?? false}
            onChange={(event) => {
              onUpdate({ upcomingHidden: event.currentTarget.checked });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Displayed When" editable>
          <select
            defaultValue={
              promotion ? promotion.displayOption : defaultDisplayOption
            }
            onChange={(event) => {
              const option = event.currentTarget.value;
              if (!isPromotionDisplayOption(option)) {
                throw new Error(`invalid PromotionDisplayOption: ` + option);
              }
              onUpdate({ displayOption: option });
            }}
          >
            {Array.from(promotionDisplayOptions.entries()).map(
              ([option, name], i) => (
                <option key={i} value={option}>
                  {name}
                </option>
              ),
            )}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label="Priority" editable>
          <select
            value={updateRequest.priority ?? promotion?.priority}
            onChange={(event) => {
              const priority = event.currentTarget.value;
              if (!isPromotionOrTournamentPriority(priority)) {
                throw new Error(`invalid promotion priority: ` + priority);
              }
              onUpdate({ priority: priority });
            }}
          >
            {!promotion?.priority && !updateRequest.priority && (
              <option value="" />
            )}
            {promotionOrTournamentPriorities.map((priority, i) => (
              <option key={i} value={priority}>
                {priority}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label="Valid From" editable>
          <CustomDatePicker
            selected={validFromSelected}
            placeholder="Add a valid from date"
            onChange={(date) => {
              onUpdate({ validFrom: date ? date.getTime() : undefined });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Valid To" editable>
          <CustomDatePicker
            selected={validToSelected}
            placeholder="Add a valid to date"
            onChange={(date) => {
              onUpdate({ validTo: date ? date.getTime() : undefined });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Translations" editable noPadding>
          <TranslationsComp
            byCountry={true}
            selectedCountry={selectedCountry}
            existingCountries={existingCountries()}
            validCountries={validCountries()}
            onSelectedCountry={(country) => {
              if (country !== selectedCountry) {
                setSelectedCountry(country);
                setSelectedLang(
                  getFirstTranslatedLanguage(translations, country),
                );
              }
            }}
            selectedLang={selectedLang}
            existingLangs={existingLangs(selectedCountry)}
            validLangs={validLangs(selectedCountry)}
            onSelectedLang={(lang) => setSelectedLang(lang)}
          >
            {selectedCountry && selectedLang && (
              <div className={styles.translationActions}>
                <button
                  className={styles.translationAction}
                  onClick={() => onTranslationUpdate(emptyTranslation())}
                >
                  <Icon name="close" />
                  <p>Clear</p>
                </button>
                {Array.from(getCopyTranslations().entries()).map(
                  ([lang, trans], i) => (
                    <button
                      key={i}
                      className={styles.translationAction}
                      onClick={() => onTranslationUpdate(trans)}
                    >
                      <Icon name="copy" />
                      <p>{`Copy from ${lang}`}</p>
                    </button>
                  ),
                )}
              </div>
            )}

            <div
              className={
                previewAvailable ? styles.previews : styles.previewsDisabled
              }
            >
              <div
                className={
                  previewAvailable ? styles.preview : styles.previewDisabled
                }
                onClick={() => {
                  if (previewAvailable) {
                    setShowPreview(true);
                    setDesktopPreview(true);
                  }
                }}
                title="Desktop Preview"
              >
                <Icon name="desktop" className={styles.previewIcon} />
              </div>
              <div
                className={
                  previewAvailable ? styles.preview : styles.previewDisabled
                }
                onClick={() => {
                  if (previewAvailable) {
                    setShowPreview(true);
                    setDesktopPreview(false);
                  }
                }}
                title="Mobile Preview"
              >
                <Icon name="mobile" className={styles.previewIcon} />
              </div>
            </div>

            <TranslationsItem label="Title">
              <input
                type="text"
                disabled={!promotion && !selectedLang}
                placeholder="Add a title"
                value={selectedTrans?.title ?? ``}
                onChange={(event) => {
                  onTranslationUpdate({ title: event.currentTarget.value });
                }}
              />
            </TranslationsItem>

            {!newsType && (
              <TranslationsItem label="Button text">
                <input
                  type="text"
                  disabled={!promotion && !selectedLang}
                  placeholder="Add a button text"
                  value={selectedTrans?.buttonText ?? ``}
                  onChange={(event) => {
                    onTranslationUpdate({
                      buttonText: event.currentTarget.value,
                    });
                  }}
                />
              </TranslationsItem>
            )}

            {!newsType && (
              <TranslationsItem label="Opt-in button text">
                <input
                  type="text"
                  disabled={!promotion && !selectedLang}
                  placeholder="Add an opt-in button text"
                  value={selectedTrans?.optInButtonText ?? ``}
                  onChange={(event) => {
                    onTranslationUpdate({
                      optInButtonText: event.currentTarget.value,
                    });
                  }}
                />
              </TranslationsItem>
            )}

            <TranslationsItem
              label="Description"
              invalid={!textValid(selectedTrans?.description)}
            >
              <MarkdownEditor
                text={selectedTrans?.description ?? ``}
                editMode={true}
                editCols={40}
                editRows={10}
                disabled={!promotion && !selectedLang}
                placeholder="Add a description"
                markdownClass={styles.markdownDesc}
                onChange={(value) => {
                  onTranslationUpdate({ description: value });
                }}
              />
            </TranslationsItem>

            {!newsType && (
              <TranslationsItem
                label="Terms And Conditions"
                invalid={!textValid(selectedTrans?.termsAndConditions)}
              >
                <MarkdownEditor
                  text={selectedTrans?.termsAndConditions ?? ``}
                  editMode={true}
                  editCols={40}
                  disabled={!promotion && !selectedLang}
                  placeholder="Add terms and conditions"
                  editRows={10}
                  markdownClass={styles.markdownTac}
                  onChange={(value) => {
                    onTranslationUpdate({ termsAndConditions: value });
                  }}
                />
              </TranslationsItem>
            )}
          </TranslationsComp>
        </KeyValueGridItem>

        <KeyValueGridItem label="Desktop Image" editable>
          <ImagePicker
            imageUrl={promotion?.image.desktop.default}
            onSelectedImage={(image) => {
              onUpdate({ desktopImage: image.id });
              setDesktopImageUpdate(image.urls.default);
            }}
            filter={(image) =>
              image.type === `Promotion` &&
              (image.platform === `desktop` ||
                image.platform === `desktopAndMobile`)
            }
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Mobile Image" editable>
          <ImagePicker
            imageUrl={promotion?.image.mobile.default}
            onSelectedImage={(image) => {
              onUpdate({ mobileImage: image.id });
              setMobileImageUpdate(image.urls.default);
            }}
            filter={(image) =>
              image.type === `Promotion` &&
              (image.platform === `mobile` ||
                image.platform === `desktopAndMobile`)
            }
          />
        </KeyValueGridItem>
      </KeyValueGrid>
    </div>
  );
};

export default PromotionEdit;

interface GameProps {
  game?: Game;
  onSelectedGame: (game: Game) => void;
}

const GameComp: React.FC<GameProps> = (props) => {
  const game = props.game;
  const [edit, setEdit] = useState<boolean | undefined>();

  if (!game || edit) {
    return (
      <GameComboBox
        onSelectedGame={(game) => {
          setEdit(false);
          props.onSelectedGame(game);
        }}
      />
    );
  }

  return (
    <div className={styles.refLink}>
      <NavLink to={`/games/${game.id}`}>{`${game.name} - ${game.external
        .filter((e) => e.active)
        .map((e) => e.id)
        .join(` - `)}`}</NavLink>
      <Icon
        name="edit"
        className={styles.refEditIcon}
        onClick={() => setEdit(true)}
      />
    </div>
  );
};

interface AffiliateProps {
  referrers?: string[];
  onSelectedReferrers: (referrers: string[]) => void;
}

const ReferrersComp: React.FC<AffiliateProps> = (props) => {
  const referrers = props.referrers ?? [];

  return (
    <AffiliateWebsitePicker
      websites={referrers}
      onSelectedWebsites={(websites) => {
        props.onSelectedReferrers(websites);
      }}
    />
  );
};
