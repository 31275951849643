import { GameCategory, GameSubCategory } from "@vipscasino/core";
import { Game, searchGames } from "./gameService";
import { getGameTag } from "./tag/gameTagService";

export interface GamesFilter {
  showAll?: boolean;
  name?: string;
  enabled?: boolean;
  disabled?: boolean;
  incomplete?: boolean;
  archived?: boolean;
  provider?: string;
  category?: GameCategory;
  subCategory?: GameSubCategory;
  volatility?: string;
  publishedStart?: number;
  publishedEnd?: number;
  createdStart?: number;
  createdEnd?: number;
  noTags?: boolean;
  tag?: string;
}

export function filterGames(filter: GamesFilter, games: Game[]): Game[] {
  // special handling for showAll and empty
  if (filter.showAll) {
    return games;
  } else if (filterIsEmpty(filter)) {
    return [...games]
      .sort((g1, g2) => {
        const res = g2.created.toMillis() - g1.created.toMillis();
        return res === 0 ? g2.id.localeCompare(g1.id) : res;
      })
      .slice(0, 5);
  }

  let result = [...games];
  if (filter.name) {
    const name = filter.name;
    result = searchGames(name);
  }
  if (filter.enabled) {
    result = result.filter((game) => {
      return (
        !game.disabled &&
        !game.incomplete &&
        game.external.findIndex((e) => e.active) >= 0
      );
    });
  }
  if (filter.disabled) {
    result = result.filter((game) => {
      return game.disabled;
    });
  }
  if (filter.incomplete) {
    result = result.filter((game) => {
      return game.incomplete;
    });
  }
  if (filter.archived) {
    result = result.filter((game) => {
      return game.external.every((e) => !e.active);
    });
  }
  if (filter.provider) {
    const provider = filter.provider;
    result = result.filter((game) => {
      return game.provider.id === provider;
    });
  }
  if (filter.category) {
    const category = filter.category;
    result = result.filter((game) => {
      return game.category === category;
    });
  }
  if (filter.subCategory) {
    const subCategory = filter.subCategory;
    result = result.filter((game) => {
      return game.subCategory === subCategory;
    });
  }
  if (filter.volatility) {
    const volatility = filter.volatility;
    result = result.filter((game) => {
      return game.volatility === volatility;
    });
  }
  if (filter.publishedStart) {
    const publishedStart = filter.publishedStart;
    result = result.filter((game) => {
      return game.published.toMillis() >= publishedStart;
    });
  }
  if (filter.publishedEnd) {
    const publishedEnd = filter.publishedEnd;
    result = result.filter((game) => {
      return game.published.toMillis() <= publishedEnd;
    });
  }
  if (filter.createdStart) {
    const createdStart = filter.createdStart;
    result = result.filter((game) => {
      return game.created.toMillis() >= createdStart;
    });
  }
  if (filter.createdEnd) {
    const createdEnd = filter.createdEnd;
    result = result.filter((game) => {
      return game.created.toMillis() <= createdEnd;
    });
  }
  if (filter.noTags) {
    result = result.filter((game) => {
      return game.tags === undefined || game.tags.length === 0;
    });
  }
  if (filter.tag) {
    let filterTag = filter.tag;
    const exactMatch = filterTag.match(`".+"`);
    if (exactMatch) {
      filterTag = filterTag.substring(1, filterTag.length - 1);
    }
    result = result.filter((game) => {
      if (!game.tags) {
        return false;
      }
      for (const tag of game.tags) {
        const key = getGameTag(tag.id)?.key;
        const match = exactMatch
          ? key === filterTag
          : key?.startsWith(filterTag);
        if (match) {
          return true;
        }
      }
      return false;
    });
  }
  return result;
}

export function filterIsEmpty(filter: GamesFilter): boolean {
  if (filter.name && filter.name.trim().length > 0) {
    return false;
  } else {
    return Object.values({ ...filter, name: undefined }).every(
      (v) => v === undefined,
    );
  }
}
