import {
  GameProvider as FirestoreGameProvider,
  gameProviderCollection,
} from "@vipscasino/core";
import firebase from "firebase/app";
import { firebaseApp } from "../../lib/firebase";

const db = firebaseApp.firestore();

export interface GameProvider
  extends FirestoreGameProvider<firebase.firestore.Timestamp> {
  id: string;
}

const providers = new Map<string, GameProvider>();
let cancelSubscription: () => void;

export function loadGameProviders(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collection(gameProviderCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        const provider = { ...data, id: doc.id } as GameProvider;
        if (change.type === `removed`) {
          providers.delete(provider.id);
        } else {
          providers.set(provider.id, provider);
        }
      });
      window.dispatchEvent(new Event(`game-providers-loaded`));
    });
}

export function getGameProvider(providerId: string): GameProvider | undefined {
  return providers.get(providerId);
}

export function getGameProviders(): GameProvider[] {
  return Array.from(providers.values());
}
