import {
  brands,
  GameCollection,
  gameCollectionCollection,
  GameCollectionCreateRequest,
  GameCollectionName,
  GameCollectionUpdateRequest,
  getTranslatedLanguages,
  isBrand,
  Language,
  languages,
} from "@vipscasino/core";
import firebase from "firebase/app";
import React, { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router";
import AppContext from "../../AppContext";
import ActionToolbar from "../../component/ActionToolbar";
import ActionToolbarItem from "../../component/ActionToolbarItem";
import Icon from "../../component/Icon";
import KeyValueGrid from "../../component/KeyValueGrid";
import KeyValueGridItem from "../../component/KeyValueGridItem";
import Loader from "../../component/Loader";
import { config } from "../../config";
import { firebaseApp } from "../../lib/firebase";
import { defaultHeaders } from "../../lib/util/httpUtil";
import Translations from "../../translation/Translations";
import TranslationsItem from "../../translation/TranslationsItem";
import GamePicker from "../GamePicker";
import { Game, getGame } from "../gameService";
import styles from "./GameCollectionEdit.module.scss";

const db = firebaseApp.firestore();

interface Params {
  id?: string;
  clone?: string;
}

const GameCollectionEdit: React.FC = () => {
  const history = useHistory();
  const params = useParams<Params>();
  const id = params.id;
  const clone = params.clone;
  const { state } = useContext(AppContext);
  const [gameCollection, setGameCollection] = useState<
    | GameCollection<
        firebase.firestore.Timestamp,
        firebase.firestore.DocumentReference
      >
    | undefined
  >();
  const [
    updateRequest,
    setUpdateRequest,
  ] = useState<GameCollectionUpdateRequest>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<Language | undefined>();
  const [nameMap, setNameMap] = useState<Map<Language, string>>(new Map());

  // validate form
  useEffect(() => {
    setValid(isValid(gameCollection, updateRequest, clone));
  }, [gameCollection, updateRequest, clone]);

  function isValid(
    gameCollection:
      | GameCollection<
          firebase.firestore.Timestamp,
          firebase.firestore.DocumentReference
        >
      | undefined,
    ur: GameCollectionUpdateRequest,
    clone: string | undefined,
  ): boolean {
    if (gameCollection && !clone) {
      return ur.path === undefined || ur.path.trim().length > 0;
    }
    if (!ur.brand) {
      return false;
    }
    return ur.path !== undefined && ur.path.trim().length > 0;
  }

  // load game collection
  useEffect(() => {
    if (id) {
      setLoading(true);
      db.collection(gameCollectionCollection)
        .doc(id)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) {
            throw Error(`GameCollection with id ${id} does not exist`);
          }

          const gc = snapshot.data() as GameCollection<
            firebase.firestore.Timestamp,
            firebase.firestore.DocumentReference
          >;
          setGameCollection(gc);

          const translations = new Map<Language, string>();
          const name = gc.name;
          for (const lang of getTranslatedLanguages(name)) {
            translations.set(lang, name[lang]!);
          }
          setNameMap(translations);
          if (translations.size > 0) {
            setSelectedLang(Array.from(translations.keys())[0]);
          }

          if (clone) {
            setUpdateRequest({
              brand: gc.brand,
              path: gc.path,
              disabled: gc.disabled,
              games: gc.games.map((g) => g.id),
            });
          }

          setLoading(false);
        });
    }
  }, [id, clone]);

  if (loading || !state.gamesLoaded) {
    return <Loader message="Loading collection ..." />;
  }

  if (saving) {
    return <Loader message="Saving collection ..." />;
  }

  async function save(): Promise<void> {
    setSaving(true);

    const update = id && !clone;
    const data = update ? getUpdateRequest() : getCreateRequest();
    const url = `${config.api}/game_collections/${update ? `${id}/` : ``}`;
    const res = await fetch(url, {
      method: `${update ? `PUT` : `POST`}`,
      headers: defaultHeaders(state),
      body: JSON.stringify(data),
    });
    console.info(await res.text());

    setSaving(false);
    history.goBack();
  }

  function getUpdateRequest(): GameCollectionUpdateRequest {
    const nur = getNameUpdateRequest();
    if (Object.keys(nur).length === 0) {
      // no name changes
      return updateRequest;
    }

    return {
      ...updateRequest,
      ...{
        name: nur,
      },
    };
  }

  function getNameUpdateRequest(): GameCollectionName {
    const nur: GameCollectionName = {};

    for (const [lang, n] of Array.from(nameMap.entries())) {
      const nu = getNameUpdate(lang, n);
      if (nu !== undefined) {
        nur[lang] = nu;
      }
    }

    return nur;
  }

  function getNameUpdate(lang: Language, name: string): string | undefined {
    if (gameCollection?.name[lang]) {
      return name !== gameCollection.name[lang]! ? name : undefined;
    }
    return name.trim().length > 0 ? name.trim() : undefined;
  }

  function getCreateRequest(): GameCollectionCreateRequest {
    const name: GameCollectionName = {};
    for (const [lang, n] of Array.from(nameMap.entries())) {
      if (n.trim().length > 0) {
        name[lang] = n.trim();
      }
    }

    return {
      brand: updateRequest.brand!,
      path: updateRequest.path!,
      disabled: updateRequest.disabled ? true : false,
      games: updateRequest.games ?? [],
      name: name,
    };
  }

  function cancel(): void {
    history.goBack();
  }

  function onUpdate(field: GameCollectionUpdateRequest): void {
    setUpdateRequest({ ...updateRequest, ...field });
  }

  function onNameUpdate(name: string): void {
    if (!selectedLang) {
      return;
    }

    const nameMapCopy: Map<Language, string> = new Map(nameMap);
    nameMapCopy.set(selectedLang, name);
    setNameMap(nameMapCopy);
  }

  function existingLangs(): Language[] {
    return languages.filter((lang) => {
      const name = nameMap.get(lang);
      return name !== undefined && name.trim().length > 0;
    });
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  function getGames(): Game[] {
    const games = updateRequest.games
      ? updateRequest.games
      : gameCollection
      ? gameCollection.games.map((g) => g.id)
      : [];
    return games
      .map((gameId) => getGame(gameId))
      .filter((game) => game !== undefined) as Game[];
  }

  return (
    <section className={styles.main}>
      <ActionToolbar name={`${gameCollection ? `Edit` : `Add`} collection`}>
        <ActionToolbarItem
          type="button"
          icon={<Icon name="cancel" />}
          name="Cancel"
          onClick={() => cancel()}
        />
        <ActionToolbarItem
          type="button"
          icon={<Icon name="save" />}
          name="Save"
          disabled={!valid}
          onClick={() => save()}
        />
      </ActionToolbar>

      <KeyValueGrid>
        {id && !clone && (
          <KeyValueGridItem label="ID" editable={false}>
            {id}
          </KeyValueGridItem>
        )}

        {gameCollection && !clone && (
          <KeyValueGridItem label="Created" editable={false}>
            {dateTimeString(gameCollection.created.toDate() as Date)}
          </KeyValueGridItem>
        )}

        <KeyValueGridItem label="Brand" editable>
          <select
            value={updateRequest.brand ?? gameCollection?.brand}
            onChange={(event) => {
              const brand = event.currentTarget.value;
              if (!isBrand(brand)) {
                throw new Error(`invalid brand: ` + brand);
              }
              onUpdate({ brand: brand });
            }}
          >
            {!gameCollection?.brand && !updateRequest.brand && (
              <option value="" />
            )}
            {brands.map((brand, i) => (
              <option key={i} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </KeyValueGridItem>

        <KeyValueGridItem label="Disabled" editable>
          <input
            type="checkbox"
            defaultChecked={gameCollection ? gameCollection.disabled : false}
            onChange={(event) => {
              onUpdate({ disabled: event.currentTarget.checked });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Path" editable>
          <input
            type="text"
            pattern="[-a-z0-9]+"
            placeholder="Add a path"
            value={
              updateRequest.path
                ? updateRequest.path
                : gameCollection
                ? gameCollection.path
                : ``
            }
            onChange={(event) => {
              if (event.target.validity.valid) {
                onUpdate({ path: event.currentTarget.value });
              }
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Games" editable>
          <GamePicker
            games={getGames()}
            onSelectedGames={(games) => {
              onUpdate({ games: games.map((game) => game.id) });
            }}
          />
        </KeyValueGridItem>

        <KeyValueGridItem label="Translations" editable noPadding>
          <Translations
            selectedLang={selectedLang}
            existingLangs={existingLangs()}
            onSelectedLang={(lang) => setSelectedLang(lang)}
          >
            <TranslationsItem label="Name">
              <input
                type="text"
                disabled={!gameCollection && !selectedLang}
                placeholder="Add a name"
                value={
                  selectedLang && nameMap.get(selectedLang)
                    ? nameMap.get(selectedLang)!
                    : ``
                }
                onChange={(event) => {
                  onNameUpdate(event.currentTarget.value);
                }}
              />
            </TranslationsItem>
          </Translations>
        </KeyValueGridItem>
      </KeyValueGrid>
    </section>
  );
};

export default GameCollectionEdit;
