import moment from "moment";
import React, { useContext, useRef, useState } from "react";
import AppContext from "../AppContext";
import CustomDatePicker from "../component/CustomDatePicker";
import KeyValueGrid from "../component/KeyValueGrid";
import KeyValueGridItem from "../component/KeyValueGridItem";
import { config } from "../config";
import styles from "./Reporting.module.scss";

const reports = [`Games`, `Deposits`, `Registrations`, `Transactions`] as const;

type Report = typeof reports[number];

function isReport(report: string): report is Report {
  return reports.includes(report as Report);
}

function triggerPath(report: Report): string {
  switch (report) {
    case `Deposits`:
      return `deposits`;
    case `Games`:
      return `games`;
    case `Registrations`:
      return `registrations`;
    case `Transactions`:
      return `transactions`;
  }
}

const Reporting: React.FC = () => {
  const { state } = useContext(AppContext);
  const [report, setReport] = useState<Report | undefined>();
  const [fromDate, setFromDate] = useState<number | undefined>();
  const [toDate, setToDate] = useState<number | undefined>();
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [result, setResult] = useState<string>();
  const [multiRequestCount, setMultiRequestCount] = useState(0);
  const [multiRequestSuccessCount, setMultiRequestSuccessCount] = useState(0);
  const [multiRequestErrors, setMultiRequestErrors] = useState<string[]>([]);

  const multiRequestSuccessRef = useRef(multiRequestSuccessCount);
  multiRequestSuccessRef.current = multiRequestSuccessCount;
  const multiRequestErrorsRef = useRef(multiRequestErrors);
  multiRequestErrorsRef.current = multiRequestErrors;

  function getActualTriggerDate(triggerDate: number): number {
    return report === `Games`
      ? triggerDate
      : triggerDate - new Date(triggerDate).getTimezoneOffset() * 60 * 1000;
  }

  async function request(url: string): Promise<Response> {
    return await fetch(url, {
      method: `POST`,
      headers: {
        authorization: state.user
          ? state.user.googleLoginResponse.getAuthResponse().id_token
          : ``,
      },
    });
  }

  function resetMultiRequestState(): void {
    setMultiRequestCount(0);
    setMultiRequestSuccessCount(0);
    setMultiRequestErrors([]);
  }

  async function triggerAll(): Promise<void> {
    if (!triggerSpanEnabled()) {
      return;
    }

    resetMultiRequestState();
    setInProgress(true);
    setResult(``);
    setError(false);

    const timeUnit = report === `Games` ? `hours` : `days`;
    const requests: Promise<Response>[] = [];

    let actualTriggerDate = getActualTriggerDate(fromDate!);
    while (actualTriggerDate < toDate!) {
      const url = `${config.reporting}/${triggerPath(
        report!,
      )}/${actualTriggerDate}/`;

      requests.push(request(url));

      actualTriggerDate = moment(actualTriggerDate)
        .add(1, timeUnit)
        .toDate()
        .getTime();
    }

    setMultiRequestCount(requests.length);
    for (const request of requests) {
      try {
        const response = await request;
        if (response.ok) {
          setMultiRequestSuccessCount((val) => val + 1);
        } else {
          const responseText = await response.text();
          setMultiRequestErrors((val) => [...val, responseText]);
        }
      } catch (e) {
        setMultiRequestErrors((val) => [...val, e.message]);
      }
    }

    if (multiRequestErrorsRef.current.length === 0) {
      setResult(`Successfully triggered all ${report} reports`);
    } else {
      setResult(
        `Successfully triggered ${multiRequestSuccessRef.current} ${report} reports.
        ${multiRequestErrorsRef.current.length} reports failed:`,
      );
    }

    setMultiRequestCount(0);
    setInProgress(false);
  }

  async function triggerReport(): Promise<void> {
    if (!triggerSingleEnabled()) {
      return;
    }

    resetMultiRequestState();
    setInProgress(true);
    setResult(``);

    const actualTriggerDate = getActualTriggerDate(fromDate!);
    const url = `${config.reporting}/${triggerPath(
      report!,
    )}/${actualTriggerDate}/`;

    try {
      const response = await request(url);
      if (response.ok) {
        setError(false);
        setResult(`Successfully triggered ${report} report`);
      } else {
        setError(true);
        const responseText = await response.text();
        setResult(responseText);
      }
    } catch (e) {
      const msg = `Request failed to ${url}:`;
      console.error(msg, e);
      setError(true);
      setResult(`${msg} ${e.message}`);
    } finally {
      setInProgress(false);
    }
  }

  function triggerSingleEnabled(): boolean {
    return !!report && !!fromDate && !inProgress;
  }

  function triggerSpanEnabled(): boolean {
    return triggerSingleEnabled() && !!toDate;
  }

  function reportComp(): JSX.Element {
    return (
      <select
        onChange={(event) => {
          const report = event.currentTarget.value;
          if (!isReport(report)) {
            throw new Error(`invalid Report: ` + report);
          }
          setReport(report);
        }}
      >
        {!report && <option value="">-- Choose a report type --</option>}
        {reports.map((report, i) => (
          <option key={i} value={report}>
            {report}
          </option>
        ))}
      </select>
    );
  }

  function date(from: boolean): JSX.Element {
    const date = from ? fromDate : toDate;
    return (
      <CustomDatePicker
        selected={date ? new Date(date) : null}
        placeholder={`Pick a ${from ? `from` : `to`} date`}
        omitTimeSelect={report !== `Games`}
        dateFormat={report !== `Games` ? `yyyy-MM-dd` : undefined}
        disabled={!report}
        optional={!from}
        onChange={(date) => {
          const value = date ? date.getTime() : undefined;
          from ? setFromDate(value) : setToDate(value);
        }}
      />
    );
  }

  function getMultiRequestProgress(): number {
    return Math.round(
      ((multiRequestSuccessCount + multiRequestErrors.length) * 100) /
        multiRequestCount,
    );
  }

  function getMultiRequestProgressPercent(): string {
    return `${getMultiRequestProgress()}%`;
  }

  function getMultiRequestProgressText(): string {
    return `${getMultiRequestProgress()}% done`;
  }

  return (
    <section>
      <KeyValueGrid>
        <KeyValueGridItem label="Report Type" editable>
          {reportComp()}
        </KeyValueGridItem>
        {!!report && (
          <KeyValueGridItem label="From Date" editable>
            {date(true)}
          </KeyValueGridItem>
        )}
        {!!report && (
          <KeyValueGridItem label="To Date" editable>
            {date(false)}
          </KeyValueGridItem>
        )}
      </KeyValueGrid>

      <button
        className={styles.button}
        disabled={!triggerSingleEnabled()}
        onClick={() => triggerReport()}
      >
        Trigger report using from date only
      </button>

      <br />

      <button
        className={styles.button}
        disabled={!triggerSpanEnabled()}
        onClick={() => triggerAll()}
      >
        Trigger all reports in span
      </button>

      {multiRequestCount > 0 && (
        <div className={styles.progress}>
          <div className={styles.progressText}>
            {getMultiRequestProgressText()}
          </div>
          <div
            className={styles.progressBar}
            style={{ width: getMultiRequestProgressPercent() }}
          />
        </div>
      )}

      <div className={`${styles.result} ${error ? styles.error : ``}`}>
        {result}
      </div>

      {!inProgress &&
        multiRequestErrors.map((e, i) => (
          <div key={i} className={styles.multiErrors}>
            {e}
          </div>
        ))}
    </section>
  );
};

export default Reporting;
