import React, { useEffect, useState } from "react";
import FileUpload from "../component/FileUpload";
import styles from "./ImageEditor.module.scss";

interface Props {
  imageUrl?: string;
  uploadUrl: string;
  multi?: boolean;
  onUploaded: (uploadResult: string) => void;
}

const ImageEditor: React.FC<Props> = (props) => {
  const [edit, setEdit] = useState<boolean>(!props.imageUrl);

  useEffect(() => {
    setEdit(!props.imageUrl);
  }, [props.imageUrl]);

  function onUploaded(uploadResult: string): void {
    setEdit(false);
    props.onUploaded(uploadResult);
  }

  if (edit) {
    return (
      <FileUpload
        url={props.uploadUrl}
        method={props.multi ? `POST` : `PUT`}
        multi={props.multi}
        placeholder={`Upload image${
          props.multi ? `s` : ``
        } must be of type jpg or png`}
        validTypes={[`image/jpeg`, `image/png`]}
        uploadingMessage={`Uploading image${props.multi ? `s` : ``} ...`}
        onUploaded={onUploaded}
        onCancel={props.imageUrl ? () => setEdit(false) : undefined}
      />
    );
  }

  return (
    <div className={styles.content}>
      <a
        href="dummy"
        onClick={(event) => {
          event.preventDefault();
          setEdit(true);
        }}
      >
        Change
      </a>
      <img src={props.imageUrl} alt="Upload" className={styles.image} />
    </div>
  );
};

export default ImageEditor;
