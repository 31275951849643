import { bannerTypes, brands, isBannerType, isBrand } from "@vipscasino/core";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../component/CustomDatePicker";
import FilterForm from "../component/FilterForm";
import FilterFormButton from "../component/FilterFormButton";
import FilterFormItem from "../component/FilterFormItem";
import { removeBannersFilter, setBannersFilter } from "../lib/storage";
import { BannersFilter, filterIsEmpty } from "./bannersFilter";
import styles from "./BannersFilter.module.scss";

interface Props {
  filter: BannersFilter;
  onChange: (filter: BannersFilter) => void;
}

const BannersFilterComp: React.FC<Props> = (props) => {
  const [filter, setFilter] = useState<BannersFilter>(props.filter);

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  useEffect(() => {
    if (filterIsEmpty(filter)) {
      removeBannersFilter();
    } else {
      setBannersFilter(filter);
    }
  }, [filter]);

  function updateFilter(filter: BannersFilter): void {
    setFilter(filter);
    props.onChange(filter);
  }

  function onChange(field: BannersFilter): void {
    updateFilter({ ...filter, ...field });
  }

  return (
    <FilterForm>
      <FilterFormItem label="Enabled">
        <input
          type="checkbox"
          checked={!!filter.active}
          onChange={(event) => {
            const active = event.currentTarget.checked;
            onChange({ active: active ? active : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Live">
        <input
          type="checkbox"
          checked={!!filter.live}
          onChange={(event) => {
            const live = event.currentTarget.checked;
            onChange({ live: live ? live : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Upcoming">
        <input
          type="checkbox"
          checked={!!filter.upcoming}
          onChange={(event) => {
            const upcoming = event.currentTarget.checked;
            onChange({ upcoming: upcoming ? upcoming : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Brand">
        <select
          value={filter.brand ? filter.brand : ``}
          onChange={(event) => {
            const brand = event.currentTarget.value;
            if (!brand) {
              onChange({ brand: undefined });
            } else if (isBrand(brand)) {
              onChange({ brand: brand });
            }
          }}
        >
          <option />
          {brands.map((brand, i) => (
            <option key={i} value={brand}>
              {brand}
            </option>
          ))}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Type">
        <select
          value={filter.type ? filter.type : ``}
          onChange={(event) => {
            const type = event.currentTarget.value;
            if (!type) {
              onChange({ type: undefined });
            } else if (isBannerType(type)) {
              onChange({ type: type });
            }
          }}
        >
          <option />
          {bannerTypes.map((type, i) => (
            <option key={i} value={type}>
              {type}
            </option>
          ))}
        </select>
      </FilterFormItem>

      <FilterFormItem label="Max start date">
        <CustomDatePicker
          selected={filter.validFrom ? new Date(filter.validFrom) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ validFrom: date ? date.getTime() : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Min end date">
        <CustomDatePicker
          selected={filter.validTo ? new Date(filter.validTo) : null}
          placeholder="Leave blank for any value"
          optional={true}
          onChange={(date) => {
            onChange({ validTo: date ? date.getTime() : undefined });
          }}
        />
      </FilterFormItem>

      <FilterFormItem label="Created between">
        <div className={styles.createdContainer}>
          <CustomDatePicker
            selected={
              filter.createdStart ? new Date(filter.createdStart) : null
            }
            placeholder="Leave blank for any value"
            optional={true}
            onChange={(date) => {
              onChange({ createdStart: date ? date.getTime() : undefined });
            }}
          />
          &nbsp;-&nbsp;
          <CustomDatePicker
            selected={filter.createdEnd ? new Date(filter.createdEnd) : null}
            placeholder="Leave blank for any value"
            optional={true}
            onChange={(date) => {
              onChange({ createdEnd: date ? date.getTime() : undefined });
            }}
          />
        </div>
      </FilterFormItem>

      <FilterFormButton
        label="Reset"
        type="reset"
        onClick={() => updateFilter({})}
      />
    </FilterForm>
  );
};

export default BannersFilterComp;
