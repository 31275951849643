import React from "react";
import { ReactComponent as LogoSvg } from "../assets/vips-logo.svg";
import styles from "./Logo.module.scss";

interface Props {
  large?: boolean;
}
const Logo: React.FC<Props> = (props) => {
  return (
    <div className={`${styles.logo} ${props.large && styles.logoLarge}`}>
      <LogoSvg />
      <h1>Backoffice</h1>
    </div>
  );
};

export default Logo;
