export function toPercent(
  num: number,
  fractionDigits: number,
  fromFraction = true,
): string {
  const numberFormat = new Intl.NumberFormat(`en-US`, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return `${numberFormat.format(num * (fromFraction ? 100 : 1))}%`;
}
