import React, { ErrorInfo, ReactNode } from "react";
import { handleError } from "../lib/util/errorHandler";

interface State {
  hasError: boolean;
}

interface Props {}

export default class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error): unknown {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    handleError(error, info);
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1 style={{ color: `white` }}>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
