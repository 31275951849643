import elasticlunr from "../elasticlunrCustom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let affiliateIndex: any;

export interface AffiliateDoc {
  id: string;
  company: string;
}

export interface AffiliateSearchResult {
  ref: string;
}

export function indexAffiliates(affiliates: AffiliateDoc[]): void {
  affiliateIndex = elasticlunr(function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`id`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.addField(`company`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.saveDocument(false);

    affiliates.forEach((doc) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.addDoc(doc);
    });
  });
}

export function searchAffiliates(
  query: string,
): AffiliateSearchResult[] | undefined {
  if (!affiliateIndex) {
    return undefined;
  }

  const res = affiliateIndex.search(query, {
    fields: {
      id: { boost: 1 },
      company: { boost: 1 },
    },
    bool: `AND`,
    expand: true,
  });

  return res;
}
