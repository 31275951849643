import { BannerLayoutPosition, BannerPlatformLayout } from "@vipscasino/core";
import React, { CSSProperties } from "react";
import Markdown from "../component/Markdown";
import styles from "./BannerPreview.module.scss";

export interface BannerPreviewProps {
  desktop: boolean;
  layout?: BannerPlatformLayout;
  image?: string;
  text?: string;
  buttonText?: string;
  onRequestClose: () => void;
}

const BannerPreview: React.FC<BannerPreviewProps> = ({
  desktop,
  layout,
  image,
  text,
  buttonText,
  onRequestClose,
}) => {
  function overlayPosition(layoutPos: BannerLayoutPosition): CSSProperties {
    switch (layoutPos) {
      case `topLeft`:
        return { left: 0, top: 0 };
      case `topCenter`:
        return { left: 0, right: 0, top: 0 };
      case `topRight`:
        return { right: 0, top: 0 };
      case `centerLeft`:
        return { left: 0, top: 0, bottom: 0 };
      case `center`:
        return { left: 0, right: 0, top: 0, bottom: 0 };
      case `centerRight`:
        return { right: 0, top: 0, bottom: 0 };
      case `bottomLeft`:
        return { left: 0, bottom: 0 };
      case `bottomCenter`:
        return { left: 0, right: 0, bottom: 0 };
      case `bottomRight`:
        return { right: 0, bottom: 0 };
    }
  }

  function overlayItemsAlignment(
    layoutPos: BannerLayoutPosition,
  ): CSSProperties {
    switch (layoutPos) {
      case `topLeft`:
      case `centerLeft`:
      case `bottomLeft`:
        return { alignItems: `flex-start` };
      case `topRight`:
      case `centerRight`:
      case `bottomRight`:
        return { alignItems: `flex-end` };
      default:
        return {};
    }
  }

  function overlayMargin(layoutPos: BannerLayoutPosition): CSSProperties {
    switch (layoutPos) {
      case `topLeft`:
      case `centerLeft`:
      case `bottomLeft`:
      case `topRight`:
      case `centerRight`:
      case `bottomRight`:
        return desktop ? { marginLeft: `4rem`, marginRight: `4rem` } : {};
      case `bottomCenter`:
        return { marginBottom: `0.5rem` };
      default:
        return {};
    }
  }

  function overlayStyle(
    layoutPos: BannerLayoutPosition,
    standalone: boolean,
  ): CSSProperties {
    const style = {
      ...overlayPosition(layoutPos),
      ...overlayMargin(layoutPos),
    };

    return standalone
      ? style
      : { ...style, ...overlayItemsAlignment(layoutPos) };
  }

  function overlayButton(buttonText: string, standalone: boolean): JSX.Element {
    let className = standalone ? styles.standaloneButton : styles.groupedButton;
    if (!desktop) {
      className = `${className} ${styles.smallButton}`;
    }
    return <button className={className}>{buttonText}</button>;
  }

  function overlay(text?: string, buttonText?: string): JSX.Element {
    if (!layout) {
      return <></>;
    }

    if (layout.text === layout.button && buttonText) {
      return (
        <div
          className={styles.overlay}
          style={overlayStyle(layout.text, false)}
        >
          <Markdown className={styles.markdownBanner} source={text} />
          {buttonText && overlayButton(buttonText, false)}
        </div>
      );
    }

    return (
      <>
        <div className={styles.overlay} style={overlayStyle(layout.text, true)}>
          <Markdown className={styles.markdownBanner} source={text} />
        </div>
        {buttonText && (
          <div
            className={styles.overlay}
            style={overlayStyle(layout.button, true)}
          >
            {overlayButton(buttonText, true)}
          </div>
        )}
      </>
    );
  }

  const modalContentClassName = desktop
    ? styles.modalContentDesktop
    : styles.modalContentMobile;
  const mainClassName = desktop ? styles.mainDesktop : styles.mainMobile;
  const outerContainerClassName = desktop
    ? styles.outerContainerDesktop
    : styles.outerContainerMobile;

  return (
    <div className={styles.modal} onClick={onRequestClose}>
      <div className={modalContentClassName}>
        <div className={mainClassName}>
          <div className={outerContainerClassName}>
            <div className={styles.innerContainer}>
              <div className={styles.content}>
                {(text || buttonText) && overlay(text, buttonText)}
                <img className={styles.image} src={image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPreview;
