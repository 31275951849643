import React, { useContext, useEffect, useState } from "react";
import AppContext from "../AppContext";
import Chip from "../component/Chip";
import { Affiliate, getAffiliates } from "./affiliateService";
import AffiliateWebsiteComboBox from "./AffiliateWebsiteComboBox";
import styles from "./AffiliateWebsitePicker.module.scss";

interface Props {
  onSelectedWebsites: (websites: string[]) => void;
  websites: string[];
}

const AffiliateWebsitePicker: React.FC<Props> = (props) => {
  const { state } = useContext(AppContext);
  const [affiliates, setAffiliates] = useState<Affiliate[]>([]);
  const [selectedWebsites, setSelectedWebsites] = useState<string[]>(
    props.websites,
  );

  useEffect(() => {
    setSelectedWebsites(props.websites);
  }, [props.websites]);

  useEffect(() => {
    if (state.affiliatesLoaded) {
      setAffiliates(getAffiliates());
    }
  }, [state.affiliatesLoaded]);

  function title(website: string): string {
    const affiliate = affiliates.find((a) => a.websites.includes(website));
    return affiliate
      ? `${affiliate.id} - ${affiliate.company} - ${website}`
      : website;
  }

  return (
    <div className={styles.main}>
      {selectedWebsites.length > 0 && (
        <div className={styles.chips}>
          {selectedWebsites.map((website, i) => {
            return (
              <Chip
                key={i}
                title={title(website)}
                onRemove={() => {
                  const newWebsites = selectedWebsites.filter(
                    (ws) => ws !== website,
                  );
                  props.onSelectedWebsites(newWebsites);
                }}
              />
            );
          })}
        </div>
      )}
      <AffiliateWebsiteComboBox
        onSelectedWebsite={(website) => {
          const newWebsites = [...selectedWebsites, website];
          props.onSelectedWebsites(newWebsites);
        }}
        excludedWebsites={selectedWebsites}
      />
    </div>
  );
};

export default AffiliateWebsitePicker;
