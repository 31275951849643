import { Country } from "@vipscasino/core";

const currencySigns = [`\\$`, `€`, `£`, `C\\$`, `₹`, `kr`, `S/`] as const;

type CurrencySign = typeof currencySigns[number];

const regexPadding = `(^|[\\s\\r\\n\\.\\|0-9]|$)`;

function getCurrencySign(country: Country): CurrencySign {
  switch (country) {
    case `ZZ`:
    case `DE`:
    case `FI`:
      return `€`;
    case `NZ`:
    case `CL`:
      return `\\$`;
    case `CA`:
      return `C\\$`;
    case `GB`:
      return `£`;
    case `IN`:
      return `₹`;
    case `NO`:
      return `kr`;
    case `PE`:
      return `S/`;
  }
}

function getInvalidCurrencyExpression(country: Country): string {
  const currency = getCurrencySign(country);
  const otherCurrenciesExpression = currencySigns
    .filter((cs) => cs !== currency)
    .join(`|`);
  return `${regexPadding}(${otherCurrenciesExpression})${regexPadding}`;
}

export function validateTextByCountry(text: string, country: Country): boolean {
  return !text.match(getInvalidCurrencyExpression(country));
}
