/* global errorHandler */
import { ErrorInfo } from "react";

const env = process.env.NODE_ENV;

export function handleError(error: Error | string, info?: ErrorInfo): void {
  if (env === `development`) {
    if (info) {
      console.error(error, info);
    } else {
      console.error(error);
    }
  } else {
    if (errorHandler) {
      errorHandler.report(error);
    }
  }
}
