import {
  Game as FirestoreGame,
  gameCollection,
  GameDemoDisabled,
  GameDisabled,
  GameExternal,
  GameProviderCommissionType,
  gameSubCategories,
  GameSubCategory,
  GameUpdateRequest,
  licenses,
} from "@vipscasino/core";
import firebase from "firebase/app";
import { useTranslation } from "react-i18next";
import { firebaseApp } from "../lib/firebase";
import * as index from "./gameIndex";

const db = firebaseApp.firestore();

export interface Game
  extends FirestoreGame<
    firebase.firestore.Timestamp,
    firebase.firestore.DocumentReference
  > {
  id: string;
}

export const defaultDisabledOption: GameDisabled = `never`;
export const defaultDemoDisabledOption: GameDemoDisabled = `never`;

const games = new Map<string, Game>();
const paths = new Map<string, string>();
const externalIds = new Map<string, string>();
const tags = new Map<string, Set<string>>();
let cancelSubscription: () => void;
let defaultSubCategories: Map<GameSubCategory, string>;

export function loadGames(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collection(gameCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        const game = { ...data, id: doc.id } as Game;
        const oldGame = games.get(game.id);
        if (change.type === `removed`) {
          games.delete(game.id);
          paths.delete(game.path.trim());
          game.external.forEach((e) => externalIds.delete(e.id));
          updateTags(game.id, oldGame?.tags ?? [], []);
        } else {
          if (oldGame) {
            paths.delete(oldGame.path.trim());
            oldGame.external.forEach((e) => externalIds.delete(e.id));
          }
          games.set(game.id, game);
          paths.set(game.path.trim(), game.id);
          game.external.forEach((e) => externalIds.set(e.id, game.id));
          updateTags(game.id, oldGame?.tags ?? [], game.tags ?? []);
        }
      });
      indexGames();
      window.dispatchEvent(new Event(`games-loaded`));
    });
}

function updateTags(
  gameId: string,
  oldTags: firebase.firestore.DocumentReference[],
  newTags: firebase.firestore.DocumentReference[],
): void {
  const tagsToRemove: string[] = [];
  const tagsToAdd: string[] = [];

  if (oldTags.length === 0) {
    tagsToAdd.push(...newTags.map((tRef) => tRef.id));
  } else if (newTags.length === 0) {
    tagsToRemove.push(...oldTags.map((tRef) => tRef.id));
  } else {
    const oldTagIds = oldTags.map((tRef) => tRef.id);
    const newTagIds = newTags.map((tRef) => tRef.id);
    tagsToRemove.push(...oldTagIds.filter((tId) => !newTagIds.includes(tId)));
    tagsToAdd.push(...newTagIds.filter((tId) => !oldTagIds.includes(tId)));
  }

  for (const tag of tagsToRemove) {
    let gamesForTag = tags.get(tag);
    if (!gamesForTag) {
      gamesForTag = new Set();
      tags.set(tag, gamesForTag);
    }
    gamesForTag.delete(gameId);
  }

  for (const tag of tagsToAdd) {
    let gamesForTag = tags.get(tag);
    if (!gamesForTag) {
      gamesForTag = new Set();
      tags.set(tag, gamesForTag);
    }
    gamesForTag.add(gameId);
  }
}

function indexGames(): void {
  const gameDocs = Array.from(games.values()).map((game) => {
    const doc: index.GameDoc = {
      id: game.id,
      externalId: game.external.map((e) => e.id).join(` `),
      name: game.name,
    };
    return doc;
  });

  index.indexGames(gameDocs);
}

export function getGame(id: string): Game | undefined {
  return games.get(id);
}

export function getGames(): Game[] {
  return Array.from(games.values());
}

export function searchGames(
  query: string,
  limit?: number,
  excludedIds?: string[],
): Game[] {
  let results = index.searchGames(query);
  if (!results || results.length === 0) {
    return [];
  }
  if (excludedIds) {
    results = results.filter((result) => !excludedIds.includes(result.ref));
  }
  if (limit) {
    results = results.splice(0, limit);
  }

  return results
    .map((result) => {
      const gameId = result.ref;
      const game = games.get(gameId);
      if (game) {
        return game;
      }
      return undefined;
    })
    .filter((game) => game !== undefined) as Game[];
}

export function useDefaultGameSubCategories(): Map<GameSubCategory, string> {
  const { t } = useTranslation();

  if (defaultSubCategories) {
    return defaultSubCategories;
  }

  const result: Map<GameSubCategory, string> = new Map();
  Array.from(gameSubCategories.entries()).forEach(([key, value]) => {
    result.set(key, t(value));
  });

  defaultSubCategories = result;
  return defaultSubCategories;
}

export function isValidCreateOrUpdate(
  game: Game | undefined,
  ur: GameUpdateRequest,
  update: boolean,
): boolean {
  if (update) {
    if (ur.external !== undefined && !isValidGameExternal(ur.external, game)) {
      return false;
    }
    if (ur.name !== undefined && ur.name.trim().length === 0) {
      return false;
    }
    if (
      ur.path !== undefined &&
      (ur.path.trim().length === 0 || pathExists(ur.path, game))
    ) {
      return false;
    }
    return true;
  }

  if (!ur.external || !isValidGameExternal(ur.external)) {
    return false;
  }
  if (!ur.name || ur.name.trim().length === 0) {
    return false;
  }
  if (!ur.category) {
    return false;
  }
  if (!ur.subCategory) {
    return false;
  }
  if (!ur.provider) {
    return false;
  }
  if (!ur.published) {
    return false;
  }
  if (!ur.path || ur.path.trim().length === 0 || pathExists(ur.path)) {
    return false;
  }
  return true;
}

function isValidGameExternal(external: GameExternal[], game?: Game): boolean {
  if (external.length === 0) {
    return false;
  }

  for (const license of licenses) {
    let activeDesktop = 0;
    let activeMobile = 0;

    external
      .filter((e) => e.active)
      .filter((e) => !e.license || e.license === license)
      .forEach((e) => {
        if (e.disabled === `never`) {
          activeDesktop++;
          activeMobile++;
        } else if (e.disabled === `desktop`) {
          activeMobile++;
        } else if (e.disabled === `mobile`) {
          activeDesktop++;
        }
      });

    if (activeDesktop > 1 || activeMobile > 1) {
      return false;
    }
  }

  return external.every(
    (e) => !isNaN(parseInt(e.id)) && !externalIdExists(e.id, game),
  );
}

export function externalIdExists(
  externalId: string,
  excludedGame?: Game,
): boolean {
  if (excludedGame && excludedGame.id === externalIds.get(externalId)) {
    return false;
  }
  return externalIds.has(externalId);
}

export function pathExists(path: string, excludedGame?: Game): boolean {
  //TODO(danne): re-enable logic when duplicate games are consolidated
  // if (excludedGame?.path.trim() === path.trim()) {
  //   return false;
  // }
  // return paths.has(path.trim());
  return false;
}

export function getTagGameCount(tagId: string): number {
  return tags.get(tagId)?.size ?? 0;
}

export function getProviderCommissionFromType(
  type: GameProviderCommissionType,
): number {
  switch (type) {
    case `3rd Party`:
      return 16;
    case `3rd Party - Branded`:
      return 18;
    case `3rd Party - Premium`:
      return 20;
    case `Live Casino`:
      return 16;
    case `Live Casino - Branded`:
      return 20;
    case `Live Casino - Premium`:
      return 20;
    case `Live Casino - Premium+`:
      return 20;
    case `Non Branded`:
      return 16;
    case `Branded`:
      return 18;
  }
}
