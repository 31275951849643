import {
  GameCollection as FirestoreGameCollection,
  gameCollectionCollection,
} from "@vipscasino/core";
import firebase from "firebase/app";
import { firebaseApp } from "../../lib/firebase";

const db = firebaseApp.firestore();

export interface GameCollection
  extends FirestoreGameCollection<
    firebase.firestore.Timestamp,
    firebase.firestore.DocumentReference
  > {
  id: string;
}

const collections = new Map<string, GameCollection>();
let cancelSubscription: () => void;

export function loadGameCollections(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collection(gameCollectionCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        const collection = { ...data, id: doc.id } as GameCollection;
        if (change.type === `removed`) {
          collections.delete(collection.id);
        } else {
          collections.set(collection.id, collection);
        }
      });
      window.dispatchEvent(new Event(`game-collections-loaded`));
    });
}

export function getGameCollection(
  collectionId: string,
): GameCollection | undefined {
  return collections.get(collectionId);
}

export function getGameCollections(): GameCollection[] {
  return Array.from(collections.values());
}
