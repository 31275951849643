export interface ActionToolbarState {
  expanded?: JSX.Element;
}

export const initialActionToolbarState: ActionToolbarState = {
  // expanded: "",
};

export enum ActionToolbarActionType {
  ShowExpanded = `ShowExpanded`,
}

export type ActionToolbarAction = {
  type: ActionToolbarActionType.ShowExpanded;
  component?: JSX.Element;
};

export function actionToolbarReducer(
  state: ActionToolbarState,
  action: ActionToolbarAction,
): ActionToolbarState {
  // console.info("action", action);
  switch (action.type) {
    case ActionToolbarActionType.ShowExpanded:
      return {
        ...state,
        expanded: action.component,
      };
  }
}
