import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./ActionRowItem.module.scss";
import Icon, { IconName } from "./Icon";

interface Props {
  iconName: IconName;
  title: string;
  to?: string;
  onClick?: () => void;
}

export const ActionRowItem: React.FC<Props> = (props) => {
  if (props.to) {
    return (
      <NavLink className={styles.item} to={props.to} title={props.title}>
        <Icon name={props.iconName} className={styles.icon} />
      </NavLink>
    );
  }

  if (props.onClick) {
    return (
      <div className={styles.item} onClick={props.onClick} title={props.title}>
        <Icon name={props.iconName} className={styles.icon} />
      </div>
    );
  }

  return <></>;
};

export default ActionRowItem;
