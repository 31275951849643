import React, { useEffect, useState } from "react";
import Chip from "../component/Chip";
import GameComboBox from "./GameComboBox";
import styles from "./GamePicker.module.scss";
import { Game } from "./gameService";

interface Props {
  onSelectedGames: (games: Game[]) => void;
  games: Game[];
}

const GamePicker: React.FC<Props> = (props) => {
  const [selectedGames, setSelectedGames] = useState<Game[]>(props.games);

  useEffect(() => {
    setSelectedGames(props.games);
  }, [props.games]);

  return (
    <div className={styles.main}>
      {selectedGames.length > 0 && (
        <div className={styles.chips}>
          {selectedGames.map((game, i) => {
            return (
              <Chip
                key={i}
                title={game.name}
                onRemove={() => {
                  const newGames = selectedGames.filter(
                    (g) => g.id !== game.id,
                  );
                  props.onSelectedGames(newGames);
                }}
              />
            );
          })}
        </div>
      )}
      <GameComboBox
        onSelectedGame={(game) => {
          const newGames = [...selectedGames, game];
          props.onSelectedGames(newGames);
        }}
        excludedIds={selectedGames.map((g) => g.id)}
      />
    </div>
  );
};

export default GamePicker;
