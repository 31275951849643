import {
  gameCategories,
  gameProviderCommissionTypes,
  GameSubCategory,
  GameUpdateRequest,
  gameVolatilities,
  gameWageringContributions,
  getTranslatedLanguages,
  isGameBanner,
  isGameCategory,
  isGamePromotion,
  isGameProviderCommissionType,
  isGameSubCategory,
  isGameWageringContribution,
} from "@vipscasino/core";
import firebase from "firebase/app";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import AppContext from "../AppContext";
import { getBanners } from "../banner/bannerService";
import ActionRow from "../component/ActionRow";
import ActionRowItem from "../component/ActionRowItem";
import ActionToolbar from "../component/ActionToolbar";
import ActionToolbarContext from "../component/ActionToolbarContext";
import ActionToolbarItem from "../component/ActionToolbarItem";
import {
  ActionToolbarActionType,
  actionToolbarReducer,
  initialActionToolbarState,
} from "../component/ActionToolbarState";
import HorizontalScroll from "../component/HorizontalScroll";
import Icon from "../component/Icon";
import Languages from "../component/Languages";
import Loader from "../component/Loader";
import SortableTable from "../component/SortableTable";
import { config } from "../config";
import { compare, defaultSortOrder, Sort } from "../lib/sort";
import { getGamesFilter, getGamesSort, setGamesSort } from "../lib/storage";
import { defaultHeaders } from "../lib/util/httpUtil";
import { toPercent } from "../lib/util/numbers";
import { getPromotions } from "../promotion/promotionService";
import { getTournaments } from "../tournament/tournamentService";
import { getGameCollections } from "./collection/gameCollectionService";
import { getGameDescription } from "./gameDescriptionService";
import styles from "./Games.module.scss";
import GameSearchFilterComp from "./GameSearchFilter";
import {
  Game,
  getGame,
  getGames,
  getProviderCommissionFromType,
  isValidCreateOrUpdate,
  useDefaultGameSubCategories,
} from "./gameService";
import { filterGames, filterIsEmpty, GamesFilter } from "./gamesFilter";
import GamesFilterComp from "./GamesFilter";
import {
  GameProvider,
  getGameProvider,
  getGameProviders,
} from "./provider/gameProviderService";

const updates: Map<string, GameUpdateRequest> = new Map();

enum SortField {
  Name = `Name`,
  Category = `Category`,
  SubCategory = `SubCategory`,
  Provider = `Provider`,
  Path = `Path`,
  ReturnToPlayer = `Rtp`,
  ReturnToPlayerUk = `RtpUk`,
  HitRate = `HitRate`,
  Volatility = `Volatility`,
  Released = `Released`,
  Wheels = `Wheels`,
  WinLines = `WinLines`,
  Disabled = `Disabled`,
  MaxExposure = `MaxExposure`,
  Wagering = `Wagering`,
  CommissionType = `CommissionType`,
  Turnover = `Turnover`,
  Published = `Published`,
  Created = `Created`,
  Modified = `Modified`,
  LastPlayed = `LastPlayed`,
}

const defaultSortField = SortField.Modified;

export interface GamesProps {
  filterOverride?: GamesFilter;
}

const Games: React.FC = () => {
  const history = useHistory<GamesProps | undefined>();
  const locationState = history.location.state;
  const filterOverride = locationState?.filterOverride;
  const { state } = useContext(AppContext);
  const [actionToolbarState, actionToolbarDispatch] = useReducer(
    actionToolbarReducer,
    initialActionToolbarState,
  );
  const [games, setGames] = useState<Game[] | undefined>();
  const [filteredGames, setFilteredGames] = useState<Game[]>([]);
  const [filterActive, setFilterActive] = useState(false);
  const [providers, setProviders] = useState<GameProvider[]>([]);
  const initialFilter = filterOverride ?? getGamesFilter();
  const [filter, setFilter] = useState<GamesFilter>(initialFilter ?? {});
  const [showIds, setShowIds] = useState(false);
  const [gamesLoading, setGamesLoading] = useState(true);
  const [providersLoading, setProvidersLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [sort, setSort] = useState<Sort>(
    getGamesSort() ?? {
      field: defaultSortField,
      order: defaultSortOrder,
    },
  );

  // set games when loaded/updated
  useEffect(() => {
    if (state.gamesLoaded) {
      setGames(getGames());
    }
  }, [state.gamesLoaded]);

  // filter games
  useEffect(() => {
    if (games && state.gameTagsLoaded) {
      setFilteredGames(filterGames(filter, games));
      setGamesLoading(false);
    }
  }, [filter, games, state.gameTagsLoaded]);

  // load providers
  useEffect(() => {
    if (providersLoading && state.gameProvidersLoaded) {
      const providers = getGameProviders().sort((p1, p2) => {
        return p1.name.localeCompare(p2.name);
      });
      setProviders(providers);
      setProvidersLoading(false);
    }
  }, [providersLoading, state.gameProvidersLoaded]);

  const gameSubCategories = useDefaultGameSubCategories();

  if (saving) {
    return <Loader message="Saving ..." />;
  }

  if (
    gamesLoading ||
    providersLoading ||
    !state.gameDescriptionsLoaded ||
    !state.gameCollectionsLoaded ||
    !state.bannersLoaded ||
    !state.promotionsLoaded ||
    !state.tournamentsLoaded
  ) {
    return <Loader message="Loading games ..." />;
  }

  async function remove(id: string): Promise<void> {
    const referencingBanners = getBanners(
      (banner) =>
        isGameBanner<unknown, firebase.firestore.DocumentReference>(banner) &&
        banner.game.id === id,
    );
    if (referencingBanners.length > 0) {
      const bannerString = referencingBanners
        .map((b) => `id=${b.id}`)
        .join(`, `);
      window.alert(
        `This game is referenced by the following banner${
          referencingBanners.length > 1 ? `s` : ``
        } [${bannerString}], and cannot be removed.`,
      );
      return;
    }
    const referencingPromotions = getPromotions().filter(
      (promotion) =>
        isGamePromotion<unknown, firebase.firestore.DocumentReference>(
          promotion,
        ) && promotion.game.id === id,
    );
    if (referencingPromotions.length > 0) {
      const promoString = referencingPromotions
        .map((p) => `id=${p.id}`)
        .join(`, `);
      window.alert(
        `This game is referenced by the following promotion${
          referencingPromotions.length > 1 ? `s` : ``
        } [${promoString}], and cannot be removed.`,
      );
      return;
    }
    const referencingTournaments = getTournaments().filter((tournament) =>
      tournament.games.map((g) => g.id).includes(id),
    );
    if (referencingTournaments.length > 0) {
      const tournamentString = referencingTournaments
        .map((t) => `id=${t.id}`)
        .join(`, `);
      window.alert(
        `This game is referenced by the following tournament${
          referencingTournaments.length > 1 ? `s` : ``
        } [${tournamentString}], and cannot be removed.`,
      );
      return;
    }
    const referencingCollections = getGameCollections().filter((collection) =>
      collection.games.map((g) => g.id).includes(id),
    );
    if (referencingCollections.length > 0) {
      const collectionString = referencingCollections
        .map((c) => `id=${c.id}`)
        .join(`, `);
      window.alert(
        `This game is referenced by the following collection${
          referencingCollections.length > 1 ? `s` : ``
        } [${collectionString}], and cannot be removed.`,
      );
    } else if (window.confirm(`Are you sure you want to remove this game?`)) {
      const url = `${config.api}/games/${id}/`;
      const res = await fetch(url, {
        method: `DELETE`,
        headers: defaultHeaders(state),
      });
      if (games && res.ok) {
        setGames(games.filter((g) => g.id !== id));
      }
    }
  }

  async function update(): Promise<void> {
    const invalidUpdate = Array.from(updates.entries()).find(
      ([id, data]) => !isValidCreateOrUpdate(getGame(id), data, true),
    );

    if (invalidUpdate) {
      window.alert(`One or more fields contain invalid data. Save aborted.`);
      return;
    }

    setSaving(true);
    setEditMode(false);

    for (const [id, data] of Array.from(updates.entries())) {
      const res = await fetch(`${config.api}/games/${id}`, {
        method: `PUT`,
        headers: defaultHeaders(state),
        body: JSON.stringify(data),
      });
      console.info(await res.text());
    }

    updates.clear();
    setSaving(false);
  }

  function isSortField(sortField: string): sortField is SortField {
    return Object.values(SortField).includes(sortField as SortField);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function sortFieldValues(field: string, g1: Game, g2: Game): any[] {
    switch (field) {
      case SortField.Name:
        return [g1.name.toLowerCase(), g2.name.toLowerCase()];
      case SortField.Category:
        return [g1.category, g2.category];
      case SortField.SubCategory:
        return [
          gameSubCategories.get(g1.subCategory),
          gameSubCategories.get(g2.subCategory),
        ];
      case SortField.Provider:
        return [
          g1.provider && getGameProvider(g1.provider.id)?.name,
          g2.provider && getGameProvider(g2.provider.id)?.name,
        ];
      case SortField.Path:
        return [g1.path.toLowerCase(), g2.path.toLowerCase()];
      case SortField.ReturnToPlayer:
        return [g1.rtp, g2.rtp];
      case SortField.ReturnToPlayerUk:
        return [g1.rtpUk, g2.rtpUk];
      case SortField.HitRate:
        return [g1.hitRate, g2.hitRate];
      case SortField.Volatility:
        return [g1.volatility, g2.volatility];
      case SortField.Released:
        return [g1.releaseYear, g2.releaseYear];
      case SortField.Wheels:
        return [g1.wheels, g2.wheels];
      case SortField.WinLines:
        return [g1.winLines, g2.winLines];
      case SortField.Disabled:
        return [g1.disabled, g2.disabled];
      case SortField.MaxExposure:
        return [g1.maxExposure, g2.maxExposure];
      case SortField.Wagering:
        return [g1.wageringContribution, g2.wageringContribution];
      case SortField.CommissionType:
        return [g1.providerCommissionType, g2.providerCommissionType];
      case SortField.Turnover:
        return [g1.turnover, g2.turnover];
      case SortField.Published:
        return [g1.published, g2.published];
      case SortField.Created:
        return [g1.created, g2.created];
      case SortField.Modified:
        return [g1.modified.date, g2.modified.date];
      case SortField.LastPlayed:
        return [g1.lastPlayed, g2.lastPlayed];
      default:
        throw new Error(`Unsupported sort field: ` + field);
    }
  }

  function getSortedGames(): Game[] {
    const sortField = isSortField(sort.field) ? sort.field : defaultSortField;
    return filteredGames.sort((g1, g2) => {
      const [val1, val2] = sortFieldValues(sortField, g1, g2);
      return compare(val1, val2, sort.order);
    });
  }

  function onSort(sort: Sort): void {
    setGamesSort(sort);
    setSort(sort);
  }

  return (
    <div>
      <ActionToolbarContext.Provider
        value={{ actionToolbarState, actionToolbarDispatch }}
      >
        <ActionToolbar name="Games">
          {editMode ? (
            <>
              <ActionToolbarItem
                type="button"
                icon={<Icon name="cancel" />}
                name="Cancel"
                onClick={() => {
                  setEditMode(false);
                  updates.clear();
                }}
              />
              <ActionToolbarItem
                type="button"
                icon={<Icon name="save" />}
                name="Save"
                onClick={() => update()}
              />
            </>
          ) : (
            <>
              <ActionToolbarItem
                type="link"
                icon={<Icon name="add" />}
                name="Add"
                to={`/games/add`}
              />
              <ActionToolbarItem
                type="button"
                icon={<Icon name="edit" />}
                name="Multi edit"
                onClick={() => {
                  actionToolbarDispatch({
                    type: ActionToolbarActionType.ShowExpanded,
                    component: undefined,
                  });
                  setEditMode(true);
                }}
              />

              {!filterOverride && (
                <ActionToolbarItem
                  type="button"
                  icon={<Icon name="filter" />}
                  name="Filter"
                  active={filterActive}
                  notification={!filterIsEmpty(filter)}
                  onClick={() => {
                    setFilterActive((value) => !value);
                    actionToolbarDispatch({
                      type: ActionToolbarActionType.ShowExpanded,
                      component: actionToolbarState.expanded ? undefined : (
                        <GamesFilterComp
                          filter={filter}
                          onChange={(filter: GamesFilter) => setFilter(filter)}
                        />
                      ),
                    });
                  }}
                />
              )}

              {!filterOverride && !actionToolbarState.expanded && (
                <GameSearchFilterComp
                  filter={filter}
                  onChange={(filter: GamesFilter) => setFilter(filter)}
                />
              )}
            </>
          )}
        </ActionToolbar>
      </ActionToolbarContext.Provider>
      <HorizontalScroll>
        <SortableTable
          sort={sort}
          onSort={onSort}
          columns={[
            { name: `Actions` },
            {
              name: `ID`,
              onShowToggle: () => setShowIds((value) => !value),
              show: showIds,
            },
            { name: `External ID's` },
            { name: `Languages` },
            { name: `Name`, sortField: editMode ? undefined : SortField.Name },
            {
              name: `Category`,
              sortField: editMode ? undefined : SortField.Category,
            },
            {
              name: `Sub category`,
              sortField: editMode ? undefined : SortField.SubCategory,
            },
            {
              name: `Provider`,
              sortField: editMode ? undefined : SortField.Provider,
            },
            { name: `Path`, sortField: editMode ? undefined : SortField.Path },
            {
              name: `RTP`,
              sortField: editMode ? undefined : SortField.ReturnToPlayer,
            },
            {
              name: `RTP UK`,
              sortField: editMode ? undefined : SortField.ReturnToPlayerUk,
            },
            {
              name: `Hit Rate`,
              sortField: editMode ? undefined : SortField.HitRate,
            },
            {
              name: `Volatility`,
              sortField: editMode ? undefined : SortField.Volatility,
            },
            {
              name: `Released`,
              sortField: editMode ? undefined : SortField.Released,
            },
            {
              name: `Wheels`,
              sortField: editMode ? undefined : SortField.Wheels,
            },
            {
              name: `Win Lines`,
              sortField: editMode ? undefined : SortField.WinLines,
            },
            {
              name: `Disabled`,
              sortField: editMode ? undefined : SortField.Disabled,
            },
            {
              name: `Max Exposure`,
              sortField: editMode ? undefined : SortField.MaxExposure,
            },
            {
              name: `Wagering`,
              sortField: editMode ? undefined : SortField.Wagering,
            },
            {
              name: `Commission Type`,
              sortField: editMode ? undefined : SortField.CommissionType,
            },
            {
              name: `Turnover (€)`,
              sortField: editMode ? undefined : SortField.Turnover,
            },
            {
              name: `Published`,
              sortField: editMode ? undefined : SortField.Published,
            },
            {
              name: `Created`,
              sortField: editMode ? undefined : SortField.Created,
            },
            {
              name: `Modified`,
              sortField: editMode ? undefined : SortField.Modified,
            },
            {
              name: `Last Played`,
              sortField: editMode ? undefined : SortField.LastPlayed,
            },
          ].splice(editMode ? 1 : 0)}
        >
          {getSortedGames().map((game, i) => {
            return (
              <GameComp
                key={i}
                game={game}
                gameSubCategories={gameSubCategories}
                editMode={editMode}
                providers={providers}
                showIds={showIds}
                onRemove={remove}
                onUpdate={(id, field) => {
                  let dto = updates.get(id);
                  if (!dto) {
                    dto = {};
                  }
                  dto = { ...dto, ...field };
                  updates.set(id, dto);
                }}
              />
            );
          })}
        </SortableTable>
      </HorizontalScroll>
    </div>
  );
};

export default Games;

interface Props {
  editMode: boolean;
  game: Game;
  gameSubCategories: Map<GameSubCategory, string>;
  providers: GameProvider[];
  showIds: boolean;
  onRemove: (id: string) => Promise<void>;
  onUpdate: (id: string, field: GameUpdateRequest) => void;
}

const GameComp: React.FC<Props> = (props) => {
  const [removing, setRemoving] = useState(false);
  const id = props.game.id;
  const game = props.game;
  const description = getGameDescription(id);

  function langs(): JSX.Element {
    if (!description) {
      return <></>;
    }

    return (
      <Languages
        existingLangs={getTranslatedLanguages(description.translations)}
        selectedClassName={styles.selected}
      />
    );
  }

  function external(): JSX.Element {
    return (
      <div>
        {game.external
          .sort((e1, e2) => {
            if (e1.active === e2.active) {
              return 0;
            }
            return e1.active ? -1 : 1;
          })
          .map((e, i) => (
            <span
              key={i}
              title={e.active ? `` : `Archived`}
              className={
                e.active ? styles.externalId : styles.externalIdInactive
              }
            >
              {e.id}
            </span>
          ))}
      </div>
    );
  }

  function name(): JSX.Element | string {
    if (props.editMode) {
      return (
        <input
          className={styles.requiredTextInput}
          required
          type="text"
          defaultValue={game.name}
          onChange={(event) => {
            props.onUpdate(id, { name: event.currentTarget.value });
          }}
        />
      );
    } else {
      return (
        <a
          href={`${config.site}/play/${game.path}`}
          title="Launch Game"
          target="_blank"
          rel="noopener noreferrer"
        >
          {game.name}
        </a>
      );
    }
  }

  function category(): JSX.Element | string {
    if (props.editMode) {
      return (
        <select
          onChange={(event) => {
            const value = event.currentTarget.value;
            if (!isGameCategory(value)) {
              throw new Error(`invalud GameCategory: ` + value);
            }
            props.onUpdate(id, { category: value });
          }}
          defaultValue={game.category}
        >
          {Array.from(gameCategories.entries()).map(([category, name], i) => (
            <option key={i} value={category}>
              {name}
            </option>
          ))}
        </select>
      );
    } else {
      return game.category;
    }
  }

  function subCategory(): JSX.Element | string | undefined {
    if (props.editMode) {
      return (
        <select
          onChange={(event) => {
            const value = event.currentTarget.value;
            if (!isGameSubCategory(value)) {
              throw new Error(`invalud GameSubCategory: ` + value);
            }
            props.onUpdate(id, { subCategory: value });
          }}
          defaultValue={game.subCategory}
        >
          {Array.from(props.gameSubCategories.entries()).map(
            ([category, name], i) => (
              <option key={i} value={category}>
                {name}
              </option>
            ),
          )}
        </select>
      );
    } else {
      return props.gameSubCategories.get(game.subCategory);
    }
  }

  function provider(): JSX.Element {
    if (props.editMode) {
      return (
        <select
          defaultValue={game ? game.provider?.id : undefined}
          onChange={(event) => {
            const provider = event.currentTarget.value;
            props.onUpdate(id, { provider: provider });
          }}
        >
          {props.providers.map((provider, i) => {
            return (
              <option key={i} value={provider.id}>
                {provider.name}
              </option>
            );
          })}
        </select>
      );
    } else {
      if (!game.provider) {
        return <></>;
      }
      return (
        <NavLink to={`/game_providers/${game.provider.id}`}>
          {getGameProvider(game.provider.id)?.name ?? ``}
        </NavLink>
      );
    }
  }

  function path(): JSX.Element | string {
    if (props.editMode) {
      return (
        <input
          type="text"
          defaultValue={game.path}
          onChange={(event) => {
            props.onUpdate(id, { path: event.currentTarget.value });
          }}
        />
      );
    } else {
      return game.path;
    }
  }

  function rtp(): JSX.Element | string | undefined {
    if (props.editMode) {
      return (
        <input
          type="text"
          defaultValue={game.rtp}
          style={{ width: `5rem` }}
          onChange={(event) => {
            props.onUpdate(id, { rtp: event.currentTarget.value });
          }}
        />
      );
    } else {
      return game.rtp;
    }
  }

  function rtpUk(): JSX.Element | string | undefined {
    if (props.editMode) {
      return (
        <input
          type="text"
          defaultValue={game.rtpUk}
          style={{ width: `5rem` }}
          onChange={(event) => {
            props.onUpdate(id, { rtpUk: event.currentTarget.value });
          }}
        />
      );
    } else {
      return game.rtpUk;
    }
  }

  function hitRate(): JSX.Element | string | undefined {
    if (props.editMode) {
      return (
        <input
          type="text"
          defaultValue={game.hitRate}
          pattern="((0(\.[0-9]+)?)|(1(\.0+)?))"
          onChange={(event) => {
            const value = event.currentTarget.value.trim();
            if (!value) {
              props.onUpdate(id, { hitRate: 0 });
            } else if (event.currentTarget.validity.valid) {
              props.onUpdate(id, { hitRate: parseFloat(value) });
            }
          }}
        />
      );
    } else {
      return game.hitRate ? toPercent(game.hitRate, 2) : undefined;
    }
  }

  function volatility(): JSX.Element | string {
    if (props.editMode) {
      return (
        <select
          onChange={(event) => {
            props.onUpdate(id, { volatility: event.currentTarget.value });
          }}
          defaultValue={game.volatility}
        >
          <option value="" />
          {Array.from(gameVolatilities.entries()).map(
            ([volatility, name], i) => (
              <option key={i} value={volatility}>
                {name}
              </option>
            ),
          )}
        </select>
      );
    } else {
      if (!game.volatility) {
        return ``;
      } else if (gameVolatilities.has(game.volatility)) {
        return gameVolatilities.get(game.volatility)!;
      } else {
        return `'` + game.volatility + `'`;
      }
    }
  }

  function releaseYear(): JSX.Element | string | undefined {
    if (props.editMode) {
      return (
        <input
          type="text"
          defaultValue={game.releaseYear}
          style={{ width: `5rem` }}
          onChange={(event) => {
            props.onUpdate(id, { releaseYear: event.currentTarget.value });
          }}
        />
      );
    } else {
      return game.releaseYear;
    }
  }

  function wheels(): JSX.Element | string | undefined {
    if (props.editMode) {
      return (
        <input
          type="text"
          defaultValue={game.wheels}
          style={{ width: `10rem` }}
          onChange={(event) => {
            props.onUpdate(id, { wheels: event.currentTarget.value });
          }}
        />
      );
    } else {
      return game.wheels;
    }
  }

  function winLines(): JSX.Element | string {
    if (props.editMode) {
      return (
        <input
          type="number"
          defaultValue={game.winLines}
          style={{ width: `5rem` }}
          onChange={(event) => {
            const value = event.currentTarget.value.trim();
            if (!value) {
              props.onUpdate(id, { winLines: 0 });
            } else if (event.currentTarget.validity.valid) {
              props.onUpdate(id, { winLines: parseInt(value) });
            }
          }}
        />
      );
    } else {
      return game.winLines?.toString() ?? ``;
    }
  }

  function disabled(): JSX.Element | string {
    if (props.editMode) {
      return (
        <input
          type="checkbox"
          defaultChecked={game.disabled}
          onChange={(event) => {
            props.onUpdate(id, {
              disabled: event.currentTarget.checked,
            });
          }}
        />
      );
    } else {
      return game.disabled ? `✓` : ``;
    }
  }

  function maxExposure(): JSX.Element | string {
    if (props.editMode) {
      return (
        <input
          type="text"
          defaultValue={game.maxExposure}
          pattern="((0(\.[0-9]+)?)|([1-9]([0-9]+)?(\.[0-9]+)?))"
          onChange={(event) => {
            const value = event.currentTarget.value.trim();
            if (!value) {
              props.onUpdate(id, { maxExposure: 0 });
            } else if (event.currentTarget.validity.valid) {
              props.onUpdate(id, { maxExposure: parseFloat(value) });
            }
          }}
        />
      );
    } else {
      return game.maxExposure
        ? `${game.maxExposure}${game.maxExposureAsCoins ? ` Coins` : `X`}`
        : ``;
    }
  }

  function wagering(): JSX.Element | string {
    if (props.editMode) {
      return (
        <select
          onChange={(event) => {
            const value = event.currentTarget.value;
            if (value) {
              const wc = parseInt(value);
              if (!isGameWageringContribution(wc)) {
                throw new Error(`invalid GameWageringContribution: ` + value);
              }
              props.onUpdate(id, { wageringContribution: wc });
            } else {
              props.onUpdate(id, { wageringContribution: `removed` });
            }
          }}
          defaultValue={game.wageringContribution}
        >
          <option key={0} value="" />
          {gameWageringContributions.map((wc, i) => (
            <option key={i + 1} value={wc}>
              {wc}%
            </option>
          ))}
        </select>
      );
    } else {
      return game.wageringContribution === undefined
        ? ``
        : `${game.wageringContribution}%`;
    }
  }

  function commissionType(): JSX.Element | string {
    if (props.editMode) {
      return (
        <select
          onChange={(event) => {
            const value = event.currentTarget.value;
            if (value) {
              if (!isGameProviderCommissionType(value)) {
                throw new Error(`invalid GameProviderCommissionType: ` + value);
              }
              props.onUpdate(id, { providerCommissionType: value });
            } else {
              props.onUpdate(id, { providerCommissionType: `removed` });
            }
          }}
          defaultValue={game.providerCommissionType}
        >
          <option key={0} value="" />
          {gameProviderCommissionTypes.map((type, i) => (
            <option key={i + 1} value={type}>
              {type} - {getProviderCommissionFromType(type)}%
            </option>
          ))}
        </select>
      );
    } else {
      const type = game.providerCommissionType;
      return type === undefined
        ? ``
        : `${type} - ${getProviderCommissionFromType(type)}%`;
    }
  }

  function dateTimeString(date: Date): string {
    return `${date.toLocaleDateString(`sv`)} ${date.toLocaleTimeString(`sv`)}`;
  }

  async function remove(): Promise<void> {
    setRemoving(true);
    await props.onRemove(id);
    setRemoving(false);
  }

  const editStyle: React.CSSProperties = props.editMode
    ? { backgroundColor: `white` }
    : {};

  const published = game.published.toDate();
  const created = game.created.toDate();
  const modified = game.modified.date.toDate();
  const lastPlayed = game.lastPlayed?.toDate();

  return (
    <tr>
      {!props.editMode && (
        <td>
          <ActionRow>
            <ActionRowItem iconName="edit" to={`/games/${id}`} title="Edit" />
            <ActionRowItem
              iconName="copy"
              to={`/games/${id}/clone`}
              title="Clone"
            />
            <ActionRowItem
              iconName="history"
              to={`/game_history/${id}`}
              title="History"
            />
            <ActionRowItem
              iconName="delete"
              onClick={() => (removing ? null : remove())}
              title="Delete"
            />
          </ActionRow>
        </td>
      )}
      <td className={styles.id}>{props.showIds && id}</td>
      <td style={{ whiteSpace: `nowrap` }} className={styles.center}>
        {external()}
      </td>
      <td className={styles.languages}>{langs()}</td>
      <td style={editStyle}>{name()}</td>
      <td style={editStyle}>{category()}</td>
      <td style={editStyle}>{subCategory()}</td>
      <td style={editStyle}>{provider()}</td>
      <td style={editStyle}>{path()}</td>
      <td style={editStyle}>{rtp()}</td>
      <td style={editStyle}>{rtpUk()}</td>
      <td style={editStyle} className={styles.center}>
        {hitRate()}
      </td>
      <td style={editStyle} className={styles.center}>
        {volatility()}
      </td>
      <td style={editStyle} className={styles.center}>
        {releaseYear()}
      </td>
      <td style={editStyle} className={styles.center}>
        {wheels()}
      </td>
      <td style={editStyle} className={styles.center}>
        {winLines()}
      </td>
      <td style={editStyle} className={styles.checked}>
        {disabled()}
      </td>
      <td style={editStyle} className={styles.center}>
        {maxExposure()}
      </td>
      <td style={editStyle} className={styles.center}>
        {wagering()}
      </td>
      <td style={editStyle} className={styles.center}>
        {commissionType()}
      </td>
      <td className={styles.center}>{game.turnover}</td>
      <td title={dateTimeString(published)} style={{ whiteSpace: `nowrap` }}>
        {published.toLocaleDateString(`sv`)}
      </td>
      <td title={dateTimeString(created)} style={{ whiteSpace: `nowrap` }}>
        {created.toLocaleDateString(`sv`)}
      </td>
      <td title={dateTimeString(modified)} style={{ whiteSpace: `nowrap` }}>
        {modified.toLocaleDateString(`sv`)}
      </td>
      <td
        title={lastPlayed ? dateTimeString(lastPlayed) : ``}
        style={{ whiteSpace: `nowrap` }}
      >
        {lastPlayed?.toLocaleDateString(`sv`)}
      </td>
    </tr>
  );
};
