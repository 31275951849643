// import * as core from "@vipscasino/core";
import { GameProviderUpdateRequest } from "@vipscasino/core";
import React, { memo, useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import ActionToolbar from "../../component/ActionToolbar";
import ActionToolbarItem from "../../component/ActionToolbarItem";
import HorizontalScroll from "../../component/HorizontalScroll";
import Icon from "../../component/Icon";
import Loader from "../../component/Loader";
import SortableTable from "../../component/SortableTable";
import { config } from "../../config";
import { compare, defaultSortOrder, Sort } from "../../lib/sort";
import { getGameProvidersSort, setGameProvidersSort } from "../../lib/storage";
import { defaultHeaders } from "../../lib/util/httpUtil";
import styles from "./GameProviders.module.scss";
import { GameProvider, getGameProviders } from "./gameProviderService";

class ProviderUpdate {
  public readonly updated: GameProviderUpdateRequest[] = [];

  public update(field: GameProviderUpdateRequest): void {
    this.updated.push(field);
  }

  public toJson(): string {
    let updatedObj: GameProviderUpdateRequest = {};
    this.updated.forEach((obj) => {
      updatedObj = { ...updatedObj, ...obj };
    });
    return JSON.stringify({
      updated: updatedObj,
    });
  }
}

const updates: Map<string, ProviderUpdate> = new Map();

enum SortField {
  Name = `Name`,
  Path = `Path`,
  CreateCollection = `CreateCollection`,
  Disabled = `Disabled`,
  Created = `Created`,
  Modified = `Modified`,
}

const defaultSortField = SortField.Modified;

const GameProviders: React.FC = () => {
  const { state } = useContext(AppContext);
  const [providers, setProviders] = useState<GameProvider[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [showIds, setShowIds] = useState(false);
  const [sort, setSort] = useState<Sort>(
    getGameProvidersSort() ?? {
      field: defaultSortField,
      order: defaultSortOrder,
    },
  );

  // clear map on unmount
  useEffect(() => {
    return () => updates.clear();
  }, []);

  // set providers when loaded/updated
  useEffect(() => {
    if (state.gameProvidersLoaded) {
      setProviders(getGameProviders());
      setLoading(false);
    }
  }, [state.gameProvidersLoaded]);

  if (loading) {
    return <Loader message="Loading game providers ..." />;
  }

  if (saving) {
    return <Loader message="Saving ..." />;
  }

  function cancel(): void {
    updates.clear();
    setEditMode(false);
  }

  async function update(): Promise<void> {
    console.info(updates);
    setSaving(true);
    setEditMode(false);

    for (const [id, data] of Array.from(updates.entries())) {
      const res = await fetch(`${config.api}/game_providers/${id}/`, {
        method: `PUT`,
        headers: defaultHeaders(state),
        body: data.toJson(),
      });
      console.info(await res.text());
    }
    updates.clear();
    setSaving(false);
  }

  async function create(
    name: string,
    path: string,
    createCollection: boolean,
  ): Promise<void> {
    setSaving(true);
    setAddMode(false);

    const body: GameProviderUpdateRequest = {
      name,
      path,
      createCollection,
    };
    const res = await fetch(`${config.api}/game_providers/`, {
      method: `POST`,
      headers: defaultHeaders(state),
      body: JSON.stringify(body),
    });
    console.info(await res.text());
    setSaving(false);
  }

  function isSortField(sortField: string): sortField is SortField {
    return Object.values(SortField).includes(sortField as SortField);
  }

  function sortFieldValues(
    field: string,
    p1: GameProvider,
    p2: GameProvider,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any[] {
    switch (field) {
      case SortField.Name:
        return [p1.name.toLowerCase(), p2.name.toLowerCase()];
      case SortField.Path:
        return [p1.path, p2.path];
      case SortField.CreateCollection:
        return [p1.createCollection, p2.createCollection];
      case SortField.Disabled:
        return [p1.disabled, p2.disabled];
      case SortField.Created:
        return [p1.created, p2.created];
      case SortField.Modified:
        return [p1.modified.date, p2.modified.date];
      default:
        throw new Error(`Unsupported sort field: ` + field);
    }
  }

  function getSortedProviders(): GameProvider[] {
    const sortField = isSortField(sort.field) ? sort.field : defaultSortField;
    return providers.sort((p1, p2) => {
      const [val1, val2] = sortFieldValues(sortField, p1, p2);
      return compare(val1, val2, sort.order);
    });
  }

  function onSort(sort: Sort): void {
    setGameProvidersSort(sort);
    setSort(sort);
  }

  return (
    <div className={styles.main}>
      <ActionToolbar name="Providers">
        {!addMode && (
          <>
            {editMode ? (
              <>
                <ActionToolbarItem
                  type="button"
                  icon={<Icon name="cancel" />}
                  name="Cancel"
                  onClick={() => cancel()}
                />
                <ActionToolbarItem
                  type="button"
                  icon={<Icon name="save" />}
                  name="Save"
                  onClick={() => update()}
                />
              </>
            ) : (
              <>
                <ActionToolbarItem
                  type="button"
                  icon={<Icon name="edit" />}
                  name="Edit"
                  onClick={() => setEditMode(true)}
                />
                <ActionToolbarItem
                  type="button"
                  icon={<Icon name="add" />}
                  name="Add"
                  onClick={() => setAddMode(true)}
                />
              </>
            )}
          </>
        )}
      </ActionToolbar>

      <HorizontalScroll>
        <SortableTable
          sort={sort}
          onSort={onSort}
          columns={[
            {
              name: `ID`,
              onShowToggle: () => setShowIds((value) => !value),
              show: showIds,
            },
            {
              name: `Name`,
              sortField: editMode || addMode ? undefined : SortField.Name,
            },
            {
              name: `Path`,
              sortField: editMode || addMode ? undefined : SortField.Path,
            },
            {
              name: `Create collection`,
              sortField:
                editMode || addMode ? undefined : SortField.CreateCollection,
            },
            {
              name: `Disabled`,
              sortField: editMode || addMode ? undefined : SortField.Disabled,
            },
            {
              name: `Created`,
              sortField: editMode || addMode ? undefined : SortField.Created,
            },
            {
              name: `Modified`,
              colSpan: 2,
              sortField: editMode || addMode ? undefined : SortField.Modified,
            },
          ]}
        >
          {addMode ? (
            <AddProvider
              onCancel={() => setAddMode(false)}
              onCreate={(name, path, createCollection) => {
                create(name, path, createCollection);
              }}
            />
          ) : (
            getSortedProviders().map((provider, i) => {
              return (
                <Provider
                  key={i}
                  showIds={showIds}
                  provider={provider}
                  editMode={editMode}
                  onUpdate={(id, field) => {
                    let gu = updates.get(id);
                    if (!gu) {
                      gu = new ProviderUpdate();
                      updates.set(id, gu);
                    }
                    gu.update(field);
                  }}
                />
              );
            })
          )}
        </SortableTable>
      </HorizontalScroll>
    </div>
  );
};

export default GameProviders;

interface Props {
  showIds: boolean;
  editMode: boolean;
  provider: GameProvider;
  onUpdate: (id: string, field: GameProviderUpdateRequest) => void;
}

const Provider = memo((props: Props) => {
  const provider = props.provider;
  const id = provider.id;

  function name(): JSX.Element | string {
    if (props.editMode) {
      return (
        <input
          type="text"
          defaultValue={provider.name}
          onChange={(event) => {
            props.onUpdate(id, { name: event.currentTarget.value });
          }}
        />
      );
    } else {
      return provider.name;
    }
  }

  function path(): JSX.Element | string {
    if (props.editMode) {
      return (
        <input
          type="text"
          defaultValue={provider.path}
          onChange={(event) => {
            props.onUpdate(id, { path: event.currentTarget.value });
          }}
        />
      );
    } else {
      return provider.path;
    }
  }

  function createCollection(): JSX.Element | string {
    if (props.editMode) {
      return (
        <input
          type="checkbox"
          defaultChecked={provider.createCollection}
          onChange={(event) => {
            props.onUpdate(id, {
              createCollection: event.currentTarget.checked,
            });
          }}
        />
      );
    } else {
      return provider.createCollection ? `✓` : ``;
    }
  }

  function disabled(): JSX.Element | string {
    if (props.editMode) {
      return (
        <input
          type="checkbox"
          defaultChecked={provider.disabled}
          onChange={(event) => {
            props.onUpdate(id, {
              disabled: event.currentTarget.checked,
            });
          }}
        />
      );
    } else {
      return provider.disabled ? `✓` : ``;
    }
  }

  const editStyle: React.CSSProperties = props.editMode
    ? { backgroundColor: `white` }
    : {};

  const created = provider.created.toDate() as Date;
  return (
    <tr>
      <td className={styles.id}>{props.showIds && id}</td>
      <td style={editStyle}>{name()}</td>
      <td style={editStyle}>{path()}</td>
      <td style={editStyle} className={styles.checked}>
        {createCollection()}
      </td>
      <td style={editStyle} className={styles.checked}>
        {disabled()}
      </td>
      <td
        title={`${created.toLocaleDateString(
          `sv`,
        )} ${created.toLocaleTimeString(`sv`)}`}
        style={{ whiteSpace: `nowrap` }}
      >
        {created.toLocaleDateString(`sv`)}
      </td>
      {provider.modified ? (
        <>
          <td>{provider.modified.user}</td>
          <td
            title={`${provider.modified.date
              .toDate()
              .toLocaleDateString(
                `sv`,
              )} ${provider.modified.date.toDate().toLocaleTimeString(`sv`)}`}
            style={{ whiteSpace: `nowrap` }}
          >
            {provider.modified.date.toDate().toLocaleDateString(`sv`)}
          </td>
        </>
      ) : (
        <td></td>
      )}
    </tr>
  );
});

interface AddProviderProps {
  onCreate: (name: string, path: string, createCollection: boolean) => void;
  onCancel: () => void;
}

const AddProvider: React.FC<AddProviderProps> = (props) => {
  const [name, setName] = useState(``);
  const [path, setPath] = useState(``);
  const [createCollection, setCreateCollection] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  useEffect(() => {
    let valid = true;
    if (name.trim().length === 0) {
      valid = false;
    }
    if (path.trim().length === 0) {
      valid = false;
    }
    setValidated(valid);
  }, [name, path]);

  return (
    <>
      <tr>
        <td className={styles.id}>[generated]</td>
        <td style={{ backgroundColor: `white` }}>
          <input
            type="text"
            onChange={(event) => {
              setName(event.currentTarget.value);
            }}
          />
        </td>
        <td style={{ backgroundColor: `white` }}>
          <input
            type="text"
            onChange={(event) => {
              setPath(event.currentTarget.value);
            }}
          />
        </td>
        <td className={styles.center} style={{ backgroundColor: `white` }}>
          <input
            type="checkbox"
            onChange={(event) => {
              setCreateCollection(event.currentTarget.checked);
            }}
          />
        </td>
        <td className={styles.id}>[generated]</td>
        <td className={styles.id}>[generated]</td>
      </tr>
      <tr>
        <td colSpan={6}>
          <div className={styles.buttons}>
            <button
              disabled={!validated}
              className={styles.button}
              onClick={() => {
                props.onCreate(name, path, createCollection);
              }}
            >
              Create
            </button>
            <button className={styles.button} onClick={() => props.onCancel()}>
              Cancel
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};
