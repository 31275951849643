import React, { useRef, useState } from "react";
import styles from "./Dropzone.module.scss";
import Icon from "./Icon";

interface Props {
  disabled: boolean;
  onFilesAdded: (files: File[]) => void;
}

const Dropzone: React.FC<Props> = (props) => {
  const [highlight, setHighlight] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  function openFileDialog(): void {
    if (!props.disabled && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  function onDragOver(): void {
    if (!props.disabled) {
      setHighlight(true);
    }
  }

  function onDragLeave(): void {
    setHighlight(false);
  }

  function addFiles(files: FileList): void {
    if (!props.disabled && props.onFilesAdded) {
      props.onFilesAdded(fileListToArray(files));
    }
  }

  function onFilesAdded(files: FileList): void {
    addFiles(files);
  }

  function onDrop(files: FileList): void {
    addFiles(files);
    setHighlight(false);
  }

  function fileListToArray(list: FileList): File[] {
    const array: File[] = [];
    for (let i = 0; i < list.length; i++) {
      const file = list.item(i);
      if (file) {
        array.push(file);
      }
    }
    return array;
  }

  return (
    <div
      className={`${styles.dropzone} ${highlight ? styles.highlight : ``}`}
      onDragOver={(event) => {
        event.preventDefault();
        onDragOver();
      }}
      onDragLeave={onDragLeave}
      onDrop={(event) => {
        event.preventDefault();
        const fileList = event.dataTransfer.files;
        onDrop(fileList);
      }}
      onClick={openFileDialog}
      style={{ cursor: props.disabled ? `default` : `pointer` }}
    >
      <input
        ref={fileInputRef}
        className={styles.fileInput}
        type="file"
        multiple
        onChange={(event) => {
          const fileList = event.target.files;
          if (fileList) {
            onFilesAdded(fileList);
          }
        }}
      />
      <Icon name="upload" className={styles.icon} />
      <span className={styles.info}>
        Drop files here, or click to select files for upload
      </span>
    </div>
  );
};

export default Dropzone;
