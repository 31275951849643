import {
  Brand,
  Country,
  getTranslatedCountries,
  getTranslatedLanguages,
  Language,
  Tournament as FirestoreTournament,
  tournamentCollection,
  TournamentTranslation,
} from "@vipscasino/core";
import firebase from "firebase/app";
import { firebaseApp } from "../lib/firebase";
import * as index from "./tournamentIndex";

const db = firebaseApp.firestore();

export interface Tournament
  extends FirestoreTournament<
    firebase.firestore.Timestamp,
    firebase.firestore.DocumentReference
  > {
  id: string;
}

const tournaments = new Map<string, Tournament>();
const paths = new Map<string, string>();
let cancelSubscription: () => void;

export function loadTournaments(): void {
  if (cancelSubscription) {
    cancelSubscription();
  }

  cancelSubscription = db
    .collection(tournamentCollection)
    .where(`removed`, `==`, null)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data();
        const tournament = { ...data, id: doc.id } as Tournament;
        if (change.type === `removed`) {
          tournaments.delete(tournament.id);
          paths.delete(pathKey(tournament.brand, tournament.path));
        } else {
          const oldTournament = tournaments.get(tournament.id);
          if (oldTournament) {
            paths.delete(pathKey(oldTournament.brand, oldTournament.path));
          }
          tournaments.set(tournament.id, tournament);
          paths.set(pathKey(tournament.brand, tournament.path), tournament.id);
        }
      });
      indexTournaments();
      window.dispatchEvent(new Event(`tournaments-loaded`));
    });
}

function pathKey(brand: Brand, path: string): string {
  return `${brand}_${path.trim()}`;
}

function indexTournaments(): void {
  const docs = Array.from(tournaments.values()).map((tournament) => {
    const title = getTranslatedCountries(tournament.translations)
      .flatMap((country) =>
        getTranslatedLanguages(tournament.translations[country]!).map(
          (lang) => tournament.translations[country]![lang]!.title,
        ),
      )
      .join(` `);
    const doc: index.TournamentDoc = {
      id: tournament.id,
      title: title,
      path: tournament.path,
    };
    return doc;
  });

  index.indexTournaments(docs);
}

export function getTournament(tournamentId: string): Tournament | undefined {
  return tournaments.get(tournamentId);
}

export function getTournaments(): Tournament[] {
  return Array.from(tournaments.values());
}

export function searchTournaments(
  query: string,
  limit?: number,
  excludedIds?: string[],
): Tournament[] {
  let results = index.searchTournaments(query);
  if (!results || results.length === 0) {
    return [];
  }
  if (excludedIds) {
    results = results.filter((result) => !excludedIds.includes(result.ref));
  }
  if (limit) {
    results = results.splice(0, limit);
  }

  return results
    .map((result) => {
      const tournamentId = result.ref;
      const tournament = tournaments.get(tournamentId);
      if (tournament) {
        return tournament;
      }
      return undefined;
    })
    .filter((tournament) => tournament !== undefined) as Tournament[];
}

export function pathExists(
  path: string,
  brand: Brand,
  excludedTournament?: Tournament,
): boolean {
  if (excludedTournament) {
    if (
      excludedTournament.brand === brand &&
      excludedTournament.path.trim() === path.trim()
    ) {
      return false;
    }
  }
  return paths.has(pathKey(brand, path));
}

export function getTournamentTranslation(
  tournament: Tournament,
  preferredLang: Language | undefined,
): TournamentTranslation | undefined {
  const translations = tournament.translations;
  const countries = getTranslatedCountries(translations);

  if (countries.length === 0) {
    return undefined;
  }

  let preferredCountry: Country | undefined;
  if (preferredLang) {
    preferredCountry = countries.find((c) => !!translations[c]![preferredLang]);
  }
  if (preferredCountry && preferredLang) {
    return translations[preferredCountry]![preferredLang];
  } else {
    const t = translations[countries[0]]!;
    return t[getTranslatedLanguages(t)[0]];
  }
}

export function isTournamentPublished(tournament: Tournament): boolean {
  const now = Date.now();
  return (
    tournament.publishedFrom.toMillis() <= now &&
    tournament.publishedTo.toMillis() >= now &&
    getTranslatedCountries(tournament.translations).length > 0
  );
}

export function isTournamentLive(tournament: Tournament): boolean {
  const now = Date.now();
  return (
    tournament.activeFrom.toMillis() <= now &&
    tournament.activeTo.toMillis() >= now &&
    getTranslatedCountries(tournament.translations).length > 0
  );
}

export function isTournamentUpcoming(tournament: Tournament): boolean {
  return (
    isTournamentPublished(tournament) &&
    tournament.activeFrom.toMillis() > Date.now()
  );
}
