import {
  BannerType,
  Brand,
  isDepositBanner,
  isGameBanner,
  isLinkBanner,
  isPromotionBanner,
  isRegistrationBanner,
  isTournamentBanner,
} from "@vipscasino/core";
import { Banner, isBannerLive, isBannerUpcoming } from "./bannerService";

export interface BannersFilter {
  active?: boolean;
  live?: boolean;
  upcoming?: boolean;
  brand?: Brand;
  type?: BannerType;
  validFrom?: number;
  validTo?: number;
  createdStart?: number;
  createdEnd?: number;
}

export function filterBanners(
  filter: BannersFilter,
  banners: Banner[],
): Banner[] {
  let result = [...banners];
  if (filter.active) {
    result = result.filter((banner) => {
      return banner.active;
    });
  }
  if (filter.live) {
    result = result.filter((banner) => isBannerLive(banner));
  }
  if (filter.upcoming) {
    result = result.filter((banner) => isBannerUpcoming(banner));
  }
  if (filter.brand) {
    result = result.filter((banner) => banner.brand === filter.brand);
  }
  if (filter.type) {
    const type = filter.type;
    result = result.filter((banner) => {
      return bannerIsOfType(banner, type);
    });
  }
  if (filter.validFrom) {
    const validFrom = filter.validFrom;
    result = result.filter((banner) => {
      return banner.validFrom.toMillis() <= validFrom;
    });
  }
  if (filter.validTo) {
    const validTo = filter.validTo;
    result = result.filter((banner) => {
      return banner.validTo.toMillis() >= validTo;
    });
  }
  if (filter.createdStart) {
    const createdStart = filter.createdStart;
    result = result.filter((banner) => {
      return banner.created.toMillis() >= createdStart;
    });
  }
  if (filter.createdEnd) {
    const createdEnd = filter.createdEnd;
    result = result.filter((banner) => {
      return banner.created.toMillis() <= createdEnd;
    });
  }
  return result;
}

function bannerIsOfType(banner: Banner, type: BannerType): boolean {
  switch (type) {
    case `Game`:
      return isGameBanner(banner);
    case `Promotion`:
      return isPromotionBanner(banner);
    case `Tournament`:
      return isTournamentBanner(banner);
    case `Link`:
      return isLinkBanner(banner);
    case `Registration`:
      return isRegistrationBanner(banner);
    case `Deposit`:
      return isDepositBanner(banner);
    case `Image`:
      return !(
        isGameBanner(banner) ||
        isPromotionBanner(banner) ||
        isTournamentBanner(banner) ||
        isLinkBanner(banner) ||
        isRegistrationBanner(banner) ||
        isDepositBanner(banner)
      );
  }
}

export function filterIsEmpty(filter: BannersFilter): boolean {
  return Object.values(filter).every((v) => v === undefined);
}
