import React from "react";
import styles from "./FilterFormButton.module.scss";
import FilterFormItem from "./FilterFormItem";

interface Props {
  label: string;
  type: "button" | "reset";
  onClick: () => void;
}

const FilterFormButton: React.FC<Props> = (props) => {
  return (
    <FilterFormItem label="">
      <button
        className={styles.button}
        type={props.type}
        onClick={props.onClick}
      >
        {props.label}
      </button>
    </FilterFormItem>
  );
};

export default FilterFormButton;
